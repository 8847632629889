import { useMemo } from 'react';

import { useIsQuestionsOnlyMode, useIsStorylineAdminEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useStorylineAdminPageSettings: UseMainUIFeatureSettings<'storylineAdminPage'> = (options) => {
  const storylineAdminPageOverrides = options?.overrides?.storylineAdminPage;

  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();
  const isStorylineAdminEnabled = useIsStorylineAdminEnabled();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        ...storylineAdminPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: isStorylineAdminEnabled,
      ...storylineAdminPageOverrides,
    };
  }, [storylineAdminPageOverrides, isStorylineAdminEnabled, isQuestionsOnlyMode]);
};
