export enum AppLayoutBreakpoint {
  /**
   * Extra small AppLayout breakpoint.
   */
  Width375 = 375,

  /**
   * Small AppLayout breakpoint.
   */
  Width568 = 568,

  /**
   * Medium AppLayout breakpoint.
   */
  Width812 = 812,

  /**
   * Teams content column max-width.
   */
  Width910 = 910,

  /**
   * Large AppLayout breakpoint.
   */
  Width1032 = 1032,

  /**
   * Extra Large AppLayout breakpoint.
   */
  Width1132 = 1132,

  /**
   * Back Button medium floating breakpoint.
   */
  Width1185 = 1185,

  /*
   * New Left Nav Phase 1 limit for small width
   */

  Width1292 = 1292,

  /**
   * Back Button large floating breakpoint.
   */
  Width1350 = 1350,

  /*
   * New Left Nav Phase 1 limit for default width
   */

  Width1352 = 1352,

  /**
   * Left Nav expansion breakpoint.
   */
  Width1416 = 1416,

  /**
   * Back Button extra large floating breakpoint.
   */
  Width1600 = 1600,
}

export enum LegacyPageLayoutBreakpoint {
  /**
   * Medium legacy PageLayout breakpoint.
   */
  Width1044 = 1044,

  /**
   * Large legacy PageLayout breakpoint.
   */
  Width1304 = 1304,
}

export enum FeatureBreakpoint {
  /**
   * Narrow ContentColumn breakpoint.
   */
  Width370 = 370,

  /**
   * Many features are using this. We should check with
   * Design and determine if this is the correct breakpoint to use.
   */
  Width438 = 438,

  /**
   * Medium ContentColumn, Narrow ModalScreen breakpoint.
   */
  Width500 = 500,

  /**
   * A few features are using this. We should check with
   * Design and determine if this is the correct breakpoint to use.
   */
  Width580 = 580,

  /**
   * Wide ContentColumn breakpoint.
   */
  Width596 = 596,

  /**
   * Many features are using this. We should check with
   * Design and determine if this is the correct breakpoint to use.
   */
  Width638 = 638,

  /**
   *  Narrow Metadata breakpoint.
   */
  Width680 = 680,

  /**
   * Content area max-width when a content sidebar is rendered next to it.
   * Small legacy PageLayout, Narrow PaneLayout breakpoint.
   */
  Width700 = 700,

  /**
   * Used by OutlookPublisher.tsx, unclear why this specific breakpoint is necessary.
   */
  Width822 = 822,

  /**
   * Narrow ScreenWidth breakpoint.
   */
  Width855 = 855,

  /**
   * Used by Viva Topic feed shimmer for wide SharePoint views. Maybe
   * we should check with Design to determine if this correct usage?
   */
  Width960 = 960,
}

export enum ContentHeaderBreakpoint {
  /**
   * Larger breakpoint for some ContentHeader layouts.
   */
  Width520 = 520,

  /**
   * ContentHeader expansion breakpoint.
   */
  Width480 = 480,
}

export type Breakpoint = AppLayoutBreakpoint | LegacyPageLayoutBreakpoint | FeatureBreakpoint | ContentHeaderBreakpoint;
