import { getStatus } from '@ms/yammer-data-support';

import { NormalizedPushSubscriptionServerKey } from '../../normalization/graphql/normalizePushSubscriptionServerKey.types';
import { YammerState } from '../../state/types';

type IsNotRequested = (state: YammerState) => boolean;
export const isNotRequested: IsNotRequested = (state) => getStatus(state.pushSubscriptionServerKey) === 'notRequested';

type IsApplicationServerKeyLoaded = (state: YammerState) => boolean;
export const isApplicationServerKeyLoaded: IsApplicationServerKeyLoaded = (state) =>
  !!state.pushSubscriptionServerKey.fulfilled;

type GetPushSubscriptionServerKey = (
  state: YammerState
) => NormalizedPushSubscriptionServerKey['applicationServerKey'] | undefined;
export const getPushSubscriptionServerKey: GetPushSubscriptionServerKey = (state) =>
  state.pushSubscriptionServerKey.fulfilled?.applicationServerKey;
