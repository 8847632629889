import { setCurrentlyVisiblePromptKey } from '@ms/yammer-data/dist/domains/dismissablePrompts/actions';
import {
  getCurrentlyVisiblePromptKey,
  isCurrentlyVisiblePromptKey,
} from '@ms/yammer-data/dist/domains/dismissablePrompts/selectors';
import { DismissablePromptKey } from '@ms/yammer-data/dist/state/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useIsDataLoaded } from '../dismissablePrompts';
import { useSelector } from '../useSelector';

type UseCurrentlyVisiblePromptKey = () => DismissablePromptKey | null;
export const useCurrentlyVisiblePromptKey: UseCurrentlyVisiblePromptKey = () =>
  useSelector<DismissablePromptKey | null>(getCurrentlyVisiblePromptKey);

type UseSetCurrentlyVisiblePromptKey = (promptKey: DismissablePromptKey, shouldDisplay: boolean) => void;
export const useSetCurrentlyVisiblePromptKey: UseSetCurrentlyVisiblePromptKey = (
  promptKey: DismissablePromptKey,
  shouldDisplay: boolean
) => {
  const dispatch = useDispatch();
  const currentPromptKey = useCurrentlyVisiblePromptKey();
  const isDataLoaded = useIsDataLoaded(promptKey);

  useEffect(() => {
    if (!currentPromptKey && shouldDisplay && isDataLoaded) {
      dispatch(setCurrentlyVisiblePromptKey(promptKey));
    }
  }, [dispatch, promptKey, currentPromptKey, shouldDisplay, isDataLoaded]);
};

type UseIsTheCurrentlyVisiblePromptKey = (promptKey: DismissablePromptKey) => boolean;
export const useIsCurrentlyVisiblePromptKey: UseIsTheCurrentlyVisiblePromptKey = (promptKey) =>
  useSelector((state) => isCurrentlyVisiblePromptKey(state, promptKey));
