import { PluralName } from './types';

export const PLURAL_NAMES: PluralName[] = ['zero', 'one', 'two', 'few', 'many', 'other'];
export const PLURAL_ZERO = 0;
export const PLURAL_ONE = 1;
export const PLURAL_TWO = 2;
export const PLURAL_FEW = 3;
export const PLURAL_MANY = 4;
export const PLURAL_OTHER = 5;

// Pluralisation rules taken from Mozilla Plural Form:
// https://developer.mozilla.org/en-US/docs/Mozilla/Localization/Localization_and_Plurals
export const DEFAULT_PLURAL_RULE_KEY = 0;
