import { useEffect, useRef, useState } from 'react';

interface UseDidVisibilityStateChangeOptions {
  /**
   * A unique value per scenario. Providing a new key will reset the return value to false until the next visibility state change.
   */
  readonly key: string;
}
interface UseDidVisibilityStateChangeResult {
  readonly didVisibilityStateChange: boolean;
  readonly didVisibilityStateChangeRef: React.MutableRefObject<boolean>;
}
type UseDidVisibilityStateChange = (options: UseDidVisibilityStateChangeOptions) => UseDidVisibilityStateChangeResult;

/**
 * Tracks if the visibility state of the document has changed. Returns both a state object and a ref
 * so the consumer can react to state changes or asynchronously check the ref value depending on the situation.
 */
export const useDidVisibilityStateChange: UseDidVisibilityStateChange = ({ key }) => {
  const [didVisibilityStateChange, setDidVisibilityStateChange] = useState(false);
  const didVisibilityStateChangeRef = useRef(false);

  useEffect(() => {
    const updateVisibilityStatus = () => {
      if (!didVisibilityStateChangeRef.current) {
        didVisibilityStateChangeRef.current = true;
        setDidVisibilityStateChange(true);
      }
    };

    document.addEventListener('visibilitychange', updateVisibilityStatus);

    return () => {
      document.removeEventListener('visibilitychange', updateVisibilityStatus);
      didVisibilityStateChangeRef.current = false;
      setDidVisibilityStateChange(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  return { didVisibilityStateChange, didVisibilityStateChangeRef };
};
