import {
  getIsStorylineMultiTenantOrganizationEnabled,
  getStorylineSettings,
  getStorylineSettingsStatus,
  isCurrentUserStoriesPreviewEnabled,
} from '@ms/yammer-data/dist/domains/storylineSettings/selectors';

import { useSelector } from '../useSelector';

type UseStorylineSettings = () => ReturnType<typeof getStorylineSettings>;
export const useStorylineSettings: UseStorylineSettings = () => useSelector(getStorylineSettings);

type UseStorylineSettingsStatus = () => ReturnType<typeof getStorylineSettingsStatus>;
export const useStorylineSettingsStatus: UseStorylineSettingsStatus = () => useSelector(getStorylineSettingsStatus);

type UseIsStorylineMultiTenantOrganizationEnabled = () => ReturnType<
  typeof getIsStorylineMultiTenantOrganizationEnabled
>;
export const useIsStorylineMultiTenantOrganizationEnabled: UseIsStorylineMultiTenantOrganizationEnabled = () =>
  useSelector(getIsStorylineMultiTenantOrganizationEnabled);

type UseIsCurrentUserStoriesPreviewEnabled = () => ReturnType<typeof isCurrentUserStoriesPreviewEnabled>;
export const useIsCurrentUserStoriesPreviewEnabled: UseIsCurrentUserStoriesPreviewEnabled = () =>
  useSelector(isCurrentUserStoriesPreviewEnabled);
