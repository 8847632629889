import { PlaceholderMap } from './types';

type InterpolateAll = (regexp: RegExp, str: string, placeholders?: PlaceholderMap) => string;
export const interpolateAll: InterpolateAll = (regexp, str, placeholders = {}) => {
  if (!regexp.global) {
    throw new Error('Regular expression must be a global match');
  }

  return str.replace(regexp, (match, key) => {
    const value = placeholders[key];

    return value == null ? match : value;
  });
};

type SeparateAll = <T>(items: T[], separator: string) => (string | T)[];
export const separateAll: SeparateAll = <T>(items: T[], separator: string) => {
  const l = items.length;

  return items.reduce((memo, item, i) => {
    memo.push(item);
    if (l > 1 && i < l - 1) {
      memo.push(separator);
    }

    return memo;
  }, [] as (string | T)[]);
};
