import React, { FC, useLayoutEffect, useRef } from 'react';

import { ContainerWidthProvider } from '../../util/responsiveness';
import { useMeasureContainerWidth } from '../../util/responsiveness/useMeasureContainerWidth';
import Flex, { FlexItem } from '../Flex';
import FocusCatcher from '../FocusCatcher';
import Scrollable from '../Scrollable';
import { VisibilityObserverRootContextProvider } from '../VisibilityObserver';

import { ContentContainerProps } from './ContentContainer.types';
import { useContentContainerContext, useCurrentRoute, useScrollToTopOnRouteChange } from './hooks';

const ContentContainer: FC<ContentContainerProps> = ({
  children,
  disableScrolling = false,
  forceScrollable = false,
  paddingLeft,
  paddingTop,
  contentHeight = '100%',
  scrollPaddingTop,
}) => {
  const scrollableRef = useRef<HTMLElement>(null);
  useScrollToTopOnRouteChange(scrollableRef.current);

  const containerQueryDivRef = useRef<HTMLDivElement>(null);
  const width = useMeasureContainerWidth(containerQueryDivRef);
  const currentRoute = useCurrentRoute();

  const { onContentContainerWidthChanged } = useContentContainerContext();
  useLayoutEffect(() => {
    if (width) {
      onContentContainerWidthChanged(width);
    }
  });

  const content = (
    <FlexItem grow={1} height={contentHeight}>
      <ContainerWidthProvider>
        <FocusCatcher state={currentRoute} fullHeight={true}>
          {children}
        </FocusCatcher>
      </ContainerWidthProvider>
    </FlexItem>
  );

  const shouldWrapInScrollable = !disableScrolling || forceScrollable;

  const scrollableHeight = disableScrolling && forceScrollable ? '100%' : undefined;
  const scrollableContent = shouldWrapInScrollable ? (
    <Scrollable
      ref={scrollableRef}
      disableScrolling={disableScrolling}
      height={scrollableHeight}
      scrollPaddingTop={scrollPaddingTop}
    >
      <VisibilityObserverRootContextProvider root={scrollableRef.current}>
        {content}
      </VisibilityObserverRootContextProvider>
    </Scrollable>
  ) : (
    content
  );

  return (
    <Flex
      direction="column"
      height="100%"
      grow={1}
      alignItems="stretch"
      className="qaContentContainer"
      paddingLeft={paddingLeft}
      paddingTop={paddingTop}
    >
      {scrollableContent}
      <div ref={containerQueryDivRef} />
    </Flex>
  );
};

export default ContentContainer;
