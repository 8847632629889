import { useSelectedOnBehalfOfUserId } from '../analyticsSettings/selectors';
import { useCanAccessCampaignsInsights, useCanCreateCampaigns } from '../campaignRights';
import { useExperimentTreatmentWithoutReporting } from '../experiments';

export const useIsCampaignsEntryPointVisible = (): boolean => {
  const isVivaEngagePremium = useExperimentTreatmentWithoutReporting('VivaEngagePremium') === true;
  const canAccessCampaignInsights = useCanAccessCampaignsInsights();
  const canCreateCampaigns = useCanCreateCampaigns();
  const hasCampaignAnalyticsAccess = canAccessCampaignInsights || canCreateCampaigns;
  const selectedOnBehalfOfUserId = useSelectedOnBehalfOfUserId();
  const isLeaderSelectedOnBehalfOfViewer = !!selectedOnBehalfOfUserId;

  return isVivaEngagePremium && hasCampaignAnalyticsAccess && isLeaderSelectedOnBehalfOfViewer === false;
};
