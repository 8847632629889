const loadedModules = new Map();

type LoaderFunction<ModuleType> = () => Promise<ModuleType>;

// eslint-disable-next-line func-style
export async function loadModule<ModuleType>(loader: LoaderFunction<ModuleType>): Promise<ModuleType> {
  if (loadedModules.has(loader)) {
    return loadedModules.get(loader);
  }

  const module = await loader();

  const wasModuleLoadedAsynchronouslyByAnotherCaller = loadedModules.has(loader);
  if (!wasModuleLoadedAsynchronouslyByAnotherCaller) {
    loadedModules.set(loader, module);
  }

  return loadedModules.get(loader);
}

// eslint-disable-next-line func-style
export function getLoadedModule<ModuleType>(loader: LoaderFunction<ModuleType>): ModuleType | undefined {
  return loadedModules.get(loader);
}
