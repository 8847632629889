import React, { FC } from 'react';

import { FlexItem } from '../../../Flex';
import { useIsLeftNavSuppressedAtCurrentWidth, useLeftNavWidth } from '../../hooks';

import { useClassNames } from './LeftNavContainer.styles';

interface LeftNavContainerProps {
  readonly contentHeight?: number;
}

const LeftNavContainer: FC<LeftNavContainerProps> = ({ children, contentHeight }) => {
  const width = useLeftNavWidth();
  const isSuppressedAtCurrentWidth = useIsLeftNavSuppressedAtCurrentWidth();
  const classNames = useClassNames();

  if (isSuppressedAtCurrentWidth) {
    return null;
  }

  return (
    <FlexItem width={width} shrink={0} height="100%" className={classNames.leftNavContainer}>
      <div style={{ height: contentHeight }}>{children}</div>
    </FlexItem>
  );
};

export default LeftNavContainer;
