import { defaultLocale } from './context';

const rtlLocales = ['ar', 'fa', 'he', 'ur'];

type GetDirFromLocale = (locale: string | undefined) => 'ltr' | 'rtl';
export const getDirFromLocale: GetDirFromLocale = (locale = defaultLocale) => {
  const languagePart = locale.split('-')[0];
  const isRTL = rtlLocales.includes(languagePart);
  const dir = isRTL ? 'rtl' : 'ltr';

  return dir;
};
