import { useMemo } from 'react';

import { useIsLeadershipCornerAdminEnabled, useIsQuestionsOnlyMode } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useLeadershipCornerAdminPageSettings: UseMainUIFeatureSettings<'leadershipCornerAdminPage'> = (
  options
) => {
  const leadershipCornerAdminPageOverrides = options?.overrides?.leadershipCornerAdminPage;

  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();
  const isLeadershipCornerAdminEnabled = useIsLeadershipCornerAdminEnabled();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        ...leadershipCornerAdminPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: isLeadershipCornerAdminEnabled,
      ...leadershipCornerAdminPageOverrides,
    };
  }, [leadershipCornerAdminPageOverrides, isLeadershipCornerAdminEnabled, isQuestionsOnlyMode]);
};
