import { InformationBarrierSettingsClients } from '@ms/yammer-graphql';
import { normalize } from 'normalizr';

import { NormalizedUserInformationBarrierSettings } from './normalizeUserInformationBarrierSettings.types';
import { userInformationBarrierSettingsSchema } from './schemas/userInformationBarrierSettings';

type UserInformationBarrierSettingsNormalizer = (response: {
  readonly data: InformationBarrierSettingsClients;
}) => NormalizedUserInformationBarrierSettings;

export const normalizeUserInformationBarrierSettings: UserInformationBarrierSettingsNormalizer = (response) => {
  const { user } = response.data;

  if (user == null) {
    throw new Error('GraphQL response for this user is null');
  }

  return normalize(user, userInformationBarrierSettingsSchema);
};
