import { makeStyles, mergeClasses } from '@griffel/react';

import { createTokens } from '../../theme/fluent9';
import { getGutterValue } from '../gutters';
import { PaddingPropName, PaddingProps } from '../padding';

import { ClassNameAndInlineStyles } from './types';

export type { PaddingProps };

const orderedPaddingPropNames: PaddingPropName[] = [
  'padding',
  'verticalPadding',
  'horizontalPadding',
  'paddingTop',
  'paddingRight',
  'paddingBottom',
  'paddingLeft',
];

const { tokens, cssVariables } = createTokens(orderedPaddingPropNames, 'padding');

type UsePadding = (props: PaddingProps) => ClassNameAndInlineStyles;
export const usePadding: UsePadding = (props) => {
  const paddingClassNames = usePaddingClassNames();

  const providedPaddingProps = orderedPaddingPropNames.filter((propName) => props[propName] != null);

  const className = mergeClasses(...providedPaddingProps.map((prop) => paddingClassNames[prop]));
  const inlineStyles = providedPaddingProps.reduce(
    (styles, prop) => ({
      ...styles,
      [cssVariables[prop]]: getGutterValue(props[prop] as number),
    }),
    {}
  );

  return {
    className,
    inlineStyles,
  };
};

const usePaddingClassNames = makeStyles<PaddingPropName>({
  padding: {
    paddingTop: tokens.padding,
    paddingRight: tokens.padding,
    paddingBottom: tokens.padding,
    paddingLeft: tokens.padding,
  },
  verticalPadding: {
    paddingTop: tokens.verticalPadding,
    paddingBottom: tokens.verticalPadding,
  },
  horizontalPadding: {
    paddingRight: tokens.horizontalPadding,
    paddingLeft: tokens.horizontalPadding,
  },
  paddingTop: { paddingTop: tokens.paddingTop },
  paddingRight: { paddingRight: tokens.paddingRight },
  paddingBottom: { paddingBottom: tokens.paddingBottom },
  paddingLeft: { paddingLeft: tokens.paddingLeft },
});
