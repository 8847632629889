import { register as registerSubscription } from '@ms/yammer-data/dist/domains/pushSubscriptions/actions';
import { subscribe } from '@ms/yammer-web-support/dist/pushNotifications';
import { MutableRefObject, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useGetPushSubscriptionServerKey } from '../pushSubscriptionServerKey';

export const useCreatePushSubscriptionCallback = (
  subscription: MutableRefObject<PushSubscription | null | undefined>
) => {
  const dispatch = useDispatch();

  const applicationServerKey = useGetPushSubscriptionServerKey();

  return useCallback(async () => {
    if (subscription.current != null || !applicationServerKey) {
      return;
    }

    subscription.current = await subscribe(applicationServerKey);
    await dispatch(registerSubscription(subscription.current, applicationServerKey));
  }, [subscription, dispatch, applicationServerKey]);
};
