import React, { FC, createContext, useMemo } from 'react';

import { MainAreaRowContextValue } from './MainAreaRowContextProvider.types';

export const MainAreaRowContext = createContext<MainAreaRowContextValue | undefined>(undefined);

type MainAreaRowContextProviderProps = MainAreaRowContextValue;

export const MainAreaRowContextProvider: FC<MainAreaRowContextProviderProps> = ({ height, children }) => {
  const value: MainAreaRowContextValue = useMemo(
    () => ({
      height,
    }),
    [height]
  );

  return <MainAreaRowContext.Provider value={value}>{children}</MainAreaRowContext.Provider>;
};
