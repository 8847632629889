import { useMemo } from 'react';

import { ComponentClassNames } from '../../util/classNames';
import { mergeStyleSets } from '../../util/styling';
import { useLinkClassNames } from '../../util/useLinkClassNames';
import { ThemedClickableStyleProps } from '../../util/useLinkStyles';

type UseClickableClassNames = (props: ThemedClickableStyleProps) => ComponentClassNames<'clickable'>;
export const useClickableClassNames: UseClickableClassNames = (props) => {
  const linkClassNames = useLinkClassNames({
    activeColor: props.activeColor,
    alwaysUnderline: props.alwaysUnderline,
    className: props.className,
    color: props.color,
    colorOverride: props.colorOverride,
    disabled: props.disabled,
    display: props.display,
    focusOutline: props.focusOutline,
    theme: props.theme,
    unstyled: true,
  });

  return useMemo(
    () =>
      mergeStyleSets({
        clickable: [
          {
            width: props.display === 'block' ? '100%' : undefined,
            selectors: {
              '> .y-block': {
                width: '100%',
              },
            },
          },
          linkClassNames.link,
        ],
      }),
    [linkClassNames.link, props.display]
  );
};
