import { useMemo } from 'react';

import { ITheme, defaultTheme } from '../../../util/theme';
import { getGlobalComponentStyleOverrides } from '../AppContainer.styles.legacy';

type UseThemeWithComponentStyleOverrides = (theme?: ITheme) => ITheme;
export const useThemeWithComponentStyleOverrides: UseThemeWithComponentStyleOverrides = (theme = defaultTheme) =>
  useMemo(
    () => ({
      ...theme,
      components: getGlobalComponentStyleOverrides(theme),
    }),
    [theme]
  );
