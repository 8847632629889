import { useEffect } from 'react';

import { useIsEnabled as useIsPushNotificationsEnabled } from '../pushNotifications';
import { useIsApplicationServerKeyLoaded, useLoadPushSubscriptionServerKey } from '../pushSubscriptionServerKey';

import { useIsRegistered, useIsRegisteredWithExpiredKey } from './selectors';
import { useCreatePushSubscriptionCallback } from './useCreatePushSubscriptionCallback';
import { useDeletePushSubscriptionCallback } from './useDeletePushSubscriptionCallback';
import { useLoadPushSubscriptions } from './useLoadPushSubscriptions';
import { usePushSubscriptionRef } from './usePushSubscriptionRef';
import { useRegisterPushSubscriptionCallback } from './useRegisterPushSubscriptionCallback';
import { useUpdatePushSubscriptionCallback } from './useUpdatePushSubscriptionCallback';

export const usePushSubscription = (): void => {
  const isEnabled = useIsPushNotificationsEnabled();
  const isApplicationServerKeyLoaded = useIsApplicationServerKeyLoaded();

  const subscription = usePushSubscriptionRef();
  const isSubscriptionRegistered = useIsRegistered(subscription.current);
  const isSubscriptionRegisteredWithExpiredKey = useIsRegisteredWithExpiredKey(subscription.current);

  const createPushSubscription = useCreatePushSubscriptionCallback(subscription);
  const registerPushSubscription = useRegisterPushSubscriptionCallback(subscription);
  const updatePushSubscription = useUpdatePushSubscriptionCallback(subscription);
  const deletePushSubscription = useDeletePushSubscriptionCallback(subscription);

  useLoadPushSubscriptionServerKey();
  useLoadPushSubscriptions(subscription);

  useEffect(() => {
    (async () => {
      if (
        subscription.current === undefined ||
        isSubscriptionRegistered === undefined ||
        !isApplicationServerKeyLoaded
      ) {
        return;
      }

      const hasExistingSubscription = subscription.current != null;
      const shouldCreatePushSubscription = isEnabled && !hasExistingSubscription;
      const shouldRegisterPushSubscription = isEnabled && hasExistingSubscription && !isSubscriptionRegistered;
      const shouldUpdatePushSubscription =
        isEnabled && hasExistingSubscription && isSubscriptionRegistered && isSubscriptionRegisteredWithExpiredKey;
      const shouldDeletePushSubscription = !isEnabled && hasExistingSubscription;

      if (shouldCreatePushSubscription) {
        await createPushSubscription();
      } else if (shouldRegisterPushSubscription) {
        await registerPushSubscription();
      } else if (shouldUpdatePushSubscription) {
        await updatePushSubscription();
      } else if (shouldDeletePushSubscription) {
        await deletePushSubscription();
      }
    })();
  }, [
    subscription,
    isApplicationServerKeyLoaded,
    isEnabled,
    isSubscriptionRegistered,
    isSubscriptionRegisteredWithExpiredKey,
    createPushSubscription,
    registerPushSubscription,
    updatePushSubscription,
    deletePushSubscription,
  ]);
};
