import { makeStyles } from '@griffel/react';

export type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';

const useFlexWrapClassNames = makeStyles<FlexWrap>({
  nowrap: {
    flexWrap: 'nowrap',
  },
  wrap: {
    flexWrap: 'wrap',
  },
  'wrap-reverse': {
    flexWrap: 'wrap-reverse',
  },
});

export const useFlexWrapClassName = (wrap?: FlexWrap) => {
  const classNames = useFlexWrapClassNames();

  return wrap ? classNames[wrap] : undefined;
};
