import { createContext } from 'react';

export interface BrandColorOptions {
  readonly linksUseBrandColor: boolean;
}

export const defaultOptions: BrandColorOptions = {
  linksUseBrandColor: false,
};

export const BrandAccentColorContext = createContext(defaultOptions);
