export const mainContentColumnMaxWidth = 700;

export interface ContentMainColumnProps {
  /**
   * Optional "role" attribute.
   */
  readonly role?: string;

  /**
   * Allows the main content column to fill the content area instead of limiting to 700px
   */
  readonly fullWidth?: boolean;
}
