import Clickable from '@ms/yammer-web-core-ui/dist/components/Clickable';
import { getFirstFocusable } from '@ms/yammer-web-core-ui/dist/util/getFirstFocusable';
import React, { FC } from 'react';

export interface FocusMainContentButtonProps {
  readonly text: string;
}

const focusFirstFocusableElementInMain = () => {
  const target = document.querySelector('main, [role="main"]');
  const root = document.body;
  const firstFocusable: HTMLElement | null = getFirstFocusable(root, target as HTMLElement, true);

  if (target && firstFocusable) {
    firstFocusable.focus();
  }
};

/**
 * `GetFirstFocusable` leverages FluentUI's getFirstFocusable function to get the
 * first focusable element
 */
const FocusMainContentButton: FC<FocusMainContentButtonProps> = ({ text }) => (
  <Clickable onClick={focusFirstFocusableElementInMain}>{text}</Clickable>
);

export default FocusMainContentButton;
