import { ReportUsage } from '@ms/yammer-data/dist/telemetry';
import React, { FC, ReactNode } from 'react';

import { EntityWrapperContext } from './EntityWrapperContext';
import { SessionContext } from './SessionContext';
import { TelemetryContext, emptyReportUsage } from './TelemetryContext';
import { passthroughRenderer } from './renderer';
import { RenderGroupReferenceType, RenderUserReferenceType } from './renderer/types';

interface SessionContextProviderProps {
  readonly baseHost?: string;
  readonly renderGroupReference?: RenderGroupReferenceType;
  readonly renderUserReference?: RenderUserReferenceType;
  readonly reportUsage?: ReportUsage;
  readonly children: ReactNode;
}

const SessionContextProvider: FC<SessionContextProviderProps> = ({
  baseHost = '',
  renderGroupReference = passthroughRenderer,
  renderUserReference = passthroughRenderer,
  reportUsage = emptyReportUsage,
  children,
}) => (
  <SessionContext.Provider value={{ baseHost }}>
    <EntityWrapperContext.Provider value={{ renderGroupReference, renderUserReference }}>
      <TelemetryContext.Provider value={{ reportUsage }}>{children}</TelemetryContext.Provider>
    </EntityWrapperContext.Provider>
  </SessionContext.Provider>
);

export default SessionContextProvider;
