import { useMemo } from 'react';

import {
  useIsAskMeAnythingEnabled,
  useIsCalendarInvitationEnabled,
  useIsMtoTeamsMeetingEnabled,
  useIsOnlineMeetingEnabled,
  useIsQuestionsOnlyMode,
} from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useAskMeAnythingEventCreationPageSettings: UseMainUIFeatureSettings<'askMeAnythingEventCreationPage'> = (
  options
) => {
  const askMeAnythingEventCreationPageOverrides = options?.overrides?.askMeAnythingEventCreationPage;

  const isAMAEnabled = useIsAskMeAnythingEnabled();
  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();
  const isCalendarInvitationEnabled = useIsCalendarInvitationEnabled();
  const isOnlineMeetingEnabled = useIsOnlineMeetingEnabled();
  const isMtoTeamsMeetingEnabled = useIsMtoTeamsMeetingEnabled();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        calendarInvitationToggleEnabled: isCalendarInvitationEnabled,
        onlineMeetingToggleEnabled: isOnlineMeetingEnabled,
        mtoTeamsMeetingEnabled: isMtoTeamsMeetingEnabled,
        ...askMeAnythingEventCreationPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: isAMAEnabled,
      calendarInvitationToggleEnabled: isCalendarInvitationEnabled,
      onlineMeetingToggleEnabled: isOnlineMeetingEnabled,
      mtoTeamsMeetingEnabled: isMtoTeamsMeetingEnabled,
      ...askMeAnythingEventCreationPageOverrides,
    };
  }, [
    askMeAnythingEventCreationPageOverrides,
    isAMAEnabled,
    isCalendarInvitationEnabled,
    isMtoTeamsMeetingEnabled,
    isQuestionsOnlyMode,
    isOnlineMeetingEnabled,
  ]);
};
