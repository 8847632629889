import { load } from '@ms/yammer-data/dist/domains/suggestedActions/actions';
import { SuggestedActionSurface } from '@ms/yammer-graphql/dist/types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

type UseLoadSuggestedActions = (surface: SuggestedActionSurface) => void;
export const useLoadSuggestedActions: UseLoadSuggestedActions = (surface) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(load(surface));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
