import { set } from 'idb-keyval';
import { useEffect } from 'react';

import { useCurrentLocale } from './selectors';

export const usePersistLocale = () => {
  const locale = useCurrentLocale();

  useEffect(() => {
    set('locale', locale);
  }, [locale]);
};
