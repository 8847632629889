import { ComponentClassNames } from '../../util/classNames';
import { memoizeFunction, mergeStyleSets } from '../../util/styling';

type FakeLinkClassNames = ComponentClassNames<'fakeLink'>;

export const getClassNames = memoizeFunction(
  (suppressUnderline: boolean, suppressLinkColor: boolean, className?: string): FakeLinkClassNames =>
    mergeStyleSets({
      fakeLink: [
        'y-fakeLink',
        suppressUnderline ? 'noUnderline' : undefined,
        suppressLinkColor ? 'noLinkColor' : undefined,
        className,
      ],
    })
);
