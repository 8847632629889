import { Action } from 'redux';

import { NormalizedAllLeadersInUsersNetworkEntities } from '../../normalization/graphql/normalizeAllLeaders.types';

export const userNetworkLeadersListRequestStatusKey = 'userNetworkLeadersListRequestKey';

export interface UserNetworkLeadersListLoadMorePendingAction extends Action {
  readonly type: 'ALL_LEADERS_IN_USERS_NETWORK.LOAD_MORE_PENDING';
  readonly userNetworkLeadersListRequestStatusKey: string;
}

export interface UserNetworkLeadersListLoadMoreFulfilledAction extends Action {
  readonly type: 'ALL_LEADERS_IN_USERS_NETWORK.LOAD_MORE_FULFILLED';
  readonly userNetworkLeadersListRequestStatusKey: string;
  readonly payload: {
    readonly entities: NormalizedAllLeadersInUsersNetworkEntities;
  };
}

export interface UserNetworkLeadersListLoadMoreRejectedAction extends Action {
  readonly type: 'ALL_LEADERS_IN_USERS_NETWORK.LOAD_MORE_REJECTED';
  readonly userNetworkLeadersListRequestStatusKey: string;
  readonly payload: {
    readonly error: unknown;
  };
  readonly error: true;
}

export type UserNetworkLeadersListLoadMoreAction =
  | UserNetworkLeadersListLoadMorePendingAction
  | UserNetworkLeadersListLoadMoreFulfilledAction
  | UserNetworkLeadersListLoadMoreRejectedAction;
