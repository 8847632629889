import { useAndReportExperimentTreatment } from '../experiments';
import { useFeatureAccessSettings } from '../featureAccessSettings';
import { useStorylineSettings } from '../storylineSettings';

type UseCanCurrentUserAccessStoryline = () => boolean;
export const useCanCurrentUserAccessStoryline: UseCanCurrentUserAccessStoryline = () => {
  const featureAccessSettings = useFeatureAccessSettings();
  const storylineSettings = useStorylineSettings();
  const isStorylineBootstrapNetworkCallEnabled =
    useAndReportExperimentTreatment('StorylineBootstrapNetworkCall') === 'enabled';

  if (isStorylineBootstrapNetworkCallEnabled) {
    return !!storylineSettings?.viewerCanAccessStoryline;
  }

  return !!featureAccessSettings?.viewerCanAccessStoryline;
};
