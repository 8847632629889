import { useSuiteHeaderTheme } from '@ms/yammer-hooks/dist/suiteHeaderTheme';
import { ITheme, createCustomizedThemeWithSlotOverrides } from '@ms/yammer-web-core-ui/dist/util/theme';
import { useMemo } from 'react';

const getName = (id?: string, type?: string) => {
  const nameParts = ['yammerWeb'];
  if (id) {
    nameParts.push(`id:${id}`);
  }

  if (type) {
    nameParts.push(`type:${type}`);
  }

  return nameParts.join('-');
};

type UseThemeWithSuiteHeaderOverrides = () => ITheme;
export const useThemeWithSuiteHeaderOverrides: UseThemeWithSuiteHeaderOverrides = () => {
  const suiteHeaderTheme = useSuiteHeaderTheme();

  return useMemo(
    () =>
      createCustomizedThemeWithSlotOverrides({
        name: getName(suiteHeaderTheme.id, suiteHeaderTheme.type),
        theme: { isInverted: suiteHeaderTheme.isInverted },
        paletteOverrides: suiteHeaderTheme.colors,
      }),
    [suiteHeaderTheme]
  );
};
