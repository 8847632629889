import { GriffelRenderer } from '@fluentui/react-components';
import { useMemo } from 'react';

const compareMediaQueries = () => 0;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const insertCSSRules = () => {};

type CreateNoopRenderer = () => GriffelRenderer;
const createNoopRenderer: CreateNoopRenderer = () => ({
  id: 'noop-renderer',
  compareMediaQueries,
  stylesheets: {},
  insertionCache: {},
  insertCSSRules,
});

/**
 * Creates a noop Griffel renderer. This will prevent partner code (or Griffel styles
 * written outside of .style.ts files, which our lint rules prevent) from emitting styles into style tags
 * in the DOM. This behavior is only enabled in production environments.
 *
 * @see https://griffel.js.org/react/api/create-dom-renderer
 */
export const useNoopGriffelRenderer = () => useMemo(() => createNoopRenderer(), []);
