import { AppLayoutBreakpoint } from '../../../util/responsiveness';

import { useContentContainerContext } from './useContentContainerContext';

type UseShouldRenderSidebarAtCurrentWidth = () => boolean;
export const useShouldRenderContentSidebarAtCurrentWidth: UseShouldRenderSidebarAtCurrentWidth = () => {
  const { contentContainerWidth } = useContentContainerContext();

  return shouldRenderContentSidebarAtWidth(contentContainerWidth);
};

export const shouldRenderContentSidebarAtWidth = (width: number) => width >= AppLayoutBreakpoint.Width812;
