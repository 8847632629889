import { useAndReportExperimentTreatment } from '@ms/yammer-hooks/dist/experiments';
import { useShouldShowSuggestedActionsLightbox } from '@ms/yammer-web-components/dist/firstRunExperience/hooks';
import React, { FC } from 'react';

import SuggestedActionsLightbox from '../SuggestedActionsLightbox';

export const SuggestedActionsLightboxDialog: FC = () => {
  const hasSuggestedActions = useShouldShowSuggestedActionsLightbox();
  useAndReportExperimentTreatment('LightboxFollowUsers');

  if (hasSuggestedActions) {
    return <SuggestedActionsLightbox />;
  }

  return null;
};

export default SuggestedActionsLightboxDialog;
