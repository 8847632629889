import { getStatus } from '@ms/yammer-data-support';
import hashjs from 'hash.js';

import { YammerState } from '../../state/types';

type IsNotRequested = (state: YammerState) => boolean;
export const isNotRequested: IsNotRequested = (state) => getStatus(state.pushSubscriptions) === 'notRequested';

type IsRegistered = (state: YammerState, subscription: PushSubscription | null | undefined) => boolean | undefined;
export const isRegistered: IsRegistered = (state, subscription) => {
  if (subscription === undefined || state.pushSubscriptions.fulfilled === undefined) {
    return undefined;
  }

  return (
    state.pushSubscriptions.fulfilled.find(
      (registeredSubscription) => subscription && registeredSubscription.endpoint === subscription.endpoint
    ) != null
  );
};

export const getSha256 = (contents: string) => hashjs.sha256().update(contents).digest('hex');

type IsRegisteredWithExpiredKey = (state: YammerState, subscription: PushSubscription | null | undefined) => boolean;
export const isRegisteredWithExpiredKey: IsRegisteredWithExpiredKey = (state, subscription) => {
  if (subscription === undefined || !state.pushSubscriptions.fulfilled || !state.pushSubscriptionServerKey.fulfilled) {
    return false;
  }

  const applicationServerKeyHash = getSha256(state.pushSubscriptionServerKey.fulfilled.applicationServerKey);

  const registeredSubscription = state.pushSubscriptions.fulfilled.find(
    (registeredSubscription) => subscription && registeredSubscription.endpoint === subscription.endpoint
  );

  if (!registeredSubscription) {
    return false;
  }

  return (
    !registeredSubscription?.applicationServerKeyHash ||
    registeredSubscription?.applicationServerKeyHash !== applicationServerKeyHash
  );
};
