import { getStatusForId as getStatus } from '@ms/yammer-data-support';
import { FileState } from '@ms/yammer-graphql';

import { ImageFile, YammerState } from '../../state/types';
import { createFindById, createFindByIdThrows } from '../findById';
import { getLegacyHost } from '../session/selectors';

export const findById = createFindById('imageFiles');
export const findByIdThrows = createFindByIdThrows('imageFiles');

type GetUrlFromLegacyHost = (legacyHost: string, databaseId: ImageFile['databaseId']) => string;
export const getUrlFromLegacyHost: GetUrlFromLegacyHost = (legacyHost, id) => `${legacyHost}/uploaded_files/${id}`;

type GetUrl = (state: YammerState, id: ImageFile['id']) => string;
export const getUrl: GetUrl = (state, id) => {
  const { databaseId } = findByIdThrows(state, id);

  return getUrlFromLegacyHost(getLegacyHost(state), databaseId);
};

type ImageVisibilitySelector = (state: YammerState, id: ImageFile['id']) => boolean;

export const isDeleted: ImageVisibilitySelector = (state, id) => {
  const { state: imageFileState } = findByIdThrows(state, id);

  return imageFileState === FileState.DELETED;
};

export const isInaccessible: ImageVisibilitySelector = (state, id) => {
  const { state: imageFileState } = findByIdThrows(state, id);

  return imageFileState === FileState.INACCESSIBLE;
};

export const isActive: ImageVisibilitySelector = (state, id) => {
  const { state: imageFileState } = findByIdThrows(state, id);

  return imageFileState === FileState.ACTIVE;
};

type ImageFileVisibilitySelector = (imageFile: ImageFile) => boolean;
export const isImageFileDeleted: ImageFileVisibilitySelector = (imageFile) => imageFile.state === FileState.DELETED;

export const isImageFileActive: ImageFileVisibilitySelector = (imageFile) => imageFile.state === FileState.ACTIVE;

export const isImageFileInaccessible: ImageFileVisibilitySelector = (imageFile) =>
  imageFile.state === FileState.INACCESSIBLE;

type GetFulfilledImageFiles = (state: YammerState) => Record<string, ImageFile>;
export const getFulfilledImageFiles: GetFulfilledImageFiles = (state) => state.imageFiles.fulfilled.byId;

type GetStatusForId = (state: YammerState, fileId: ImageFile['id']) => ReturnType<typeof getStatus>;
export const getStatusForId: GetStatusForId = (state, fileId) => getStatus(state.imageFiles, fileId);
