import {
  getLoadMoreUserNetworkLeadersListStatus,
  getUserNetworkLeadersList,
  getUserNetworkLeadersListIdsFromState,
  getUserNetworkLeadersListStatus,
  hasNextPageOfUserNetworkLeadersListAvailable,
} from '@ms/yammer-data/dist/domains/userNetworkLeadersList/selectors';

import { useSelector } from '../useSelector';

type UseUserNetworkLeadersListStatus = () => ReturnType<typeof getUserNetworkLeadersListStatus>;
export const useUserNetworkLeadersListStatus: UseUserNetworkLeadersListStatus = () =>
  useSelector(getUserNetworkLeadersListStatus);

type UseLoadMoreUserNetworkLeadersListStatus = () => ReturnType<typeof getLoadMoreUserNetworkLeadersListStatus>;
export const useLoadMoreUserNetworkLeadersListStatus: UseLoadMoreUserNetworkLeadersListStatus = () =>
  useSelector(getLoadMoreUserNetworkLeadersListStatus);

type UseUserNetworkLeadersList = () => ReturnType<typeof getUserNetworkLeadersList>;
export const useUserNetworkLeadersList: UseUserNetworkLeadersList = () => useSelector(getUserNetworkLeadersList);

type UseHasNextPageOfUserNetworkLeadersListAvailable = () => ReturnType<
  typeof hasNextPageOfUserNetworkLeadersListAvailable
>;
export const useHasNextPageOfUserNetworkLeadersListAvailable: UseHasNextPageOfUserNetworkLeadersListAvailable = () =>
  useSelector(hasNextPageOfUserNetworkLeadersListAvailable);

type UseGetUserNetworkLeadersListIds = () => ReturnType<typeof getUserNetworkLeadersListIdsFromState>;
export const useGetUserNetworkLeadersListIds: UseGetUserNetworkLeadersListIds = () =>
  useSelector(getUserNetworkLeadersListIdsFromState);
