import { useMemo } from 'react';

import { useIsArticlePublisherEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useArticlePublisherSettings: UseMainUIFeatureSettings<'articlePublisher'> = (options) => {
  const articlePublisherOverrides = options?.overrides?.articlePublisher;
  const isArticlePublisherEnabled = useIsArticlePublisherEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: isArticlePublisherEnabled,
      isArticlePublisherEnabled,
      ...articlePublisherOverrides,
    }),
    [isArticlePublisherEnabled, articlePublisherOverrides]
  );
};
