import { mergeClasses } from '@griffel/react';

import { useTextAlign } from '../../util/styles/fluent9';

import { HorizontalAlignment } from './VerticalAlign.types';

export const useClassNames = (horizontalAlign: HorizontalAlignment, className?: string) => {
  const textAlign = useTextAlign({ align: horizontalAlign });

  return {
    root: mergeClasses(textAlign.className, className),
  };
};
