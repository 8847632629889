import { NetworkQuestionsEnablementMode } from '@ms/yammer-graphql';
import { useExperimentTreatmentWithoutReporting } from '@ms/yammer-hooks/dist/experiments';
import { useFeatureAccessSettings } from '@ms/yammer-hooks/dist/featureAccessSettings';

import { UseUIFeatureCheck } from '../types';

export const useAreQuestionsEnabled: UseUIFeatureCheck = () => {
  const isAnswersInVivaGAEnabled = useExperimentTreatmentWithoutReporting('AnswersInVivaGA') === true;

  const { viewerCanAccessNetworkQuestions, networkQuestionsEnablementMode } = useFeatureAccessSettings() ?? {};

  return (
    isAnswersInVivaGAEnabled &&
    viewerCanAccessNetworkQuestions === true &&
    networkQuestionsEnablementMode !== NetworkQuestionsEnablementMode.DISABLED
  );
};

export const useIsQuestionsOnlyMode: UseUIFeatureCheck = () => {
  const { networkQuestionsEnablementMode } = useFeatureAccessSettings() ?? {};

  return networkQuestionsEnablementMode === NetworkQuestionsEnablementMode.ENABLED_NETWORK_QUESTIONS_ONLY_MODE;
};
