import { IFontStyles, IRawStyle, ITheme } from '../styling';

const defaultFontSizeInPixels = 16;

export { typographyStyles } from '@fluentui/react-components';

export const pxToRawRemNumber = (pixels: number): number => pixels / defaultFontSizeInPixels;

export const pxToRemString = (pixels: number): string => `${pxToRawRemNumber(pixels)}rem`;

/**
 * @deprecated Please use FUI9 makeStyles and tokens instead
 */
export enum FontWeight {
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
}

export type TextAlign = 'left' | 'right' | 'center';
export type TextDirection = 'auto' | 'ltr' | 'rtl';

export enum TextDecoration {
  Underline = 'underline',
  LineThrough = 'line-through',
}

export enum FontStyle {
  Normal = 'normal',
  Italic = 'italic',
}

export enum TextTransform {
  Capitalize = 'capitalize',
  Lowercase = 'lowercase',
  Uppercase = 'uppercase',
}

export enum TextColor {
  /**
   * Primary black text color
   */
  Primary = 'primary',

  /**
   * Secondary dark gray text color
   */
  Secondary = 'secondary',

  /**
   * Accent color for mentions, topics, etc.
   */
  Accent = 'accent',

  /**
   * Tertiary color for metadata
   */
  Metadata = 'metadata',

  /*
   * For use on darker backgrounds.
   */
  White = 'white',

  /**
   * For use on darker backgrounds. Note: This will always be white, even in dark mode.
   */
  StaticWhite = 'staticWhite',

  /**
   * For use in error messages.
   */
  Error = 'error',

  /**
   * For use in interaction elements which have become disabled.
   */
  Disabled = 'disabled',
}

/**
 * @deprecated Please use numeric font sizes on Text or Block components, or Fluent9 styling with tokens.
 */
export enum TextSize {
  /**
   * X-Large headers. Font-size 48px, line-height 46px.
   * @deprecated Please use numeric font size 900
   */
  XLarge = 'xLarge',

  /**
   * Large headers. Font-size 24px, line-height 32px.
   * @deprecated Please use numeric font size 600
   */
  Large = 'large',

  /**
   * Medium plus headers. Font-size 18px, line-height 24px.
   * @deprecated Please use numeric font size 400
   */
  MediumPlus = 'mediumPlus',

  /**
   * Body text, default size. Font-size 16px, line-height 22px.
   * @deprecated Please use numeric font size 400
   */
  Medium = 'medium',

  /**
   * Secondary text. Font-size 14px, line-height 20px.
   * @deprecated Please use numeric font size 300
   */
  MediumSub = 'mediumSub',

  /**
   * Metadata. Font-size 12px, line-height 16px.
   * @deprecated Please use numeric font size 200
   */
  Small = 'small',

  /**
   * Extra small headings or badges. Font-size 10px, line-height 14px.
   * @deprecated Please use numeric font size 100
   */
  XSmall = 'xSmall',
}

export interface FontSizeDimension {
  readonly fontSize: string;
  readonly lineHeight: string;
}

interface FontSizeDimensionInRems {
  readonly fontSize: number;
  readonly lineHeight: number;
}

export type ThemeFontSizeName = keyof IFontStyles;

type ThemeFontSizeDimensionsInRems = Record<ThemeFontSizeName, FontSizeDimensionInRems>;
const baseThemeFontSizeDimensionsInRems: ThemeFontSizeDimensionsInRems = {
  tiny: { fontSize: pxToRawRemNumber(10), lineHeight: pxToRawRemNumber(14) },
  xSmall: { fontSize: pxToRawRemNumber(10), lineHeight: pxToRawRemNumber(14) },
  small: { fontSize: pxToRawRemNumber(12), lineHeight: pxToRawRemNumber(16) },
  smallPlus: { fontSize: pxToRawRemNumber(12), lineHeight: pxToRawRemNumber(16) },
  medium: { fontSize: pxToRawRemNumber(14), lineHeight: pxToRawRemNumber(20) },
  mediumPlus: { fontSize: pxToRawRemNumber(16), lineHeight: pxToRawRemNumber(22) },
  large: { fontSize: pxToRawRemNumber(16), lineHeight: pxToRawRemNumber(22) },
  xLarge: { fontSize: pxToRawRemNumber(20), lineHeight: pxToRawRemNumber(28) },
  xLargePlus: { fontSize: pxToRawRemNumber(24), lineHeight: pxToRawRemNumber(32) },
  xxLarge: { fontSize: pxToRawRemNumber(28), lineHeight: pxToRawRemNumber(36) },
  xxLargePlus: { fontSize: pxToRawRemNumber(32), lineHeight: pxToRawRemNumber(42) },
  superLarge: { fontSize: pxToRawRemNumber(40), lineHeight: pxToRawRemNumber(52) },
  mega: { fontSize: pxToRawRemNumber(68), lineHeight: pxToRawRemNumber(90) },
};

const textSizeToFontSizeMap: Record<TextSize, ThemeFontSizeName> = {
  [TextSize.XSmall]: 'xSmall',
  [TextSize.Small]: 'small',
  [TextSize.MediumSub]: 'medium',
  [TextSize.Medium]: 'mediumPlus',
  [TextSize.MediumPlus]: 'large',
  [TextSize.Large]: 'xLargePlus',
  [TextSize.XLarge]: 'superLarge',
};

type FontSizeDimensionsInRems = Record<TextSize, FontSizeDimensionInRems>;
const baseTextSizeDimensionsInRems: FontSizeDimensionsInRems = (Object.values(TextSize) as TextSize[]).reduce(
  (accDimensions: FontSizeDimensionsInRems, textSize: TextSize) => ({
    ...accDimensions,
    [textSize]: baseThemeFontSizeDimensionsInRems[textSizeToFontSizeMap[textSize]],
  }),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  {} as FontSizeDimensionsInRems
);

export const getBaseFontSizeStyles: (size: TextSize) => FontSizeStyle = (size) => ({
  fontSize: `${baseTextSizeDimensionsInRems[size].fontSize}rem`,
  lineHeight: `${baseTextSizeDimensionsInRems[size].lineHeight}rem`,
});

type ThemeFontSizeStyles = Record<ThemeFontSizeName, FontSizeDimension>;
export const getBaseThemeFontSizeStyles: () => ThemeFontSizeStyles = () =>
  Object.entries(baseThemeFontSizeDimensionsInRems).reduce(
    (fontStyles: ThemeFontSizeStyles, [key, value]) => ({
      ...fontStyles,
      [key]: { fontSize: `${value.fontSize}rem`, lineHeight: `${value.lineHeight}rem` },
    }),
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as ThemeFontSizeStyles
  );

export interface FontSizeStyle {
  readonly fontSize: string | number;
  readonly lineHeight: string | number;
}

export const getThemeFontSizeStyles: (size: TextSize, theme: ITheme) => FontSizeStyle = (size, theme) => {
  const { fontSize, lineHeight } = theme.fonts[textSizeToFontSizeMap[size]];

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return { fontSize, lineHeight } as FontSizeStyle;
};

export const textColors: (theme: ITheme) => Record<TextColor, string> = (theme) => ({
  primary: theme.semanticColors.bodyText,
  secondary: theme.palette.neutralSecondary,
  accent: theme.palette.accent,
  metadata: theme.semanticColors.bodySubtext,
  white: theme.palette.white,
  staticWhite: theme.isInverted ? theme.palette.black : theme.palette.white,
  error: theme.semanticColors.errorText,
  disabled: theme.semanticColors.disabledText,
});

export enum TextWrapStyle {
  /**
   * Limits text to a single line and hides overflow with an ellpsis character.
   */
  Ellipsis = 'ellipsis',

  /**
   * Limits text to multiple lines and hides overflow with an ellpsis character.
   */
  MultiLineEllipsis = 'multiLineEllipsis',

  /**
   * Breaks long words onto the next line to prevent text from pushing out of the containing element.
   */
  BreakWords = 'breakWords',

  /**
   * Preserves text formatting like a <pre> tag, but wraps long lines of text.
   */
  PreserveTextFormatting = 'preserveTextFormatting',

  /**
   * Preserves text formatting like a <pre> tag, but wraps long lines of text, and breaks long words when necessary.
   */
  BreakWordsAndPreserveTextFormatting = 'breakWordsAndPreserveTextFormatting',

  /**
   * Prevents text from wrapping. This has the potential to push long text outside of the Block's boundaries, so
   * this should only be used where a very short string needs to remain on one line.
   */
  PreventWrap = 'preventWrap',
}

export const ellipsisStyles: IRawStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const breakWordStyles: IRawStyle = {
  wordWrap: 'break-word',
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
};

export const preserveTextFormattingStyles: IRawStyle = {
  whiteSpace: 'pre-wrap',
};

export const preventWrapStyles: IRawStyle = {
  whiteSpace: 'nowrap',
};

export type GetTextWrapStyles = (maxLines: number) => IRawStyle;
export const getMultiLineEllipsisStyles: GetTextWrapStyles = (maxLines) => ({
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': `${maxLines}`,
});
