import { reportError } from '@ms/yammer-web-support/dist/telemetry';
import React, { Component, cloneElement, useState } from 'react';

import { CatchErrorProps, CatchErrorState, ErrorBoundaryCode, ErrorBoundaryProps } from './ErrorBoundary.types';

class CatchError extends Component<CatchErrorProps, CatchErrorState> {
  public state = { error: null };

  public static getDerivedStateFromError(error: Error): CatchErrorState {
    return { error };
  }

  public componentDidCatch(error: Error) {
    reportError({
      error,
      eventProperties: {
        errorCode: this.props.name ?? 'ErrorBoundary',
      },
    });
  }

  public componentDidUpdate(_: Readonly<CatchErrorProps>, prevState: Readonly<CatchErrorState>): void {
    if (!prevState.error && this.state.error && this.props.onError) {
      this.props.onError(this.state.error, this.retry);
    }
  }

  private retry = () => {
    this.setState({ error: null });
    this.props.onRetry();
  };

  public render() {
    const { children, onRenderError } = this.props;
    const { error } = this.state;

    if (error) {
      if (onRenderError == null) {
        return null;
      }

      return onRenderError(this.retry);
    }

    return cloneElement(children, { fetchKey: this.props.fetchKey });
  }
}

export const ErrorBoundary = <T extends ErrorBoundaryCode<string, string>>({
  children,
  onRenderError,
  name,
  onError,
}: ErrorBoundaryProps<T>) => {
  const [fetchKey, setFetchKey] = useState(0);

  const onRetry = () => {
    setFetchKey((oldKey) => oldKey + 1);
  };

  return (
    <CatchError name={name} onRenderError={onRenderError} onError={onError} onRetry={onRetry} fetchKey={fetchKey}>
      {children}
    </CatchError>
  );
};
