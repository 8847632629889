import {
  ViewerCallToActionClients,
  ViewerCallToActionClients_viewer_suggestedActions as ViewerSuggestedAction,
} from '@ms/yammer-graphql';
import { normalize, schema } from 'normalizr';

import { SuggestedActionsSchemas } from '../../state/types';

import { groupCoverImageSchema } from './schemas/groupCoverImage';
import { groupMemberCountSchema } from './schemas/groupMemberCount';
import { suggestedActionsArraySchema } from './schemas/suggestedActions';
import { viewerIsFollowingUserSchema } from './schemas/viewerIsFollowingUser';
import { NormalizedSuggestedActions, NormalizedSuggestedActionsEntities } from './types';

const typenameToSchemaMap: Record<string, SuggestedActionsSchemas> = {
  SuggestedActionJoinGroup: 'joinGroup',
  SuggestedActionFollowUser: 'followUser',
};

const transformSuggestedAction = (action: ViewerSuggestedAction) => ({
  ...action,
  __typename: typenameToSchemaMap[action.__typename],
});

type NormalizeSuggestedActions = (response: { readonly data: ViewerCallToActionClients }) => NormalizedSuggestedActions;

export const normalizeSuggestedActions: NormalizeSuggestedActions = (response) => {
  const { suggestedActions } = response.data.viewer;
  const transformedSuggestedActions = suggestedActions
    .map((a) => transformSuggestedAction(a))
    .filter((a) => !!a?.__typename);
  const { entities: entitiesForSuggestedActions, result: resultsSuggestedActionsIds } = normalize<
    unknown,
    NormalizedSuggestedActionsEntities
  >(transformedSuggestedActions, suggestedActionsArraySchema);

  const { entities: entitiesForGroupCoverImages } = normalize<unknown, NormalizedSuggestedActionsEntities>(
    transformedSuggestedActions,
    [new schema.Object({ group: groupCoverImageSchema })]
  );

  const { entities: entitiesForGroupMemberCounts } = normalize<unknown, NormalizedSuggestedActionsEntities>(
    transformedSuggestedActions,
    [new schema.Object({ group: groupMemberCountSchema })]
  );

  const { entities: entitiesForViewerIsFollowing } = normalize(transformedSuggestedActions, [
    new schema.Object({ user: viewerIsFollowingUserSchema }),
  ]);

  const entities = {
    groupCoverImages: entitiesForGroupCoverImages.groupCoverImages,
    groupMemberCounts: entitiesForGroupMemberCounts.groupMemberCounts,
    groups: entitiesForSuggestedActions.groups,
    networks: entitiesForSuggestedActions.networks,
    followUsers: entitiesForSuggestedActions.followUsers,
    joinGroups: entitiesForSuggestedActions.joinGroups,
    suggestedActionsIds: resultsSuggestedActionsIds,
    users: entitiesForSuggestedActions.users,
    viewerIsFollowingUsers: entitiesForViewerIsFollowing.viewerIsFollowingUsers,
  };

  return {
    entities,
    result: undefined,
  };
};
