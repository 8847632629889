import { universalCreatePublisherId } from '@ms/yammer-data/dist/domains/publishers/getId';
import { useRenderToggleableContent } from '@ms/yammer-web-core-ui/dist/util/useRenderToggleableContent';
import React from 'react';

import LoadableUniversalCreatePublisherModal from '../LoadableUniversalCreatePublisherModal';

type UsePostCreateModal = () => ReturnType<typeof useRenderToggleableContent>;
export const usePostCreateModal: UsePostCreateModal = () =>
  useRenderToggleableContent(({ hideContent }) => (
    <LoadableUniversalCreatePublisherModal publisherId={universalCreatePublisherId} onDismiss={hideContent} />
  ));

export default usePostCreateModal;
