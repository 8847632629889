import {
  discoverGroupsExploreUrl,
  discoverGroupsRecommendedUrl,
  feedPageUrl,
  leaderDashboardPageUrl,
  leadershipCornerPageUrl,
  m365CopilotAdoptionPageUrl,
  questionsPageUrl,
  storylinesPageUrl,
} from '@ms/yammer-web-support/dist/urls';
import { useMemo } from 'react';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

type UseIsTopLevelFeaturePage = () => boolean;
export const useIsTopLevelFeaturePage: UseIsTopLevelFeaturePage = () => {
  const { pathname } = useLocation();

  return useMemo(
    () =>
      [
        feedPageUrl,
        discoverGroupsExploreUrl,
        discoverGroupsRecommendedUrl,
        storylinesPageUrl,
        leadershipCornerPageUrl,
        leaderDashboardPageUrl,
        questionsPageUrl,
        m365CopilotAdoptionPageUrl,
      ].some((url) => matchPath(pathname, { path: url, exact: true })),
    [pathname]
  );
};
