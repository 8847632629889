import { useMemo } from 'react';

import { useIsNetworkAdminEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useNetworkAdminPageSettings: UseMainUIFeatureSettings<'networkAdminPage'> = (options) => {
  const networkAdminPageOverrides = options?.overrides?.networkAdminPage;

  const isNetworkAdminEnabled = useIsNetworkAdminEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: isNetworkAdminEnabled,
      ...networkAdminPageOverrides,
    }),
    [networkAdminPageOverrides, isNetworkAdminEnabled]
  );
};
