import { useSendPresenceState } from '@ms/yammer-hooks/dist/userPresence';
import { FC } from 'react';
/*
 *  Extracted from App.tsx
 *  Expressed as a component as it will trigger a re-render when the user's presence state changes.
 */
export const SendPresenceState: FC = () => {
  useSendPresenceState();

  return null;
};
