import { Group, GroupMemberCount, GroupMemberCounts, YammerState } from '../../state/types';
import { createFindRecordById, createFindRecordByIdThrows } from '../findRecordById';

const findByIdThrows = createFindRecordByIdThrows<GroupMemberCount>('groupMemberCounts');
const findById = createFindRecordById<GroupMemberCount>('groupMemberCounts');

type GetTotalMemberCountByIdThrows = (state: YammerState, groupId: Group['id']) => GroupMemberCount['totalMemberCount'];

export const getTotalMemberCountByIdThrows: GetTotalMemberCountByIdThrows = (state, groupId) =>
  findByIdThrows(state, groupId).totalMemberCount;

type GetTotalMemberCountById = (state: YammerState, groupId: Group['id']) => GroupMemberCount['totalMemberCount'];
export const getTotalMemberCountById: GetTotalMemberCountById = (state, groupId) =>
  findById(state, groupId)?.totalMemberCount ?? 0;

type GetFulfilledGroupMemberCounts = (state: YammerState) => GroupMemberCounts;
export const getFulfilledGroupMemberCounts: GetFulfilledGroupMemberCounts = (state) => state.groupMemberCounts;
