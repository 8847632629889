import { useMemo } from 'react';

import {
  useIsCommunityInsightsV2Enabled,
  useIsInsightsCampaignEnabled,
  useIsInsightsLeadersMyInsightsNavigationEnabled,
  useIsInsightsLeadersOrgNavigationEnabled,
  useIsInsightsQnAMyInsightsEnabled,
  useIsInsightsQnAOrgEnabled,
  useIsPremiumCommunityInsightsV2Enabled,
  useIsQuestionsOnlyMode,
  useIsVivaInsightsNetworkEnabled,
} from '../../featureChecks';
import { UseMainUIFeatureSettings } from '../../types';

export const useInsightsNavigationSettings: UseMainUIFeatureSettings<'insightsNavigation'> = (options) => {
  const insightsNavigationOverrides = options?.overrides?.insightsNavigation;

  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();

  const campaign = useIsInsightsCampaignEnabled();
  const communityInsightsV2 = useIsCommunityInsightsV2Enabled();
  const premiumCommunityInsightsV2 = useIsPremiumCommunityInsightsV2Enabled();
  const leadersMyInsights = useIsInsightsLeadersMyInsightsNavigationEnabled();
  const leadersOrg = useIsInsightsLeadersOrgNavigationEnabled();
  const network = useIsVivaInsightsNetworkEnabled();
  const qnaMyInsights = useIsInsightsQnAMyInsightsEnabled();
  const qnaOrg = useIsInsightsQnAOrgEnabled();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        campaign: false,
        communityInsightsV2: false,
        premiumCommunityInsightsV2: false,
        leadersMyInsights: false,
        leadersOrg: false,
        network: false,
        qnaMyInsights,
        qnaOrg,
        ...insightsNavigationOverrides,
      };
    }

    return {
      campaign,
      communityInsightsV2,
      premiumCommunityInsightsV2,
      leadersMyInsights,
      leadersOrg,
      network,
      qnaMyInsights,
      qnaOrg,
      ...insightsNavigationOverrides,
    };
  }, [
    campaign,
    communityInsightsV2,
    premiumCommunityInsightsV2,
    insightsNavigationOverrides,
    isQuestionsOnlyMode,
    leadersMyInsights,
    leadersOrg,
    network,
    qnaMyInsights,
    qnaOrg,
  ]);
};
