import { FC } from 'react';

import { useReduxAnnouncerBridge } from './hooks';

const ReduxAnnouncerBridge: FC = () => {
  useReduxAnnouncerBridge();

  return null;
};

export default ReduxAnnouncerBridge;
