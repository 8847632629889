import { DEFAULT_PLURAL_RULE_KEY, PLURAL_NAMES } from './constants';
import { PLURAL_RULES, PLURAL_RULE_KEYS_BY_LOCALE } from './rules';
import { PluralName } from './types';

type GetPluralName = (count: number | undefined, locale: string) => PluralName | undefined;
export const getPluralName: GetPluralName = (count, locale) => {
  if (typeof count === 'number') {
    const ruleKey = PLURAL_RULE_KEYS_BY_LOCALE[locale] || DEFAULT_PLURAL_RULE_KEY;
    const rule = PLURAL_RULES[ruleKey];

    return PLURAL_NAMES[rule(count)];
  }
};
