import { createContext, useContext } from 'react';

export interface FlairColorContextProps {
  readonly isOnDarkBackground: boolean;
}

export const defaultFlairColorContext: FlairColorContextProps = {
  isOnDarkBackground: false,
};

export const FlairColorContext = createContext(defaultFlairColorContext);

export type UseFlairColorContext = () => FlairColorContextProps;
export const useFlairColorContext: UseFlairColorContext = () => useContext(FlairColorContext);
