import React, { FC } from 'react';

import { CreateCampaignProvider, CreateCampaignProviderProps } from './CreateCampaignProvider';
import { CreateCommunityProvider, CreateCommunityProviderProps } from './CreateCommunityProvider';
import { CreatePostProvider, CreatePostProviderProps } from './CreatePostProvider';

export type UniversalCreateButtonProviderProps = CreateCommunityProviderProps &
  CreateCampaignProviderProps &
  CreatePostProviderProps;

export const UniversalCreateButtonProvider: FC<UniversalCreateButtonProviderProps> = ({
  useGroupCreateModal,
  campaignCreateModal,
  usePostCreateModal,
  children,
}) => (
  <CreateCommunityProvider useGroupCreateModal={useGroupCreateModal}>
    <CreateCampaignProvider campaignCreateModal={campaignCreateModal}>
      <CreatePostProvider usePostCreateModal={usePostCreateModal}>{children}</CreatePostProvider>
    </CreateCampaignProvider>
  </CreateCommunityProvider>
);
