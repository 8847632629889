import React, { FC } from 'react';

import { ContainerWidthProvider } from '../../util/responsiveness/ContainerWidthContext';
import { useTheme } from '../../util/theme';
import Flex from '../Flex';

import { getClassNames } from './AppLayout.styles.legacy';
import { AppLayoutProps } from './AppLayout.types';
import AppLayoutContextProvider from './AppLayoutContextProvider';
import ContentContainerContextProvider from './ContentContainerContextProvider';

const AppLayout: FC<AppLayoutProps> = ({
  children,
  height = '100vh',
  disableNativePageScroll = false,
  openContentSidebarAriaLabel,
  closeContentSidebarAriaLabel,
  hasBackButton = false,
  hasOuterLeftNav = false,
  backgroundColor,
  removeContentPadding = false,
}) => {
  const theme = useTheme();

  return (
    <ContainerWidthProvider>
      <AppLayoutContextProvider
        openContentSidebarAriaLabel={openContentSidebarAriaLabel}
        closeContentSidebarAriaLabel={closeContentSidebarAriaLabel}
        hasBackButton={hasBackButton}
        hasOuterLeftNav={hasOuterLeftNav}
        removeContentPadding={removeContentPadding}
      >
        <ContentContainerContextProvider>
          <Flex
            direction="column"
            className={getClassNames(height, disableNativePageScroll, theme, backgroundColor).appLayout}
          >
            {children}
          </Flex>
        </ContentContainerContextProvider>
      </AppLayoutContextProvider>
    </ContainerWidthProvider>
  );
};
export default AppLayout;
