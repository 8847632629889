import { MainColumnAriaRole } from '@ms/yammer-web-core-ui/dist/components/AppLayout';
import React, { FC, useMemo } from 'react';

import AppContext, { AppContextOptions, defaultOptions } from './context';

export interface AppContextProviderProps {
  readonly mainColumnAriaRole: MainColumnAriaRole;
  readonly options?: Partial<AppContextOptions>;
}

export const AppContextProvider: FC<AppContextProviderProps> = ({ mainColumnAriaRole, options, children }) => {
  const contextProps = useMemo(
    () => ({ ...defaultOptions, ...options, mainColumnAriaRole }),
    [options, mainColumnAriaRole]
  );

  return <AppContext.Provider value={contextProps}>{children}</AppContext.Provider>;
};
