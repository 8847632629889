import { ReactNode, useCallback, useMemo, useState } from 'react';

export interface ToggleableEvents {
  readonly showContent: () => void;
  readonly hideContent: () => void;
  readonly toggleContent: () => void;
}

interface ToggleableContentProps extends ToggleableEvents {
  readonly content?: ReactNode;
}

export type RenderCallback = (props: ToggleableEvents) => ReactNode;
type UseRenderToggleableContent = (render: RenderCallback, initialVisibilityState?: boolean) => ToggleableContentProps;

export const useRenderToggleableContent: UseRenderToggleableContent = (render, initialVisibilityState = false) => {
  const [isContentVisible, setIsContentVisible] = useState(initialVisibilityState);

  const showContent = useCallback(() => setIsContentVisible(true), [setIsContentVisible]);
  const hideContent = useCallback(() => setIsContentVisible(false), [setIsContentVisible]);
  const toggleContent = useCallback(() => {
    if (isContentVisible) {
      hideContent();
    } else {
      showContent();
    }
  }, [isContentVisible, hideContent, showContent]);

  return useMemo(
    () => ({
      content: isContentVisible ? render({ showContent, hideContent, toggleContent }) : null,
      showContent,
      hideContent,
      toggleContent,
    }),
    [isContentVisible, render, hideContent, showContent, toggleContent]
  );
};
