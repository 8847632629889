import { FeatureRollout } from '@ms/yammer-data/dist/state/ecsFeatureRollouts/types';

import { useIsFeatureRolloutEnabled } from '../featureRollouts';
import { useCanCurrentUserAccessStoryline } from '../userProfile';

type UseCanCurrentUserAccessStorylineHome = () => boolean;
export const useCanCurrentUserAccessStorylineHome: UseCanCurrentUserAccessStorylineHome = () => {
  const isStorylineHomeEnabled = useIsFeatureRolloutEnabled(FeatureRollout.StorylineHome);
  const canCurrentUserAccessStoryline = useCanCurrentUserAccessStoryline();

  return isStorylineHomeEnabled && canCurrentUserAccessStoryline;
};
