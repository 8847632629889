import React, { forwardRef } from 'react';

import { FlexItemProps } from './Flex.types';
import { useClassNameAndInlineStyles } from './FlexItem.styles';

const FlexItem = forwardRef<HTMLDivElement, FlexItemProps>(({ children, ariaHidden, role, ...styleProps }, ref) => {
  const styling = useClassNameAndInlineStyles(styleProps);

  return (
    <div role={role} aria-hidden={ariaHidden} className={styling.className} style={styling.inlineStyles} ref={ref}>
      {children}
    </div>
  );
});

FlexItem.displayName = 'FlexItem';

/**
 * A `FlexItem` component renders a div with flex-item styles.
 */
export default FlexItem;
