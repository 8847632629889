import React, { PropsWithChildren, forwardRef } from 'react';

import { useTheme } from '../../util/theme';
import { useLinkClassNames } from '../../util/useLinkClassNames';
import ScreenreaderText from '../ScreenreaderText';

import { ExternalLinkProps } from './ExternalLink.types';
import { useExternalLinkScreenreaderSuffixContext } from './ExternalLinkScreenreaderSuffixContext';
import { useExternalLinkClickHandler } from './hooks';

const ExternalLink = forwardRef<HTMLAnchorElement, PropsWithChildren<ExternalLinkProps>>(
  (
    {
      ariaLabel,
      href,
      title,
      children,
      unstyled,
      display,
      className,
      focusOutline,
      onClick,
      onFocus,
      onHover,
      activeColor,
      color,
      colorOverride,
      alwaysUnderline,
      disallowTabFocus,
      download,
    },
    ref
  ) => {
    const theme = useTheme();
    const clickHandler = useExternalLinkClickHandler(href, onClick);
    const linkClassName = useLinkClassNames({
      unstyled,
      activeColor,
      colorOverride,
      display,
      theme,
      focusOutline,
      color,
      alwaysUnderline,
      className,
    });

    const screenreaderSuffix = useExternalLinkScreenreaderSuffixContext();
    const hasScreenreaderSuffix = !!screenreaderSuffix;
    const hasAriaLabel = !!ariaLabel;
    const suffixScreenreaderText = hasScreenreaderSuffix ? (
      <ScreenreaderText>{` (${screenreaderSuffix})`}</ScreenreaderText>
    ) : null;
    const ariaLabelWithSuffix =
      hasScreenreaderSuffix && hasAriaLabel ? `${ariaLabel} (${screenreaderSuffix})` : ariaLabel;

    const rel = 'nofollow noopener noreferrer';
    const target = '_blank';
    const tabIndex = disallowTabFocus ? -1 : undefined;

    return (
      // eslint-disable-next-line react/forbid-elements
      <a
        className={linkClassName.link}
        href={href}
        rel={rel}
        target={target}
        title={title}
        aria-label={ariaLabelWithSuffix}
        onClick={clickHandler}
        onFocus={onFocus}
        onMouseEnter={onHover}
        tabIndex={tabIndex}
        ref={ref}
        download={download}
      >
        {children}
        {suffixScreenreaderText}
      </a>
    );
  }
);
ExternalLink.displayName = 'ExternalLink';

/**
 * A `ExternalLink` renders an `a` tag for navigation between web pages.
 */
export default ExternalLink;
