import { PLURAL_FEW, PLURAL_MANY, PLURAL_ONE, PLURAL_OTHER, PLURAL_TWO, PLURAL_ZERO } from './constants';
import { PluralRule, PluralRuleMap } from './types';

type Between = (n: number, min: number, max: number) => boolean;
const between: Between = (n, min, max) => n >= min && n <= max;

export const PLURAL_RULES: PluralRule[] = [
  // #0 Asian (Chinese, Japanese, Korean, Vietnamese), Persian, Turkic/Altaic
  // (Turkish), Thai, Lao
  () => PLURAL_OTHER,

  // #1 Germanic (Danish, Dutch, English, Faroese, Frisian, German, Norwegian,
  // Swedish), Finno-Ugric (Estonian, Finnish, Hungarian), Language isolate
  // (Basque), Latin/Greek (Greek), Semitic (Hebrew), Romanic (Italian,
  // Portuguese, Spanish, Catalan)
  (n: number) => (n === 1 ? PLURAL_ONE : PLURAL_OTHER),

  // #2 Romanic (French, Brazilian Portuguese)
  (n: number) => (between(n, 0, 1) ? PLURAL_ONE : PLURAL_OTHER),

  // #3 Baltic (Latvian)
  (n: number) => {
    if (n % 10 === 0 || between(n % 100, 11, 19)) {
      return PLURAL_ZERO;
    }

    if (n % 10 === 1 && n % 100 !== 11) {
      return PLURAL_ONE;
    }

    return PLURAL_OTHER;
  },

  // #4 Celtic (Scottish Gaelic)
  (n: number) => {
    if (n === 1 || n === 11) {
      return PLURAL_ONE;
    }

    if (n === 2 || n === 12) {
      return PLURAL_TWO;
    }

    if (between(n, 3, 10) || between(n, 13, 19)) {
      return PLURAL_FEW;
    }

    return PLURAL_OTHER;
  },

  // #5 Romanic (Romanian)
  (n: number) => {
    if (n === 1) {
      return PLURAL_ONE;
    }

    if (n === 0 || between(n % 100, 1, 19)) {
      return PLURAL_FEW;
    }

    return PLURAL_OTHER;
  },

  // #6 Baltic (Lithuanian)
  (n: number) => {
    if (n % 10 === 1 && !between(n % 100, 11, 19)) {
      return PLURAL_ONE;
    }

    if (between(n % 10, 2, 9) && !between(n % 100, 11, 19)) {
      return PLURAL_FEW;
    }

    return PLURAL_OTHER;
  },

  // #7 Slavic (Belarusian, Russian, Ukrainian)
  (n: number) => {
    if (n % 10 === 1 && n % 100 !== 11) {
      return PLURAL_ONE;
    }

    if (between(n % 10, 2, 4) && !between(n % 100, 12, 14)) {
      return PLURAL_FEW;
    }

    if (n % 10 === 0 || between(n % 10, 5, 9) || between(n % 100, 11, 14)) {
      return PLURAL_MANY;
    }

    return PLURAL_OTHER;
  },

  // #8 Slavic (Slovak, Czech)
  (n: number) => {
    if (n === 1) {
      return PLURAL_ONE;
    }

    if (between(n, 2, 4)) {
      return PLURAL_FEW;
    }

    return PLURAL_OTHER;
  },

  // #9 Slavic (Polish)
  (n: number) => {
    if (n === 1) {
      return PLURAL_ONE;
    }

    if (between(n % 10, 2, 4) && !between(n % 100, 12, 14)) {
      return PLURAL_FEW;
    }

    if (between(n % 10, 0, 1) || between(n % 10, 5, 9) || between(n % 100, 12, 14)) {
      return PLURAL_MANY;
    }

    return PLURAL_OTHER;
  },

  // #10 Slavic (Slovenian, Sorbian)
  (n: number) => {
    if (n % 100 === 1) {
      return PLURAL_ONE;
    }

    if (n % 100 === 2) {
      return PLURAL_TWO;
    }

    if (between(n % 100, 3, 4)) {
      return PLURAL_FEW;
    }

    return PLURAL_OTHER;
  },

  // #11 Celtic (Irish Gaelic)
  (n: number) => {
    if (n === 1) {
      return PLURAL_ONE;
    }

    if (n === 2) {
      return PLURAL_TWO;
    }

    if (between(n, 3, 6)) {
      return PLURAL_FEW;
    }

    if (between(n, 7, 10)) {
      return PLURAL_MANY;
    }

    return PLURAL_OTHER;
  },

  // #12 Semitic (Arabic)
  (n: number) => {
    if (n === 0) {
      return PLURAL_ZERO;
    }

    if (n === 1) {
      return PLURAL_ONE;
    }

    if (n === 2) {
      return PLURAL_TWO;
    }

    if (between(n % 100, 3, 10)) {
      return PLURAL_FEW;
    }

    if (between(n % 100, 11, 99)) {
      return PLURAL_MANY;
    }

    return PLURAL_OTHER;
  },

  // #13 Semitic (Maltese)
  (n: number) => {
    if (n === 1) {
      return PLURAL_ONE;
    }

    if (n === 0 || between(n % 100, 2, 10)) {
      return PLURAL_FEW;
    }

    if (between(n % 100, 11, 19)) {
      return PLURAL_MANY;
    }

    return PLURAL_OTHER;
  },

  // #14 Slavic (Macedonian)
  (n: number) => {
    if (n % 10 === 1) {
      return PLURAL_ONE;
    }

    return PLURAL_OTHER;
  },

  // #15 Icelandic
  (n: number) => {
    if (n % 10 === 1 && n % 100 !== 11) {
      return PLURAL_ONE;
    }

    return PLURAL_OTHER;
  },

  // #16 Celtic (Breton)
  (n: number) => {
    const m10 = n % 10;
    const m100 = n % 100;
    if (m10 === 1 && m100 !== 11 && m100 !== 71 && m100 !== 91) {
      return PLURAL_ONE;
    }

    if (m10 === 2 && m100 !== 12 && m100 !== 72 && m100 !== 92) {
      return PLURAL_TWO;
    }

    if (
      (between(m10, 3, 4) || m10 === 9) &&
      !between(m100, 10, 19) &&
      !between(m100, 70, 79) &&
      !between(m100, 90, 99)
    ) {
      return PLURAL_FEW;
    }

    if (n !== 0 && n % 1000000 === 0) {
      return PLURAL_MANY;
    }

    return PLURAL_OTHER;
  },

  // #17 Welsh (Cymraeg)
  (n: number) => {
    if (n === 0) {
      return PLURAL_ZERO;
    }

    if (n === 1) {
      return PLURAL_ONE;
    }

    if (n === 2) {
      return PLURAL_TWO;
    }

    if (n === 3) {
      return PLURAL_FEW;
    }

    if (n === 6) {
      return PLURAL_MANY;
    }

    return PLURAL_OTHER;
  },

  // #18 Slavic (Bosnian, Croatian, Serbian)
  (n: number) => {
    if (n % 10 === 1 && n % 100 !== 11) {
      return PLURAL_ONE;
    }

    if (between(n % 10, 2, 4) && !between(n % 100, 12, 14)) {
      return PLURAL_FEW;
    }

    return PLURAL_OTHER;
  },
];

export const PLURAL_RULE_KEYS_BY_LOCALE: PluralRuleMap = {
  af: 1, // Africaans
  sq: 1, // Albanian
  ar: 12, // Arabic
  eu: 1, // Basque
  br: 16, // Breton
  bg: 1, // Bulgarian
  ca: 1, // Catalan
  zh: 0, // Chinese simplified & traditional
  hr: 18, // Croatian
  cs: 8, // Czech
  da: 1, // Danish
  nl: 1, // Dutch
  en: 1, // English
  et: 1, // Estonian
  fr: 2, // French
  gl: 1, // Galician
  de: 1, // German
  fi: 1, // Finnish
  el: 1, // Greek
  he: 1, // Hebrew
  hi: 2, // Hindi
  hu: 1, // Hungarian
  is: 15, // Icelanic
  id: 0, // Indonesian
  ga: 11, // Irish Gaelic
  it: 1, // Italian
  ja: 0, // Japanese
  ko: 0, // Korean
  lv: 3, // Latvian
  lt: 6, // Lithuanian
  ms: 0, // Malay
  ml: 1, // Malayalam
  mt: 13, // Maltese
  mk: 14, // Macedonian
  nb: 1, // Norwegian bokmal
  nn: 1, // Norwegian nynorsk
  fa: 2, // Persian
  pl: 9, // Polish
  pt: 1, // Portuguese
  ro: 5, // Romanian
  ru: 7, // Russian
  gd: 4, // Scottish Gaelic
  sr: 18, // Serbian
  es: 1, // Spanish
  sk: 8, // Slovak
  sl: 10, // Slovenian
  sv: 1, // Swedish
  th: 0, // Thai
  tr: 0, // Turkish
  uk: 7, // Ukrainian
  vi: 0, // Vietnamese
  cy: 17, // Welsh
};
