import { SearchLocationParameters, SearchResultType } from '@ms/yammer-search-types';

export { SearchResultType };

export const searchQueryTextKey = 'search';
export const searchQueryGroupScopeKey = 'groupScope';
export const searchQueryCampaignIdKey = 'campaignId';
export const searchQueryStartDateKey = 'start';
export const searchQueryEndDateKey = 'end';
export const searchQueryTypeKey = 'type';
export const searchQueryTypeInboxValue = 'inbox';
export const searchQueryNetworkIdKey = 'networkId';
export const searchQueryParticipantUserIdsKey = 'participantUserIds';
export const searchQueryThreadStarterSenderIdKey = 'threadStarterSenderId';
export const searchQueryPostTypeKey = 'postType';
export const searchQueryScopeTypeKey = 'scopeType';

export interface SearchQueryParameters {
  readonly [searchQueryTextKey]?: string;
  readonly [searchQueryGroupScopeKey]?: string;
  readonly [searchQueryCampaignIdKey]?: string;
  readonly [searchQueryTypeKey]?: string;
  readonly [searchQueryStartDateKey]?: number;
  readonly [searchQueryEndDateKey]?: number;
  readonly [searchQueryNetworkIdKey]?: string;
  readonly [searchQueryParticipantUserIdsKey]?: string;
  readonly [searchQueryThreadStarterSenderIdKey]?: string;
  readonly [searchQueryPostTypeKey]?: string;
  readonly [searchQueryScopeTypeKey]?: string;
}

type MakeQueryObjectWithSearchParameters = (searchParameters: SearchLocationParameters) => SearchQueryParameters;

export const makeSearchQueryStringParameters: MakeQueryObjectWithSearchParameters = ({
  searchText,
  resultType,
  groupScope,
  campaignId,
  startDate,
  endDate,
  networkId,
  participantUserIds,
  threadStarterSenderId,
  postType,
  scopeType,
}) => {
  const searchTextParameter = { [searchQueryTextKey]: searchText };
  const typeParameter =
    resultType === SearchResultType.Inbox ? { [searchQueryTypeKey]: searchQueryTypeInboxValue } : {};
  const groupScopeParameter = groupScope ? { [searchQueryGroupScopeKey]: groupScope } : {};
  const campaignIdParameter = campaignId ? { [searchQueryCampaignIdKey]: campaignId } : {};
  const startDateParameter = startDate ? { [searchQueryStartDateKey]: startDate } : {};
  const endDateParameter = endDate ? { [searchQueryEndDateKey]: endDate } : {};
  const networkIdParameter = networkId ? { [searchQueryNetworkIdKey]: networkId } : {};
  const participantUserIdsParameter =
    participantUserIds && participantUserIds.length > 0
      ? { [searchQueryParticipantUserIdsKey]: participantUserIds.join(',') }
      : {};
  const threadStarterSenderIdParameter = threadStarterSenderId
    ? { [searchQueryThreadStarterSenderIdKey]: threadStarterSenderId }
    : {};
  const postTypeParameter = postType ? { [searchQueryPostTypeKey]: postType } : {};
  const scopeTypeParameter = scopeType ? { [searchQueryScopeTypeKey]: scopeType } : {};

  return {
    ...searchTextParameter,
    ...groupScopeParameter,
    ...campaignIdParameter,
    ...typeParameter,
    ...startDateParameter,
    ...endDateParameter,
    ...networkIdParameter,
    ...participantUserIdsParameter,
    ...threadStarterSenderIdParameter,
    ...postTypeParameter,
    ...scopeTypeParameter,
  };
};
