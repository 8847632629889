import { FeatureAccessInsightsClients } from '@ms/yammer-graphql';
import queryFeatureAccessInsights from '@ms/yammer-graphql/dist/operations/queryFeatureAccessInsights.graphql';

import { ManagedApiLifecycle, getGraphqlApiRequest, managedApiAction } from '../../api';
import { normalizeFeatureAccessInsights } from '../../normalization/graphql/normalizeFeatureAccessInsights';
import { reportError } from '../../telemetry';
import { PromiseThunkAction } from '../../types';

import {
  FeatureAccessInsightsLoadFulfilledAction,
  FeatureAccessInsightsLoadPendingAction,
  FeatureAccessInsightsLoadRejectedAction,
} from './actions.types';
import { getFeatureAccessInsightsStatus } from './selectors';

interface FeatureAccessInsightsResponse {
  readonly data: FeatureAccessInsightsClients;
}

const loadFeatureAccessInsightsPending = (): FeatureAccessInsightsLoadPendingAction => ({
  type: 'FEATURE_ACCESS_INSIGHTS.LOAD_PENDING',
});

const loadFeatureAccessInsightsFulfilled = (
  payload: FeatureAccessInsightsLoadFulfilledAction['payload']
): FeatureAccessInsightsLoadFulfilledAction => ({
  type: 'FEATURE_ACCESS_INSIGHTS.LOAD_FULFILLED',
  payload,
});

const loadFeatureAccessInsightsRejected = (error: unknown): FeatureAccessInsightsLoadRejectedAction => ({
  type: 'FEATURE_ACCESS_INSIGHTS.LOAD_REJECTED',
  payload: {
    error,
  },
  error: true,
});

const loadFeatureAccessInsightsRequest = (onBehalfOfUserId?: string) =>
  getGraphqlApiRequest({
    body: {
      operationName: 'FeatureAccessInsightsClients',
      query: queryFeatureAccessInsights,
      variables: {
        onBehalfOfUserId,
      },
    },
  });

type LoadFeatureAccessInsightsLifecycle = (
  onBehalfOfUserId?: string
) => ManagedApiLifecycle<FeatureAccessInsightsResponse>;

const loadFeatureAccessInsightsLifecycle: LoadFeatureAccessInsightsLifecycle = (onBehalfOfUserId) => ({
  pending: () => loadFeatureAccessInsightsPending(),
  fulfilled: (rawFeatureAccessInsightsResponse) => {
    const payload = normalizeFeatureAccessInsights(rawFeatureAccessInsightsResponse, onBehalfOfUserId);

    return loadFeatureAccessInsightsFulfilled(payload);
  },
  rejected: (error) => loadFeatureAccessInsightsRejected(error),
});

interface LoadOptions {
  readonly onBehalfOfUserId?: string;
}
type Load = (options: LoadOptions) => PromiseThunkAction<void>;
export const load: Load =
  ({ onBehalfOfUserId }) =>
  async (dispatch, getState) => {
    const state = getState();
    const status = getFeatureAccessInsightsStatus(state);

    if (status === 'pending') {
      return;
    }

    const action = managedApiAction({
      request: loadFeatureAccessInsightsRequest(onBehalfOfUserId),
      lifecycle: loadFeatureAccessInsightsLifecycle(onBehalfOfUserId),
    });

    try {
      await dispatch(action);
    } catch (error) {
      reportError({ error });
    }
  };
