/*

AnnouncerContent: See companion Announcer for details: that class manages the state, this class just
does the HTML rendering.

*/

import React from 'react';

import ScreenreaderText from '../ScreenreaderText';

/**
 * Ready - DIV with content has been hidden. Remain here till new string needs to be announced.
 * Cleared - DIV has been cleared. Transition to this when adding a new string, then proceed to
 *           Added. This intermediate cleared state is needed to ensure the same string can be
 *           announced twice in a row.
 * Added - DIV with content has been added. Pause then proceed to Ready
 */

export enum AnnouncerContentState {
  Ready,
  Cleared,
  Added,
}

export interface AnnouncerContentProps {
  readonly text: string;
  readonly state: AnnouncerContentState;
}

const AnnouncerContent: React.FC<AnnouncerContentProps> = ({ text, state }) => {
  const ariaHidden = state === AnnouncerContentState.Ready ? true : undefined;
  const contents = state === AnnouncerContentState.Cleared ? null : <div aria-hidden={ariaHidden}>{text}</div>;

  return (
    <ScreenreaderText>
      <div aria-live="assertive" aria-atomic="true" aria-relevant="additions">
        {contents}
      </div>
    </ScreenreaderText>
  );
};

export default AnnouncerContent;
