import { load as loadMultiTenantOrganization } from '@ms/yammer-data/dist/domains/multiTenantOrganization/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useMtoEnabled } from './selectors';

type UseLoadMultiTenantOrganization = () => void;
export const useLoadMultiTenantOrganization: UseLoadMultiTenantOrganization = () => {
  const isMultiTenantOrganizationEnabled = useMtoEnabled();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isMultiTenantOrganizationEnabled) {
      return;
    }
    dispatch(loadMultiTenantOrganization());
  }, [dispatch, isMultiTenantOrganizationEnabled]);
};
