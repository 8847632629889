import { loadTheme } from '@fluentui/react/lib/Styling';
import { useEffect } from 'react';

import { ITheme } from '../../../util/theme';

type UseLoadTheme = (suppressGlobalStyles: boolean, theme: ITheme) => void;
export const useLoadTheme: UseLoadTheme = (suppressGlobalStyles, theme) =>
  useEffect(() => {
    if (!suppressGlobalStyles) {
      loadTheme(theme);
    }
  }, [theme, suppressGlobalStyles]);
