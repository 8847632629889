import ErrorBoundary, { ErrorBoundaryName } from '@ms/yammer-libs-error-boundary';
import React, { FC } from 'react';

import VivaAppBarExperimentWrapper from './VivaAppBarExperimentWrapper';

const VivaAppBarBoundary: FC = () => (
  <ErrorBoundary name={ErrorBoundaryName.VivaAppBar}>
    <VivaAppBarExperimentWrapper />
  </ErrorBoundary>
);

export default VivaAppBarBoundary;
