import { reportAnalyticsEvent } from '@ms/yammer-data/dist/telemetry';
import { TelemetryContextProvider, TelemetryOptions } from '@ms/yammer-web-support/dist/telemetry';
import React, { FC } from 'react';

const telemetryContextProviderValue: TelemetryOptions = {
  reportAnalyticsEvent,
};

const ReduxTelemetryContextProvider: FC = ({ children }) => (
  <TelemetryContextProvider value={telemetryContextProviderValue}>{children}</TelemetryContextProvider>
);

export default ReduxTelemetryContextProvider;
