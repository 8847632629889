import { SyntheticEvent, useCallback } from 'react';

import { useExternalLinkClickHandlerContext } from './ExternalLinkClickHandlerContext';

type UseExternalLinkClickHandler = (
  href: string,
  onClick?: (event: SyntheticEvent) => void
) => (event: SyntheticEvent) => void;
export const useExternalLinkClickHandler: UseExternalLinkClickHandler = (href, onClick) => {
  const externalLinkClickHandler = useExternalLinkClickHandlerContext();

  return useCallback(
    (event: SyntheticEvent) => {
      if (onClick) {
        onClick(event);
      }

      if (externalLinkClickHandler) {
        externalLinkClickHandler(event, href);
      }
    },
    [externalLinkClickHandler, href, onClick]
  );
};
