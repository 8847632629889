import FavoritesLimitReachedDialog from '@ms/yammer-web-components/dist/group/FavoritesLimitReachedDialog';
import UsagePolicyDialog from '@ms/yammer-web-components/dist/usagePolicy/UsagePolicyDialog';
import React, { FC } from 'react';

import FirstRunExperience from '../FirstRunExperience';

const Dialogs: FC = () => (
  <>
    <FirstRunExperience />
    <UsagePolicyDialog />
    <FavoritesLimitReachedDialog />
  </>
);

export default Dialogs;
