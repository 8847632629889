import { EntityStatus, getStatusForId } from '@ms/yammer-data-support';

import { User, YammerState } from '../../state/types';
import { createFindById } from '../findById';

export const findById = createFindById('userInformationBarrierSettings');

type GetUserInformationBarrierSettingsStatus = (state: YammerState, userId: User['id']) => EntityStatus;
export const getUserInformationBarrierSettingsStatus: GetUserInformationBarrierSettingsStatus = (state, userId) =>
  getStatusForId(state.userInformationBarrierSettings, userId);

type HasInformationBarrierRestrictions = (state: YammerState, userId: User['id']) => boolean;
export const hasInformationBarrierRestrictions: HasInformationBarrierRestrictions = (state, userId) =>
  findById(state, userId)?.hasInformationBarrierRestrictions ?? false;
