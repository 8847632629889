import { useCurrentAnnouncement } from '@ms/yammer-hooks/dist/announcements/selectors';
import { useClearAnnouncementsCallback } from '@ms/yammer-hooks/dist/announcements/useClearAnnouncementsCallback';
import { useAnnouncer } from '@ms/yammer-web-core-ui/dist/components/Announcer';
import { useEffect } from 'react';

type UseReduxAnnouncerBridge = () => void;
export const useReduxAnnouncerBridge: UseReduxAnnouncerBridge = () => {
  const currentAnnouncement = useCurrentAnnouncement();
  const clearAnnouncements = useClearAnnouncementsCallback();
  const announce = useAnnouncer();

  useEffect(() => {
    if (currentAnnouncement) {
      announce(currentAnnouncement);
      clearAnnouncements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnnouncement]);
};
