import createCachedSelector from 're-reselect';

import { ClientSharePointWebLinkEmbed, YammerState } from '../../state/types';
import { createFindById, createFindByIdThrows } from '../findById';

export const findById = createFindById('clientSharePointWebLinkEmbeds');
export const findByIdThrows = createFindByIdThrows('clientSharePointWebLinkEmbeds');

type GetFulfilledClientSharePointWebLinkEmbeds = (state: YammerState) => Record<string, ClientSharePointWebLinkEmbed>;
export const getFulfilledClientSharePointWebLinkEmbeds: GetFulfilledClientSharePointWebLinkEmbeds = (state) =>
  state.clientSharePointWebLinkEmbeds.fulfilled.byId;

const getRejectedClientSharePointWebLinkEmbeds = (state: YammerState) =>
  state.clientSharePointWebLinkEmbeds.rejected.byId;

type GetRejectedClientSharePointWebLinkEmbedsIds = (state: YammerState) => ClientSharePointWebLinkEmbed['id'][];
export const getRejectedClientSharePointWebLinkEmbedsIds: GetRejectedClientSharePointWebLinkEmbedsIds =
  createCachedSelector(getRejectedClientSharePointWebLinkEmbeds, (rejectedClientSharePointWebLinkEmbeds) =>
    Object.keys(rejectedClientSharePointWebLinkEmbeds)
  )((_state) => 'cache');
