import { reportError } from '@ms/yammer-data/dist/telemetry';
import Null from '@ms/yammer-libs-null';
import React, { PureComponent, ReactNode } from 'react';

import { ErrorBoundaryName, ErrorComponent } from './types';

export interface MappedDispatchProps {
  readonly logComponentError: (componentName: string, error: unknown) => void;
}

export interface OwnProps {
  readonly children: ReactNode;
  readonly name: ErrorBoundaryName;
  readonly ErrorComponent?: ErrorComponent;
}

type ErrorBoundaryProps = OwnProps & MappedDispatchProps;

export interface ErrorBoundaryState {
  readonly error: unknown;
}

export default class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    error: undefined,
  };

  public static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  public componentDidCatch(error: unknown) {
    this.props.logComponentError(this.props.name, error);

    reportError({
      error,
      eventProperties: {
        errorBoundaryName: this.props.name,
        errorCode: 'ErrorBoundary',
      },
    });
  }

  public render() {
    const { ErrorComponent, children } = this.props;

    const ErrorComponentOrDefault = ErrorComponent || Null;

    return this.state.error ? (
      <div className="qaErrorBoundary">
        <ErrorComponentOrDefault error={this.state.error} />
      </div>
    ) : (
      children
    );
  }
}
