import { Group, Publisher, YammerState, isEditPublisher, isEventPublisher, isGroupPublisher } from '../../state/types';
import { findByIdThrows as findMessageThrows } from '../messages/selectors';
import { findByIdThrows as findThread } from '../threads/selectors';

import { findByIdThrows as getPublisherThrows } from './findPublisherSelectors';

type GetGroupId = (state: YammerState, publisherId: Publisher['id']) => Group['id'] | undefined | null;
export const getGroupId: GetGroupId = (state, publisherId) => {
  const publisher = getPublisherThrows(state, publisherId);

  if (isGroupPublisher(publisher) || isEventPublisher(publisher)) {
    return publisher.groupId;
  }

  if (isEditPublisher(publisher)) {
    const { threadId } = findMessageThrows(state, publisher.messageId);
    const { groupId } = findThread(state, threadId);

    return groupId;
  }

  return;
};
