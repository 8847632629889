import { useMemo } from 'react';

import { useIsVivaInsightsNetworkEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useInsightsNetworkPageSettings: UseMainUIFeatureSettings<'insightsNetworkPage'> = (options) => {
  const insightsNetworkPageOverrides = options?.overrides?.insightsNetworkPage;

  const isInsightsNetworkEnabled = useIsVivaInsightsNetworkEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: isInsightsNetworkEnabled,
      ...insightsNetworkPageOverrides,
    }),
    [isInsightsNetworkEnabled, insightsNetworkPageOverrides]
  );
};
