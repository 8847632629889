import React, { FC } from 'react';

import { getClassNames } from './FluentUISystemIcon.styles.legacy';
import { FluentUISystemIconProps } from './FluentUISystemIcon.types';

const FluentUISystemIcon: FC<FluentUISystemIconProps> = ({ block = false, className, color, size, Icon, testId }) => (
  <Icon className={getClassNames(block, color, size, className).fluentUISystemIcon} data-testid={testId} />
);

export default FluentUISystemIcon;
