import { lazyLoadOnRender } from '@ms/yammer-libs-lazy';
import Null from '@ms/yammer-libs-null';
import React from 'react';

export { useDispatchToast } from './hooks';

const LoadableToaster = lazyLoadOnRender({
  loader: () =>
    import(/* webpackChunkName: "4-feat-toast" */ './Toast').then((module) => ({ default: module.Toaster })),
  loading: Null,
});

export const Toaster = () => <LoadableToaster />;
