import { makeStyles, mergeClasses, shorthands } from '@griffel/react';
import { pxToRemString } from '@ms/yammer-web-core-ui/dist/util/styles/fonts';
import { fluentLayerZIndex } from '@ms/yammer-web-core-ui/dist/util/styles/zIndex';

const useClasses = makeStyles({
  root: {
    wordWrap: 'normal',
    textTransform: 'none',
    ...shorthands.border('0'),
    clip: 'rect(0 0 0 0)',
    height: '1px',
    ...shorthands.margin('-1px'),
    ...shorthands.overflow('hidden'),
    ...shorthands.padding('0'),
    position: 'absolute',
    width: '1px',
    display: 'block',
  },
  focused: {
    position: 'fixed',
    width: 'auto',
    height: 'auto',
    ...shorthands.margin('10px'),
    ...shorthands.padding('6px', '10px'),
    textAlign: 'left',
    backgroundColor: '#fcefd3',
    ...shorthands.border('solid', '1px', '#ffddb3'),
    ...shorthands.borderRadius('2px'),
    fontSize: pxToRemString(12),
    lineHeight: pxToRemString(16),
    clip: 'auto',
    zIndex: fluentLayerZIndex + 1,
  },
  topLeft: {
    left: '0px',
    top: '50px',
  },
});

export const useClassNames = (containsFocus: boolean, alignWithContent: boolean) => {
  const classes = useClasses();

  return {
    root: mergeClasses(
      classes.root,
      containsFocus && classes.focused,
      containsFocus && !alignWithContent && classes.topLeft
    ),
  };
};
