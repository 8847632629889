import { unregister as unregisterSubscription } from '@ms/yammer-data/dist/domains/pushSubscriptions/actions';
import { unsubscribe } from '@ms/yammer-web-support/dist/pushNotifications';
import { MutableRefObject, useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useDeletePushSubscriptionCallback = (
  subscription: MutableRefObject<PushSubscription | null | undefined>
) => {
  const dispatch = useDispatch();

  return useCallback(async () => {
    if (subscription.current == null) {
      return;
    }

    await unsubscribe(subscription.current);
    await dispatch(unregisterSubscription(subscription.current));
    subscription.current = null;
  }, [subscription, dispatch]);
};
