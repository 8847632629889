import { useDisplayableDocumentHeaderCount } from '@ms/yammer-hooks/dist/feedCounts';
import { uiFeatureAccessSymbol, useUIFeatures } from '@ms/yammer-web-ui-features/dist/features';

type UseGetHeaderCount = () => string | undefined;
export const useGetHeaderCount: UseGetHeaderCount = () => {
  const features = useUIFeatures();

  return useDisplayableDocumentHeaderCount({
    showInboxCount: features.inboxPage?.[uiFeatureAccessSymbol] === true,
    showNotificationCount: features.navigation?.notifications === true,
  });
};
