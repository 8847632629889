import { getStatusForId as getStatus } from '@ms/yammer-data-support';

import { Group, GroupCoverImage, YammerState } from '../../state/types';
import { createFindById, createFindByIdThrows } from '../findById';

export const findById = createFindById('groupCoverImages');
export const findByIdThrows = createFindByIdThrows('groupCoverImages');

type IsFulfilled = (state: YammerState, groupId: Group['id']) => boolean;
export const isFulfilled: IsFulfilled = (state, groupId) => getStatus(state.groupCoverImages, groupId) === 'fulfilled';

type GetFulfilledCoverImages = (state: YammerState) => Record<string, GroupCoverImage>;
export const getFulfilledCoverImages: GetFulfilledCoverImages = (state) => state.groupCoverImages.fulfilled.byId;
