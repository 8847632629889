import { NavBarLinkData } from '@1js/suiteux-shell-react';
import { getStatus } from '@ms/yammer-data-support';

import { EntityStatus, SuiteHeader, YammerState } from '../../state/types';

type GetSuiteHeader = (state: YammerState) => SuiteHeader['fulfilled'];
export const getSuiteHeader: GetSuiteHeader = (state) => state.suiteHeader.fulfilled;

type GetSuiteHeaderSignOutLink = (state: YammerState) => NavBarLinkData | undefined;
export const getSuiteHeaderSignOutLink: GetSuiteHeaderSignOutLink = (state) => state.suiteHeader.fulfilled?.SignOutLink;

type GetSuiteHeaderStatus = (state: YammerState) => EntityStatus;
export const getSuiteHeaderStatus: GetSuiteHeaderStatus = (state) => getStatus(state.suiteHeader);
