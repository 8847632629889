import { UserFieldsWithViewerIsFollowing } from '@ms/yammer-graphql';

import { ViewerIsFollowingUser } from '../../../state/types';

export const processViewerIsFollowingUser: (value: UserFieldsWithViewerIsFollowing) => ViewerIsFollowingUser = (
  value
) => ({
  id: value.id,
  isFollowing: value.viewerIsFollowing,
});
