import React, { FC } from 'react';

import { useTheme } from '../../../../util/theme';
import { FlexItem } from '../../../Flex';
import AppLayoutRow from '../../AppLayoutRow';

import { getClassNames } from './HeaderRow.styles.legacy';
import { HeaderRowProps } from './HeaderRow.types';

const HeaderRow: FC<HeaderRowProps> = ({ children, depth = 'depth4' }) => {
  const theme = useTheme();
  const classNames = getClassNames(theme, depth);

  if (!children) {
    return null;
  }

  return (
    <AppLayoutRow role="banner" zIndex={4}>
      <FlexItem grow={1} className={classNames.header}>
        {children}
      </FlexItem>
    </AppLayoutRow>
  );
};
export default HeaderRow;
