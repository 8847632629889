import { HomeFeedSwitcher } from '@ms/yammer-features-app-navigation';
import { useCurrentSessionUser } from '@ms/yammer-hooks/dist/session';
import VivaEngageAppInbox from '@ms/yammer-web-components/dist/inbox/VivaEngageAppInbox';
import VivaEngageAppNotifications from '@ms/yammer-web-components/dist/notifications/VivaEngageAppNotifications';
import React, { ReactNode, useMemo } from 'react';

type UseHomeLinkActionButtons = () => ReactNode[];
export const useHomeLinkActionButtons: UseHomeLinkActionButtons = () => {
  const { homeFeedPreference } = useCurrentSessionUser();

  return useMemo(
    () => [
      <HomeFeedSwitcher key="FeedSwitcher" selectedKey={homeFeedPreference} />,
      <VivaEngageAppInbox key="Inbox" />,
      <VivaEngageAppNotifications key="Notifications" />,
    ],
    [homeFeedPreference]
  );
};
