import { LIST_SEPARATOR_LAST, LIST_SEPARATOR_MIDDLE, LIST_SEPARATOR_START, LIST_SEPARATOR_TWO } from './constants';
import { lookup } from './lookup';
import { separateAll } from './string';
import { PhraseMap } from './types';

/**
 * Returns the list of items joined using the appropriate separator. If strings for separators are not found, it throws.
 *
 * @param items Items to join.
 */
type Join = <T>(locale: string, phrases: PhraseMap, items: T[]) => (string | T)[];
export const join: Join = (locale, phrases, items) => {
  switch (items.length) {
    case 0:
      return items;
    case 1:
      return items;
    case 2:
      return [items[0], lookup(locale, phrases, LIST_SEPARATOR_TWO), items[1]];
    case 3:
      return [
        items[0],
        lookup(locale, phrases, LIST_SEPARATOR_START),
        items[1],
        lookup(locale, phrases, LIST_SEPARATOR_LAST),
        items[2],
      ];
    default:
      return [
        items[0],
        lookup(locale, phrases, LIST_SEPARATOR_START),
        ...separateAll(items.slice(1, items.length - 1), lookup(locale, phrases, LIST_SEPARATOR_MIDDLE)),
        lookup(locale, phrases, LIST_SEPARATOR_LAST),
        items[items.length - 1],
      ];
  }
};

/**
 * Returns the list of items joined using the appropriate separator. If strings for separators are not found, it joins
 * the list of items using `, `.
 *
 * @param items Items to join.
 */
type JoinSafe = <T>(locale: string, phrases: PhraseMap, items: T[]) => (string | T)[];
export const joinSafe: JoinSafe = (locale, phrases, items) => {
  try {
    return join(locale, phrases, items);
  } catch (err) {
    return separateAll(items, ', ');
  }
};

/**
 * Returns the list of items joined using the comma separator. If strings for separators are not found, it throws.
 *
 * @param items Items to join.
 */
type SimpleJoin = <T>(locale: string, phrases: PhraseMap, items: T[]) => (string | T)[];
export const simpleJoin: SimpleJoin = (locale, phrases, items) =>
  separateAll(items, lookup(locale, phrases, LIST_SEPARATOR_MIDDLE));

/**
 * Returns the list of items joined using the comma separator. If strings for separators are not found, it joins
 * the list of items using `, `.
 *
 * @param items Items to join.
 */
type SimpleJoinSafe = <T>(locale: string, phrases: PhraseMap, items: T[]) => (string | T)[];
export const simpleJoinSafe: SimpleJoinSafe = (locale, phrases, items) => {
  try {
    return simpleJoin(locale, phrases, items);
  } catch (err) {
    return separateAll(items, ', ');
  }
};

/**
 * Convenience helper for joining strings - returns string, so can be used in templates.
 */
type JoinStringsSafe = (locale: string, phrases: PhraseMap, items: string[]) => string;
export const joinStringsSafe: JoinStringsSafe = (locale, phrases, items) => joinSafe(locale, phrases, items).join('');

type SimpleJoinStringsSafe = (locale: string, phrases: PhraseMap, items: string[]) => string;
export const simpleJoinStringsSafe: SimpleJoinStringsSafe = (locale, phrases, items) =>
  simpleJoinSafe(locale, phrases, items).join('');
