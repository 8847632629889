import { ComponentWithLegacyClassNameProps } from '../../util/ComponentWithLegacyClassName/props';

export interface SVGIconProps extends ComponentWithLegacyClassNameProps {
  /**
   * Sets icon style to `display: block`. This is helpful if the inline icon's `line-height` is
   * taller than the current line's `line-height`, and is creating extra vertical space.
   */
  readonly block?: boolean;

  /**
   * Manual color override (by default the icon inherits its parent's color). Can be any valid CSS
   * color.
   */
  readonly color?: string;

  /**
   * Icon size, which also accounts for `font-size` and `line-height`. If not set, size will
   * match the nearest <Block> or <Text> parent with textSize specified.
   */
  readonly size?: IconSize;

  /**
   * TestId, will be rendered on DOM as `data-testid`, which is commonly used by `...ByTestId`
   * in React Testing Library, to query specific DOM element.
   */
  readonly testId?: string;
}

export enum IconSize {
  XXXLarge = 42,
  XXLarge = 32,
  XLarge = 24,
  Large = 20,
  Medium = 16,
  Small = 14,
  XSmall = 12,
  XXSmall = 10,
  XXXSmall = 8,
}
