import createCachedSelector from 're-reselect';

import { YammerI18nState } from '../../state/types';
import { getCurrentLocale } from '../locales/selectors';

import { DateTimeFormatType, dateTimeFormatOptionsMap } from './shared';

export const getDateTimeFormatter = createCachedSelector(
  getCurrentLocale,
  (_state: YammerI18nState, formatType: DateTimeFormatType) => formatType,
  (locale, formatType) => new Intl.DateTimeFormat(locale, dateTimeFormatOptionsMap[formatType])
)((_state, formatType) => formatType);
