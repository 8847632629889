// eslint-disable-next-line @ms/forbid-file-names
import { getNetworkContext } from '@ms/yammer-data/dist/domains/networkContext/selectors';
import { Store } from '@ms/yammer-data/dist/state/types';
import { basenameForWebApp } from '@ms/yammer-web-support/dist/location';
import { getBasenameWithNetworkContext } from '@ms/yammer-web-support/dist/urls';

import { YammerWebState } from '../../../state/types';

type GetBasename = (store: Store<YammerWebState>) => string;
export const getBasename: GetBasename = (store) => {
  const { getState } = store;
  const state = getState();
  const currentNetworkContext = getNetworkContext(state);

  if (currentNetworkContext) {
    return getBasenameWithNetworkContext(basenameForWebApp, getNetworkContext(state));
  }

  return basenameForWebApp;
};
