import React from 'react';

import { useCurrentRoute } from './useCurrentRoute';

/**
 * Scrolls the given element to the top when the current route changes.
 */
export const useScrollToTopOnRouteChange = (element: HTMLElement | null) => {
  const currentRoute = useCurrentRoute();

  // eslint-disable-next-line @ms/no-react-namespace
  React.useLayoutEffect(() => {
    if (element) {
      element.scrollTo(0, 0);
    }
  }, [currentRoute, element]);
};
