import { useExperimentTreatmentWithoutReporting } from '@ms/yammer-hooks/dist/experiments';
import { usePermanentAppState } from '@ms/yammer-modules-townhall-event';
import { getEventBroadcastIdFromPath } from '@ms/yammer-web-support/dist/location';
import React, { FC, useEffect } from 'react';

import App from '../App';
import AppLiveEvent from '../AppLiveEvent';

const Main: FC = () => {
  const canRenderLiveEventsApp = useExperimentTreatmentWithoutReporting('LiveEventsScaleLoadPerf') === true;
  const broadcastId = getEventBroadcastIdFromPath(window.location.pathname);
  const { permanentApp, setPermanentApp } = usePermanentAppState();

  useEffect(() => {
    if (!permanentApp) {
      setPermanentApp(broadcastId && canRenderLiveEventsApp ? 'AppLiveEvent' : 'App');
    }
  }, [broadcastId, canRenderLiveEventsApp, permanentApp, setPermanentApp]);

  if (permanentApp === 'AppLiveEvent') {
    return <AppLiveEvent />;
  }

  if (permanentApp === 'App') {
    return <App />;
  }

  return null;
};

export default Main;
