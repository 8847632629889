import { useMemo } from 'react';

import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useArticlePageSettings: UseMainUIFeatureSettings<'articlePage'> = (options) => {
  const articlePageOverrides = options?.overrides?.articlePage;

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: true,
      ...articlePageOverrides,
    }),
    [articlePageOverrides],
  );
};
