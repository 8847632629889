import { useMemo } from 'react';

import { useIsLeadersAdminEnabled, useIsQuestionsOnlyMode } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useLeadersAdminPageSettings: UseMainUIFeatureSettings<'leadersAdminPage'> = (options) => {
  const leadersAdminPageOverrides = options?.overrides?.leadersAdminPage;

  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();
  const isLeadersAdminEnabled = useIsLeadersAdminEnabled();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        ...leadersAdminPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: isLeadersAdminEnabled,
      ...leadersAdminPageOverrides,
    };
  }, [leadersAdminPageOverrides, isLeadersAdminEnabled, isQuestionsOnlyMode]);
};
