import { lazyLoadOnRender } from '@ms/yammer-libs-lazy';

const GlobalCopilotDrawer = async () => {
  const { GlobalCopilotDrawer } = await import(
    /* webpackChunkName: "4-feat-global-copilot-drawer" */ './GlobalCopilotDrawer'
  );

  return { default: GlobalCopilotDrawer };
};

export const LoadableGlobalCopilotDrawer = lazyLoadOnRender({
  loader: GlobalCopilotDrawer,
});
