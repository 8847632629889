import type { Experiment, ExperimentTreatmentValue, FeatureRollout } from '@ms/yammer-data/dist/state/types';
import React, { FC, createContext, memo, useContext, useEffect, useMemo } from 'react';

export interface ExperimentContextType {
  useExperimentTreatment(experiment: Experiment): ExperimentTreatmentValue;
  useReportExperimentTreatmentCallback(experiment: Experiment, treatment: ExperimentTreatmentValue): () => void;
  useIsFeatureRolloutEnabled(featureRollout: FeatureRollout): boolean;
  useAndReportExperimentTreatment(experiment: Experiment): ExperimentTreatmentValue;
}

const ExperimentContext = createContext<ExperimentContextType | undefined>(undefined);

const useExperimentContext = () => {
  const experimentContext = useContext(ExperimentContext);

  if (experimentContext == null) {
    throw new Error('ExperimentContext has not been initialized');
  }

  return experimentContext;
};

type UseExperimentTreatment = ExperimentContextType['useExperimentTreatment'];
export const useExperimentTreatment: UseExperimentTreatment = (experiment) =>
  useExperimentContext().useExperimentTreatment(experiment);

type UseReportExperimentTreatmentCallback = ExperimentContextType['useReportExperimentTreatmentCallback'];
export const useReportExperimentTreatmentCallback: UseReportExperimentTreatmentCallback = (experiment, treatment) =>
  useExperimentContext().useReportExperimentTreatmentCallback(experiment, treatment);

type UseIsFeatureRolloutEnabled = ExperimentContextType['useIsFeatureRolloutEnabled'];
export const useIsFeatureRolloutEnabled: UseIsFeatureRolloutEnabled = (featureRollout) =>
  useExperimentContext().useIsFeatureRolloutEnabled(featureRollout);

type UseAndReportExperimentTreatment = (experiment: Experiment) => ExperimentTreatmentValue;
export const useAndReportExperimentTreatment: UseAndReportExperimentTreatment = (experiment) => {
  const experimentTreatment = useExperimentTreatment(experiment);
  const reportExperimentTreatment = useReportExperimentTreatmentCallback(experiment, experimentTreatment);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reportExperimentTreatment, [experimentTreatment]);

  return experimentTreatment;
};

export const ExperimentContextProvider: FC<ExperimentContextType> = memo(
  ({
    children,
    useExperimentTreatment,
    useReportExperimentTreatmentCallback,
    useIsFeatureRolloutEnabled,
    useAndReportExperimentTreatment,
  }) => {
    const experimentContext = useMemo(
      () => ({
        useExperimentTreatment,
        useReportExperimentTreatmentCallback,
        useIsFeatureRolloutEnabled,
        useAndReportExperimentTreatment,
      }),
      [
        useExperimentTreatment,
        useReportExperimentTreatmentCallback,
        useIsFeatureRolloutEnabled,
        useAndReportExperimentTreatment,
      ]
    );

    return <ExperimentContext.Provider value={experimentContext}>{children}</ExperimentContext.Provider>;
  }
);
