import { InformationBarrierSettingsClients_user_User } from '@ms/yammer-graphql';

import { UserInformationBarrierSettings } from '../../../state/userInformationBarrierSettings/types';

type InformationBarrierRestrictionsFields = InformationBarrierSettingsClients_user_User;

export const processUserInformationBarrierSettings: (
  value: InformationBarrierRestrictionsFields
) => UserInformationBarrierSettings = (value) => ({
  id: value.id,
  hasInformationBarrierRestrictions: value.hasInformationBarrierRestrictions,
});
