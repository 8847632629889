import { SizeQueriesMap } from './useMeasureContainerWithResizeObserver';

const getNumericEnumValues = (numericEnum: Record<string, unknown>): number[] =>
  Object.values(numericEnum).filter((item) => !isNaN(Number(item))) as number[];

const compareNumbers = (a: number, b: number) => a - b;

const addNumericEnumValuesToList = (list: number[], numericEnum: Record<string, unknown>): number[] => [
  ...list,
  ...getNumericEnumValues(numericEnum),
];

export const buildContainerQueryFromBreakpoints = (
  breakpointEnums: Record<string, unknown>[]
): SizeQueriesMap<string> => {
  const orderedBreakpointValues: number[] = breakpointEnums.reduce(addNumericEnumValuesToList, []).sort(compareNumbers);

  const queryObject: SizeQueriesMap<string> = {};

  const smallestBreakpoint = orderedBreakpointValues[0];
  queryObject[`lessThan${smallestBreakpoint}`] = { maxWidth: smallestBreakpoint - 1 };

  for (let i = 0; i < orderedBreakpointValues.length - 1; i++) {
    queryObject[`from${orderedBreakpointValues[i]}to${orderedBreakpointValues[i + 1] - 1}`] = {
      minWidth: orderedBreakpointValues[i],
      maxWidth: orderedBreakpointValues[i + 1] - 1,
    };
  }

  const largestBreakpoint = orderedBreakpointValues[orderedBreakpointValues.length - 1];
  queryObject[`atLeast${largestBreakpoint}`] = {
    minWidth: largestBreakpoint,
  };

  return queryObject;
};
