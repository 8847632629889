import { GroupHeaderFields } from '@ms/yammer-graphql';

import { GroupCoverImage } from '../../../state/types';

export const processGroupCoverImage: (value: GroupHeaderFields) => GroupCoverImage = ({
  id,
  coverImageUrlTemplate: backgroundUrl,
  defaultCoverImageUrlTemplate: fallbackBackgroundUrl,
  hasDefaultCoverImage,
}) => ({
  id,
  hasDefaultCoverImage,
  backgroundUrl,
  fallbackBackgroundUrl,
});
