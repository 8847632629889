import { Message, PushNotificationsSettings, YammerState } from '../../state/types';
import { findByIdThrows as findMessage, hasMentionedMe, isCurrentUserAuthor } from '../messages/selectors';
import { findByIdThrows as findThread } from '../threads/selectors';

type GetSettings = (state: YammerState) => PushNotificationsSettings;
export const getSettings: GetSettings = (state) => state.pushNotificationsSettings;

type ShouldShowNotificationForMessage = (state: YammerState, messageId: Message['id']) => boolean;
export const shouldShowNotificationForMessage: ShouldShowNotificationForMessage = (state, messageId) => {
  const message = findMessage(state, messageId);
  const thread = findThread(state, message.threadId);

  const isCurrentUser = isCurrentUserAuthor(state, messageId);
  const isMention = hasMentionedMe(state, messageId);
  const { isAnnouncement } = thread;

  return !isCurrentUser && (isMention || isAnnouncement);
};
