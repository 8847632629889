import { load } from '@ms/yammer-data/dist/domains/featureAccessInsights/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSelectedOnBehalfOfUserId } from '../analyticsSettings/selectors';
import { useExperimentTreatmentWithoutReporting } from '../experiments';

import { useFeatureAccessInsights, useFeatureAccessInsightsStatus } from '.';

interface UseLoadFeatureAccessInsightsOptions {
  readonly vivaEngagePremiumOnly: boolean;
}

type UseLoadFeatureAccessInsights = (options?: UseLoadFeatureAccessInsightsOptions) => void;
export const useLoadFeatureAccessInsights: UseLoadFeatureAccessInsights = (options) => {
  const dispatch = useDispatch();
  const featureAccessInsights = useFeatureAccessInsights();
  const status = useFeatureAccessInsightsStatus();
  const selectedOnBehalfOfUserId = useSelectedOnBehalfOfUserId();
  const vivaEngagePremiumExperiment = useExperimentTreatmentWithoutReporting('VivaEngagePremium');
  const loadDisallowed = !!options?.vivaEngagePremiumOnly && !vivaEngagePremiumExperiment;

  useEffect(() => {
    const isFulfilledAndSameOnBehalfOfUserId =
      featureAccessInsights?.onBehalfOfUserId === selectedOnBehalfOfUserId && status === 'fulfilled';

    if (isFulfilledAndSameOnBehalfOfUserId || loadDisallowed || status === 'rejected') {
      return;
    }

    dispatch(load({ onBehalfOfUserId: selectedOnBehalfOfUserId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureAccessInsights?.onBehalfOfUserId, selectedOnBehalfOfUserId, status, loadDisallowed]);
};
