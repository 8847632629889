import { useMemo } from 'react';

import { useIsQuestionsOnlyMode, useIsTownhallEventEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useTownhallCreateEventPageSettings: UseMainUIFeatureSettings<'townhallCreateEventPage'> = (options) => {
  const townhallCreateEventPageOverrides = options?.overrides?.townhallCreateEventPage;

  const isTownhallEventEnabled = useIsTownhallEventEnabled();
  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        ...townhallCreateEventPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: isTownhallEventEnabled,
      ...townhallCreateEventPageOverrides,
    };
  }, [isTownhallEventEnabled, isQuestionsOnlyMode, townhallCreateEventPageOverrides]);
};
