import { isEngageDomainEnabled, shouldUseEngageDomain } from '@ms/yammer-data/dist/domains/domainMigration/selectors';

import { useSelector } from '../useSelector';

type UseShouldUseEngageDomain = () => boolean;
export const useShouldUseEngageDomain: UseShouldUseEngageDomain = () =>
  useSelector((state) => shouldUseEngageDomain(state));

export const useIsEngageDomainEnabled: UseShouldUseEngageDomain = () =>
  useSelector((state) => isEngageDomainEnabled(state));
