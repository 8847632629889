import { useGetProductName } from '@ms/yammer-hooks/dist/rebranding';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

import AppDocumentLanguage from '../AppDocumentLanguage';
import { faviconDotPath, faviconPath, useGetHeaderCount } from '../hooks';

/**
 * Component that updates the language, text direction and shortcut icon of the `<html>` tag.
 */
const AppDocumentHeadOverrides: FC = () => {
  const productName = useGetProductName();
  const displayableHeaderCount = useGetHeaderCount();

  const faviconHref = displayableHeaderCount ? faviconDotPath : faviconPath;

  return (
    <>
      <AppDocumentLanguage />
      <Helmet defaultTitle={productName} titleTemplate={`${productName} - %s`} defer={false}>
        <link rel="shortcut icon" href={faviconHref} />
      </Helmet>
    </>
  );
};

export default AppDocumentHeadOverrides;
