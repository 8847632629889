import { Icon as FluentIcon, IIconProps } from '@fluentui/react/lib/Icon';
import React, { FC } from 'react';

export * from '@fluentui/react/lib/Icon';
export type { IImageProps } from '@fluentui/react/lib/Image';
export type { IconNamesInput as IconName } from '@fluentui/font-icons-mdl2/lib/IconNames';

/**
 * @deprecated Please Fluent9 `FluentUISystemIcon` wherever possible
 */
const Icon: FC<IIconProps> = (props) => <FluentIcon {...props} />;
export default Icon;
