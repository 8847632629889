import { IPalette, ISemanticColors } from './colors.types';
import { CommunicationColors, NeutralColors } from './yammerPalette';

const defaultPaletteSemanticOverrides = (palette: Partial<IPalette>) => ({
  bodyBackground: NeutralColors.gray10,
  bodyBackgroundHovered: palette.neutralLighter,
  bodyFrameBackground: NeutralColors.gray10,
  bodyStandoutBackground: NeutralColors.white,
  bodyText: palette.neutralDark,
  buttonBackground: palette.white,
  buttonBackgroundHovered: palette.neutralLighter,
  buttonBackgroundPressed: palette.neutralQuaternaryAlt,
  buttonBorder: palette.neutralQuaternaryAlt,
  buttonText: palette.neutralDark,
  buttonTextDisabled: palette.neutralSecondary,
  disabledText: palette.neutralQuaternary,
  inputBorder: palette.neutralQuaternaryAlt,
  inputBackgroundChecked: palette.themePrimary,
  inputBorderHovered: palette.neutralPrimary,
  link: palette.neutralSecondary,
  linkHovered: palette.accent,
  menuIcon: palette.neutralDark,
  menuHeader: palette.neutralDark,
  primaryButtonBackground: palette.themePrimary,
  primaryButtonBackgroundHovered: palette.themeDarkAlt,
  primaryButtonBackgroundPressed: palette.themeDark,
  primaryButtonTextDisabled: palette.neutralSecondary,
});

const defaultDarkPaletteSemanticOverrides = (palette: Partial<IPalette>) => ({
  ...defaultPaletteSemanticOverrides(palette),
  bodyBackground: NeutralColors.gray220,
  bodyFrameBackground: NeutralColors.gray10,
  bodyStandoutBackground: NeutralColors.gray190,
  bodyBackgroundHovered: NeutralColors.gray150,
  bodyText: NeutralColors.gray20,
  bodySubtext: NeutralColors.gray50,
  buttonBackground: NeutralColors.gray170,
  buttonBackgroundHovered: NeutralColors.gray190,
  buttonBackgroundPressed: NeutralColors.gray210,
  buttonBorder: NeutralColors.gray130,
  buttonText: NeutralColors.white,
  buttonTextDisabled: NeutralColors.gray50,
  disabledText: NeutralColors.gray50,
  inputBorder: NeutralColors.gray130,
  inputBackgroundChecked: NeutralColors.white,
  inputBorderHovered: NeutralColors.gray50,
  inputText: NeutralColors.gray20,
  link: NeutralColors.gray50,
  linkHovered: CommunicationColors.tint10,
  menuIcon: NeutralColors.white,
  menuHeader: NeutralColors.white,
  primaryButtonBackground: NeutralColors.white,
  primaryButtonBackgroundHovered: NeutralColors.gray50,
  primaryButtonBackgroundPressed: NeutralColors.gray40,
  primaryButtonTextDisabled: NeutralColors.gray50,
});

export const getSemanticColorOverridesFromPalette: (
  palette: Partial<IPalette>,
  isInverted?: boolean
) => Partial<ISemanticColors> = (palette, isInverted) =>
  isInverted ? defaultDarkPaletteSemanticOverrides(palette) : defaultPaletteSemanticOverrides(palette);
