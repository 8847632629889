import { load as loadSubscriptions } from '@ms/yammer-data/dist/domains/pushSubscriptions/actions';
import { getSubscription } from '@ms/yammer-web-support/dist/pushNotifications';
import { MutableRefObject, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useIsEnabled as useIsPushNotificationsEnabled } from '../pushNotifications';

export const useLoadPushSubscriptions = (subscription: MutableRefObject<PushSubscription | null | undefined>) => {
  const dispatch = useDispatch();
  const isEnabled = useIsPushNotificationsEnabled();

  useEffect(() => {
    (async () => {
      subscription.current = await getSubscription();

      if (isEnabled) {
        await dispatch(loadSubscriptions());
      }
    })();
  }, [subscription, dispatch, isEnabled]);
};
