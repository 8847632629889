import { StorylineSettingsClients } from '@ms/yammer-graphql';
import queryStorylineSettings from '@ms/yammer-graphql/dist/operations/queryStorylineSettings.graphql';
import { RequestInitiator } from '@ms/yammer-telemetry-support';

import {
  GraphqlApiTreatmentRecord,
  ManagedApiLifecycle,
  getGraphqlApiRequest,
  getTreatmentsExtensionForGraphqlBody,
  managedApiAction,
} from '../../api';
import { normalizeStorylineSettings } from '../../normalization/graphql/normalizeStorylineSettings';
import { reportError } from '../../telemetry';
import { PromiseThunkAction } from '../../types';

import {
  StorylineSettingsLoadFulfilledAction,
  StorylineSettingsLoadPendingAction,
  StorylineSettingsLoadRejectedAction,
} from './actions.types';
import { getStorylineSettingsStatus } from './selectors';

interface StorylineSettingsResponse {
  readonly data: StorylineSettingsClients;
}

const loadStorylineSettingsPending = (): StorylineSettingsLoadPendingAction => ({
  type: 'STORYLINE_SETTINGS.LOAD_PENDING',
});

const loadStorylineSettingsFulfilled = (
  payload: StorylineSettingsLoadFulfilledAction['payload']
): StorylineSettingsLoadFulfilledAction => ({
  type: 'STORYLINE_SETTINGS.LOAD_FULFILLED',
  payload,
});

const loadStorylineSettingsRejected = (error: unknown): StorylineSettingsLoadRejectedAction => ({
  type: 'STORYLINE_SETTINGS.LOAD_REJECTED',
  payload: {
    error,
  },
  error: true,
});

const loadStorylineSettingsRequest = (treatments: GraphqlApiTreatmentRecord[], initiator?: RequestInitiator) =>
  getGraphqlApiRequest({
    body: {
      operationName: 'StorylineSettingsClients',
      query: queryStorylineSettings,
    },
    initiator,
    treatments,
  });

const loadStorylineSettingsLifecycle: ManagedApiLifecycle<StorylineSettingsResponse> = {
  pending: () => loadStorylineSettingsPending(),
  fulfilled: (rawFeatureAccessSettings) => {
    const payload = normalizeStorylineSettings(rawFeatureAccessSettings);

    return loadStorylineSettingsFulfilled(payload);
  },
  rejected: (error) => loadStorylineSettingsRejected(error),
};

type Load = (initiator?: RequestInitiator) => PromiseThunkAction<void>;
export const load: Load = (initiator) => async (dispatch, getState) => {
  const state = getState();
  const status = getStorylineSettingsStatus(state);
  const treatments = getTreatmentsExtensionForGraphqlBody({
    state,
    experiments: ['VivaEngagePremium', 'Stories', 'StorylinePerUserControl'],
  });

  if (status === 'pending') {
    return;
  }

  const action = managedApiAction({
    request: loadStorylineSettingsRequest(treatments, initiator),
    lifecycle: loadStorylineSettingsLifecycle,
  });

  try {
    await dispatch(action);
  } catch (error) {
    reportError({ error });
  }
};
