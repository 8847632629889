import { ParsedUrlQuery, stringify } from 'querystring';

import { CurrentNetworkContext, Message, Thread } from '@ms/yammer-data/dist/state/types';
import { SearchLocationParameters } from '@ms/yammer-search-types';
import { LocationDescriptor } from 'history';

import { GetBaseUrlProps, getBaseUrl } from '../location/getBaseUrl';

import { campaignUrl } from './campaigns';
import { groupFeedRedirectPage } from './groups';
import { rootPageUrl } from './rootRoutes';
import { makeSearchQueryStringParameters } from './search';
import { StateWithPrevPathname, ThreadPageQueryOptions } from './threads';
import { userPage } from './users';

export const articleBasePath = '/articles';
export const articlePageMatchPath = `${articleBasePath}/:threadId/:topLevelMessageId?/:secondLevelMessageId?`;
export const articleEditPageMatchPath = `${articleBasePath}/:threadId/edit`;
export const articleNewMatchPath = `${articleBasePath}/new`;
export const articleNewBasePath = `${articleBasePath}/new`;

export interface PageArticleMatchParams {
  readonly threadId: Thread['id'];
  readonly topLevelMessageId?: Thread['threadStarterId'];
  readonly secondLevelMessageId?: Message['id'];
}

export type ArticlePageQueryOptions = ThreadPageQueryOptions;

const getLocationSearchQueryString = (queryOptions?: ArticlePageQueryOptions) =>
  queryOptions ? `?${stringify(queryOptions as unknown as ParsedUrlQuery)}` : undefined;

type ArticlePage = (
  articleId: Thread['id'],
  queryOptions?: ArticlePageQueryOptions,
  networkContext?: CurrentNetworkContext
) => string;
export const articlePage: ArticlePage = (articleId, queryOptions, networkContext) => {
  const queryString = getLocationSearchQueryString(queryOptions) ?? '';

  if (networkContext) {
    return `/${networkContext.networkPath}${articleBasePath}/${articleId}${queryString}`;
  }

  return `${articleBasePath}/${articleId}${queryString}`;
};

type ArticleMessagePage = (messagePath: Message['id'][], queryOptions?: ArticlePageQueryOptions) => string;
export const articleMessagePage: ArticleMessagePage = (messagePath, queryOptions) => {
  const queryString = getLocationSearchQueryString(queryOptions) ?? '';

  return `${articleBasePath}/${messagePath.join('/')}${queryString}`;
};

type ArticleMessagePageWithPreviousPathname = (
  ids: Message['id'][],
  prevPathname: string,
  options?: ArticlePageQueryOptions
) => LocationDescriptor<StateWithPrevPathname>;
export const articleMessagePageWithPreviousPathname: ArticleMessagePageWithPreviousPathname = (
  ids,
  prevPathname,
  options
) => ({
  pathname: articleMessagePage(ids),
  search: getLocationSearchQueryString(options),
  state: { prevPathname },
});

interface ArticlePageNewUrlParams {
  readonly userId?: string;
  readonly groupId?: string | null;
  readonly campaignId?: string;
}

type ArticlePageNewUrl = (params: ArticlePageNewUrlParams) => string;
export const articlePageNewUrl: ArticlePageNewUrl = ({ userId, groupId, campaignId }) => {
  const params: Record<string, string> = {};

  if (groupId) {
    params['groupId'] = groupId;
  }

  if (userId) {
    params['userId'] = userId;
  }

  if (campaignId) {
    params['campaignId'] = campaignId;
  }

  const keys = Object.keys(params);
  const pathWithParams = keys.length > 0 ? `${articleNewBasePath}?${stringify(params)}` : articleNewBasePath;

  return pathWithParams;
};

type ArticlePageEditUrl = (threadId: Thread['id']) => string;
export const articlePageEditUrl: ArticlePageEditUrl = (threadId) => `${articleBasePath}/${threadId}/edit`;

type OnArticlePublishRedirectPath = (params: ArticlePageNewUrlParams) => string;
export const onArticlePublishRedirectPath: OnArticlePublishRedirectPath = ({ userId, groupId, campaignId }) => {
  if (groupId) {
    return groupFeedRedirectPage(groupId);
  }

  if (userId) {
    return userPage(userId);
  }

  if (campaignId) {
    return campaignUrl(campaignId);
  }

  return rootPageUrl;
};

interface articleUrlProps {
  readonly threadId: string;
  readonly options?: ThreadPageQueryOptions;
  readonly currentNetworkContext?: CurrentNetworkContext;
  readonly host?: GetBaseUrlProps['host'];
}
type ArticleUrl = (props: articleUrlProps) => string;
export const articleUrl: ArticleUrl = ({ threadId, options, currentNetworkContext, host }) => {
  const baseUrl = getBaseUrl({ currentNetworkContext, host });

  return `${baseUrl}${articlePage(threadId, options)}`;
};

type ArticlePageWithSearchParameters = (id: Thread['id'], searchParams: SearchLocationParameters) => string;
export const articlePageWithSearchParameters: ArticlePageWithSearchParameters = (id, searchParams) => {
  const queryOptions = makeSearchQueryStringParameters(searchParams);

  return articlePage(id, queryOptions);
};
