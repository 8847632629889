import { isDataLoaded, isPromptDismissed } from '@ms/yammer-data/dist/domains/dismissablePromptsByType/selectors';
import { DismissablePromptType, DismissablePromptTypeEntity } from '@ms/yammer-data/dist/state/types';

import { useSelector } from '../useSelector';

type UseIsPromptDismissed = (key: DismissablePromptType) => ReturnType<typeof isPromptDismissed>;
export const useIsPromptDismissed: UseIsPromptDismissed = (key) =>
  useSelector((state) => isPromptDismissed(state, key));

type UseIsDataLoaded = (id: DismissablePromptTypeEntity['id']) => ReturnType<typeof isDataLoaded>;
export const useIsDataLoaded: UseIsDataLoaded = (id) => useSelector((state) => isDataLoaded(state, id));
