import { useMemo } from 'react';

import { useIsCommunityInsightsV2Enabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useInsightsGroupPageSettings: UseMainUIFeatureSettings<'insightsGroupPage'> = (options) => {
  const insightsGroupPageOverrides = options?.overrides?.insightsGroupPage;

  const isEnabled = useIsCommunityInsightsV2Enabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: isEnabled,
      ...insightsGroupPageOverrides,
    }),
    [isEnabled, insightsGroupPageOverrides]
  );
};
