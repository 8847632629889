const validCopyLinkValues = ['V1', 'V2', 'V2_HTML'];
export const getValidCopyLinkValue = (copyLink: string | undefined) => {
  if (!copyLink) {
    return undefined;
  }

  return validCopyLinkValues.includes(copyLink) ? copyLink : '';
};

const numbersRegex = new RegExp('^[0-9]+$');
export const getValidNumberId = (id: string | undefined) => validateId(id, numbersRegex);

const alphanumericRegex = new RegExp('^[a-zA-Z0-9-]+$');
export const getValidAlphanumericId = (id: string | undefined) => validateId(id, alphanumericRegex);

const validateId = (id: string | undefined, regex: RegExp) => {
  if (!id) {
    return undefined;
  }

  return regex.test(id) ? id : '';
};
