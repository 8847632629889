import { ManagedApiLifecycle } from '../..';
import { normalizeSuggestedActions } from '../../normalization/graphql/normalizeSuggestedActions';
import { NormalizedSuggestedActionsEntities } from '../../normalization/types';
import { SuggestedActionSurface } from '../../state/types';
import { PromiseThunkAction } from '../../types';

import {
  SuggestedActionsLoadFulfilledAction,
  SuggestedActionsLoadPendingAction,
  SuggestedActionsRejectedAction,
  SuggestedActionsResponse,
} from './actions.types';
import { getSuggestedActionsStatus } from './selectors';
import { loadSuggestedActions } from './shared';

const loadSuggestedActionPending = (surface: SuggestedActionSurface): SuggestedActionsLoadPendingAction => ({
  type: 'SUGGESTED_ACTION.LOAD_PENDING',
  surface,
});

const loadSuggestedActionFulfilled = (
  entities: NormalizedSuggestedActionsEntities,
  surface: SuggestedActionSurface
): SuggestedActionsLoadFulfilledAction => ({
  type: 'SUGGESTED_ACTION.LOAD_FULFILLED',
  surface,
  payload: {
    entities,
  },
});

const loadSuggestedActionRejected = (
  error: unknown,
  surface: SuggestedActionSurface
): SuggestedActionsRejectedAction => ({
  type: 'SUGGESTED_ACTION.LOAD_REJECTED',
  surface,
  payload: {
    error,
  },
  error: true,
});

type LoadSuggestedActionLifecycle = (surface: SuggestedActionSurface) => ManagedApiLifecycle<SuggestedActionsResponse>;
const loadSuggestedActionLifecycle: LoadSuggestedActionLifecycle = (surface) => ({
  pending: () => loadSuggestedActionPending(surface),
  fulfilled: (rawSuggestedGroups) => {
    const { entities } = normalizeSuggestedActions(rawSuggestedGroups);

    return loadSuggestedActionFulfilled(entities, surface);
  },
  rejected: (error: Error) => loadSuggestedActionRejected(error, surface),
});

type Load = (surface: SuggestedActionSurface) => PromiseThunkAction<void>;
export const load: Load = (surface) => async (dispatch, getState) => {
  const state = getState();
  const status = getSuggestedActionsStatus(state, surface);

  if (status === 'pending' || status === 'fulfilled') {
    return;
  }

  const lifecycle = loadSuggestedActionLifecycle(surface);
  await dispatch(loadSuggestedActions(lifecycle, surface));
};
