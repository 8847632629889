import { getOcpsPolicies } from '@ms/yammer-data/dist/domains/ocps/selectors';
import { OcpsPolicy } from '@ms/yammer-data/dist/state/ocps/types';

import { useSelector } from '../useSelector';

export const useIsFeedbackEnabled = (
  policy: OcpsPolicy.UserFeedbackFormEnabled | OcpsPolicy.SurveyFeedbackFormEnabled
) =>
  useSelector((state) => {
    const policies = getOcpsPolicies(state);

    return !!policies && (policies[policy] === true || policies[policy] == null);
  });
