let pageLoadId = 0;

export const getPageLoadId = () => pageLoadId;
export const incrementPageLoadId = () => {
  pageLoadId = pageLoadId + 1;

  return pageLoadId;
};

export const debugResetPageLoadId = () => {
  pageLoadId = 0;
};
