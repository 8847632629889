import { ThemeProvider as FluentThemeProvider } from '@fluentui/react/lib/Theme';
import { FocusRects } from '@fluentui/react/lib/Utilities';
import { PortalCompatProvider } from '@fluentui/react-portal-compat';
import React, { FC } from 'react';

import { Fluent9ThemeProvider, IdPrefixProvider } from '../../fluent9/components/FluentProvider';
import { LocaleProvider, getDirFromLocale } from '../../util/i18n';
import { HighContrastEmulationClassName } from '../../util/styles/highContrastEmulation';
import useAppendBodyClassName from '../../util/useAppendBodyClassName';

import {
  DarkThemeClassName,
  DarkThemeCoreUITokensClassName,
  LightThemeClassName,
  LightThemeCoreUITokensClassName,
  getClassNames,
  getFluent9ThemeProviderInlineStyles,
} from './AppContainer.styles.legacy';
import { AppContainerProps } from './AppContainer.types';
import AppContainerFUI9ThemePrivateContextProvider from './AppContainerFUI9ThemePrivateContextProvider';
import { useFluent9Theme, useLoadTheme, useThemeWithComponentStyleOverrides } from './hooks';

const AppContainer: FC<AppContainerProps> = ({
  children,
  theme,
  fluent9Theme,
  locale,
  emulateHighContrast = false,
  suppressGlobalStyles = false,
  useTransparentBackground = false,
}) => {
  useAppendBodyClassName(HighContrastEmulationClassName, emulateHighContrast);

  const themeWithComponentOverrides = useThemeWithComponentStyleOverrides(theme);
  useLoadTheme(suppressGlobalStyles, themeWithComponentOverrides);

  const isDarkMode = !!themeWithComponentOverrides?.isInverted;
  const shouldAppendBodyDarkClassName = isDarkMode && !suppressGlobalStyles;
  useAppendBodyClassName(DarkThemeClassName, shouldAppendBodyDarkClassName);

  const shouldAppendBodyLightClassName = !isDarkMode && !suppressGlobalStyles;
  useAppendBodyClassName(LightThemeClassName, shouldAppendBodyLightClassName);

  useAppendBodyClassName(DarkThemeCoreUITokensClassName, isDarkMode);
  useAppendBodyClassName(LightThemeCoreUITokensClassName, !isDarkMode);

  const classNames = getClassNames(
    themeWithComponentOverrides,
    suppressGlobalStyles,
    emulateHighContrast,
    useTransparentBackground
  );

  const v9Theme = useFluent9Theme(themeWithComponentOverrides, fluent9Theme);
  const fluent9ThemeProviderInlineStyles = getFluent9ThemeProviderInlineStyles(
    themeWithComponentOverrides,
    useTransparentBackground
  );

  const dir = getDirFromLocale(locale);

  const focusRects = !suppressGlobalStyles ? <FocusRects /> : null;

  return (
    <IdPrefixProvider value="coreui-">
      <Fluent9ThemeProvider theme={v9Theme} dir={dir} style={fluent9ThemeProviderInlineStyles}>
        <PortalCompatProvider>
          <FluentThemeProvider theme={themeWithComponentOverrides} className={classNames.appContainer} applyTo="none">
            <AppContainerFUI9ThemePrivateContextProvider theme={v9Theme} dir={dir}>
              <LocaleProvider locale={locale}>{children}</LocaleProvider>
            </AppContainerFUI9ThemePrivateContextProvider>
          </FluentThemeProvider>
        </PortalCompatProvider>
      </Fluent9ThemeProvider>
      {focusRects}
    </IdPrefixProvider>
  );
};

/**
 * AppContainer sets some baseline visual and accessibility styling.
 */
export default AppContainer;
