import { schema } from 'normalizr';

import { processUserCoverImage } from '../strategies/userCoverImage';

export const userCoverImageSchema = new schema.Entity(
  'userCoverImages',
  {},
  { processStrategy: processUserCoverImage }
);

export type UserCoverImageEdgesSchema = typeof userCoverImageEdgesSchema;
export const userCoverImageEdgesSchema = [new schema.Object({ node: userCoverImageSchema })];
