import React, { FC } from 'react';

import MediaLightbox from './LoadableMediaLightbox';

export interface MappedStateProps {
  readonly isShowing: boolean;
}

export type LazyMediaLightboxProps = MappedStateProps;

const LazyMediaLightbox: FC<LazyMediaLightboxProps> = ({ isShowing }) => (isShowing ? <MediaLightbox /> : null);

export default LazyMediaLightbox;
