import { useAllDelegationTeamLeadersForCurrentUser } from '../delegation';
import { useExperimentTreatmentWithoutReporting } from '../experiments';
import { useFeatureAccessInsights } from '../featureAccessInsights';

export const useIsLeadersInsightsEntryPointVisible = (): boolean => {
  const isVivaEngagePremium = useExperimentTreatmentWithoutReporting('VivaEngagePremium') === true;
  const isViewerDelegateOfLeaders = !!useAllDelegationTeamLeadersForCurrentUser().length;
  const featureAccessInsights = useFeatureAccessInsights();

  return (
    isVivaEngagePremium && (isViewerDelegateOfLeaders || featureAccessInsights?.canViewLeadershipActivity === true)
  );
};
