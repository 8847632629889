import { Campaign, Group, Thread, User } from '@ms/yammer-data/dist/state/types';

export const insightsRootPage = '/insights';
export const insightsLeadersPage = '/insights/leaders';
export const insightsSelectedLeadersOrgPage = '/insights/leaders/:leaderId';
export const insightsMyInsightsPage = '/insights/users/myInsights';
export const insightsSelectedLeaderMyInsightsPage = '/insights/users/myInsights/leaders/:leaderId';
export const insightsQnAPage = '/insights/qna';
export const insightsQnAOrgPage = '/insights/qna/all';
export const insightsQnA = '/insights/qna/:insightsQnASubroute';
export const insightsQnAMyInsightsPage = '/insights/qna/myInsights';
export const insightsCampaignPage = '/insights/campaign';
export const insightsPremiumCampaignPage = '/insights/campaign/:campaignId?';
export const insightsNetworkPage = '/insights/network';
export const insightsPremiumGroupsPage = '/insights/groups';
export const insightsPremiumGroupPage = '/insights/groups/:groupId?';
export const insightsPremiumGroupOptimizationsPage = '/insights/group-optimizations/:groupId';
export const insightsConversationInsightsPage = '/threads/:threadId/insights';
export const insightsStorylinePage = '/users/:userId/storyline-insights';

type InsightsCampaignPageWithCampaignId = (campaignId: Campaign['id']) => string;
export const insightsCampaignPageWithCampaignId: InsightsCampaignPageWithCampaignId = (campaignId) =>
  `/insights/campaign/${campaignId}`;

type InsightsPersonalWithLeaderId = (leaderId: User['id']) => string;
export const insightsPersonalWithLeaderId: InsightsPersonalWithLeaderId = (leaderId) =>
  insightsSelectedLeaderMyInsightsPage.replace(':leaderId', leaderId);

type InsightsAudienceWithLeaderId = (leaderId: User['id']) => string;
export const insightsAudienceWithLeaderId: InsightsAudienceWithLeaderId = (leaderId) =>
  insightsSelectedLeadersOrgPage.replace(':leaderId', leaderId);

type InsightsPremiumGroupWithGroupId = (groupId: Group['id']) => string;
export const insightsPremiumGroupWithGroupId: InsightsPremiumGroupWithGroupId = (groupId) =>
  insightsPremiumGroupPage.replace(':groupId?', groupId);

export const getInsightsPremiumGroupWithGroupIdAndTimeframe = (groupId: Group['id'], timeframe: string) =>
  `${insightsPremiumGroupWithGroupId(groupId)}?timeframe=${timeframe}`;

type InsightsPremiumGroupOptimizationsWithGroupId = (groupId: Group['id']) => string;
export const insightsPremiumGroupOptimizationsWithGroupId: InsightsPremiumGroupOptimizationsWithGroupId = (groupId) =>
  insightsPremiumGroupOptimizationsPage.replace(':groupId', groupId);

export const getInsightsPremiumGroupOptimizationsWithGroupIdAndTimeframe = (groupId: Group['id'], timeframe: string) =>
  `${insightsPremiumGroupOptimizationsWithGroupId(groupId)}?timeframe=${timeframe}`;

type InsightsConversationInsightsWithThreadId = (threadId: Thread['id']) => string;
export const insightsConversationInsightsWithThreadId: InsightsConversationInsightsWithThreadId = (threadId) =>
  insightsConversationInsightsPage.replace(':threadId', threadId);

type InsightsStorylinePageWithUserId = (userId: User['id']) => string;
export const insightsStorylinePageWithUserId: InsightsStorylinePageWithUserId = (userId) =>
  insightsStorylinePage.replace(':userId', userId);
