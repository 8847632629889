import { useMemo } from 'react';

import { useIsQuestionsOnlyMode } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useDiscoverGroupsPageSettings: UseMainUIFeatureSettings<'discoverGroupsPage'> = (options) => {
  const discoverGroupsPageOverrides = options?.overrides?.discoverGroupsPage;

  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        ...discoverGroupsPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: true,
      ...discoverGroupsPageOverrides,
    };
  }, [discoverGroupsPageOverrides, isQuestionsOnlyMode]);
};
