import { HighContrastSelector, IRawStyle, IsFocusVisibleClassName, getHighContrastNoAdjustStyle } from '../styling';

const normalOutline: IRawStyle = {
  boxShadow: 'inset 0 0 0 1px',
  selectors: {
    [HighContrastSelector]: {
      outline: '1px solid transparent',
    },
  },
};

export const overlayPseudoElementOutline: IRawStyle = {
  content: '""',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  boxShadow: 'inset 0 0 0 1px white, inset 0 0 0 2px black, inset 0 0 0 3px white',
  pointerEvents: 'none',
  zIndex: 1,
  selectors: {
    [HighContrastSelector]: {
      /* Use thicker version in high contrast mode, so that it's visually distinct from
       * a regular box border.
       */
      boxShadow: 'inset 0 0 0 1px Window, inset 0 0 0 3px WindowText, inset 0 0 0 4px Window',
      ...getHighContrastNoAdjustStyle(),
    },
  },
};

export interface FocusOutlineProps {
  /**
   * Focus outline option. Default is a simple 1px inset outline, which works for most
   * text links/buttons. For cases where a link/button contains an image exactly, use the overlay
   * option, which produces a higher-visibility focus outline that sits on top of the child image
   * or other elements.
   */
  readonly focusOutline?: 'overlay';
}

export const getNormalFocusStyle = (): IRawStyle => ({
  outline: 'none',
  selectors: {
    [`.${IsFocusVisibleClassName} &:focus`]: normalOutline,
  },
});

export const getOverlayFocusStyle = (): IRawStyle => ({
  outline: 'none',

  position: 'relative',

  selectors: {
    [`.${IsFocusVisibleClassName} &:focus::after`]: overlayPseudoElementOutline,
  },
});

export const getFocusStyle = ({ focusOutline }: FocusOutlineProps) => {
  if (focusOutline === 'overlay') {
    return getOverlayFocusStyle();
  }

  return getNormalFocusStyle();
};

export const getWrapperOverlayOutlineStyle = (): IRawStyle => ({
  position: 'relative',
  selectors: {
    [`.${IsFocusVisibleClassName} &::after`]: overlayPseudoElementOutline,
  },
});
