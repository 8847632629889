import { makeStyles, mergeClasses } from '@griffel/react';

import {
  ClassNameAndInlineStyles,
  useAlignItemsClassName,
  useDisplayClassName,
  useFlexDirectionClassName,
  useFlexWrapClassName,
  useGap,
  useJustifyContentClassName,
} from '../../util/styles/fluent9';

import { FlexProps } from './Flex.types';
import { useClassNameAndInlineStyles as useFlexItemClassNameAndInlineStyles } from './FlexItem.styles';

type ClassNameProps = Exclude<FlexProps, 'children' | 'ariaHidden' | 'role'>;
type UseClassNamesAndInlineStyles = (props: ClassNameProps) => ClassNameAndInlineStyles;

export const useClassNameAndInlineStyles: UseClassNamesAndInlineStyles = ({
  alignItems,
  direction,
  display = 'flex',
  justifyContent,
  wrap,

  gap,
  columnGap,
  rowGap,
  className,

  ...flexItemStyleProps
}) => {
  const classNames = useFlexClassNames();
  const flexItemStyling = useFlexItemClassNameAndInlineStyles(flexItemStyleProps);
  const gapStyling = useGap({ gap, columnGap, rowGap });

  return {
    className: mergeClasses(
      classNames.root,
      flexItemStyling.className,
      useAlignItemsClassName(alignItems),
      useFlexDirectionClassName(direction),
      useDisplayClassName(display),
      useJustifyContentClassName(justifyContent),
      useFlexWrapClassName(wrap),
      gapStyling.className,
      className
    ),
    inlineStyles: {
      ...flexItemStyling.inlineStyles,
      ...gapStyling.inlineStyles,
    },
  };
};

const useFlexClassNames = makeStyles({
  root: {
    /**
     * Allows text ellipsis to work for all children of <Flex>, even if not wrapped in a <FlexItem> component.
     * https://css-tricks.com/flexbox-truncated-text/
     */
    '> *': {
      minWidth: 0,
    },
  },
});
