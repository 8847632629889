import { useCallback, useMemo, useState } from 'react';

export interface OpenCloseState {
  readonly isOpen: boolean;
  close(): void;
  open(): void;
}

type UseOpenCloseState = () => OpenCloseState;
const useOpenCloseState: UseOpenCloseState = () => {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return useMemo(
    () => ({
      isOpen,
      close,
      open,
    }),
    [isOpen, close, open]
  );
};

export default useOpenCloseState;
