import { makeStyles } from '@griffel/react';

import { getGutterValue } from '../../util/styles/gutters';

export const useClassNames = makeStyles({
  root: {
    margin: `0 0 ${getGutterValue(4, true)}`,
    lineHeight: 'inherit',
  },
});
