import { useTranslations } from '@ms/yammer-i18n-react';
import ErrorStrings from '@ms/yammer-strings/dist/Error';
import React, { FC } from 'react';

import FailedToLoad from '../FailedToLoad';

const UnlicensedUserError: FC = () => {
  const t = useTranslations(ErrorStrings);

  return <FailedToLoad title={t.bootstrapErrorTitleYammer()} error={t.bootstrapErrorUnlicensedUserYammer()} />;
};

export default UnlicensedUserError;
