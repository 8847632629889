import { getDateTimeFormatter } from '../domains/dateTimeFormat/selectors';
import { DateTimeFormatType } from '../domains/dateTimeFormat/shared';
import { getCurrentLocale, isCurrentLocaleRTL } from '../domains/locales/selectors';

import { useSelector } from './useSelector';

type UseCurrentLocale = () => ReturnType<typeof getCurrentLocale>;

export const useCurrentLocale: UseCurrentLocale = () => useSelector(getCurrentLocale);

type IsCurrentLocaleRTL = () => ReturnType<typeof isCurrentLocaleRTL>;
export const useIsCurrentLocaleRTL: IsCurrentLocaleRTL = () => useSelector(isCurrentLocaleRTL);

type CurrentLocaleDirection = () => 'rtl' | 'ltr';
export const useCurrentLocaleDirection: CurrentLocaleDirection = () => {
  const isRTL = useIsCurrentLocaleRTL();

  return isRTL ? 'rtl' : 'ltr';
};

type UseFormattedDateTime = (timestamp: number, formatType: DateTimeFormatType) => string;
export const useFormattedDateTime: UseFormattedDateTime = (timestamp, formatType) =>
  useSelector((state) => getDateTimeFormatter(state, formatType).format(timestamp));
