import { parseToBigInt } from '@ms/yammer-protobuf';
import {
  CampaignCreatedV2,
  CampaignCreatedV2_Entrypoint as Entrypoint,
} from '@ms/yammer-protobuf/dist/events/current/campaigns/campaign_created_pb';

import { Campaign, CampaignReference, Group } from '../../../../state/types';
import { reportAnalyticsEvent } from '../../../api/usageV2';

import { getProtobufCampaignMessageType } from './getProtobufCampaignMessageType';

export enum CreateCampaignClickedEntrypointEnum {
  CampaignManagementPage = 0,
  GroupCampaignManagementPage = 1,
  CampaignWidget = 2,
  UniversalCreateButton = 3,
}

export interface CampaignCreatedProps {
  readonly groupTelemetryId?: Group['telemetryId'];
  readonly campaignTelemetryId: CampaignReference['telemetryId'] | undefined;
  readonly publisherDefaultMessageType: Campaign['threadStarterDefaultContentType'] | undefined | null;
  readonly entrypoint: CreateCampaignClickedEntrypointEnum;
}

type ReportCampaignCreated = (props: CampaignCreatedProps) => void;
export const reportCampaignCreated: ReportCampaignCreated = async ({
  groupTelemetryId,
  campaignTelemetryId,
  publisherDefaultMessageType,
  entrypoint,
}) => {
  if (!campaignTelemetryId) {
    return;
  }

  const campaignId = parseToBigInt(campaignTelemetryId);
  const defaultPublisherMessageType = getProtobufCampaignMessageType(publisherDefaultMessageType);
  const groupId = groupTelemetryId ? parseToBigInt(groupTelemetryId) : undefined;

  const protobufCreateCampaignEntrypoint = {
    [CreateCampaignClickedEntrypointEnum.CampaignManagementPage]: Entrypoint.CAMPAIGN_MANAGEMENT_PAGE,
    [CreateCampaignClickedEntrypointEnum.GroupCampaignManagementPage]: Entrypoint.GROUP_CAMPAIGN_MANAGEMENT_PAGE,
    [CreateCampaignClickedEntrypointEnum.CampaignWidget]: Entrypoint.CAMPAIGN_WIDGET,
    [CreateCampaignClickedEntrypointEnum.UniversalCreateButton]: Entrypoint.UNIVERSAL_CREATE_BUTTON,
  };

  const props = {
    groupId,
    campaignId,
    defaultPublisherMessageType,
    entrypoint: protobufCreateCampaignEntrypoint[entrypoint],
  };

  reportAnalyticsEvent(CampaignCreatedV2, props);
};
