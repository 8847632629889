import { getStatusForId } from '@ms/yammer-data-support';
import createCachedSelector from 're-reselect';

import {
  EntityStatus,
  FirstRunGroupFields,
  SuggestedAction,
  SuggestedActionSurface,
  YammerState,
} from '../../state/types';
import { createFindById } from '../findById';
import { getFulfilledCoverImages } from '../groupCoverImages/selectors';
import { getFulfilledGroupMemberCounts } from '../groupMemberCounts/selectors';
import { getFulfilledGroups } from '../groups/selectors';

type GetSuggestedActionsStatus = (state: YammerState, surface: SuggestedActionSurface) => EntityStatus;
export const getSuggestedActionsStatus: GetSuggestedActionsStatus = (state, surface) =>
  getStatusForId(state.suggestedActions, surface);

export const findById = createFindById('suggestedActions');

type GetSuggestedActionRecommendationId = (
  state: YammerState,
  surface: SuggestedActionSurface,
  suggestedActionId: string
) => string | undefined;
export const getSuggestedActionGroupRecommendationId: GetSuggestedActionRecommendationId = (
  state,
  surface,
  suggestedActionId
) => {
  const list = findById(state, surface)?.joinGroups;

  return list?.find((suggestedAction) => suggestedAction.id === suggestedActionId)?.recommendationId;
};

export const getSuggestedActionUserRecommendationId: GetSuggestedActionRecommendationId = (
  state,
  surface,
  suggestedActionId
) => {
  const list = findById(state, surface)?.followUsers;

  return list?.find((suggestedAction) => suggestedAction.id === suggestedActionId)?.recommendationId;
};

const noSuggestedActions: SuggestedAction[] = [];
type GetSuggestedActionsList = (state: YammerState, surface: SuggestedActionSurface) => SuggestedAction[];
export const getSuggestedActionGroupsList: GetSuggestedActionsList = (state, surface) => {
  const list = findById(state, surface);

  if (list && list.joinGroups) {
    return list.joinGroups;
  }

  return noSuggestedActions;
};

const noSuggestedActionUsers: SuggestedAction[] = [];
export const getSuggestedActionUsersList: GetSuggestedActionsList = (state, surface) => {
  const list = findById(state, surface);

  if (list && list.followUsers) {
    return list.followUsers;
  }

  return noSuggestedActionUsers;
};

const maxDisplayedSuggestedGroups = 6;

type GetSuggestedActionGroups = (state: YammerState, surface: SuggestedActionSurface) => FirstRunGroupFields[];
export const getSuggestedActionGroups: GetSuggestedActionGroups = createCachedSelector(
  getSuggestedActionGroupsList,
  getFulfilledGroups,
  getFulfilledCoverImages,
  getFulfilledGroupMemberCounts,
  (suggestedItems, fulfilledGroups, fulfilledCoverImages, fulfilledCounts) =>
    suggestedItems.slice(0, maxDisplayedSuggestedGroups).reduce<FirstRunGroupFields[]>((acc, itemId) => {
      const group = fulfilledGroups[itemId.id];

      if (group == null) {
        throw new Error('Missing group data in state for suggested actions.');
      }

      const id = group.id;
      const name = group.name;
      const telemetryId = group.telemetryId;
      const backgroundUrl = fulfilledCoverImages[id].backgroundUrl;
      const fallbackBackgroundUrl = fulfilledCoverImages[id].fallbackBackgroundUrl;
      const totalMemberCount = fulfilledCounts[id].totalMemberCount;
      const groupAvatarUrl = group.avatarUrlTemplate;
      const recommendationId = itemId.recommendationId;

      acc.push({
        id,
        name,
        telemetryId,
        backgroundUrl,
        fallbackBackgroundUrl,
        totalMemberCount,
        groupAvatarUrl,
        recommendationId,
      });

      return acc;
    }, [])
)(() => 'cache');
