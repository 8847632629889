import { useMemo } from 'react';

import { useIsInsightsLeadersMyInsightsPageEnabled, useIsQuestionsOnlyMode } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useInsightsLeadersMyInsightsPageSettings: UseMainUIFeatureSettings<'insightsLeadersMyInsightsPage'> = (
  options
) => {
  const insightsLeadersMyInsightsPageOverrides = options?.overrides?.insightsLeadersMyInsightsPage;

  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();
  const isInsightsLeadersMyInsightsEnabled = useIsInsightsLeadersMyInsightsPageEnabled();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        ...insightsLeadersMyInsightsPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: isInsightsLeadersMyInsightsEnabled,
      ...insightsLeadersMyInsightsPageOverrides,
    };
  }, [insightsLeadersMyInsightsPageOverrides, isInsightsLeadersMyInsightsEnabled, isQuestionsOnlyMode]);
};
