import { useMemo } from 'react';

import {
  useAreQuestionsEnabled,
  useCanAccessCommunicationsDashboard,
  useCanCurrentUserAccessEnhancedUserProfile,
  useCanCurrentUserAccessStorylineHome,
  useCanUserAccessLeadershipCorner,
  useIsChatEnabled,
  useIsFlightControlPanelEnabled,
  useIsGroupsEnabled,
  useIsInsightsLeadersMyInsightsNavigationEnabled,
  useIsQuestionsOnlyMode,
  useIsStorylineAdminEnabled,
  useIsUserFeedbackEnabled,
} from '../../featureChecks';
import { UseMainUIFeatureSettings } from '../../types';

// eslint-disable-next-line max-lines-per-function
export const useNavigationSettings: UseMainUIFeatureSettings<'navigation'> = (options) => {
  const navigationOverrides = options?.overrides?.navigation;

  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();

  const chat = useIsChatEnabled();
  const feedback = useIsUserFeedbackEnabled();
  const flights = useIsFlightControlPanelEnabled();
  const groups = useIsGroupsEnabled();
  const insights = useIsInsightsLeadersMyInsightsNavigationEnabled();
  const leadershipCorner = useCanUserAccessLeadershipCorner();
  const questions = useAreQuestionsEnabled();
  const storylinesAdmin = useIsStorylineAdminEnabled();
  const storylines = useCanCurrentUserAccessStorylineHome();
  const userProfile = useCanCurrentUserAccessEnhancedUserProfile();
  const communicationsDashboard = useCanAccessCommunicationsDashboard();

  return useMemo(() => {
    const admin = false;
    const help = true;
    const leftSidebar = true;
    const networks = true;
    const search = true;
    const settings = true;

    if (isQuestionsOnlyMode) {
      return {
        admin,
        chat,
        collapsiblePanel: false,
        feedback,
        flights,
        groups: false,
        help,
        homeFeed: false,
        inbox: false,
        insights: false,
        leadershipCorner: false,
        leftSidebar,
        networks,
        notifications: false,
        questions,
        search,
        settings,
        showGroupList: false,
        storylines: false,
        storylinesAdmin: false,
        userProfile,
        viewerFavoriteGroupList: false,
        viewerJoinedGroupList: false,
        communicationsDashboard: false,
        ...navigationOverrides,
      };
    }

    return {
      admin,
      chat,
      collapsiblePanel: false,
      feedback,
      flights,
      groups,
      help,
      homeFeed: true,
      inbox: true,
      insights,
      leadershipCorner,
      leftSidebar,
      networks,
      notifications: true,
      questions,
      search,
      settings,
      showGroupList: true,
      storylines,
      storylinesAdmin,
      userProfile,
      viewerFavoriteGroupList: true,
      viewerJoinedGroupList: true,
      communicationsDashboard,
      ...navigationOverrides,
    };
  }, [
    chat,
    communicationsDashboard,
    feedback,
    flights,
    groups,
    insights,
    isQuestionsOnlyMode,
    leadershipCorner,
    navigationOverrides,
    questions,
    storylines,
    storylinesAdmin,
    userProfile,
  ]);
};
