import { AdminFeatureAccessSettings, EntityStatus, YammerState, getStatus } from '../../state/types';

type GetAdminFeatureAccessSettingsStatus = (state: YammerState) => EntityStatus;
export const getAdminFeatureAccessSettingsStatus: GetAdminFeatureAccessSettingsStatus = (state) =>
  getStatus(state.adminFeatureAccessSettings);

type GetAdminFeatureAccessSettings = (state: YammerState) => AdminFeatureAccessSettings | undefined;
export const getAdminFeatureAccessSettings: GetAdminFeatureAccessSettings = (state: YammerState) =>
  state.adminFeatureAccessSettings.fulfilled;

type GetViewerCanAccessAnalyticsAdminSettings = (state: YammerState) => boolean;
export const getViewerCanAccessAnalyticsAdminSettings: GetViewerCanAccessAnalyticsAdminSettings = (state) =>
  !!state.adminFeatureAccessSettings.fulfilled?.viewerCanAccessAnalyticsAdminSettings;

type GetCanEditNetworkQuestionSettings = (state: YammerState) => boolean;
export const getCanEditNetworkQuestionSettings: GetCanEditNetworkQuestionSettings = (state) =>
  !!state.adminFeatureAccessSettings.fulfilled?.viewerCanEditNetworkQuestionsSettings;

type GetCanEditNetworkQuestionsOnlyMode = (state: YammerState) => boolean;
export const getCanEditNetworkQuestionsOnlyMode: GetCanEditNetworkQuestionsOnlyMode = (state) =>
  !!state.adminFeatureAccessSettings.fulfilled?.viewerCanEditNetworkQuestionsOnlyMode;

type GetViewerCanEditLeadershipAnalyticsSettings = (state: YammerState) => boolean;
export const getViewerCanEditLeadershipAnalyticsSettings: GetViewerCanEditLeadershipAnalyticsSettings = (state) =>
  !!state.adminFeatureAccessSettings.fulfilled?.viewerCanEditLeadershipAnalyticsSettings;

type GetViewerCanEditCampaignAnalyticsSettings = (state: YammerState) => boolean;
export const getViewerCanEditCampaignAnalyticsSettings: GetViewerCanEditCampaignAnalyticsSettings = (state) =>
  !!state.adminFeatureAccessSettings.fulfilled?.viewerCanEditCampaignAnalyticsSettings;

type GetViewerCanEditNetworkQuestionAnalyticsSettings = (state: YammerState) => boolean;
export const getViewerCanEditNetworkQuestionAnalyticsSettings: GetViewerCanEditNetworkQuestionAnalyticsSettings = (
  state
) => !!state.adminFeatureAccessSettings.fulfilled?.viewerCanEditNetworkQuestionAnalyticsSettings;

type GetViewerCanEditLeaderSettings = (state: YammerState) => boolean;
export const getViewerCanEditLeaderSettings: GetViewerCanEditLeaderSettings = (state) =>
  !!state.adminFeatureAccessSettings.fulfilled?.viewerCanEditLeaderSettings;

type GetViewerCanManageComplianceData = (state: YammerState) => boolean;
export const getViewerCanManageComplianceData: GetViewerCanManageComplianceData = (state) =>
  !!state.adminFeatureAccessSettings.fulfilled?.viewerCanManageComplianceData;

type GetViewerCanManageViewOnlyModeUsers = (state: YammerState) => boolean;
export const getViewerCanManageViewOnlyModeUsers: GetViewerCanManageViewOnlyModeUsers = (state) =>
  !!state.adminFeatureAccessSettings.fulfilled?.viewerCanManageViewOnlyModeUsers;

type GetViewerCanHideUsersForNetworkInDiscovery = (state: YammerState) => boolean;
export const getViewerCanHideUsersForNetworkInDiscovery: GetViewerCanHideUsersForNetworkInDiscovery = (state) =>
  !!state.adminFeatureAccessSettings.fulfilled?.viewerCanHideUsersForNetworkInDiscovery;

type GetViewerCanManageMultiTenantOrganizationPolicy = (state: YammerState) => boolean;
export const getViewerCanManageMultiTenantOrganizationPolicy: GetViewerCanManageMultiTenantOrganizationPolicy = (
  state
) => !!state.adminFeatureAccessSettings.fulfilled?.viewerCanManageMultiTenantOrganizationPolicy;
