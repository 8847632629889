import { useSelectedOnBehalfOfUserId } from '../analyticsSettings/selectors';
import { useExperimentTreatmentWithoutReporting } from '../experiments';
import { useFeatureAccessSettings } from '../featureAccessSettings';

export const useIsQnAOrgEntryPointVisible = (): boolean => {
  const isVivaAnswersEnabled = useExperimentTreatmentWithoutReporting('AnswersInVivaGA') === true;
  const viewerCanAccessNetworkLevelInsights = useFeatureAccessSettings()?.viewerCanAccessNetworkLevelInsights;
  const viewerCanAccessNetworkQuestions = useFeatureAccessSettings()?.viewerCanAccessNetworkQuestions;
  const selectedOnBehalfOfUserId = useSelectedOnBehalfOfUserId();
  const isLeaderSelectedOnBehalfOfViewer = !!selectedOnBehalfOfUserId;

  return (
    isVivaAnswersEnabled &&
    viewerCanAccessNetworkLevelInsights === true &&
    viewerCanAccessNetworkQuestions === true &&
    isLeaderSelectedOnBehalfOfViewer === false
  );
};
