import {
  NormalizedPushSubscriptionServerKey,
  RawPushSubscriptionServerKeyResponse,
} from './normalizePushSubscriptionServerKey.types';

const OWAServerKeyType = 7;

type PushSubscriptionServerKeyNormalizer = (response: {
  readonly options: RawPushSubscriptionServerKeyResponse[];
}) => NormalizedPushSubscriptionServerKey;

export const normalizePushSubscriptionServerKey: PushSubscriptionServerKeyNormalizer = (response) => {
  if (!response.options || !Array.isArray(response.options) || response.options.length < 1) {
    throw new Error('Invalid push subscription server key response');
  }
  const option = response.options.find((opt) => opt.feature === OWAServerKeyType);
  if (!option) {
    throw new Error('Push subscription server key response missing server key option');
  }

  return {
    feature: option.feature,
    applicationServerKey: option.applicationServerKey,
  };
};
