import { logComponentError } from '@ms/yammer-data/dist/domains/diagnosticsDebugLogs/actions';
import { MapDispatchToProps } from '@ms/yammer-data/dist/types';
import { connect } from 'react-redux';

import ErrorBoundary, { MappedDispatchProps } from './ErrorBoundary';

export const mapDispatchToProps: MapDispatchToProps<MappedDispatchProps> = (dispatch) => ({
  logComponentError: (componentName, error) => dispatch(logComponentError(componentName, error)),
});

export default connect(null, mapDispatchToProps)(ErrorBoundary);
