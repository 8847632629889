import { useMemo } from 'react';

import { useIsQuestionsAdminEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useQuestionsAdminPageSettings: UseMainUIFeatureSettings<'questionsAdminPage'> = (options) => {
  const questionsAdminPageOverrides = options?.overrides?.questionsAdminPage;

  const isQuestionsAdminEnabled = useIsQuestionsAdminEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: isQuestionsAdminEnabled,
      ...questionsAdminPageOverrides,
    }),
    [questionsAdminPageOverrides, isQuestionsAdminEnabled]
  );
};
