import { DismissablePromptType } from '@ms/yammer-data/dist/state/types';

import { useIsDataLoaded, useIsPromptDismissed } from './selectors';

type UseShouldStartDismissablePrompt = (promptType: DismissablePromptType) => boolean;
export const useShouldStartDismissablePrompt: UseShouldStartDismissablePrompt = (promptType) => {
  const isLoaded = useIsDataLoaded(promptType);
  const isDismissed = useIsPromptDismissed(promptType);

  if (!isLoaded) {
    return false;
  }

  return !isDismissed;
};
