import { useAndReportExperimentTreatment } from '@ms/yammer-hooks/dist/experiments';
import React, { FC } from 'react';

import LoadableVivaAppBar from './LoadableVivaAppBar';

const VivaAppBarExperimentWrapper: FC = () => {
  const isEnabled = !!useAndReportExperimentTreatment('VivaAppBar');

  if (!isEnabled) {
    return null;
  }

  return <LoadableVivaAppBar />;
};

export default VivaAppBarExperimentWrapper;
