import React, { FC, useContext, useMemo } from 'react';

import { UIFeatures } from '../../types';
import UIFeaturesContext from '../context';

export interface UIFeaturesProviderProps {
  readonly features: UIFeatures;
}

export const UIFeaturesProvider: FC<UIFeaturesProviderProps> = ({ features: overridenFeatures, ...props }) => {
  const inheritedFeatures = useContext(UIFeaturesContext);

  const mergedFeatures = useMemo<UIFeatures>(() => {
    const features = { ...inheritedFeatures, ...overridenFeatures };

    if (process.env.NODE_ENV === 'production') {
      return features;
    }

    // When used on objects, the spread syntax enumerates and adds the key-value pairs to a new object. This means that any previous Proxy instance will be lost after the new object creation.
    // To circumvent this behaviour, we create a new Proxy reference that will delegate each get property operation to its original features object.
    // This is necessary to enable `useStrictFeatureSettings` uninitialized feature detection in development mode.
    return new Proxy(features, {
      get: (_target, prop, receiver) => {
        const target =
          !inheritedFeatures || Object.prototype.hasOwnProperty.call(overridenFeatures, prop)
            ? overridenFeatures
            : inheritedFeatures;

        return Reflect.get(target, prop, receiver);
      },
    });
  }, [inheritedFeatures, overridenFeatures]);

  return <UIFeaturesContext.Provider {...props} value={mergedFeatures} />;
};
