// eslint-disable-next-line no-restricted-syntax
export enum SearchResultType {
  All = 'All',
  Thread = 'Thread',
  Group = 'Group',
  User = 'User',
  File = 'File',
  Inbox = 'Inbox',
  Topic = 'Topic',
  NetworkQuestion = 'NetworkQuestion',
  Campaign = 'Campaign',
}
