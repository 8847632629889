import { reportUsage } from '@ms/yammer-data/dist/telemetry';
import { useBaseHost } from '@ms/yammer-hooks/dist/session';
import React, { FC } from 'react';

import SessionContextProvider from './SessionContextProvider';
import { renderGroupHoverCard, renderUserHoverCard } from './renderer';

const SessionContextProviderWithHooks: FC = ({ children }) => {
  const baseHost = useBaseHost();

  return (
    <SessionContextProvider
      baseHost={baseHost}
      renderGroupReference={renderGroupHoverCard}
      renderUserReference={renderUserHoverCard}
      reportUsage={reportUsage}
    >
      {children}
    </SessionContextProvider>
  );
};

export default SessionContextProviderWithHooks;
