import { findById as findGroupLink } from '@ms/yammer-data/dist/domains/groupLinks/selectors';
import {
  canCreateEvent,
  canEditSettings,
  findById as findGroup,
  findByIdThrows as findGroupThrows,
  getFulfilledGroup,
  getGroupTelemetryEventProperties,
  getGroupTelemetryId,
  hasGuestsInGroup,
  isConnected,
  isExternal,
  isForeignNetworkGroup,
  isFulfilled,
  isLegacyAllCompany,
  isMoveThreadToThisGroupRestricted,
  isMultiTenantOrganizationGroup,
  isNetworkQuestionGroup,
  isOfficial,
  shouldShowQuestionThreadUpdate,
} from '@ms/yammer-data/dist/domains/groups/selectors';
import { Group } from '@ms/yammer-data/dist/state/types';

import { useSelector } from '../useSelector';

export type UseGroup = (groupId: Group['id'] | undefined) => ReturnType<typeof findGroup>;
export const useGroup: UseGroup = (groupId) =>
  useSelector((state) => (groupId ? findGroup(state, groupId) : undefined));

type UseGroupThrows = (groupId: Group['id']) => ReturnType<typeof findGroupThrows>;
export const useGroupThrows: UseGroupThrows = (groupId) => useSelector((state) => findGroupThrows(state, groupId));

type UseIsGroupFulfilled = (groupId: Group['id']) => ReturnType<typeof isFulfilled>;
export const useIsGroupFulfilled: UseIsGroupFulfilled = (groupId) =>
  useSelector((state) => isFulfilled(state, groupId));

type UseGetFulfilledGroup = (groupId: string | undefined) => ReturnType<typeof getFulfilledGroup>;
export const useGetFulfilledGroup: UseGetFulfilledGroup = (groupId) =>
  useSelector((state) => getFulfilledGroup(state, groupId));

type UseIsConnected = (groupId: Group['id']) => ReturnType<typeof isConnected>;
export const useIsConnected: UseIsConnected = (groupId) => useSelector((state) => isConnected(state, groupId));

type UseCanEditSettings = (groupId: Group['id']) => ReturnType<typeof canEditSettings>;
export const useCanEditSettings: UseCanEditSettings = (groupId) =>
  useSelector((state) => canEditSettings(state, groupId));

type UseCanCreateEvent = (groupId: Group['id']) => ReturnType<typeof canCreateEvent>;
export const useCanCreateEvent: UseCanCreateEvent = (groupId) => useSelector((state) => canCreateEvent(state, groupId));

type UseIsLegacyAllCompany = (groupId: Group['id']) => ReturnType<typeof isLegacyAllCompany>;
export const useIsLegacyAllCompany: UseIsLegacyAllCompany = (groupId) =>
  useSelector((state) => isLegacyAllCompany(state, groupId));

type UseIsExternal = (groupId: Group['id']) => ReturnType<typeof isExternal>;
export const useIsExternal: UseIsExternal = (groupId) => useSelector((state) => isExternal(state, groupId));

type UseIsMultiTenantOrganizationGroup = (groupId?: Group['id'] | null) => ReturnType<typeof isOfficial>;
export const useIsMultiTenantOrganizationGroup: UseIsMultiTenantOrganizationGroup = (groupId) =>
  useSelector((state) => (groupId ? isMultiTenantOrganizationGroup(state, groupId) : false));

type UseIsOfficial = (groupId: Group['id']) => ReturnType<typeof isOfficial>;
export const useIsOfficial: UseIsOfficial = (groupId) => useSelector((state) => isOfficial(state, groupId));

type UseIsForeignNetworkGroup = (groupId: Group['id']) => ReturnType<typeof isForeignNetworkGroup>;
export const useIsForeignNetworkGroup: UseIsForeignNetworkGroup = (groupId) =>
  useSelector((state) => isForeignNetworkGroup(state, groupId));

type UseIsGroupWithGuests = (groupId: Group['id']) => ReturnType<typeof hasGuestsInGroup>;
export const useIsGroupWithGuests: UseIsGroupWithGuests = (groupId) =>
  useSelector((state) => hasGuestsInGroup(state, groupId));

type UseGroupTelemetryId = (groupId?: Group['id']) => ReturnType<typeof getGroupTelemetryId>;
export const useGroupTelemetryId: UseGroupTelemetryId = (groupId) =>
  useSelector((state) => {
    const groupTelemetryId = getGroupTelemetryId(state, groupId);
    const { telemetryId = groupTelemetryId } = (groupId && findGroupLink(state, groupId)) || {};

    return telemetryId;
  });

type UseIsMoveThreadToThisGroupRestricted = (
  groupId: Group['id'] | null | undefined
) => ReturnType<typeof isMoveThreadToThisGroupRestricted>;
export const useIsMoveThreadToThisGroupRestricted: UseIsMoveThreadToThisGroupRestricted = (groupId) =>
  useSelector((state) => isMoveThreadToThisGroupRestricted(state, groupId));

type UseIsNetworkQuestionGroup = (groupId: Group['id'] | null | undefined) => ReturnType<typeof isNetworkQuestionGroup>;
export const useIsNetworkQuestionGroup: UseIsNetworkQuestionGroup = (groupId) =>
  useSelector((state) => isNetworkQuestionGroup(state, groupId));

type UseGroupTelemetryEventProperties = (
  groupId: Group['id'] | undefined
) => ReturnType<typeof getGroupTelemetryEventProperties>;
export const useGroupTelemetryEventProperties: UseGroupTelemetryEventProperties = (groupId) =>
  useSelector((state) => getGroupTelemetryEventProperties(state, groupId));

type UseShouldShowQuestionThreadUpdate = (
  groupId: string | null | undefined
) => ReturnType<typeof shouldShowQuestionThreadUpdate>;
export const useShouldShowQuestionThreadUpdate: UseShouldShowQuestionThreadUpdate = (groupId) =>
  useSelector((state) => shouldShowQuestionThreadUpdate(state, groupId));
