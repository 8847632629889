import {
  findById as findUserInformationBarrierSettingsById,
  hasInformationBarrierRestrictions,
} from '@ms/yammer-data/dist/domains/userInformationBarrierSettings/selectors';
import { User } from '@ms/yammer-data/dist/state/types';

import { useSelector } from '../useSelector';

type UseUserInformationBarrierSettings = (id: User['id']) => ReturnType<typeof findUserInformationBarrierSettingsById>;
export const useUserInformationBarrierSettings: UseUserInformationBarrierSettings = (id) =>
  useSelector((state) => findUserInformationBarrierSettingsById(state, id));

type UseUserHasInformationBarrierRestrictions = (
  userId: User['id']
) => ReturnType<typeof hasInformationBarrierRestrictions>;
export const useUserHasInformationBarrierRestrictions: UseUserHasInformationBarrierRestrictions = (userId) =>
  useSelector((state) => hasInformationBarrierRestrictions(state, userId));
