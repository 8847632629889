import React, { FC, useRef } from 'react';

import { PageSectionRefs, PageSectionRefsContext } from './context';

export type PageSectionRefsProviderProps = Partial<PageSectionRefs>;

const PageSectionRefsProvider: FC<PageSectionRefsProviderProps> = (props) => {
  const feedRef = useRef(null);
  const pinnedThreadsRef = useRef(null);

  const { feed = feedRef, pinnedThreads = pinnedThreadsRef, children } = props;

  return <PageSectionRefsContext.Provider value={{ feed, pinnedThreads }}>{children}</PageSectionRefsContext.Provider>;
};

export default PageSectionRefsProvider;
