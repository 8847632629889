import { EntityStatus, getStatusForId as getStatus } from '@ms/yammer-data-support';
import { FileState } from '@ms/yammer-graphql';

import { File, YammerState } from '../../state/types';
import { createFindById, createFindByIdThrows } from '../findById';
import { getLegacyHost } from '../session/selectors';

export const findById = createFindById('files');
export const findByIdThrows = createFindByIdThrows('files');

type GetUrl = (state: YammerState, fileId: File['id']) => string;
export const getUrl: GetUrl = (state, fileId) => {
  const { databaseId } = findByIdThrows(state, fileId);

  return `${getLegacyHost(state)}/uploaded_files/${databaseId}`;
};

type FileVisibilityByReferenceSelector = (state: YammerState, fileId: File['id']) => boolean;

export const isDeleted: FileVisibilityByReferenceSelector = (state, fileId) => {
  const file = findByIdThrows(state, fileId);

  return isFileDeleted(file);
};

export const isInaccessible: FileVisibilityByReferenceSelector = (state, fileId) => {
  const file = findByIdThrows(state, fileId);

  return isFileInaccessible(file);
};

export const isActive: FileVisibilityByReferenceSelector = (state, fileId) => {
  const file = findByIdThrows(state, fileId);

  return isFileActive(file);
};

type FileVisibilitySelector = (file: File) => boolean;

export const isFileDeleted: FileVisibilitySelector = (file) => file.state === FileState.DELETED;

export const isFileActive: FileVisibilitySelector = (file) => file.state === FileState.ACTIVE;

export const isFileInaccessible: FileVisibilitySelector = (file) => file.state === FileState.INACCESSIBLE;

type GetFulfilledFiles = (state: YammerState) => Record<string, File>;
export const getFulfilledFiles: GetFulfilledFiles = (state) => state.files.fulfilled.byId;

type GetEmbeddablePreviewUrl = (state: YammerState, fileId: File['id']) => string | undefined;
export const getEmbeddablePreviewUrl: GetEmbeddablePreviewUrl = (state, fileId) => {
  const file = findByIdThrows(state, fileId);

  return file.embeddablePreviewUrl;
};

type GetStatusForId = (state: YammerState, fileId: File['id']) => EntityStatus;
export const getStatusForId: GetStatusForId = (state, fileId) => getStatus(state.files, fileId);
