import { registerIcons } from '@fluentui/react/lib/Styling';
import React from 'react';

import FluentUISystemIcon, {
  ArrowReplyDown20Regular,
  CalendarLtr20Regular,
  Chat20Regular,
  ChatOff20Regular,
  Delete20Regular,
  Live20Regular,
  Megaphone20Filled,
  MoreHorizontal20Regular,
  NotepadEdit20Regular,
  Pin20Regular,
  PinOff20Regular,
  Share20Regular,
  Star20Filled,
  ThumbLike20Regular,
} from '../../components/FluentUISystemIcon';

export interface IconSubset {
  [key: string]: JSX.Element | string;
}

export const CustomIcons: IconSubset = {
  ['Like']: <FluentUISystemIcon Icon={ThumbLike20Regular} />,
  ['Message']: <FluentUISystemIcon Icon={Chat20Regular} />,
  ['Reply']: <FluentUISystemIcon Icon={ArrowReplyDown20Regular} />,
  ['EditNote']: <FluentUISystemIcon Icon={NotepadEdit20Regular} />,
  ['Delete']: <FluentUISystemIcon Icon={Delete20Regular} />,
  ['Pinned']: <FluentUISystemIcon Icon={Pin20Regular} />,
  ['Unpin']: <FluentUISystemIcon Icon={PinOff20Regular} />,
  ['MuteChat']: <FluentUISystemIcon Icon={ChatOff20Regular} />,
  ['MoreVertical']: <FluentUISystemIcon Icon={MoreHorizontal20Regular} />,
  ['Share']: <FluentUISystemIcon Icon={Share20Regular} />,
  ['Calendar']: <FluentUISystemIcon Icon={CalendarLtr20Regular} />,
  ['FavoriteStarFill']: <FluentUISystemIcon Icon={Star20Filled} />,
  ['MegaphoneSolid']: <FluentUISystemIcon Icon={Megaphone20Filled} />,
  ['WebPublish']: <FluentUISystemIcon Icon={Live20Regular} />,
};

type RegisterCustomIcons = (icons?: IconSubset) => void;
export const registerCustomIcons: RegisterCustomIcons = (icons = CustomIcons) => {
  const customIconSubset = {
    fontFace: {
      fontFamily: 'FluentSystemIcons-Regular',
    },
    icons,
  };

  registerIcons(customIconSubset, { disableWarnings: true });
};
