import { getClientContext } from '@ms/yammer-data/dist/telemetry';

import { useIsEngageDomainEnabled } from '../domainMigration';
import { useExperimentTreatmentWithoutReporting, useReportExperimentTreatmentCallback } from '../experiments';

type useIsLegacyPWABlocked = () => boolean;
export const useIsLegacyPWABlocked: useIsLegacyPWABlocked = () => {
  const treatment = useExperimentTreatmentWithoutReporting('PWALegacyBrokenExperience');
  const isPWALegacyBrokenExperienceExperimentEnabled = treatment === 'enabled';
  const reportExperimentTreatmentCallback = useReportExperimentTreatmentCallback(
    'PWALegacyBrokenExperience',
    treatment
  );
  const isEngageDomainEnabled = useIsEngageDomainEnabled();
  const { isPWA, isDomainRedirect } = getClientContext();

  const shouldRedirect = !!isPWA && !!isDomainRedirect && isEngageDomainEnabled;
  if (shouldRedirect) {
    reportExperimentTreatmentCallback();
  }

  return shouldRedirect && isPWALegacyBrokenExperienceExperimentEnabled;
};
