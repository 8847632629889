import { ManagedApiLifecycle, ManagedApiRequest, managedApiAction } from '../..';
import { normalizePushSubscriptionServerKey } from '../../normalization/graphql/normalizePushSubscriptionServerKey';
import { NormalizedPushSubscriptionServerKey } from '../../normalization/graphql/normalizePushSubscriptionServerKey.types';
import { reportError } from '../../telemetry';
import { PromiseThunkAction } from '../../types';
import {
  getAdalResourceForSubstratePushSubscription,
  getSubstrateConfig,
  getSubstrateHost,
} from '../session/selectors';

import {
  PushSubscriptionServerKeyLoadFulfilledAction,
  PushSubscriptionServerKeyLoadPendingAction,
  PushSubscriptionServerKeyLoadRejectedAction,
  PushSubscriptionServerKeyLoadResponse,
} from './actions.types';
import { isNotRequested } from './selectors';

const loadPending = (): PushSubscriptionServerKeyLoadPendingAction => ({
  type: 'PUSH_SUBSCRIPTION_SERVER_KEY.LOAD_PENDING',
});

const loadFulfilled = (payload: NormalizedPushSubscriptionServerKey): PushSubscriptionServerKeyLoadFulfilledAction => ({
  type: 'PUSH_SUBSCRIPTION_SERVER_KEY.LOAD_FULFILLED',
  payload,
});

const loadRejected = (error: unknown): PushSubscriptionServerKeyLoadRejectedAction => ({
  type: 'PUSH_SUBSCRIPTION_SERVER_KEY.LOAD_REJECTED',
  payload: {
    error,
  },
  error: true,
});

interface GetLoadRequestOptions {
  readonly host: string;
  readonly path: string;
  readonly resource: string;
  readonly scopes: string[];
}

type GetLoadRequest = (options: GetLoadRequestOptions) => ManagedApiRequest;
const getLoadRequest: GetLoadRequest = ({ host, path, resource, scopes }) => ({
  apiName: 'PushSubscriptionServerKeyLoad',
  requestType: 'Substrate',
  method: 'GET',
  host,
  path,
  resource,
  scopes,
});

const getLoadLifecycle = (): ManagedApiLifecycle<PushSubscriptionServerKeyLoadResponse> => ({
  pending: () => loadPending(),
  fulfilled: (response) => loadFulfilled(normalizePushSubscriptionServerKey(response)),
  rejected: (error) => loadRejected(error),
});

type PushSubscriptionServerKeyLoad = () => PromiseThunkAction<void>;
export const load: PushSubscriptionServerKeyLoad = () => async (dispatch, getState) => {
  const state = getState();

  if (!isNotRequested(state)) {
    return;
  }

  const host = getSubstrateHost(state);
  const { pushSubscriptionKeyPath: path } = getSubstrateConfig(state);
  const resource = getAdalResourceForSubstratePushSubscription(state);
  const scopes = [resource];

  const request = getLoadRequest({ host, path, resource, scopes });
  const lifecycle = getLoadLifecycle();
  const action = managedApiAction({ request, lifecycle });

  try {
    await dispatch(action);
  } catch (error) {
    reportError({ error });
  }
};
