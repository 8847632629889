import React, { FC, useRef } from 'react';

import { AppLayoutBreakpoint, useContainerWidth } from '../../../../util/responsiveness';
import useElementSize from '../../../../util/useElementSize';
import Flex, { FlexItem } from '../../../Flex';
import { AppLayoutContextValue } from '../../AppLayoutContextProvider.types';
import ContentContainer from '../../ContentContainer';
import { MainAreaRowContextProvider } from '../../MainAreaRowContextProvider';
import { useAppLayoutContext, useContentContainerContext } from '../../hooks';

import FooterRow from './FooterRow';
import LeftNavContainer from './LeftNavContainer';
import { useClassNames } from './MainAreaRow.styles';
import { MainAreaRowProps } from './MainAreaRow.types';
import MainAreaRowContent from './MainAreaRowContent';

const MainAreaRow: FC<MainAreaRowProps> = ({ hasHeroBackground, children, footer, leftNav }) => {
  const { shouldDisableScroll, shouldFillAvailableSpace } = useContentContainerContext();
  const { hasBackButton } = useAppLayoutContext() as AppLayoutContextValue;
  const containerWidth = useContainerWidth();
  const classNames = useClassNames();

  const isBackButtonFixed = containerWidth <= AppLayoutBreakpoint.Width1185;
  const paddingLeft = hasBackButton && (isBackButtonFixed || leftNav) ? 11 : 0;

  const shouldShowLeftNav = leftNav && !shouldFillAvailableSpace;

  const footerRow = footer ? <FooterRow>{footer}</FooterRow> : null;

  const mainAreaRef = useRef(null);
  const { height: mainAreaHeight } = useElementSize(mainAreaRef.current);

  const leftNavigation = shouldShowLeftNav ? (
    <LeftNavContainer contentHeight={mainAreaHeight}>{leftNav}</LeftNavContainer>
  ) : null;

  const contentHeight = shouldFillAvailableSpace ? '100%' : 'auto';

  return (
    <MainAreaRowContextProvider height={mainAreaHeight}>
      <Flex grow={1} height="100%" maxHeight="100%">
        {leftNavigation}
        <Flex direction="column" grow={1} ref={mainAreaRef}>
          <FlexItem className={classNames.container} basis="100%">
            <ContentContainer
              disableScrolling={shouldDisableScroll}
              forceScrollable={shouldFillAvailableSpace}
              paddingLeft={paddingLeft}
              contentHeight={contentHeight}
            >
              <MainAreaRowContent hasHeroBackground={!!hasHeroBackground}>{children}</MainAreaRowContent>
            </ContentContainer>
          </FlexItem>
          {footerRow}
        </Flex>
      </Flex>
    </MainAreaRowContextProvider>
  );
};
export default MainAreaRow;
