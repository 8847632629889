import {
  canViewerAccessAIAssistedAnswers,
  getCurrentNetworkTopicsState,
  isEngageNetworkTopicsStateEnabled,
} from '@ms/yammer-data/dist/domains/networkTopicSettings/selectors';
import {
  canCurrentUserSendOnBehalfOf,
  getAdalResourceForGraph,
  getAdalResourceForLoki,
  getAdalResourceForYammer,
  getAllowedUploadFileType,
  getBootstrapError,
  getBroadcastHost,
  getCurrentNetworkPermalink,
  getCurrentNetworkUsagePolicy,
  getCurrentNetworkUsagePolicyStatus,
  getCurrentNetworkUsagePolicyThrows,
  getCurrentSessionUser,
  getDesignerAppHost,
  getDesignerAppServiceHost,
  getDesignerMiniAppHost,
  getLegacyHost,
  getMsalResourceForYammer,
  getNetworkPublisherSettingsStatus,
  getSharedSettings,
  getSubstrateConfig,
  getTenorHost,
  getThumbnailsEngageHost,
  getThumbnailsHost,
  getWebHost,
  isAllCompanyGroupMigrated,
  isCurrentNetworkNativeModeSharePointOnlyFileStorage,
  isCurrentNetworkPushNotificationsEnabled,
  isCurrentNetworkUsagePolicyDeclined,
  isCurrentUserRestricted,
  isDirectMessagesEnabled,
  isDraftPostsEnabled,
  isForcedNotificationEnabled,
  isGifShortcutEnabledInNetwork,
} from '@ms/yammer-data/dist/domains/session/selectors';
import { getHostFromUrl } from '@ms/yammer-web-support/dist/urls';

import { useAndReportExperimentTreatment } from '../experiments';
import { useSelector } from '../useSelector';

type UseCurrentSessionUser = () => ReturnType<typeof getCurrentSessionUser>;
export const useCurrentSessionUser: UseCurrentSessionUser = () => useSelector(getCurrentSessionUser);

type UseCanCurrentUserSendOnBehalfOf = () => ReturnType<typeof canCurrentUserSendOnBehalfOf>;
export const useCanCurrentUserSendOnBehalfOf: UseCanCurrentUserSendOnBehalfOf = () =>
  useSelector(canCurrentUserSendOnBehalfOf);

type UseAdalResourceForYammer = () => ReturnType<typeof getAdalResourceForYammer>;
export const useAdalResourceForYammer: UseAdalResourceForYammer = () => useSelector(getAdalResourceForYammer);

type UseMsalResourceForYammer = () => ReturnType<typeof getMsalResourceForYammer>;
export const useMsalResourceForYammer: UseMsalResourceForYammer = () => useSelector(getMsalResourceForYammer);

type UseAdalResourceForLoki = () => ReturnType<typeof getAdalResourceForLoki>;
export const useAdalResourceForLoki: UseAdalResourceForLoki = () => useSelector(getAdalResourceForLoki);

type UseAdalResourceForGraph = () => ReturnType<typeof getAdalResourceForGraph>;
export const useAdalResourceForGraph: UseAdalResourceForGraph = () => useSelector(getAdalResourceForGraph);

type UseBaseHost = () => ReturnType<typeof getWebHost>;
export const useBaseHost: UseBaseHost = () => useSelector(getWebHost);

type UseWebHostName = () => ReturnType<typeof getWebHost>;
export const useWebHostName: UseWebHostName = () => {
  const webHost = useBaseHost();
  const host = getHostFromUrl(webHost);

  return host;
};

type UseBroadcastHost = () => ReturnType<typeof getBroadcastHost>;
export const useBroadcastHost: UseBroadcastHost = () => useSelector(getBroadcastHost);

type UseLegacyHost = () => ReturnType<typeof getLegacyHost>;
export const useLegacyHost: UseLegacyHost = () => useSelector(getLegacyHost);

type UseThumbnailsHost = () => ReturnType<typeof getThumbnailsHost>;
export const useThumbnailsHost: UseThumbnailsHost = () => useSelector(getThumbnailsHost);

type UseThumbnailsEngageHost = () => ReturnType<typeof getThumbnailsEngageHost>;
export const useThumbnailsEngageHost: UseThumbnailsEngageHost = () => useSelector(getThumbnailsEngageHost);

type UseTenorHost = () => ReturnType<typeof getTenorHost>;
export const useTenorHost: UseTenorHost = () => useSelector(getTenorHost);

type UseSubstrateConfig = () => ReturnType<typeof getSubstrateConfig>;
export const useSubstrateConfig: UseSubstrateConfig = () => useSelector(getSubstrateConfig);

type UseCurrentNetworkPermalink = () => ReturnType<typeof getCurrentNetworkPermalink>;
export const useCurrentNetworkPermalink: UseCurrentNetworkPermalink = () => useSelector(getCurrentNetworkPermalink);

type UseCurrentNetworkUsagePolicy = () => ReturnType<typeof getCurrentNetworkUsagePolicy>;
export const useCurrentNetworkUsagePolicy: UseCurrentNetworkUsagePolicy = () =>
  useSelector(getCurrentNetworkUsagePolicy);

type UseIsCurrentNetworkUsagePolicyDeclined = () => ReturnType<typeof isCurrentNetworkUsagePolicyDeclined>;
export const useIsCurrentNetworkUsagePolicyDeclined: UseIsCurrentNetworkUsagePolicyDeclined = () =>
  useSelector(isCurrentNetworkUsagePolicyDeclined);

type UseGetNetworkPublisherSettingsStatus = () => ReturnType<typeof getNetworkPublisherSettingsStatus>;
export const useGetNetworkPublisherSettingsStatus: UseGetNetworkPublisherSettingsStatus = () =>
  useSelector(getNetworkPublisherSettingsStatus);

type UseCurrentNetworkUsagePolicyThrows = () => ReturnType<typeof getCurrentNetworkUsagePolicyThrows>;
export const useCurrentNetworkUsagePolicyThrows: UseCurrentNetworkUsagePolicyThrows = () =>
  useSelector(getCurrentNetworkUsagePolicyThrows);

type UseIsCurrentNetworkPushNotificationsEnabled = () => ReturnType<typeof isCurrentNetworkPushNotificationsEnabled>;
export const useIsCurrentNetworkPushNotificationsEnabled: UseIsCurrentNetworkPushNotificationsEnabled = () =>
  useSelector(isCurrentNetworkPushNotificationsEnabled);

type UseIsDirectMessagesEnabled = () => ReturnType<typeof isDirectMessagesEnabled>;
export const useIsDirectMessagesEnabled: UseIsDirectMessagesEnabled = () => useSelector(isDirectMessagesEnabled);

type UseIsDraftPostsEnabled = () => ReturnType<typeof isDraftPostsEnabled>;
export const useIsDraftPostsEnabled: UseIsDraftPostsEnabled = () => useSelector(isDraftPostsEnabled);

type UseIsCurrentNetworkForcedNotificationEnabled = () => ReturnType<typeof isForcedNotificationEnabled>;
export const useIsCurrentNetworkForcedNotificationEnabled: UseIsCurrentNetworkForcedNotificationEnabled = () =>
  useSelector(isForcedNotificationEnabled);

type UseStatus = () => ReturnType<typeof getCurrentNetworkUsagePolicyStatus>;
export const useStatus: UseStatus = () => useSelector(getCurrentNetworkUsagePolicyStatus);

type UseBootstrapError = () => ReturnType<typeof getBootstrapError>;
export const useBootstrapError: UseBootstrapError = () => useSelector(getBootstrapError);

type UseIsGifShortcutEnabledInNetwork = () => ReturnType<typeof isGifShortcutEnabledInNetwork>;
export const useIsGifShortcutEnabledInNetwork: UseIsGifShortcutEnabledInNetwork = () =>
  useSelector(isGifShortcutEnabledInNetwork);

type UseAllowedUploadFileType = () => ReturnType<typeof getAllowedUploadFileType>;
export const useAllowedUploadFileType: UseAllowedUploadFileType = () => useSelector(getAllowedUploadFileType);

type UseIsAllCompanyGroupMigrated = () => ReturnType<typeof isAllCompanyGroupMigrated>;
export const useIsAllCompanyGroupMigrated: UseIsAllCompanyGroupMigrated = () => useSelector(isAllCompanyGroupMigrated);

type UseDesignerAppHost = () => ReturnType<typeof getDesignerAppHost>;
export const useDesignerAppHost: UseDesignerAppHost = () => useSelector(getDesignerAppHost);

type UseDesignerMiniAppHost = () => ReturnType<typeof getDesignerAppHost>;
export const useDesignerMiniAppHost: UseDesignerMiniAppHost = () => useSelector(getDesignerMiniAppHost);

type UseDesignerAppServiceHost = () => ReturnType<typeof getDesignerAppHost>;
export const useDesignerAppServiceHost: UseDesignerAppServiceHost = () => useSelector(getDesignerAppServiceHost);

type UseSharedSettings = () => ReturnType<typeof getSharedSettings>;
export const useSharedSettings: UseSharedSettings = () => useSelector(getSharedSettings);

type UseIsCurrentNetworkNativeMode = () => ReturnType<typeof isCurrentNetworkNativeModeSharePointOnlyFileStorage>;
export const useIsCurrentNetworkNativeMode: UseIsCurrentNetworkNativeMode = () =>
  useSelector(isCurrentNetworkNativeModeSharePointOnlyFileStorage);

type UseIsCurrentUserRestricted = () => boolean;
export const useIsCurrentUserRestricted: UseIsCurrentUserRestricted = () => {
  const isRestrictedUserEnabled = useAndReportExperimentTreatment('RestrictedUser') === true;
  const isUserRestricted = useSelector(isCurrentUserRestricted);

  return isRestrictedUserEnabled && isUserRestricted;
};
type UseIsEngageNetworkTopicsStateEnabled = () => boolean;
export const useIsEngageNetworkTopicsStateEnabled: UseIsEngageNetworkTopicsStateEnabled = () =>
  useSelector(isEngageNetworkTopicsStateEnabled);

type UseCurrentNetworkTopicsState = () => ReturnType<typeof getCurrentNetworkTopicsState>;
export const useCurrentNetworkTopicsState: UseCurrentNetworkTopicsState = () =>
  useSelector(getCurrentNetworkTopicsState);

type UseCanViewerAccessAIAssistedAnswers = () => boolean | undefined;
export const useCanViewerAccessAIAssistedAnswers: UseCanViewerAccessAIAssistedAnswers = () =>
  useSelector(canViewerAccessAIAssistedAnswers);
