import { lazyLoadOnRender } from '@ms/yammer-libs-lazy';
import { articlePageMatchPath } from '@ms/yammer-web-support/dist/urls';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const LazyArticleFooter = lazyLoadOnRender({
  loader: () =>
    import(/* webpackChunkName: "4-page-articlefooter" */ '@ms/yammer-web-components/dist/articles/ArticleFooter'),
});

export default function LazyArticleFooterWithRouteParams() {
  return (
    <Switch>
      <Route exact={true} path={articlePageMatchPath}>
        <LazyArticleFooter />
      </Route>
    </Switch>
  );
}
