import { AppLayoutBreakpoint } from '../../../util/responsiveness';

import { useAppLayoutWidth } from './useAppLayoutContext';

const largeWidth = 360;
const mediumWidth = 320;
const smallWidth = 260;

/**
 * Provides the width of the leftnav based on the current app layout width.
 */
export const useLeftNavWidth = () => {
  const layoutWidth = useAppLayoutWidth();
  if (layoutWidth > AppLayoutBreakpoint.Width1352) {
    return largeWidth;
  }

  if (layoutWidth > AppLayoutBreakpoint.Width1292) {
    return mediumWidth;
  }

  return smallWidth;
};
