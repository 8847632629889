import { staticFluentColors } from './staticFluentColors';
import { DefaultColors } from './yammerPalette';

export const FlairColors: FlairColorsPalette = {
  blue: staticFluentColors.yammerPrimary,
  teal: staticFluentColors.cyan20,
  green: DefaultColors.green,
  red: staticFluentColors.orange20,
  magenta: staticFluentColors.yammerPrimary,
  purple: staticFluentColors.yammerPrimary,
  orange: staticFluentColors.yammerPrimary,
};

export const DarkBackgroundFlairColors: FlairColorsPalette = {
  blue: staticFluentColors.yammerPrimary,
  teal: staticFluentColors.cyan30,
  green: DefaultColors.green,
  red: staticFluentColors.red20,
  magenta: staticFluentColors.yammerPrimary,
  purple: staticFluentColors.yammerPrimary,
  orange: staticFluentColors.yammerPrimary,
};

export const DarkFlairColors: FlairColorsPalette = {
  blue: staticFluentColors.yammerTint30,
  teal: staticFluentColors.cyan10,
  green: DefaultColors.greenLight,
  red: staticFluentColors.orange10,
  magenta: staticFluentColors.yammerTint30,
  purple: staticFluentColors.yammerTint30,
  orange: staticFluentColors.yammerTint30,
};

export type FlairColor = 'blue' | 'teal' | 'green' | 'red' | 'magenta' | 'purple' | 'orange';
type FlairColorsPalette = Record<FlairColor, string>;
