import { useMemo } from 'react';

import { useIsQuestionsOnlyMode, useIsUserRolesAdminEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useUserRolesAdminPageSettings: UseMainUIFeatureSettings<'userRolesAdminPage'> = (options) => {
  const userRolesAdminPageOverrides = options?.overrides?.userRolesAdminPage;

  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();
  const isUserRolesAdminEnabled = useIsUserRolesAdminEnabled();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        ...userRolesAdminPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: isUserRolesAdminEnabled,
      ...userRolesAdminPageOverrides,
    };
  }, [userRolesAdminPageOverrides, isUserRolesAdminEnabled, isQuestionsOnlyMode]);
};
