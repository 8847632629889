import { useRenderToggleableContent } from '@ms/yammer-web-core-ui/dist/util/useRenderToggleableContent';
import React from 'react';

import LoadableGroupCreateModal from '../LoadableGroupCreateModal';

type UseGroupCreateModal = (isCopilotAdoptionModal?: boolean) => ReturnType<typeof useRenderToggleableContent>;
export const useGroupCreateModal: UseGroupCreateModal = (isCopilotAdoptionModal = false) =>
  useRenderToggleableContent(({ hideContent }) => (
    <LoadableGroupCreateModal onDismiss={hideContent} isCopilotAdoptionModal={isCopilotAdoptionModal} />
  ));

type UseCopilotAdoptionGroupCreateModal = () => ReturnType<typeof useRenderToggleableContent>;
export const useCopilotAdoptionGroupCreateModal: UseCopilotAdoptionGroupCreateModal = () => useGroupCreateModal(true);

export default useGroupCreateModal;
