const isElementInSubtree: (element: Element, root: HTMLElement) => boolean = (element: Element, root: HTMLElement) => {
  let scan: Element | null = element;
  while (scan) {
    if (scan === root) {
      return true;
    }
    scan = scan.parentElement;
  }

  return false;
};

export const containsValidFocus: (root: HTMLElement | null, target: Element | null) => boolean = (
  root: HTMLElement | null,
  target: Element | null
) => {
  if (!root || !target) {
    return false;
  }

  if (!isElementInSubtree(target, root)) {
    return false;
  }

  const tagName = target.tagName;
  if (tagName === 'BUTTON') {
    return !(target as HTMLButtonElement).disabled;
  }

  if (tagName === 'INPUT') {
    return !(target as HTMLInputElement).disabled;
  }

  if (tagName === 'TEXTAREA') {
    return !(target as HTMLTextAreaElement).disabled;
  }

  if (tagName === 'A' && target.getAttribute('href')) {
    return true;
  }

  const contentEditable = target.getAttribute('contentEditable');
  if (contentEditable) {
    return contentEditable === 'true';
  }

  const tabindex = target.getAttribute('tabindex');
  if (tabindex) {
    return true;
  }

  return false;
};
