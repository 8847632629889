import { ReportUsage } from '@ms/yammer-data/dist/telemetry';
import { createContext, useContext } from 'react';

export const emptyReportUsage = (): void => void 0;

export interface TelemetryContextOptions {
  readonly reportUsage: ReportUsage;
}

export const defaultOptions: TelemetryContextOptions = {
  reportUsage: emptyReportUsage,
};

export const TelemetryContext = createContext(defaultOptions);

export type UseTelemetryContext = () => TelemetryContextOptions;
export const useTelemetryContext: UseTelemetryContext = () => useContext(TelemetryContext);
