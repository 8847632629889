import { getGraphqlApiErrorReason } from '@ms/yammer-data';
import { getCurrentSessionUser } from '@ms/yammer-data/dist/domains/session/selectors';
import { Store } from '@ms/yammer-data/dist/state/types';
import FailedToLoad from '@ms/yammer-web-components/dist/error/FailedToLoad';
import UnlicensedUserError from '@ms/yammer-web-components/dist/error/UnlicensedUserError';
import React from 'react';

import { YammerWebState } from '../../../state/types';

export const getErrorComponent = (store: Store<YammerWebState>) => {
  const state = store.getState();
  const { error } = getCurrentSessionUser(state);

  switch (getGraphqlApiErrorReason(error)) {
    case 'NO_YAMMER_LICENSE':
    case 'PRINCIPAL_NOT_FOUND':
      return <UnlicensedUserError />;
    default:
      return <FailedToLoad />;
  }
};
