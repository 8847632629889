import { getMediaLightboxCurrentItem } from '@ms/yammer-data/dist/domains/mediaLightbox/selectors';
import { YammerState } from '@ms/yammer-data/dist/state/types';
import { MapStateToProps, connect } from 'react-redux';

import LazyMediaLightbox, { MappedStateProps } from './LazyMediaLightbox';

export const mapStateToProps: MapStateToProps<MappedStateProps, Record<string, unknown>, YammerState> = (state) => ({
  isShowing: !!getMediaLightboxCurrentItem(state),
});

export default connect(mapStateToProps)(LazyMediaLightbox);
