import { useMemo } from 'react';

import { useIsInsightsAdminEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useInsightsAdminPageSettings: UseMainUIFeatureSettings<'insightsAdminPage'> = (options) => {
  const insightsAdminPageOverrides = options?.overrides?.insightsAdminPage;

  const isInsightsAdminEnabled = useIsInsightsAdminEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: isInsightsAdminEnabled,
      ...insightsAdminPageOverrides,
    }),
    [insightsAdminPageOverrides, isInsightsAdminEnabled]
  );
};
