import createCachedSelector from 're-reselect';

import { DelegationTeam, EntityStatus, User, YammerState, getStatus } from '../../state/types';
import { getCurrentUser, getFulfilledUsers } from '../users/selectors';

type GetCurrentDelegationTeams = (state: YammerState) => Record<User['id'], DelegationTeam> | undefined;
export const getCurrentDelegationTeams: GetCurrentDelegationTeams = (state: YammerState) =>
  state.delegationTeams.fulfilled;

type GetCurrentDelegationTeamsStatus = (state: YammerState) => EntityStatus;
export const getCurrentDelegationTeamsStatus: GetCurrentDelegationTeamsStatus = (state) =>
  getStatus(state.delegationTeams);

type GetOwnDelegationTeam = (state: YammerState, userId: User['id']) => DelegationTeam | undefined;
export const getOwnDelegationTeam: GetOwnDelegationTeam = createCachedSelector(
  (_state: YammerState, userId: User['id']) => userId,
  getCurrentDelegationTeams,
  (userId, currentDelegationTeams) => {
    if (!currentDelegationTeams) {
      return undefined;
    }

    return currentDelegationTeams[userId];
  }
)((_state, userId) => userId);

type GetOtherDelegationTeams = (state: YammerState, userId: User['id']) => DelegationTeam[];
export const getOtherDelegationTeams: GetOtherDelegationTeams = createCachedSelector(
  (_state: YammerState, userId: User['id']) => userId,
  getCurrentDelegationTeams,
  (userId, currentDelegationTeams) => {
    if (!currentDelegationTeams) {
      return [];
    }

    const { [userId]: ownDelegationTeam, ...otherDelegationTeams } = currentDelegationTeams;

    return Object.values(otherDelegationTeams);
  }
)((_state, userId) => userId);

type GetDelegationTeamUsersForCurrentUser = (state: YammerState) => User[];
export const getDelegationTeamUsersForCurrentUser: GetDelegationTeamUsersForCurrentUser = createCachedSelector(
  getCurrentDelegationTeams,
  getCurrentUser,
  getFulfilledUsers,
  (onBehalfOfSenders, currentUser, users) => {
    if (!onBehalfOfSenders) {
      return [currentUser];
    }

    return Object.values(onBehalfOfSenders).reduce((senders, user) => {
      const sender = users[user.ownerId];

      if (sender) {
        return [...senders, sender];
      }

      return senders;
    }, []);
  }
)((_state) => 'cache');

type GetDelegationTeamUsersForCurrentUserStoryline = (state: YammerState) => User[];
export const getDelegationTeamUsersForCurrentUserStoryline: GetDelegationTeamUsersForCurrentUserStoryline =
  createCachedSelector(
    getCurrentDelegationTeams,
    getCurrentUser,
    getFulfilledUsers,
    (onBehalfOfSenders, currentUser, users) => {
      if (!onBehalfOfSenders) {
        return [currentUser];
      }

      return Object.values(onBehalfOfSenders).reduce((senders, user) => {
        const sender = users[user.ownerId];
        const senderHasPermission = user.viewerCanPostToStoryline || sender?.id === currentUser.id;

        if (sender && senderHasPermission) {
          return [...senders, sender];
        }

        return senders;
      }, []);
    }
  )((_state) => 'cache');

const emptyTeamArray: DelegationTeam[] = [];
type GetAllTeamsWithLeadersForCurrentUser = (state: YammerState) => DelegationTeam[];
const getAllTeamsWithLeadersForCurrentUser: GetAllTeamsWithLeadersForCurrentUser = createCachedSelector(
  getCurrentDelegationTeams,
  (delegationTeams) => {
    if (!delegationTeams) {
      return emptyTeamArray;
    }

    return Object.values(delegationTeams).filter((team) => !team.viewerIsOwner && team.isOwnerLeader);
  }
)((_state) => 'cache');

const emptyTeamLeaders: User[] = [];
type GetAllDelegationTeamLeadersForCurrentUser = (state: YammerState) => User[];
export const getAllDelegationTeamLeadersForCurrentUser: GetAllDelegationTeamLeadersForCurrentUser =
  createCachedSelector(getAllTeamsWithLeadersForCurrentUser, getFulfilledUsers, (teamsWithLeaders, users) => {
    if (teamsWithLeaders.length === 0) {
      return emptyTeamLeaders;
    }

    return teamsWithLeaders.reduce<User[]>((teams, team) => {
      const leader = users[team.ownerId];
      if (leader) {
        teams.push(leader);
      }

      return teams;
    }, []);
  })((_state) => 'cache');

type GetDelegationTeamWithHighestAudienceLeader = (state: YammerState) => DelegationTeam | null;
export const getDelegationTeamWithHighestAudienceLeader: GetDelegationTeamWithHighestAudienceLeader =
  createCachedSelector(getAllTeamsWithLeadersForCurrentUser, (teamsWithLeaders) => {
    if (teamsWithLeaders.length === 0) {
      return null;
    }

    return teamsWithLeaders.reduce(
      (highestAudienceLeader, teamLeader) =>
        teamLeader.ownerDirectAudience > highestAudienceLeader.ownerDirectAudience ? teamLeader : highestAudienceLeader,
      teamsWithLeaders[0]
    );
  })((_state) => 'cache');

type GetDelegationManagerUsersForCurrentUser = (state: YammerState) => User[];
export const getDelegationManagerUsersForCurrentUser: GetDelegationManagerUsersForCurrentUser = createCachedSelector(
  getCurrentDelegationTeams,
  getCurrentUser,
  getFulfilledUsers,
  (onBehalfOfSenders, currentUser, users) => {
    if (!onBehalfOfSenders) {
      return [currentUser];
    }

    return Object.values(onBehalfOfSenders).reduce((senders, user) => {
      const sender = users[user.ownerId];
      const senderHasPermission = user.viewerCanManageTeam || sender?.id === currentUser.id;

      if (sender && senderHasPermission) {
        return [...senders, sender];
      }

      return senders;
    }, []);
  }
)((_state) => 'cache');

type CanViewerManageTeam = (state: YammerState, leaderId: User['id']) => boolean;
export const canViewerManageTeam: CanViewerManageTeam = (state, leaderId) => {
  const delegationTeams = getCurrentDelegationTeams(state);
  if (!delegationTeams) {
    return false;
  }

  return !!delegationTeams[leaderId]?.viewerCanManageTeam;
};

type CanViewerPostToStoryline = (state: YammerState, leaderId: User['id'] | undefined) => boolean;
export const canViewerPostToStoryline: CanViewerPostToStoryline = (state, leaderId) => {
  if (!leaderId) {
    return false;
  }

  const delegationTeams = getCurrentDelegationTeams(state);
  if (!delegationTeams) {
    return false;
  }

  return !!delegationTeams[leaderId]?.viewerCanPostToStoryline;
};

type HasNetworkWideAudienceForLeader = (state: YammerState, leaderId: User['id'] | undefined) => boolean;
export const hasNetworkWideAudienceForLeader: HasNetworkWideAudienceForLeader = (state, leaderId) => {
  const delegationTeams = getCurrentDelegationTeams(state);
  if (!leaderId || !delegationTeams) {
    return false;
  }

  return !!delegationTeams[leaderId]?.hasNetworkWideAudience;
};

type CanViewerPostStorylineAnnouncementForLeader = (state: YammerState, leaderId: User['id'] | undefined) => boolean;
export const canViewerPostStorylineAnnouncementForLeader: CanViewerPostStorylineAnnouncementForLeader = (
  state,
  leaderId
) => {
  if (!leaderId) {
    return false;
  }

  const delegationTeams = getCurrentDelegationTeams(state);
  if (!delegationTeams) {
    return false;
  }

  return delegationTeams[leaderId]?.viewerCanPostAnnouncementToStoryline;
};

type CanPostOnBehalfOfUser = (state: YammerState, onBehalfOfUserId: User['id']) => boolean;
export const canPostOnBehalfOfUser: CanPostOnBehalfOfUser = (state, onBehalfOfUserId) => {
  const delegationTeamUsers = getDelegationTeamUsersForCurrentUserStoryline(state);

  return !!delegationTeamUsers.find((user) => user.id === onBehalfOfUserId);
};

type CanManageOnBehalfOfUser = (state: YammerState, onBehalfOfUserId: User['id'] | undefined) => boolean;
export const canManageOnBehalfOfUser: CanManageOnBehalfOfUser = (state, onBehalfOfUserId) => {
  if (!onBehalfOfUserId) {
    return false;
  }
  const delegationTeamUsers = getDelegationManagerUsersForCurrentUser(state);

  return !!delegationTeamUsers.find((user) => user.id === onBehalfOfUserId);
};
