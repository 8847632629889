import { useMemo } from 'react';

import { UIFeatures, uiFeatureAccessSymbol } from '../types';
import { useShallowMemo } from '../util';

type UseStrictFeatureSettings = <T extends UIFeatures>(features: T) => T;

export const useStrictFeatureSettingsProduction: UseStrictFeatureSettings = useShallowMemo;

export const useStrictFeatureSettingsDevelopment: UseStrictFeatureSettings = (features) => {
  const target = useShallowMemo(features);

  return useMemo(
    () =>
      new Proxy(target, {
        get: (target, prop, receiver) => {
          const shouldGetProp =
            Reflect.has(target, prop) ||
            prop === '$$typeof' ||
            (typeof prop === 'symbol' && prop !== uiFeatureAccessSymbol);

          if (shouldGetProp) {
            return Reflect.get(target, prop, receiver);
          }

          const consoleTraceStyles = [
            'background: #2259A1',
            'border-radius: 0.5em',
            'color: white',
            'font-weight: bold',
            'padding: 3px 0.5em',
          ].join(';');

          // React Developer Tools overrides `console.trace`, appending the render tree to the message.
          console.trace('%c[[UIFeatures]]', consoleTraceStyles, target);
          throw new ReferenceError(`[[UIFeatures]].${String(prop)} is not initialized in <UIFeaturesProvider>`);
        },
      }),
    [target],
  );
};

export const useStrictFeatureSettings =
  process.env.NODE_ENV === 'production' ? useStrictFeatureSettingsProduction : useStrictFeatureSettingsDevelopment;
