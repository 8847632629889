import { makeStyles } from '@griffel/react';

export type FlexAlign = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';

const useAlignItemsClassNames = makeStyles<FlexAlign>({
  'flex-start': {
    alignItems: 'flex-start',
  },
  'flex-end': {
    alignItems: 'flex-end',
  },
  center: {
    alignItems: 'center',
  },
  stretch: {
    alignItems: 'stretch',
  },
  baseline: {
    alignItems: 'baseline',
  },
});

export const useAlignItemsClassName = (align?: FlexAlign) => {
  const classNames = useAlignItemsClassNames();

  return align ? classNames[align] : undefined;
};
