import { isCurrentSessionUser, isCurrentUserAdmin } from '@ms/yammer-data/dist/domains/session/selectors';
import {
  findById as findUser,
  findByIdThrows as findUserThrows,
  findUsers,
  getCurrentUser,
  getCurrentUserField,
  getCurrentUserFieldThrows,
  getExternalNetworkName,
  getUserOfficeId,
  getUserSashCampaignId,
  getUserSubtitle,
  getUserTelemetryId,
  isExternal,
  isGuestOrExternalUser,
  isGuestUser,
  isGuestUserThrows,
  isMtoCrossNetworkUser,
  isMtoHubUser,
  isMtoSpokeUser,
  isMtoUser,
} from '@ms/yammer-data/dist/domains/users/selectors';
import { Campaign, User } from '@ms/yammer-data/dist/state/types';

import { useSelector } from '../useSelector';

type UseUserTelemetryId = (userId: User['id']) => ReturnType<typeof getUserTelemetryId>;
export const useUserTelemetryId: UseUserTelemetryId = (userId) =>
  useSelector((state) => getUserTelemetryId(state, userId));

type UseFindUser = (userId?: User['id']) => ReturnType<typeof findUser>;
export const useFindUser: UseFindUser = (userId) =>
  useSelector((state) => (userId ? findUser(state, userId) : undefined));

type UseFindUserThrows = (userId: User['id']) => ReturnType<typeof findUserThrows>;
export const useFindUserThrows: UseFindUserThrows = (userId) => useSelector((state) => findUserThrows(state, userId));

type UseExternalNetworkName = (userId: User['id']) => ReturnType<typeof getExternalNetworkName>;
export const useExternalNetworkName: UseExternalNetworkName = (userId) =>
  useSelector((state) => getExternalNetworkName(state, userId));

type UseIsExternal = (userId: User['id']) => ReturnType<typeof isExternal>;
export const useIsExternal: UseIsExternal = (userId) => useSelector((state) => isExternal(state, userId));

type UseIsCurrentUser = (userId: User['id']) => ReturnType<typeof isCurrentSessionUser>;
export const useIsCurrentUser: UseIsCurrentUser = (userId) =>
  useSelector((state) => isCurrentSessionUser(state, userId));

type UseIsGuestUserThrows = (userId: User['id']) => ReturnType<typeof isGuestUserThrows>;
export const useIsGuestUserThrows: UseIsGuestUserThrows = (userId) =>
  useSelector((state) => isGuestUserThrows(state, userId));

type UseIsGuestUser = (userId: User['id']) => ReturnType<typeof isGuestUser>;
export const useIsGuestUser: UseIsGuestUser = (userId) => useSelector((state) => isGuestUser(state, userId));

type UseFindUsers = (userIds: User['id'][]) => ReturnType<typeof findUsers>;
export const useFindUsers: UseFindUsers = (userIds) => useSelector((state) => findUsers(state, userIds));

type UseFindOfficeUserIds = (userIds: User['id'][]) => ReturnType<typeof getUserOfficeId>[];
export const useFindOfficeUserIds: UseFindOfficeUserIds = (userIds) =>
  useSelector((state) => userIds.map((id) => getUserOfficeId(state, id)));

type UseCurrentUser = () => ReturnType<typeof getCurrentUser>;
/**
 * @deprecated Please use `useCurrentUserField` or `useCurrentUserFieldThrows` instead.
 */
export const useCurrentUser: UseCurrentUser = () => useSelector(getCurrentUser);

type UseCurrentUserFieldThrows = <T extends keyof User>(
  fieldName: T
) => ReturnType<typeof getCurrentUserFieldThrows<T>>;
export const useCurrentUserFieldThrows: UseCurrentUserFieldThrows = (fieldName) =>
  useSelector((state) => getCurrentUserFieldThrows(state, fieldName));

type UseCurrentUserField = <T extends keyof User>(fieldName: T) => ReturnType<typeof getCurrentUserField<T>>;
export const useCurrentUserField: UseCurrentUserField = (fieldName) =>
  useSelector((state) => getCurrentUserField(state, fieldName));

type UseIsCurrentUserAdmin = () => boolean;
export const useIsCurrentUserAdmin: UseIsCurrentUserAdmin = () => useSelector(isCurrentUserAdmin);

type UseUserSubtitle = (userId: User['id']) => ReturnType<typeof getUserSubtitle>;
export const useUserSubtitle: UseUserSubtitle = (userId) => useSelector((state) => getUserSubtitle(state, userId));

type UseIsGuestOrExternalUser = (userId: User['id']) => boolean;
export const useIsGuestOrExternalUser: UseIsGuestOrExternalUser = (userId) =>
  useSelector((state) => isGuestOrExternalUser(state, userId));

type UseIsMtoCrossNetworkUser = (userId: User['id']) => boolean;
export const useIsMtoCrossNetworkUser: UseIsMtoCrossNetworkUser = (userId) =>
  useSelector((state) => isMtoCrossNetworkUser(state, userId));

type UseGetUserOfficeId = (userId: User['id']) => ReturnType<typeof getUserOfficeId>;
export const useGetUserOfficeId: UseGetUserOfficeId = (userId) =>
  useSelector((state) => getUserOfficeId(state, userId));

type UseIsMtoHubUser = (userId: User['id']) => ReturnType<typeof isMtoHubUser>;
export const useIsMtoHubUser: UseIsMtoHubUser = (userId) => useSelector((state) => isMtoHubUser(state, userId));

type UseIsMtoUser = (userId: User['id']) => ReturnType<typeof isMtoUser>;
export const useIsMtoUser: UseIsMtoUser = (userId) => useSelector((state) => isMtoUser(state, userId));

type UseIsMtoSpokeUser = (userId: User['id']) => ReturnType<typeof isMtoSpokeUser>;
export const useIsMtoSpokeUser: UseIsMtoSpokeUser = (userId) => useSelector((state) => isMtoSpokeUser(state, userId));

type UseUserSashCampaignId = (userId: User['id']) => ReturnType<typeof getUserSashCampaignId>;
export const useUserSashCampaignId: UseUserSashCampaignId = (userId) =>
  useSelector((state) => getUserSashCampaignId(state, userId));

type UseIsUserWearingCampaignSash = (userId: User['id'], campaignId: Campaign['id']) => boolean;
export const useIsUserWearingCampaignSash: UseIsUserWearingCampaignSash = (userId, campaignId) => {
  const userSashCampaignId = useUserSashCampaignId(userId);

  return userSashCampaignId === campaignId;
};

type UseUserHasSash = (userId: User['id']) => boolean;
export const useUserHasSash: UseUserHasSash = (userId) => {
  const userSashCampaignId = useUserSashCampaignId(userId);

  return userSashCampaignId !== undefined;
};
