import { useEffect } from 'react';

import { setRTL } from './styling';

export type Direction = 'ltr' | 'rtl';
type UseSetRTL = (dir: Direction) => void;

export const useSetRTL: UseSetRTL = (dir) => {
  const isRTL = dir === 'rtl';

  useEffect(() => {
    setRTL(isRTL);
  }, [isRTL]);
};
