import { makeStyles, mergeClasses } from '@griffel/react';

import { px } from '../../styling';
import { createTokens } from '../../theme/fluent9';
import { DimensionProps } from '../dimensions';

import { ClassNameAndInlineStyles } from './types';

export type { DimensionProps };

type DimensionPropName = keyof DimensionProps;
const dimensionPropNames: DimensionPropName[] = ['minHeight', 'height', 'minWidth', 'maxHeight', 'width', 'maxWidth'];

const { tokens, cssVariables } = createTokens(dimensionPropNames, 'dimensions');

type UseDimensions = (props: DimensionProps) => ClassNameAndInlineStyles;
export const useDimensions: UseDimensions = (props) => {
  const dimensionClassNames = useDimensionClassNames();

  const providedDimensionProps: DimensionPropName[] = (Object.keys(props) as DimensionPropName[]).filter(
    (prop) => props[prop] != null
  );

  const className = mergeClasses(...providedDimensionProps.map((prop) => dimensionClassNames[prop]));
  const inlineStyles = providedDimensionProps.reduce(
    (styles, prop) => ({
      ...styles,
      [cssVariables[prop]]: px(props[prop]),
    }),
    {}
  );

  return {
    className,
    inlineStyles,
  };
};

const useDimensionClassNames = makeStyles<DimensionPropName>({
  height: { height: tokens.height },
  minHeight: { minHeight: tokens.minHeight },
  maxHeight: { maxHeight: tokens.maxHeight },
  width: { width: tokens.width },
  minWidth: { minWidth: tokens.minWidth },
  maxWidth: { maxWidth: tokens.maxWidth },
});
