import { DismissablePromptKey } from '@ms/yammer-data/dist/state/types';

import { useIsDataLoaded, useIsPromptDismissed } from './selectors';

type UseShouldStartDismissablePrompt = (promptKey: DismissablePromptKey) => boolean;
export const useShouldStartDismissablePrompt: UseShouldStartDismissablePrompt = (promptKey) => {
  const isDismissed = useIsPromptDismissed(promptKey);
  const isLoaded = useIsDataLoaded(promptKey);

  if (!isLoaded) {
    return false;
  }

  return !isDismissed;
};
