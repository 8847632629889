import { Provider, createContext, useContext } from 'react';

type NonUndefined = {} | null; // eslint-disable-line @typescript-eslint/ban-types
type UseContextValue<T> = () => T;

type CreateRequiredContext = <T extends NonUndefined>(displayName: string) => [Provider<T>, UseContextValue<T>];
export const createRequiredContext: CreateRequiredContext = <T extends NonUndefined>(displayName: string) => {
  const Context = createContext<T | undefined>(undefined);
  Context.displayName = displayName;

  const useContextValue: UseContextValue<T> = () => {
    const value = useContext(Context);

    if (value === undefined) {
      throw new ReferenceError(`<${displayName}Provider> was not found in the component tree`);
    }

    return value;
  };

  return [Context.Provider as Provider<T>, useContextValue];
};
