import ErrorBoundary, { ErrorBoundaryName } from '@ms/yammer-libs-error-boundary';
import FailedToLoad from '@ms/yammer-web-components/dist/error/FailedToLoad';
import React, { FC } from 'react';

import App from './App';

const AppBoundary: FC = () => (
  <ErrorBoundary ErrorComponent={FailedToLoad} name={ErrorBoundaryName.App}>
    <App />
  </ErrorBoundary>
);

export default AppBoundary;
