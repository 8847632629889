import {
  findById,
  findByIdThrows,
  getFulfilledUserNetworkLeaders,
  getStatusForId,
  hasUserNetworkLeaders,
} from '@ms/yammer-data/dist/domains/userNetworkLeaders/selectors';
import { User } from '@ms/yammer-data/dist/state/types';

import { useSelector } from '../useSelector';

type UseUserNetworkLeaderThrows = (userId: User['id']) => ReturnType<typeof findByIdThrows>;
export const useUserNetworkLeaderThrows: UseUserNetworkLeaderThrows = (userId) =>
  useSelector((state) => findByIdThrows(state, userId));

type UseUserNetworkLeader = (userId: User['id']) => ReturnType<typeof findById>;
export const useUserNetworkLeader: UseUserNetworkLeader = (userId) => useSelector((state) => findById(state, userId));

type UseLeaderStatus = (userId: User['id']) => ReturnType<typeof getStatusForId>;
export const useLeaderStatus: UseLeaderStatus = (userId) => useSelector((state) => getStatusForId(state, userId));

type UseFulfilledUserNetworkLeaders = () => ReturnType<typeof getFulfilledUserNetworkLeaders>;
export const useFulfilledUserNetworkLeaders: UseFulfilledUserNetworkLeaders = () =>
  useSelector(getFulfilledUserNetworkLeaders);

type UseHasUserNetworkLeaders = () => ReturnType<typeof hasUserNetworkLeaders>;
export const useHasUserNetworkLeaders: UseHasUserNetworkLeaders = () => useSelector(hasUserNetworkLeaders);
