import { normalize } from 'normalizr';

import {
  NormalizeMultiTenantOrganization,
  PreNormalizedMultiTenantOrganizationEntities,
} from './normalizeMultiTenantOrganization.types';
import { multiTenantNetworkSchema } from './schemas/multiTenantOrganization';

export const normalizeMultiTenantOrganization: NormalizeMultiTenantOrganization = (response) => {
  const { entities: normalizedMultiTenantEntities, result } = normalize<
    unknown,
    PreNormalizedMultiTenantOrganizationEntities
  >(response.data, multiTenantNetworkSchema);

  return {
    entities: {
      multiTenantOrganization: normalizedMultiTenantEntities.multiTenantOrganization[result],
      networks: normalizedMultiTenantEntities.networks,
    },
    result,
  };
};
