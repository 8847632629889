import {
  getSuggestedActionGroups,
  getSuggestedActionGroupsList,
  getSuggestedActionUsersList,
  getSuggestedActionsStatus,
} from '@ms/yammer-data/dist/domains/suggestedActions/selectors';
import { SuggestedActionSurface } from '@ms/yammer-graphql/dist/types';

import { useSelector } from '../useSelector';

type UseStatus = (surface: SuggestedActionSurface) => ReturnType<typeof getSuggestedActionsStatus>;
export const useStatus: UseStatus = (surface) => useSelector((state) => getSuggestedActionsStatus(state, surface));

type UseSuggestedActionGroupsForLightbox = () => ReturnType<typeof getSuggestedActionGroups>;
export const useSuggestedActionGroupsForLightbox: UseSuggestedActionGroupsForLightbox = () =>
  useSelector((state) => getSuggestedActionGroups(state, SuggestedActionSurface.WEB_LIGHTBOX));

type UseSuggestedActionGroupsListForLightbox = () => ReturnType<typeof getSuggestedActionGroupsList>;
export const useSuggestedActionGroupsListForLightbox: UseSuggestedActionGroupsListForLightbox = () =>
  useSelector((state) => getSuggestedActionGroupsList(state, SuggestedActionSurface.WEB_LIGHTBOX));

type UseSuggestedActionUsersListForLightbox = () => ReturnType<typeof getSuggestedActionUsersList>;
export const useSuggestedActionUsersListForLightbox: UseSuggestedActionUsersListForLightbox = () =>
  useSelector((state) => getSuggestedActionUsersList(state, SuggestedActionSurface.WEB_LIGHTBOX));
