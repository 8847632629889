import { PageName } from '@ms/yammer-telemetry-support';

import { AppRoutes } from './AppPageRoutesWithTelemetry.types';
import { isParentRoute } from './isParentRoute';

const throwDuplicatePageError = (page: PageName) => {
  throw new Error(`Duplicate page name: ${page}`);
};

export const enforceNoDuplicatePageNames = (routes: AppRoutes) => {
  const checkedPageNames: Partial<Record<PageName, true>> = {};

  const checkRoutes = (routes: AppRoutes) => {
    routes.forEach((route) => {
      if (isParentRoute(route)) {
        checkRoutes(route.childRoutes);
      } else {
        if (checkedPageNames[route.page]) {
          throwDuplicatePageError(route.page);
        }
        checkedPageNames[route.page] = true;
      }
    });
  };

  checkRoutes(routes);
};
