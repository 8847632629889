import React, { FC } from 'react';

import { useTheme } from '../../util/theme';

import { useClassNamesAndInlineStyles } from './Block.styles';
import { BlockProps, TextWrapStyle } from './Block.types';

const Block: FC<BlockProps> = ({
  backgroundColor,
  bottomSpacing,
  children,
  className,
  dir,
  horizontalPadding,
  language,
  maxLines,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  textAlign,
  textColor,
  textPreset,
  textSize,
  textWrap,
  tooltip,
  topSpacing,
  verticalPadding,
}) => {
  const theme = useTheme();
  const blockStyling = useClassNamesAndInlineStyles({
    theme,
    topSpacing,
    bottomSpacing,
    padding,
    horizontalPadding,
    verticalPadding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    textAlign,
    textColor,
    backgroundColor,
    textPreset,
    textSize,
    maxLines,
    textWrap,
    dir,
    className,
  });

  const hasSize = !!textSize || !!textPreset;
  const hasMaxHeight = !!maxLines && !!hasSize;
  const hasSimpleTextWrap = textWrap && textWrap !== TextWrapStyle.MultiLineEllipsis;
  const hasMultilineEllipsisWithMaxLines = textWrap === TextWrapStyle.MultiLineEllipsis && maxLines && maxLines > 0;
  const content =
    hasMaxHeight || hasSimpleTextWrap || hasMultilineEllipsisWithMaxLines ? (
      <div className={blockStyling.inner.className} style={blockStyling.inner.inlineStyles}>
        {children}
      </div>
    ) : (
      children
    );

  return (
    <div
      className={blockStyling.root.className}
      style={blockStyling.root.inlineStyles}
      dir={dir}
      title={tooltip}
      lang={language}
    >
      {content}
    </div>
  );
};

/**
 * A `Block` is a layout component to build consistent padding and vertical spacing between
 * components. It allows you to `push` a chunk of UI up or down by individual pixels to keep text in
 * the `4px` vertical rhythm. It's also the primary place you should set `textSize` in your UIs,
 * providing enumerated options for the supported `font-size`/`line-height` combinations.
 */
export default Block;
