import { CampaignScope } from '@ms/yammer-data/dist/state/types';
import { CreateCampaignClickedEntrypointEnum } from '@ms/yammer-hooks/dist/campaignCreation';
import { useRenderToggleableContent } from '@ms/yammer-web-core-ui/dist/util/useRenderToggleableContent';
import React from 'react';

import LoadableCampaignCreateModal from '../LoadableCampaignCreateModal';

type UseCampaignCreateModal = (
  entryPoint: CreateCampaignClickedEntrypointEnum,
  scope?: CampaignScope
) => ReturnType<typeof useRenderToggleableContent>;
export const useCampaignCreateModal: UseCampaignCreateModal = (entryPoint, scope) =>
  useRenderToggleableContent(({ hideContent }) => (
    <LoadableCampaignCreateModal onDismiss={hideContent} scope={scope} entrypoint={entryPoint} />
  ));
