import { schema } from 'normalizr';

import { processGroupMemberCount } from '../strategies/groupMemberCount';

export const groupMemberCountSchema = new schema.Entity(
  'groupMemberCounts',
  {},
  { processStrategy: processGroupMemberCount }
);

export type GroupMemberCountEdgesSchema = typeof groupMemberCountEdgesSchema;
export const groupMemberCountEdgesSchema = [new schema.Object({ node: groupMemberCountSchema })];
