import { useContext, useMemo } from 'react';

import { UIFeatures } from '../../types';
import UIFeaturesContext from '../context';

type UseUIFeaturesMapCallback<T> = (features: UIFeatures) => T;

interface UseUIFeatures {
  (): UIFeatures;
  <T>(mapCallback: UseUIFeaturesMapCallback<T>): T;
}

export const useUIFeatures: UseUIFeatures = <T>(mapCallback?: UseUIFeaturesMapCallback<T>) => {
  const features = useContext(UIFeaturesContext);

  return useMemo(
    () => {
      const featuresObj = features ?? {};

      return mapCallback ? mapCallback(featuresObj) : featuresObj;
    },
    // `mapCallback` is intentionally excluded from the dependency array. It is meant to be invoked only when `features` changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [features]
  );
};
