import React from 'react';

import LivePersonaCard from '../../cards/LivePersonaCard';
import TeamsLivePersonaCard from '../../cards/TeamsLivePersonaCard';

import { RenderUserReferenceType } from './types';

export const renderUserHoverCard: RenderUserReferenceType = (children, options) => {
  if (options) {
    if (options.useTeamsLivePersonaCard) {
      return <TeamsLivePersonaCard {...options}>{children}</TeamsLivePersonaCard>;
    }

    return <LivePersonaCard {...options}>{children}</LivePersonaCard>;
  }

  return children;
};
