import type { Authenticator } from '@ms/yammer-web-authenticators';
import React, { FC, ReactNode, createContext, useContext } from 'react';

export interface AuthenticatorContextType {
  readonly authenticator: Authenticator;
  readonly children?: ReactNode;
}

const AuthenticatorContext = createContext<Authenticator | undefined>(undefined);

export const useAuthenticatorContext = () => {
  const authenticator = useContext(AuthenticatorContext);

  if (authenticator == null) {
    throw new Error('AuthenticatorContext has not been initialized');
  }

  return authenticator;
};

export const AuthenticatorContextProvider: FC<AuthenticatorContextType> = ({ authenticator, children }) => (
  <AuthenticatorContext.Provider value={authenticator}>{children}</AuthenticatorContext.Provider>
);
