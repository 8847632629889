import { useMemo } from 'react';

import {
  useIsAskMeAnythingEnabled,
  useIsCalendarInvitationEnabled,
  useIsMtoTeamsMeetingEnabled,
  useIsOnlineMeetingEnabled,
  useIsQuestionsOnlyMode,
} from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useAskMeAnythingEventEditingPageSettings: UseMainUIFeatureSettings<'askMeAnythingEventEditingPage'> = (
  options
) => {
  const askMeAnythingEventEditingPageOverrides = options?.overrides?.askMeAnythingEventEditingPage;

  const isAMAEnabled = useIsAskMeAnythingEnabled();
  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();
  const isCalendarInvitationToggleEnabled = useIsCalendarInvitationEnabled();
  const isOnlineMeetingToggleEnabled = useIsOnlineMeetingEnabled();
  const isMtoTeamsMeetingEnabled = useIsMtoTeamsMeetingEnabled();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        calendarInvitationToggleEnabled: isCalendarInvitationToggleEnabled,
        onlineMeetingToggleEnabled: isOnlineMeetingToggleEnabled,
        mtoTeamsMeetingEnabled: isMtoTeamsMeetingEnabled,
        ...askMeAnythingEventEditingPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: isAMAEnabled,
      calendarInvitationToggleEnabled: isCalendarInvitationToggleEnabled,
      onlineMeetingToggleEnabled: isOnlineMeetingToggleEnabled,
      mtoTeamsMeetingEnabled: isMtoTeamsMeetingEnabled,
      ...askMeAnythingEventEditingPageOverrides,
    };
  }, [
    askMeAnythingEventEditingPageOverrides,
    isAMAEnabled,
    isQuestionsOnlyMode,
    isCalendarInvitationToggleEnabled,
    isOnlineMeetingToggleEnabled,
    isMtoTeamsMeetingEnabled,
  ]);
};
