import { useAndReportExperimentTreatment } from '@ms/yammer-experiments-context';
import React, { FC, Suspense } from 'react';

import { LoadableGlobalCopilotFloatieButton as GlobalCopilotFloatieButton } from './LoadableGlobalCopilotFloatieButton';

const GlobalCopilotFloatieButtonWithExperimentGate: FC = () => {
  const isGlobalCopilotEnabled = useAndReportExperimentTreatment('VivaEngageGlobalCopilot') === true;

  if (!isGlobalCopilotEnabled) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <GlobalCopilotFloatieButton />
    </Suspense>
  );
};

export { GlobalCopilotFloatieButtonWithExperimentGate as GlobalCopilotFloatieButton };
