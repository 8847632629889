import { CoreUIColors } from '../../colors';

import type {
  CoreUIExtendedThemeTokenName,
  CoreUIExtendedThemeTokenValues,
  CoreuiExtendedThemeTokensNamespace,
} from './coreUITokens.types';
import { createTokens } from './createTokensObject';

const tokenNames: CoreUIExtendedThemeTokenName[] = [
  'colorBlueBackground1',
  'colorBlueBackground1Hover',
  'colorBlueBackground3',
  'colorBlueBackground3Hover',
  'colorBlueBackground3Pressed',
  'colorBlueBackground3Selected',
  'colorBlueBackgroundGradient',
  'colorBlueForeground1',
  'colorBlueBorder1',
  'colorBlueForegroundLink',
  'colorMagentaBackground3',
  'colorMagentaForeground1',
  'colorPurpleBackground3',
  'colorTealBackground3',
  'colorWhiteBackground190',
];

const localNamespace: CoreuiExtendedThemeTokensNamespace = 'extendedThemeToken';
const { tokens: coreuiTokens, cssVariables: coreuiCSSVariables } = createTokens(tokenNames, localNamespace);

export { coreuiTokens };

export const lightCoreUITokenValues: CoreUIExtendedThemeTokenValues = {
  [coreuiCSSVariables.colorBlueBackground1]: CoreUIColors.blueTint60,
  [coreuiCSSVariables.colorBlueBackground1Hover]: CoreUIColors.blueTint50,
  [coreuiCSSVariables.colorBlueBackground3]: CoreUIColors.blueShade10,
  [coreuiCSSVariables.colorBlueBackground3Hover]: CoreUIColors.blueShade20,
  [coreuiCSSVariables.colorBlueBackground3Pressed]: CoreUIColors.royalBlueShade20,
  [coreuiCSSVariables.colorBlueBackground3Selected]: CoreUIColors.royalBluePrimary,
  [coreuiCSSVariables.colorBlueBackgroundGradient]: CoreUIColors.lightBlueTint50,
  [coreuiCSSVariables.colorBlueForeground1]: CoreUIColors.blueShade10,
  [coreuiCSSVariables.colorBlueBorder1]: CoreUIColors.blueTint40,
  [coreuiCSSVariables.colorBlueForegroundLink]: CoreUIColors.blueShade10,
  [coreuiCSSVariables.colorMagentaBackground3]: CoreUIColors.magentaPrimary,
  [coreuiCSSVariables.colorMagentaForeground1]: CoreUIColors.magentaShade10,
  [coreuiCSSVariables.colorPurpleBackground3]: CoreUIColors.purplePrimary,
  [coreuiCSSVariables.colorTealBackground3]: CoreUIColors.tealPrimary,
  [coreuiCSSVariables.colorWhiteBackground190]: CoreUIColors.white,
};

export const darkCoreUITokenValues: CoreUIExtendedThemeTokenValues = {
  [coreuiCSSVariables.colorBlueBackground1]: CoreUIColors.blueShade40,
  [coreuiCSSVariables.colorBlueBackground1Hover]: CoreUIColors.darkBluePrimary,
  [coreuiCSSVariables.colorBlueBackground3]: CoreUIColors.blueShade10,
  [coreuiCSSVariables.colorBlueBackground3Hover]: CoreUIColors.blueShade20,
  [coreuiCSSVariables.colorBlueBackground3Pressed]: CoreUIColors.royalBlueShade20,
  [coreuiCSSVariables.colorBlueBackground3Selected]: CoreUIColors.royalBluePrimary,
  [coreuiCSSVariables.colorBlueBackgroundGradient]: CoreUIColors.lightBlueShade40,
  [coreuiCSSVariables.colorBlueForeground1]: CoreUIColors.blueTint30,
  [coreuiCSSVariables.colorBlueBorder1]: CoreUIColors.blueShade10,
  [coreuiCSSVariables.colorBlueForegroundLink]: CoreUIColors.blueTint30,
  [coreuiCSSVariables.colorMagentaBackground3]: CoreUIColors.magentaPrimary,
  [coreuiCSSVariables.colorMagentaForeground1]: CoreUIColors.magentaTint40,
  [coreuiCSSVariables.colorPurpleBackground3]: CoreUIColors.purplePrimary,
  [coreuiCSSVariables.colorTealBackground3]: CoreUIColors.tealPrimary,
  [coreuiCSSVariables.colorWhiteBackground190]: CoreUIColors.darkGray190,
};

export const getCoreUITokenValues = (isDarkMode: boolean): CoreUIExtendedThemeTokenValues =>
  isDarkMode ? darkCoreUITokenValues : lightCoreUITokenValues;
