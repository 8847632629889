import { ManagedApiLifecycle, ManagedApiRequest, managedApiAction } from '../..';
import { normalizePushSubscriptions } from '../../normalization/graphql/normalizePushSubscriptions';
import { NormalizedPushSubscription } from '../../state/types';
import { reportError } from '../../telemetry';
import { PromiseThunkAction } from '../../types';
import { getAdalResourceForSubstrateNotifications, getSubstrateConfig, getSubstrateHost } from '../session/selectors';

import {
  PushSubscriptionsLoadFulfilledAction,
  PushSubscriptionsLoadPendingAction,
  PushSubscriptionsLoadRejectedAction,
  PushSubscriptionsLoadResponse,
} from './loadAction.types';
import { isNotRequested } from './selectors';

const loadPending = (): PushSubscriptionsLoadPendingAction => ({
  type: 'PUSH_SUBSCRIPTIONS.LOAD_PENDING',
});

const loadFulfilled = (payload: NormalizedPushSubscription[]): PushSubscriptionsLoadFulfilledAction => ({
  type: 'PUSH_SUBSCRIPTIONS.LOAD_FULFILLED',
  payload,
});

const loadRejected = (error: unknown): PushSubscriptionsLoadRejectedAction => ({
  type: 'PUSH_SUBSCRIPTIONS.LOAD_REJECTED',
  payload: {
    error,
  },
  error: true,
});

interface GetLoadRequestOptions {
  readonly host: string;
  readonly path: string;
  readonly resource: string;
  readonly scopes: string[];
}

type GetLoadRequest = (options: GetLoadRequestOptions) => ManagedApiRequest;
const getLoadRequest: GetLoadRequest = ({ host, path, resource, scopes }) => ({
  apiName: 'PushSubscriptionsLoad',
  requestType: 'Substrate',
  method: 'GET',
  host,
  path,
  resource,
  scopes,
});

const getLoadLifecycle = (): ManagedApiLifecycle<PushSubscriptionsLoadResponse> => ({
  pending: () => loadPending(),
  fulfilled: (response) => loadFulfilled(normalizePushSubscriptions(response.subscriptions)),
  rejected: (error) => loadRejected(error),
});

type PushSubscriptionsLoad = () => PromiseThunkAction<void>;
export const load: PushSubscriptionsLoad = () => async (dispatch, getState) => {
  const state = getState();

  if (!isNotRequested(state)) {
    return;
  }

  const host = getSubstrateHost(state);
  const { pushSubscriptionsPath: path } = getSubstrateConfig(state);
  const resource = getAdalResourceForSubstrateNotifications(state);
  const scopes = [resource];

  const request = getLoadRequest({ host, path, resource, scopes });
  const lifecycle = getLoadLifecycle();
  const action = managedApiAction({ request, lifecycle });

  try {
    await dispatch(action);
  } catch (error) {
    reportError({ error });
  }
};
