import React, { FC } from 'react';

import { VisibilityObserverRootContext } from './context';

export interface VisibilityObserverRootContextProviderProps {
  readonly root: HTMLElement | null;
}

const VisibilityObserverRootContextProvider: FC<VisibilityObserverRootContextProviderProps> = ({ root, children }) => (
  <VisibilityObserverRootContext.Provider value={root}>{children}</VisibilityObserverRootContext.Provider>
);

export default VisibilityObserverRootContextProvider;
