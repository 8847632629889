import React, { FC } from 'react';

import Flex from '../Flex';

import { ContentRowProps } from './ContentRow.types';
import { useAppLayoutContext, useAppLayoutGutterSize } from './hooks';

const ContentRow: FC<ContentRowProps> = ({ children, role, grow }) => {
  const gutterSize = useAppLayoutGutterSize();
  const appLayoutContext = useAppLayoutContext();
  const horizontalPadding = appLayoutContext?.removeContentPadding ? 0 : gutterSize;

  return (
    <Flex horizontalPadding={horizontalPadding} columnGap={gutterSize} grow={grow} role={role} className="qaContentRow">
      {children}
    </Flex>
  );
};

export default ContentRow;
