import { useRenderToggleableContent } from '@ms/yammer-web-core-ui/dist/util/useRenderToggleableContent';
import { noop } from 'lodash';
import React, { FC, PropsWithChildren, createContext, useContext } from 'react';

interface GroupCreateModalContextProps {
  readonly showContent: () => void;
  readonly isUniversalCreateButton: boolean;
}

const defaultGroupCreateModalContext: GroupCreateModalContextProps = {
  showContent: noop,
  isUniversalCreateButton: false,
};

const GroupCreateModalContext = createContext<GroupCreateModalContextProps>(defaultGroupCreateModalContext);
export const useShowGroupCreateModal = () =>
  useContext<GroupCreateModalContextProps>(GroupCreateModalContext).showContent;

export const useIsUniversalCreateButton = () =>
  useContext<GroupCreateModalContextProps>(GroupCreateModalContext).isUniversalCreateButton;

export interface CreateCommunityProviderProps {
  readonly useGroupCreateModal: () => ReturnType<typeof useRenderToggleableContent>;
}

export const CreateCommunityProvider: FC<PropsWithChildren<CreateCommunityProviderProps>> = ({
  useGroupCreateModal,
  children,
}) => {
  const { showContent, content } = useGroupCreateModal();

  return (
    <GroupCreateModalContext.Provider
      value={{
        showContent,
        isUniversalCreateButton: true,
      }}
    >
      {children}
      {content}
    </GroupCreateModalContext.Provider>
  );
};
