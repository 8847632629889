import {
  register as registerSubscription,
  unregister as unregisterSubscription,
} from '@ms/yammer-data/dist/domains/pushSubscriptions/actions';
import { subscribe, unsubscribe } from '@ms/yammer-web-support/dist/pushNotifications';
import { MutableRefObject, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useGetPushSubscriptionServerKey } from '../pushSubscriptionServerKey';

export const useUpdatePushSubscriptionCallback = (
  subscription: MutableRefObject<PushSubscription | null | undefined>
) => {
  const dispatch = useDispatch();

  const applicationServerKey = useGetPushSubscriptionServerKey();

  return useCallback(async () => {
    if (subscription.current == null || !applicationServerKey) {
      return;
    }

    const prevSubscription = subscription.current;
    subscription.current = undefined;

    await unsubscribe(prevSubscription);
    await dispatch(unregisterSubscription(prevSubscription));

    const newSubscription = await subscribe(applicationServerKey);
    await dispatch(registerSubscription(newSubscription, applicationServerKey));
    subscription.current = newSubscription;
  }, [subscription, dispatch, applicationServerKey]);
};
