import React, { FC } from 'react';

import Flex, { FlexAlign, FlexItem, JustifyContent } from '../Flex';

import { useClassNames } from './VerticalAlign.styles';
import { HorizontalAlignment, VerticalAlignProps, VerticalAlignment } from './VerticalAlign.types';

const verticalAlignment: Record<VerticalAlignment, JustifyContent> = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
};

const horizontalAlignment: Record<HorizontalAlignment, FlexAlign> = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

/**
 * VerticalAlign is a convenience component which uses flexbox styling to align child items vertically.
 */
const VerticalAlign: FC<VerticalAlignProps> = ({
  align = 'center',
  horizontalAlign = 'left',
  height = '100%',
  className,
  children,
}) => {
  const innerWidth = horizontalAlign === 'left' ? '100%' : undefined;
  const classNames = useClassNames(horizontalAlign, className);

  return (
    <Flex
      direction="column"
      height={height}
      justifyContent={verticalAlignment[align]}
      alignItems={horizontalAlignment[horizontalAlign]}
      className={classNames.root}
    >
      <FlexItem width={innerWidth} maxWidth="100%">
        {children}
      </FlexItem>
    </Flex>
  );
};

export default VerticalAlign;
