import { AllLeadersInUsersNetwork_allLeaders_edges_node } from '@ms/yammer-graphql';

import { UserNetworkLeader } from '../../../state/userNetworkLeaders/types';
import { Override } from '../../types';

export interface ProcessLeaderOverrides {
  readonly id: AllLeadersInUsersNetwork_allLeaders_edges_node;
}

export type PreNormalizedProcessLeader = Partial<Override<UserNetworkLeader, ProcessLeaderOverrides>>;

type ProcessLeader = (value: AllLeadersInUsersNetwork_allLeaders_edges_node) => PreNormalizedProcessLeader;
export const processLeader: ProcessLeader = (value) => ({
  id: value,
  hasNetworkWideAudience: value.hasNetworkWideAudience,
  viewerCanManageDirectAudience: value.viewerCanManageDirectAudience,
  viewerCanViewDirectAudienceMembers: value.viewerCanViewDirectAudienceMembers,
  isLeader: value.isLeader,
  directAudienceCount: value.directAudience.totalUserCount,
  hasMultiTenantWideAudience: value.hasMultiTenantWideAudience,
});
