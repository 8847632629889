import ErrorBoundary, { ErrorBoundaryName } from '@ms/yammer-libs-error-boundary';
import React, { FC } from 'react';

import MobileVivaAppBarExperimentWrapper from './MobileVivaAppBarExperimentWrapper';

const MobileVivaAppBarBoundary: FC = () => (
  <ErrorBoundary name={ErrorBoundaryName.VivaAppBarMobile}>
    <MobileVivaAppBarExperimentWrapper />
  </ErrorBoundary>
);

export default MobileVivaAppBarBoundary;
