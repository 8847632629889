import { lazyLoadOnRender } from '@ms/yammer-libs-lazy';

export default lazyLoadOnRender({
  loader: async () => {
    const { InboxActionButton } = await import(
      /* webpackChunkName: "4-feat-vivaengageappinbox" */ '@ms/yammer-features-app-navigation'
    );

    return { default: InboxActionButton };
  },
});
