import { UserHeaderFields } from '@ms/yammer-graphql';

import { UserCoverImage } from '../../../state/types';

export const processUserCoverImage: (value: UserHeaderFields) => UserCoverImage = ({
  id,
  coverImageUrlTemplate: backgroundUrl,
  defaultCoverImageUrlTemplate: fallbackBackgroundUrl,
  hasDefaultCoverImage,
}) => ({
  id,
  hasDefaultCoverImage,
  backgroundUrl,
  fallbackBackgroundUrl,
});
