import { useRef } from 'react';

import { initializeIcons } from './initializeIcons';
import { registerCustomIcons } from './registerCustomIcons';

type UsePrepareIcons = () => void;
export const usePrepareIcons: UsePrepareIcons = () => {
  const hasPrepared = useRef(false);

  if (hasPrepared.current) {
    return;
  }
  hasPrepared.current = true;

  registerCustomIcons();
  initializeIcons();
};
