import React, { FC } from 'react';

import { getGutterValueInPixels } from '../../../../util/styles/gutters';
import { FlexItem } from '../../../Flex';
import StickyTop from '../../../StickyTop';
import SidebarPanelButton from '../../SidebarPanelButton';
import { useAppLayoutGutterSize } from '../../hooks';

const SidebarPanelButtonStickyColumn: FC = () => {
  const gutterSize = useAppLayoutGutterSize();
  const topOffset = getGutterValueInPixels(gutterSize);

  return (
    <FlexItem width={40} shrink={0} paddingRight={2}>
      <StickyTop topOffset={topOffset}>
        <SidebarPanelButton />
      </StickyTop>
    </FlexItem>
  );
};

export default SidebarPanelButtonStickyColumn;
