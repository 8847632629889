import { updateUserPresence } from '@ms/yammer-data/dist/domains/userPresence/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

type UseUpdateUserPresenceCallback = () => (isIdle: boolean) => void;
export const useUpdateUserPresenceCallback: UseUpdateUserPresenceCallback = () => {
  const dispatch = useDispatch();

  return useCallback(
    (isIdle) => {
      dispatch(updateUserPresence(!isIdle));
    },
    [dispatch]
  );
};
