import React, { FC } from 'react';

import { getClassNames } from './FakeLink.styles.legacy';
import { FakeLinkProps } from './FakeLink.types';

/**
 * A `FakeLink` is simply a span which imitates the styling of an `<a>` tag (or `ExternalLink`
 * component). It is meant to be nested within unstyled `ExternalLink` or `Clickable`
 * components, allowing an entire block of content to be an accessible tabbable element while
 * still showing individual nested "links" for sighted users.
 *
 * NOTE: If you are looking for click interaction please see [Clickable](#clickable).
 */

const FakeLink: FC<FakeLinkProps> = ({ children, className, suppressUnderline = false, suppressLinkColor = false }) => (
  <span className={getClassNames(suppressUnderline, suppressLinkColor, className).fakeLink}>{children}</span>
);

export default FakeLink;
