import { useAddFavoriteFailureReason } from '@ms/yammer-hooks/dist/viewerCommunityRelationships';
import React, { FC } from 'react';

import FavoritesLimitReachedModalDialog from './LoadableFavoritesLimitReachedModalDialog';

const FavoritesLimitReachedDialog: FC = () => {
  const addFavoriteFailureReason = useAddFavoriteFailureReason();
  const isFavoriteLimitReached = addFavoriteFailureReason === 'FAVORITE_LIMIT_REACHED';

  return isFavoriteLimitReached ? <FavoritesLimitReachedModalDialog /> : null;
};

export default FavoritesLimitReachedDialog;
