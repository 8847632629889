import { getStatusForId } from '@ms/yammer-data-support';
import createCachedSelector from 're-reselect';

import { FeatureTranslation, Translations, YammerI18nState } from '../../state/types';
import { getCurrentLocale, getPreviousLocale } from '../locales/selectors';

import { getFeatureTranslationKey } from './shared';

export const getTranslations: (state: YammerI18nState) => Translations = (state) => state.i18n.translations;

const getFullfilledTranslations = (state: YammerI18nState): Record<string, FeatureTranslation> =>
  getTranslations(state).fulfilled.byId;

type HaveFeatureTranslationsBeenRequested = (state: YammerI18nState, feature: string) => boolean;
export const haveFeatureTranslationsBeenRequested: HaveFeatureTranslationsBeenRequested = (state, feature) => {
  const currentLocale = getCurrentLocale(state);
  const featureId = getFeatureTranslationKey(currentLocale, feature);
  const status = getStatusForId(getTranslations(state), featureId);

  return status !== 'notRequested';
};

type HaveFeatureTranslationsCompleted = (state: YammerI18nState, feature: string) => boolean;
export const haveFeatureTranslationsCompleted: HaveFeatureTranslationsCompleted = (state, feature) => {
  const currentLocale = getCurrentLocale(state);
  const featureId = getFeatureTranslationKey(currentLocale, feature);
  const status = getStatusForId(getTranslations(state), featureId);

  return status === 'fulfilled' || status === 'rejected';
};

const defaultRawTranslations = {};

export const getTranslationDataForFeature = createCachedSelector(
  getFullfilledTranslations,
  getCurrentLocale,
  getPreviousLocale,
  (_state: YammerI18nState, feature: string) => feature,
  (translations, currentLocale, previousLocale, feature) => {
    const featureTranslations =
      translations[getFeatureTranslationKey(currentLocale, feature)] ||
      translations[getFeatureTranslationKey(previousLocale, feature)];

    return featureTranslations ? featureTranslations.strings : defaultRawTranslations;
  }
)((_state, feature) => feature);
