import { AnnouncementsAnnounce, AnnouncementsClear } from './actions.types';

type Announce = (text: string) => AnnouncementsAnnounce;
export const announce: Announce = (text) => ({
  type: 'ANNOUNCEMENTS.ANNOUNCE',
  payload: text,
});

type Clear = () => AnnouncementsClear;
export const clear: Clear = () => ({ type: 'ANNOUNCEMENTS.CLEAR' });
