import { UserFieldsWithViewerIsFollowing } from '@ms/yammer-graphql';

import { ViewerIsFollowedByUser } from '../../../state/types';

export const processViewerIsFollowedByUser: (value: UserFieldsWithViewerIsFollowing) => ViewerIsFollowedByUser = (
  value
) => ({
  id: value.id,
  isFollowedBy: value.viewerIsFollowedByUser,
});
