import { merge } from '@fluentui/react/lib/Utilities';

import { IPalette, ISemanticColors, getSemanticColorOverridesFromPalette } from '../colors';
import { ITheme } from '../styling';

import { defaultDarkTheme, defaultTheme } from './default';

const getExtendedName = (name: string, baseName?: string) => (baseName ? `${name}-extends-${baseName}` : name);

const removeEmptyPaletteValues = (palette: Partial<IPalette> = {}) =>
  Object.keys(palette).reduce((sanitizedPalette: Partial<IPalette>, key: string) => {
    const paletteKey = key as keyof IPalette;
    if (palette[paletteKey]) {
      sanitizedPalette[paletteKey] = palette[paletteKey];
    }

    return sanitizedPalette;
  }, {});

interface CreateCustomizedThemeOptions {
  readonly name: string;
  readonly theme?: Partial<ITheme>;
  readonly paletteOverrides?: Partial<IPalette>;
  readonly semanticColorOverrides?: Partial<ISemanticColors>;
  readonly fontOverrides?: Partial<ITheme['fonts']>;
}
type CreateCustomizedTheme = (options: CreateCustomizedThemeOptions) => ITheme;
export const createCustomizedThemeWithSlotOverrides: CreateCustomizedTheme = ({
  name,
  theme,
  paletteOverrides,
  semanticColorOverrides,
  fontOverrides,
}) => {
  const baseTheme = theme?.isInverted ? defaultDarkTheme : defaultTheme;

  const palette = merge({}, baseTheme.palette, theme?.palette, removeEmptyPaletteValues(paletteOverrides));

  const semanticColors = merge(
    {},
    baseTheme.semanticColors,
    theme?.semanticColors,
    getSemanticColorOverridesFromPalette(palette, baseTheme.isInverted),
    semanticColorOverrides
  );

  const fonts = merge({}, baseTheme.fonts, fontOverrides);

  return {
    ...baseTheme,
    name: getExtendedName(name, theme?.name),
    fonts,
    palette,
    semanticColors,
  };
};
