import { useMemo } from 'react';

import { useCanAccessCampaignsDashboard, useCanCreateCampaigns, useIsQuestionsOnlyMode } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useCampaignsDashboardPageSettings: UseMainUIFeatureSettings<'campaignsDashboardPage'> = (options) => {
  const campaignsDashboardPageOverrides = options?.overrides?.campaignsDashboardPage;

  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();
  const isCampaignsDashboardAccessible = useCanAccessCampaignsDashboard();

  const createCampaigns = useCanCreateCampaigns();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        createCampaigns: false,
        ...campaignsDashboardPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: isCampaignsDashboardAccessible,
      createCampaigns,
      ...campaignsDashboardPageOverrides,
    };
  }, [campaignsDashboardPageOverrides, createCampaigns, isCampaignsDashboardAccessible, isQuestionsOnlyMode]);
};
