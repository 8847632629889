import { schema } from 'normalizr';

import { processGroupCoverImage } from '../strategies/groupCoverImage';

export const groupCoverImageSchema = new schema.Entity(
  'groupCoverImages',
  {},
  { processStrategy: processGroupCoverImage }
);

export type GroupCoverImageEdgesSchema = typeof groupCoverImageEdgesSchema;
export const groupCoverImageEdgesSchema = [new schema.Object({ node: groupCoverImageSchema })];
