import { useExperimentTreatmentWithoutReporting } from '@ms/yammer-hooks/dist/experiments';
import { useFeatureAccessSettings } from '@ms/yammer-hooks/dist/featureAccessSettings';

export const useIsCompactHeaderGeneralEnabled = () =>
  useExperimentTreatmentWithoutReporting('CompactHeaderGeneral') === true;

export const useCanAccessCommunicationsDashboard = () => {
  const isCommunicationsDashboardEnabled = useExperimentTreatmentWithoutReporting('CommunicationsDashboard') === true;
  const accessSettings = useFeatureAccessSettings();
  const canAccessDashboard = !!accessSettings?.viewerCanAccessCommsDashboard;

  return canAccessDashboard && isCommunicationsDashboardEnabled;
};

export const useCanAccessCommunicationsFeaturedConversations = () => useCanAccessCommunicationsDashboard();
export const useCanAccessCommunicationsDashboardLeadersAndAudiences = () => useCanAccessCommunicationsDashboard();
