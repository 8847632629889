import { InternalLinkProps } from '../InternalLink.types';

import { useCreateInternalLinkLocationState } from './useInternalLinkLocationState';

type UseToPropWithState = (
  to: InternalLinkProps['to']
) => InternalLinkProps['to'] extends infer T ? (T extends string ? never : T) : never;
export const useToPropWithState: UseToPropWithState = (to) => {
  const state = useCreateInternalLinkLocationState();

  if (typeof to !== 'string') {
    return to;
  }

  const [, pathname, hash, search] = /^(.+?)(#.+?)?(\?.+?)?$/.exec(to) ?? ([] as (string | undefined)[]);

  return { pathname, hash, search, state };
};
