import { GroupLinkFields } from '@ms/yammer-graphql';

import { GroupLink } from '../../../state/types';

export const processGroupLinks: (value: GroupLinkFields) => GroupLink = (value) => ({
  id: value.id,
  name: value.displayName,
  isMultiTenantOrganizationGroup: value.isMultiTenantOrganizationGroup,
  isOfficial: value.isOfficial,
  officeUnifiedGroupId: value.officeUnifiedGroupId || undefined,
  telemetryId: value.telemetryId,
});
