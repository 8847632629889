import { useMemo } from 'react';

import { ComponentClassNames } from './classNames';
import { getFocusStyle } from './styles/focusOutlines';
import { mergeStyleSets } from './styling';
import { ThemedClickableStyleProps, useLinkStyles } from './useLinkStyles';

type GetLinkClassNames = (props: ThemedClickableStyleProps) => ComponentClassNames<'link'>;
export const useLinkClassNames: GetLinkClassNames = (props) => {
  const linkStyles = useLinkStyles({
    activeColor: props.activeColor,
    alwaysUnderline: props.alwaysUnderline,
    className: props.className,
    color: props.color,
    colorOverride: props.colorOverride,
    disabled: props.disabled,
    display: props.display,
    focusOutline: props.focusOutline,
    theme: props.theme,
    unstyled: props.unstyled,
  });

  return useMemo(
    () =>
      mergeStyleSets({
        link: [linkStyles, getFocusStyle({ focusOutline: props.focusOutline }), props.className],
      }),
    [linkStyles, props.className, props.focusOutline]
  );
};
