import { makeStyles } from '@griffel/react';

export type JustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch';

const useJustifyContentClassNames = makeStyles<JustifyContent>({
  'flex-start': {
    justifyContent: 'flex-start',
  },
  'flex-end': {
    justifyContent: 'flex-end',
  },
  center: {
    justifyContent: 'center',
  },
  stretch: {
    justifyContent: 'stretch',
  },
  'space-between': {
    justifyContent: 'space-between',
  },
  'space-around': {
    justifyContent: 'space-around',
  },
  'space-evenly': {
    justifyContent: 'space-evenly',
  },
});

export const useJustifyContentClassName = (justifyContent?: JustifyContent) => {
  const classNames = useJustifyContentClassNames();

  return justifyContent ? classNames[justifyContent] : undefined;
};
