import { useAuthenticatorContext } from '@ms/yammer-authenticator-context';
import { FeatureRollout, useExperimentTreatment, useIsFeatureRolloutEnabled } from '@ms/yammer-experiments-context';
import React, { FC, createContext, useContext, useMemo } from 'react';

import type { RelayContextType } from '../types';

export type LazyRelayAppWrapperProps = Omit<RelayContextType, 'environment'>;

/* istanbul ignore next */
export const LazyRelayAppWrapper: FC<LazyRelayAppWrapperProps> = ({ children, ...relayContextWithoutEnvironment }) => {
  const authenticator = useAuthenticatorContext();

  const isAutomaticPersistedQueriesEnabled = useIsFeatureRolloutEnabled(FeatureRollout.AutomaticPersistedQueries);
  const backendGraphqlApiVnextEndpoint = useExperimentTreatment('BackendGraphqlApiVnextEndpoint') as number;

  const environment = useMemo(
    async () =>
      (await import(/* webpackChunkName: "4-feat-relay" */ '../environment')).createRelayEnvironment({
        ...relayContextWithoutEnvironment,
        acquireToken: () => authenticator.acquireToken([relayContextWithoutEnvironment.authScope]),
        isAutomaticPersistedQueriesEnabled,
        backendGraphqlApiVnextEndpoint,
      }),
    [relayContextWithoutEnvironment]
  );

  const relayContext = useMemo(
    () => ({
      ...relayContextWithoutEnvironment,
      environment,
    }),
    [environment, relayContextWithoutEnvironment]
  );

  return <RelayContext.Provider value={relayContext}>{children}</RelayContext.Provider>;
};

/* istanbul ignore next */
export const RelayContext = createContext<RelayContextType>({
  environment: undefined,
  host: '',
  authScope: '',
});

/* istanbul ignore next */
export const useRelayContext = () => useContext(RelayContext);
