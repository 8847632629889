import { ComponentClassNames } from '../../util/classNames';
import { memoizeFunction, mergeStyleSets } from '../../util/styling';

type FocusPlaceholderClassNames = ComponentClassNames<'anchor' | 'placeholder'>;

export const getClassNames = memoizeFunction(
  (): FocusPlaceholderClassNames =>
    mergeStyleSets({
      // Outer div is relative and 0x0 so as to not interfere with layout
      anchor: {
        display: 'block',
        position: 'relative',
        width: 0,
        height: 0,
        margin: 0,
      },
      // Inner div is 1x1px - some browser will refuse to focus 0-sized items
      placeholder: {
        position: 'absolute',
        width: 1,
        height: 1,
        outline: 'none',
      },
    })
);
