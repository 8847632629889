import { createContext, useContext } from 'react';

export interface SessionContextOptions {
  readonly baseHost: string;
}

export const defaultOptions: SessionContextOptions = {
  baseHost: 'http://web.yammer.com/main',
};

export const SessionContext = createContext(defaultOptions);

export type UseSessionContext = () => SessionContextOptions;
export const useSessionContext: UseSessionContext = () => useContext(SessionContext);
