import {
  CommunicationColors as FluentCommunicationColors,
  DefaultPalette as FluentDefaultPalette,
  NeutralColors as FluentNeutralColors,
  SharedColors as FluentSharedColors,
} from '@fluentui/react/lib/Theme';

// https://developer.microsoft.com/en-us/fluentui#/styles/web/colors/products
export const YammerColors = {
  yammerPrimary: '#106ebe',
  yammerTint30: '#69afe5',
  yammerTint20: '#2488d8',
  heartRed: '#ff564d',
};

export const DefaultColors: Record<keyof typeof FluentDefaultPalette, string> = FluentDefaultPalette;

export const NeutralColors: Record<keyof typeof FluentNeutralColors, string> = FluentNeutralColors;

export const SharedColors: Record<keyof typeof FluentSharedColors, string> = FluentSharedColors;

export const CommunicationColors: Record<keyof typeof FluentCommunicationColors, string> = FluentCommunicationColors;
