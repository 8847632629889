import { getPersistedState } from '../../persistedState';
import { SuiteHeaderTheme, YammerState } from '../../state/types';
import { getPersistKey } from '../persistConfig/selectors';

type GetSuiteHeaderTheme = (state: YammerState) => SuiteHeaderTheme;
export const getSuiteHeaderTheme: GetSuiteHeaderTheme = (state) => state.suiteHeaderTheme;

type GetPersistedIsInverted = (key: string) => Promise<boolean>;
export const getPersistedIsInverted: GetPersistedIsInverted = async (key) => {
  const state = await getPersistedState(key);

  return !!state?.suiteHeaderTheme?.isInverted;
};

type GetPersistedSuiteHeaderThemeIsInverted = (state: YammerState) => Promise<boolean>;
export const getPersistedSuiteHeaderThemeIsInverted: GetPersistedSuiteHeaderThemeIsInverted = async (state) => {
  const persistKey = getPersistKey(state);

  if (!persistKey) {
    return false;
  }

  const isInverted = await getPersistedIsInverted(persistKey);

  return isInverted;
};
