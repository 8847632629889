import { makeStyles } from '@griffel/react';

export type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';

const useFlexDirectionClassNames = makeStyles<FlexDirection>({
  row: {
    flexDirection: 'row',
  },
  'row-reverse': {
    flexDirection: 'row-reverse',
  },
  column: {
    flexDirection: 'column',
  },
  'column-reverse': {
    flexDirection: 'column-reverse',
  },
});

export const useFlexDirectionClassName = (direction?: FlexDirection) => {
  const classNames = useFlexDirectionClassNames();

  return direction ? classNames[direction] : undefined;
};
