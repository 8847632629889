import {
  GroupFields,
  UserFields,
  ViewerCallToActionClients_viewer_suggestedActions_SuggestedActionFollowUser,
  ViewerCallToActionClients_viewer_suggestedActions_SuggestedActionJoinGroup,
} from '@ms/yammer-graphql';

import { SuggestedAction } from '../../../state/types';
import { Override } from '../../types';

export interface PreNormalizedSuggestedGroup {
  readonly id: GroupFields;
}

export interface PreNormalizedSuggestedUser {
  readonly id: UserFields;
}

type PreNormalizedSuggestedAction = PreNormalizedSuggestedGroup | PreNormalizedSuggestedUser;

type PreNormalizedSuggestedActionType = Override<SuggestedAction, PreNormalizedSuggestedAction>;

export const processSuggestedActionGroup: (
  value: ViewerCallToActionClients_viewer_suggestedActions_SuggestedActionJoinGroup
) => PreNormalizedSuggestedActionType = (value) => ({
  id: value.group,
  recommendationId: value.recommendationId,
});

export const processSuggestedActionUser: (
  value: ViewerCallToActionClients_viewer_suggestedActions_SuggestedActionFollowUser
) => PreNormalizedSuggestedActionType = (value) => ({
  id: value.user,
  recommendationId: value.recommendationId,
});
