import React, { FC, useRef } from 'react';

import { useContainerWidth } from '../useContainerWidth';
import { useMeasureContainerWidth } from '../useMeasureContainerWidth';

import { ContainerWidthContext } from './context';

export interface ContainerWidthProviderProps {
  /**
   * A performance optimization. If true, will render `null` instead of `children` on first render until
   * the local container width is measured and available.
   *
   * This is useful when a large component tree is rendered inside the `ContainerWidthProvider` and the initial
   * render (based on the next ancestor `ContainerWidthProvider` context) followed by immediate re-render (based
   * on the newly-calculated local width context) takes too long and locks up the main thread.
   */
  readonly deferRenderUntilMeasured?: boolean;
}

/**
 * @internal This component is intended for internal use within CoreUI. You are likely to introduce render performance
 * issues if you use this component in your feature because this will cause your feature to render once with the parent
 * context width and then immediately again with the new measured width. This is likely to lock up the main thread and
 * prevent any other script or user interaction until the second render cycle completes. All CoreUI surfaces (AppLayout
 * columns, Panels, Dialogs, etc.) wrap their children in a `<ContainerWidthProvider>`, please rely on the existing
 * width context instead of introducing additional layers of DOM measurements and width context. YOUR TEAM WILL BE
 * RESPONSIBLE FOR PERFORMANCE ISSUES INTRODUCED BY USE OF THIS COMPONENT.
 */
export const ContainerWidthProvider: FC<ContainerWidthProviderProps> = ({
  deferRenderUntilMeasured = false,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const parentContextWidth = useContainerWidth();
  const measuredWidth = useMeasureContainerWidth(ref);
  const width = measuredWidth ?? parentContextWidth;

  const hasMeasured = measuredWidth != null;
  const shouldRenderNull = !hasMeasured && deferRenderUntilMeasured;
  const content = shouldRenderNull ? null : children;

  return (
    <>
      <ContainerWidthContext.Provider value={width}>{content}</ContainerWidthContext.Provider>
      <div ref={ref} />
    </>
  );
};
