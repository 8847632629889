import { serviceWorkerReady } from '../pwa';

export const getSubscription = async (): Promise<PushSubscription | null> => {
  const registration = await serviceWorkerReady();

  return registration.pushManager.getSubscription();
};

export const subscribe = async (applicationServerKey: string): Promise<PushSubscription> => {
  const registration = await serviceWorkerReady();

  const subscription = await registration.pushManager.getSubscription();

  return (
    subscription ??
    registration.pushManager.subscribe({
      applicationServerKey,
      userVisibleOnly: true,
    })
  );
};

export const unsubscribe = async (subscription: PushSubscription): Promise<boolean> => subscription.unsubscribe();
