import { Image as FluentImage, ImageLoadState } from '@fluentui/react/lib/Image';
import React, { useCallback } from 'react';

import { useClassNames } from './Image.styles';
import { ImageProps } from './Image.types';
import { MaxZoom } from './MaxZoom';

/**
 * An `Image` renders an `img` element within a wrapper `div`. This `div` is displayed as
 * `inline-block`, so it behaves like a standard inline image. The `height` and `width` actually get
 * applied to the wrapper `div`, and the image will get scaled/positioned within the wrapper
 * depending on which props you provide.
 */
const Image: React.FunctionComponent<ImageProps> = ({
  description,
  fullHeight,
  fullWidth,
  height,
  width,
  id,
  limitMaxWidth,
  imageFit,
  loading,
  maxZoom,
  source,
  shouldFadeIn,
  borderRadius,
  block,
  className,
  onLoad,
  onError,
  allowClickThroughOnFrame,
}) => {
  const classNames = useClassNames({
    borderRadius,
    fullHeight,
    fullWidth,
    limitMaxWidth,
    block,
    allowClickThroughOnFrame,
    className,
  });
  const imageWidth = fullWidth ? '100%' : width;

  const onLoadingStateChange = useCallback(
    (loadState: ImageLoadState) => {
      if (loadState === ImageLoadState.loaded && onLoad) {
        onLoad(id);
      }

      if (loadState === ImageLoadState.error && onError) {
        onError(id);
      }
    },
    [id, onError, onLoad]
  );
  const image = (
    <FluentImage
      alt={description}
      className={classNames.root}
      height={height}
      imageFit={imageFit}
      width={imageWidth}
      loading={loading}
      src={source}
      onLoadingStateChange={onLoadingStateChange}
      shouldFadeIn={shouldFadeIn}
    />
  );

  if (maxZoom !== undefined && maxZoom >= 1) {
    return (
      <MaxZoom maxZoom={maxZoom} allowClickThroughOnFrame={allowClickThroughOnFrame}>
        {image}
      </MaxZoom>
    );
  }

  return image;
};

export default Image;
