import { useMemo } from 'react';

import { useIsAdminMuteUserAdminEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useAdminMuteUserAdminPageSettings: UseMainUIFeatureSettings<'adminMuteUserAdminPage'> = (options) => {
  const adminMuteUserAdminPageSettingsOverrides = options?.overrides?.adminMuteUserAdminPage;

  const viewerCanManageAdminMuteUser = useIsAdminMuteUserAdminEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: viewerCanManageAdminMuteUser,
      ...adminMuteUserAdminPageSettingsOverrides,
    }),
    [adminMuteUserAdminPageSettingsOverrides, viewerCanManageAdminMuteUser]
  );
};
