import { FeatureAccessInsightsClients } from '@ms/yammer-graphql';

import { FeatureAccessInsights, User } from '../../state/types';

type NormalizedFeatureAccessInsights = (
  response: {
    readonly data: FeatureAccessInsightsClients;
  },
  onBehalfOfUserId?: User['id']
) => FeatureAccessInsights;

export const normalizeFeatureAccessInsights: NormalizedFeatureAccessInsights = (response, onBehalfOfUserId) => ({
  canViewActivity: response.data.viewer.canViewActivity,
  canViewLeadershipActivity: response.data.viewer.canViewLeadershipActivity,
  canViewNetworkActivity: response.data.viewer.canViewNetworkActivity,
  onBehalfOfUserId,
});
