import { noop } from 'lodash';
import { createContext } from 'react';

import { Announce } from './Announcer';

export interface AnnouncerContextProps {
  readonly announce: Announce;
  readonly setActiveChildAnnouncer: (announce: Announce) => void;
  readonly clearActiveChildAnnouncer: () => void;
}

const AnnouncerContext = createContext<AnnouncerContextProps>({
  announce: noop,
  setActiveChildAnnouncer: noop,
  clearActiveChildAnnouncer: noop,
});

export default AnnouncerContext;
