import React, { FC } from 'react';

import { PageRouteAssetPreloadProviderProps } from './PageRouteAssetPreloadProvider.types';
import { PageRouteAssetPreloadContext, PreloadPageAssetsCallback } from './context';
import { preloadPageAndSubpageAssets } from './preloadPageAndSubpageAssets';

const PageRouteAssetPreloadProvider: FC<PageRouteAssetPreloadProviderProps> = ({ routes, children }) => {
  const preloadPageAssets: PreloadPageAssetsCallback = (path) => {
    preloadPageAndSubpageAssets(path, routes);
  };

  return (
    <PageRouteAssetPreloadContext.Provider value={preloadPageAssets}>{children}</PageRouteAssetPreloadContext.Provider>
  );
};

export default PageRouteAssetPreloadProvider;
