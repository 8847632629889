interface Edge {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly node: any;
}

type GetNodesFromEdges = <T extends Edge>(edges: (T | null)[]) => T['node'][];

export const getNodesFromEdges: GetNodesFromEdges = <T extends Edge>(edges: (T | null)[]) =>
  edges.reduce<T['node'][]>((memo, edge) => {
    // When GraphQL has problems with an edge, it'll send it back as `null`.
    if (edge != null) {
      memo.push(edge.node);
    }

    return memo;
  }, []);

type FlattenNodeWithIdOnlyInEdges = <T extends Edge>(edges: (T | null)[]) => typeof edges;
export const flattenNodeWithIdOnlyInEdges: FlattenNodeWithIdOnlyInEdges = (edges) =>
  edges.map((edge) => {
    if (edge == null || edge.node == null) {
      return edge;
    }

    const { node } = edge;

    return {
      ...edge,
      node: Object.keys(node).length === 1 && Object.prototype.hasOwnProperty.call(node, 'id') ? node.id : node,
    };
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renameNodeInEdges: (edges: any[]) => any = (edges) =>
  edges.map((edge) => {
    const { node, ...newEdge } = edge;
    newEdge.nodeId = node;

    return newEdge;
  });
