import {
  ViewerCallToActionClients_viewer_suggestedActions_SuggestedActionFollowUser,
  ViewerCallToActionClients_viewer_suggestedActions_SuggestedActionJoinGroup,
} from '@ms/yammer-graphql';
import { schema } from 'normalizr';

import { processSuggestedActionGroup, processSuggestedActionUser } from '../strategies/suggestedAction';

import { groupSchema } from './group';
import { userSchema } from './user';

const getGroupId = (value: ViewerCallToActionClients_viewer_suggestedActions_SuggestedActionJoinGroup) =>
  value.group.id;

const getUserId = (value: ViewerCallToActionClients_viewer_suggestedActions_SuggestedActionFollowUser) => value.user.id;

export const suggestedActionJoinGroupSchema = new schema.Entity(
  'joinGroups',
  {
    id: groupSchema,
  },
  {
    idAttribute: getGroupId,
    processStrategy: processSuggestedActionGroup,
  }
);

export const suggestedActionFollowUserSchema = new schema.Entity(
  'followUsers',
  {
    id: userSchema,
  },
  {
    idAttribute: getUserId,
    processStrategy: processSuggestedActionUser,
  }
);

export const suggestedActionUnionSchema = new schema.Union(
  {
    joinGroup: suggestedActionJoinGroupSchema,
    followUser: suggestedActionFollowUserSchema,
  },
  '__typename'
);

export type SuggestedActionsArraySchema = typeof suggestedActionsArraySchema;
export const suggestedActionsArraySchema = new schema.Array(suggestedActionUnionSchema);
