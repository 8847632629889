import {
  getSuiteHeader,
  getSuiteHeaderSignOutLink,
  getSuiteHeaderStatus,
} from '@ms/yammer-data/dist/domains/suiteHeader/selectors';
import { useMemo } from 'react';

import { useGetProductName } from '../rebranding';
import { useSelector } from '../useSelector';

type UseStatus = () => ReturnType<typeof getSuiteHeaderStatus>;
export const useStatus: UseStatus = () => useSelector(getSuiteHeaderStatus);

type UseSuiteHeader = () => ReturnType<typeof getSuiteHeader>;
export const useSuiteHeader: UseSuiteHeader = () => {
  const productName = useGetProductName();
  const suiteHeaderData = useSelector(getSuiteHeader);

  return useMemo(
    () => ({
      ...suiteHeaderData,
      CurrentWorkloadSettingsSubLinksTitle: productName,
    }),
    [suiteHeaderData, productName]
  ) as ReturnType<typeof getSuiteHeader>;
};

type UseSuiteHeaderSignOutLink = () => ReturnType<typeof getSuiteHeaderSignOutLink>;
export const useSuiteHeaderSignOutLink: UseSuiteHeaderSignOutLink = () => useSelector(getSuiteHeaderSignOutLink);
