import { isRegistered, isRegisteredWithExpiredKey } from '@ms/yammer-data/dist/domains/pushSubscriptions/selectors';

import { useSelector } from '../useSelector';

type UseIsRegistered = (subscription: PushSubscription | null | undefined) => ReturnType<typeof isRegistered>;
export const useIsRegistered: UseIsRegistered = (subscription) =>
  useSelector((state) => isRegistered(state, subscription));

type UseIsRegisteredWithExpiredKey = (
  subscription: PushSubscription | null | undefined
) => ReturnType<typeof isRegisteredWithExpiredKey>;
export const useIsRegisteredWithExpiredKey: UseIsRegisteredWithExpiredKey = (subscription) =>
  useSelector((state) => isRegisteredWithExpiredKey(state, subscription));
