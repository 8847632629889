import createCachedSelector from 're-reselect';

import { EntityStatus, User, UserNetworkLeadersListFields, YammerState, getStatus } from '../../state/types';
import { userNetworkLeadersListRequestStatusKey } from '../leadersManagement/actions.types';
import { getStatus as getRequestStatus } from '../requestStatuses/selectors';
import { getFulfilledUserNetworkLeaders } from '../userNetworkLeaders/selectors';
import { getFulfilledUsers } from '../users/selectors';

type GetLoadMoreUserNetworkLeadersListStatus = (state: YammerState) => EntityStatus;
export const getLoadMoreUserNetworkLeadersListStatus: GetLoadMoreUserNetworkLeadersListStatus = (state) =>
  getRequestStatus(state, 'UserNetworkLeadersListMore', userNetworkLeadersListRequestStatusKey);

type GetUserNetworkLeadersListStatus = (state: YammerState) => EntityStatus;
export const getUserNetworkLeadersListStatus: GetUserNetworkLeadersListStatus = (state) =>
  getStatus(state.userNetworkLeadersList);

const emptyUserNetworkLeaderIds: User['id'][] = [];
export const getUserNetworkLeadersListIdsFromState = (state: YammerState) =>
  state.userNetworkLeadersList.fulfilled?.userNetworkLeaders ?? emptyUserNetworkLeaderIds;

type GetUserNetworkLeadersList = (state: YammerState) => UserNetworkLeadersListFields[];
export const getUserNetworkLeadersList: GetUserNetworkLeadersList = createCachedSelector(
  getUserNetworkLeadersListIdsFromState,
  getFulfilledUserNetworkLeaders,
  getFulfilledUsers,
  (userNetworkLeadersListIds, fulfilledUserNetworkLeaders, fulfilledUsers) =>
    userNetworkLeadersListIds.reduce<UserNetworkLeadersListFields[]>((acc, edgeId) => {
      const userNetworkLeader = fulfilledUserNetworkLeaders[edgeId];

      if (!userNetworkLeader) {
        throw new Error('Missing user network leader data in state for user network leaders list edge.');
      }

      const {
        id,
        hasNetworkWideAudience,
        viewerCanManageDirectAudience,
        viewerCanViewDirectAudienceMembers,
        directAudienceCount,
      } = userNetworkLeader;
      const { name, jobTitle, avatarUrlTemplate } = fulfilledUsers[id];

      acc.push({
        id,
        avatarUrlTemplate,
        name,
        jobTitle,
        hasNetworkWideAudience,
        viewerCanManageDirectAudience,
        viewerCanViewDirectAudienceMembers,
        directAudienceCount,
      });

      return acc;
    }, [])
)((_state) => 'cache');

type HasNextPageOfUserNetworkLeadersListAvailable = (state: YammerState) => boolean;
export const hasNextPageOfUserNetworkLeadersListAvailable: HasNextPageOfUserNetworkLeadersListAvailable = (state) =>
  state.userNetworkLeadersList.fulfilled?.hasNextPage ?? false;
