import { KEY_SEPARATOR } from './constants';
import { getLanguage } from './locale';
import { getPluralName } from './plurals';
import { PhraseMap } from './types';

/**
 * Returns the raw string for the specified key. If the string is not found, it throws.
 *
 * @param key String key.
 * @param count Optional value used to determine plural variant of the key.
 */
type Lookup = (locale: string, phrases: PhraseMap, key: string, count?: number) => string;
export const lookup: Lookup = (locale, phrases, key, count) => {
  if (!key) {
    throw new Error(`Key "${key}" is not valid`);
  }

  const pluralName = getPluralName(count, getLanguage(locale));

  const fullKey = pluralName ? [key, pluralName].join(KEY_SEPARATOR) : key;

  const str = phrases[fullKey];

  if (!str) {
    throw new Error(`Cannot find string for key "${key}"`);
  }

  return str;
};
