import { noop } from 'lodash';
import React, { FC, createContext, useMemo } from 'react';

import { AppLayoutBreakpoint, useContainerWidth } from '../../util/responsiveness';
import useOpenCloseState from '../../util/useOpenCloseState';

import { AppLayoutContextProviderProps, AppLayoutContextValue } from './AppLayoutContextProvider.types';

export const defaultOptions: AppLayoutContextValue = {
  isSidebarPanelOpen: false,
  openSidebarPanel: noop,
  closeSidebarPanel: noop,
  openContentSidebarAriaLabel: '',
  closeContentSidebarAriaLabel: '',
  hasBackButton: false,
  hasOuterLeftNav: false,
  gutterSize: 6,
  width: Infinity,
  removeContentPadding: false,
};

export const AppLayoutContext = createContext<AppLayoutContextValue | undefined>(undefined);

const AppLayoutContextProvider: FC<AppLayoutContextProviderProps> = ({
  closeContentSidebarAriaLabel,
  openContentSidebarAriaLabel,
  hasBackButton = false,
  hasOuterLeftNav = false,
  removeContentPadding = false,
  children,
}) => {
  const width = useContainerWidth();

  const { open: openSidebarPanel, close: closeSidebarPanel, isOpen: isSidebarPanelOpen } = useOpenCloseState();

  const gutterSize = width < AppLayoutBreakpoint.Width568 ? 3 : 6;

  const value: AppLayoutContextValue = useMemo(
    () => ({
      openSidebarPanel,
      closeSidebarPanel,
      isSidebarPanelOpen,
      openContentSidebarAriaLabel,
      closeContentSidebarAriaLabel,
      hasBackButton,
      hasOuterLeftNav,
      gutterSize,
      width,
      removeContentPadding,
    }),
    [
      openSidebarPanel,
      closeSidebarPanel,
      isSidebarPanelOpen,
      openContentSidebarAriaLabel,
      closeContentSidebarAriaLabel,
      hasBackButton,
      hasOuterLeftNav,
      gutterSize,
      width,
      removeContentPadding,
    ]
  );

  return <AppLayoutContext.Provider value={value}>{children}</AppLayoutContext.Provider>;
};

export default AppLayoutContextProvider;
