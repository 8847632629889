import { DarkBackgroundFlairColors, DarkFlairColors, FlairColor, FlairColors } from '.';

export const getFlairColor = (color: FlairColor, isInverted: boolean, isOnDarkBackground = false) => {
  if (isInverted) {
    return DarkFlairColors[color];
  }

  if (isOnDarkBackground) {
    return DarkBackgroundFlairColors[color];
  }

  return FlairColors[color];
};
