import { useIsEUDB } from '@ms/yammer-hooks/dist/eudb';
import { useIsSuiteHeaderAvailable } from '@ms/yammer-hooks/dist/suiteHeader';
import { LazyLoadOnIdle } from '@ms/yammer-libs-lazy';
import Null from '@ms/yammer-libs-null';
import React, { FC } from 'react';

const loader = () => import(/* webpackChunkName: "4-feat-vivaappbar" */ './VivaAppBar');

const LoadableVivaAppBar: FC = () => {
  const isSuiteHeaderAvailable = useIsSuiteHeaderAvailable();
  const isEUDB = useIsEUDB();

  if (!isSuiteHeaderAvailable || isEUDB == null) {
    return null;
  }

  return <LazyLoadOnIdle loader={loader} isEUDB={isEUDB} Placeholder={Null} />;
};

export default LoadableVivaAppBar;
