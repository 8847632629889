import {
  getUserAchievementsSettings,
  getUserAchievementsSettingsStatus,
  getViewerCanEditUserAchievementsSettings,
} from '@ms/yammer-data/dist/domains/userAchievementsSettings/selectors';

import { useSelector } from '../useSelector';

type UseUserDirectAudience = () => ReturnType<typeof getViewerCanEditUserAchievementsSettings>;
export const useViewerCanEditUserAchievementsSettings: UseUserDirectAudience = () =>
  useSelector((state) => getViewerCanEditUserAchievementsSettings(state));

type UseGetUserAchievementsSettings = () => ReturnType<typeof getUserAchievementsSettings>;
export const useGetUserAchievementsSettings: UseGetUserAchievementsSettings = () =>
  useSelector((state) => getUserAchievementsSettings(state));

type UseGetUserAchievementsSettingsStatus = () => ReturnType<typeof getUserAchievementsSettingsStatus>;
export const useGetUserAchievementsSettingsStatus: UseGetUserAchievementsSettingsStatus = () =>
  useSelector((state) => getUserAchievementsSettingsStatus(state));
