import { ComponentClassNames } from '../../util/classNames';
import { Depth, getDepthBoxShadow } from '../../util/styles/depth';
import { memoizeFunction, mergeStyleSets } from '../../util/styling';

type GetClassNames = (zIndex: number | undefined, depth: Depth | undefined) => ComponentClassNames<'appLayoutRow'>;

export const getClassNames: GetClassNames = memoizeFunction((zIndex, depth) =>
  mergeStyleSets({
    appLayoutRow: [
      {
        zIndex,
        boxShadow: depth ? getDepthBoxShadow(depth) : undefined,
      },
      'qaAppLayoutRow',
    ],
  })
);
