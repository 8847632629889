import React, { FC } from 'react';

import { LocaleContext, defaultLocale } from './context';

interface LocaleProviderProps {
  readonly locale: string | undefined;
}

const LocaleProvider: FC<LocaleProviderProps> = ({ children, locale = defaultLocale }) => (
  <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>
);

export default LocaleProvider;
