import { CoreUICSSIdentifier, CoreUICSSVariable, CoreUIClassName } from './css.types';

/**
 * Convenience method to wrap a CSS variable in `var()`,
 * e.g. `cssVar('--coreui-gridList-itemWidth')` => `var(--coreui-gridList-itemWidth)`.
 */
type CSSVar = <T extends string>(cssVariableName: T) => `var(${T})`;
export const cssVar: CSSVar = (cssVariableName) => `var(${cssVariableName})`;

/**
 * Convenience method to append a value with 'px' only if the value is a number, otherwise returns the original value.
 * @example `px(10)` => `10px`, `px('10%')` => `10%`, px(undefined) => undefined
 */
export const px = (cssLengthValue: number | string | undefined): string | undefined =>
  typeof cssLengthValue === 'number' ? `${cssLengthValue}px` : cssLengthValue;

type CreateCoreUICSSIdentifier = <TokenName extends string, LocalNamespace extends string>(
  tokenName: TokenName,
  localNamespace: LocalNamespace
) => CoreUICSSIdentifier<LocalNamespace, TokenName>;
const createCoreUICSSIdentifier: CreateCoreUICSSIdentifier = (tokenName, localNamespace) =>
  `coreui-${localNamespace}-${tokenName}`;

/**
 * Returns a className prefixed with the global CoreUI namespace.
 */
type CreateClassName = <LocalClassName extends string>(name: LocalClassName) => CoreUIClassName<LocalClassName>;
export const createClassName: CreateClassName = (name) => createCoreUICSSIdentifier(name, 'class');

/**
 * Returns a CSS Custom Property with "global" CoreUI and local namespaces.
 */
type CreateCSSVariable = <TokenName extends string, LocalNamespace extends string>(
  tokenName: TokenName,
  localNamespace: LocalNamespace
) => CoreUICSSVariable<LocalNamespace, TokenName>;
export const createCSSVariable: CreateCSSVariable = (tokenName, localNamespace) =>
  `--${createCoreUICSSIdentifier(tokenName, localNamespace)}`;
