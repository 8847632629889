import { createRequiredContext } from '../context';

import { StringWrapperCtor } from './types';

interface TranslationsContextValue {
  readonly useCurrentLocale: () => string;
  readonly useHaveTranslationsCompleted: <T>(from: StringWrapperCtor<T>) => boolean;
  readonly useLoader: <T>(from: StringWrapperCtor<T>) => T;
}

const [TranslationsContextProvider, useTranslationsContext] =
  createRequiredContext<TranslationsContextValue>('TranslationsContext');

type UseTranslations = <T>(Strings: StringWrapperCtor<T>) => T;
export const useTranslations: UseTranslations = <T>(from: StringWrapperCtor<T>): T =>
  useTranslationsContext().useLoader(from);

export const useCurrentLocale = (): string => useTranslationsContext().useCurrentLocale();

export const useHaveTranslationsCompleted = <T>(Strings: StringWrapperCtor<T>): boolean =>
  useTranslationsContext().useHaveTranslationsCompleted(Strings);

export { TranslationsContextProvider };
