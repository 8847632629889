import { ComponentsStyles } from '@fluentui/react/lib/Theme';
import { CSSProperties } from 'react';

import { ComponentClassNames } from '../../util/classNames';
import { HighContrastEmulationSelectorStyles } from '../../util/styles/highContrastEmulation';
import { IRawStyle, ITheme, memoizeFunction, mergeStyleSets } from '../../util/styling';
import { getCoreUITokenValues } from '../../util/theme/fluent9/coreUITokens';

export const DarkThemeClassName = 'y-darkTheme';
export const LightThemeClassName = 'y-lightTheme';
export const DarkThemeCoreUITokensClassName = 'y-coreuiTokens-darkTheme';
export const LightThemeCoreUITokensClassName = 'y-coreuiTokens-lightTheme';

const boxSizingRootStyles: IRawStyle = {
  boxSizing: 'border-box',
};
const boxSizingChildStyles: IRawStyle = {
  boxSizing: 'inherit',
};

const getGlobalTypographyStyles = (theme: ITheme, useTransparentBackground: boolean): IRawStyle => ({
  ...theme.fonts.mediumPlus,
  color: theme.semanticColors.bodyText,
  backgroundColor: useTransparentBackground ? 'transparent' : theme.semanticColors.bodyBackground,
  WebkitTextSizeAdjust: '100%',
});

const getGlobalStylingForTheme = (theme: ITheme, useTransparentBackground: boolean) => {
  if (useTransparentBackground) {
    return {};
  }

  return {
    backgroundColor: theme.semanticColors.bodyBackground,
    color: theme.semanticColors.bodyText,
  };
};

const getAppContainerStyles = (
  theme: ITheme,
  suppressGlobalStyles: boolean,
  emulateHighContrast: boolean,
  useTransparentBackground: boolean
): IRawStyle => {
  const rootTypographyStyles: IRawStyle = getGlobalTypographyStyles(theme, useTransparentBackground);
  const appContainerStyles = suppressGlobalStyles
    ? {
        ...boxSizingRootStyles,
        ...rootTypographyStyles,
      }
    : {};

  const bodyBackgroundClassName = theme.isInverted ? DarkThemeClassName : LightThemeClassName;
  const themeSelectorStyles = getGlobalStylingForTheme(theme, useTransparentBackground);

  const coreUITokensClassName = theme.isInverted ? DarkThemeCoreUITokensClassName : LightThemeCoreUITokensClassName;
  const globalCoreUITokensStyles = getCoreUITokenValues(theme.isInverted);

  const globalSelectorStyles: IRawStyle['selectors'] = suppressGlobalStyles
    ? {
        '*, *::before, *::after': boxSizingChildStyles,
        [`:global(body.${coreUITokensClassName})`]: globalCoreUITokensStyles,
      }
    : {
        ':global(html), :global(body)': {
          margin: 0,
          padding: 0,
        },
        ':global(html)': boxSizingRootStyles,
        ':global(body)': rootTypographyStyles,
        ':global(html *), :global(html *::before), :global(html *::after)': boxSizingChildStyles,
        [`:global(body.${bodyBackgroundClassName})`]: themeSelectorStyles,
        [`:global(body.${coreUITokensClassName})`]: globalCoreUITokensStyles,
      };

  return {
    ...appContainerStyles,

    selectors: {
      ...globalSelectorStyles,
      ...(emulateHighContrast && HighContrastEmulationSelectorStyles),
    },
  };
};

type GetClassNamesMemoized = (
  theme: ITheme,
  suppressGlobalStyles: boolean,
  emulateHighContrast: boolean,
  useTransparentBackground: boolean
) => ComponentClassNames<'appContainer'>;
export const getClassNames: GetClassNamesMemoized = memoizeFunction(
  (theme: ITheme, suppressGlobalStyles: boolean, emulateHighContrast: boolean, useTransparentBackground: boolean) =>
    mergeStyleSets({
      appContainer: getAppContainerStyles(theme, suppressGlobalStyles, emulateHighContrast, useTransparentBackground),
    })
);

const contextualMenuItemCommonStyles = {
  lineHeight: 'inherit',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
};

export const getGlobalComponentStyleOverrides = memoizeFunction(
  (theme: ITheme): ComponentsStyles => ({
    // Applying same global app container styles into modal dialog
    // since when suppressing the global styles, the modal dialog
    // don't share the same root element (it's added at the end of the DOM)
    // and so doesn't have the styles applied.
    Modal: {
      styles: {
        root: {
          ...getGlobalTypographyStyles(theme, true),
          ...boxSizingRootStyles,

          selectors: {
            '*, *::before, *::after': boxSizingChildStyles,
          },
        },
      },
    },

    // Outlook Web overrides the FluentUI Icon style, this ensures display:inline-block is correctly applied to our componen tree.
    // https://outlookweb.visualstudio.com/Outlook%20Web/_git/client-web?path=%2Fpackages%2Fframework%2Fowa-fabric-theme%2Flib%2Forchestrators%2FapplyCustomScopedSettings.ts&version=GBmaster&line=12&lineEnd=23&lineStartColumn=1&lineEndColumn=1&lineStyle=plain
    Icon: {
      styles: { root: { display: 'inline-block' } },
    },
    ContextualMenu: {
      styles: {
        root: {
          selectors: {
            '.ms-ContextualMenu-item': {
              display: 'block',
            },
            '.ms-ContextualMenu-link': contextualMenuItemCommonStyles,
            '.ms-ContextualMenu-header': contextualMenuItemCommonStyles,
            '.ms-ContextualMenu-linkContent': {
              lineHeight: 'inherit',
              height: 'auto',
              paddingTop: 8,
              paddingBottom: 8,
            },
          },
        },
      },
    },
  })
);

export const getFluent9ThemeProviderInlineStyles = (theme: ITheme, useTransparentBackground: boolean) =>
  getGlobalTypographyStyles(theme, useTransparentBackground) as CSSProperties;
