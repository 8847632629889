import { AllLeadersFilter, AllLeadersInUsersNetworkVariables, AllLeadersSortOrder } from '@ms/yammer-graphql';
import queryAllLeaders from '@ms/yammer-graphql/dist/operations/queryAllLeaders.graphql';

import { GraphqlApiRequest, getGraphqlApiRequest } from '../..';

export interface LoadAllLeadersInUsersNetworkRequestOptions {
  readonly endCursor: string | null;
  readonly leadersCount?: number;
  readonly includeUserCoverImageFields?: boolean;
  readonly includeViewerIsFollowing?: boolean;
  readonly filterBy?: AllLeadersFilter;
  readonly sortBy?: AllLeadersSortOrder;
  readonly includeLeaderAudiences?: boolean;
}

export const loadAllLeadersInUsersNetworkRequest = (
  variables: LoadAllLeadersInUsersNetworkRequestOptions
): GraphqlApiRequest<AllLeadersInUsersNetworkVariables> =>
  getGraphqlApiRequest({
    body: {
      operationName: 'AllLeadersInUsersNetwork',
      query: queryAllLeaders,
      variables,
    },
  });
