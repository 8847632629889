import { AppRoutes } from '@ms/yammer-page-route-telemetry';
import { searchLegacyPath, searchPath } from '@ms/yammer-search-components/dist/urls';
import {
  userSettingsNotificationsCommunitiesPageRoute,
  userSettingsNotificationsPageRoute,
  userSettingsPageRoute,
} from '@ms/yammer-user-settings-page';
import {
  articleEditPageMatchPath,
  articleNewMatchPath,
  articlePageMatchPath,
  groupAboutSubpath,
  groupEventsSubpath,
  groupFeedAllOrGenericFeedSubpath,
  groupFeedNewSubpath,
  groupFeedNoRepliesQuestionsSubpath,
  groupFeedQuestionsSubpath,
  groupFeedUnansweredQuestionsSubpath,
  groupFilesSubpath,
  groupMembershipApprovalPage,
  groupParentPath,
  inboxAllSubpath,
  inboxDirectMessagesSubpath,
  inboxRootPath,
  inboxUnreadSubpath,
  insightsPremiumGroupOptimizationsPage,
  leaderSettingsManageLeaderPage,
  m365CopilotAdoptionPageUrl,
  storylineAdminSettingsUrl,
  threadRoutePath,
} from '@ms/yammer-web-support/dist/urls';

export const pageRoutes: AppRoutes = [
  {
    page: 'ArticleNew',
    path: articleNewMatchPath,
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-article-publisher" */ '../../pages/PageArticleNew'),
  },
  {
    page: 'ArticleEdit',
    path: articleEditPageMatchPath,
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-article-publisher" */ '../../pages/PageArticleEdit'),
  },
  {
    page: 'Article',
    path: articlePageMatchPath,
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-article" */ '../../pages/PageArticle'),
  },
  {
    page: 'CommunicationsDashboard',
    path: '/dashboard/comms-activity',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-dashboard" */ '../../pages/PageDashboardCommunications'),
  },
  {
    page: 'CommsDashboardFeaturedConversations',
    path: '/dashboard/comms-activity/featured-conversations',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-dashboard-featured-conversations" */ '../../pages/PageDashboardFeaturedConversations'
      ),
  },
  {
    page: 'CommunicationsDashboardDigitalSafety',
    path: '/dashboard/digital-safety',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-dashboard-digital-safety" */ '../../pages/PageDashboardDigitalSafety'),
  },
  {
    page: 'CommunicationsDashboardLeadersAndAudiences',
    path: '/dashboard/leaders-and-audiences',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-dashboard-leader-and-audiences" */ '../../pages/PageDashboardLeadersAndAudiences'
      ),
  },
  {
    page: 'CommunicationsDashboardLeadersActivityLog',
    path: '/dashboard/leaders-and-audiences/activity-log',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-dashboard-leader-log" */ '../../pages/PageDashboardLeadersActivityLog'),
  },
  {
    page: 'MediaPostImmersiveView',
    path: '/media-post/:threadId',
    exact: true,
    hero: 'thread',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-media-post-immersive-view" */ '@ms/yammer-web-components/dist/mediaPost/PageMediaPostImmersiveView'
      ),
  },
  {
    page: 'Logout',
    path: '/logout',
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-logout" */ '../../pages/PageLogout'),
  },
  {
    page: 'AMAGroupCreate',
    path: '/ama/new/group/:groupId',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-ama-event-creation" */ '../../pages/PageAskMeAnythingEventCreation'),
  },
  {
    page: 'AMACreate',
    path: '/ama/new/:userId?',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-ama-new" */ '../../pages/PageAskMeAnythingEventCreation'),
  },
  {
    page: 'AMAEdit',
    path: '/ama/:teamsMeetingId/edit',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-ama-edit" */ '../../pages/PageAskMeAnythingEventEditing'),
  },
  {
    page: 'AMAEvent',
    path: '/ama/:teamsMeetingId',
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-ama" */ '../../pages/PageAskMeAnything'),
  },
  {
    page: 'AMAUserEvents',
    path: '/users/:userId/events/askmeanything',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-ama-user-events" */ '../../pages/PageAskMeAnythingUserEvents'),
  },
  {
    page: 'AMALeadersEvents',
    path: '/leadership-corner/events/askmeanything',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-ama-leaders-events" */ '../../pages/PageAskMeAnythingLeadersEvents'),
  },
  {
    page: 'LiveEventCreate',
    path: '/events/new/:groupId',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-liveevent-new" */ '@ms/yammer-modules-townhall-event/dist/pages/CreateTownhallEventPage'
      ),
  },
  {
    page: 'LiveEvent',
    path: '/events/:broadcastId/(questions|unanswered)?',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-liveevent" */ '../../pages/PageLiveEvent'),
  },
  {
    page: 'LiveEventEdit',
    path: '/events/:broadcastId/edit',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-liveevent-edit" */ '@ms/yammer-modules-townhall-event/dist/pages/EditTownhallEventPage'
      ),
  },
  {
    page: 'HomeFeed',
    path: '/feed',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-feed" */ '../../pages/PageFeed'),
  },
  {
    page: 'StorylinesHome',
    path: '/storylines',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-storyline-home" */ '../../pages/PageStorylineHome'),
  },
  {
    page: 'UserPendingPosts',
    path: '/users/:userId/pending-posts/:subRoute?',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-drafts" */ '../../pages/PagePendingPosts'),
  },
  {
    path: inboxRootPath,
    loader: () => import(/* webpackChunkName: "4-page-inbox" */ '@ms/yammer-web-components/dist/inbox/PageInbox'),
    childRoutes: [
      {
        page: 'InboxUnread',
        path: inboxUnreadSubpath,
        exact: true,
        hero: 'feed',
        loader: () =>
          import(/* webpackChunkName: "4-page-inbox" */ '@ms/yammer-web-components/dist/inbox/SubpageInboxUnread'),
      },
      {
        page: 'InboxDMs',
        path: inboxDirectMessagesSubpath,
        exact: true,
        hero: 'feed',
        loader: () =>
          import(
            /* webpackChunkName: "4-subpage-inbox-dms" */ '@ms/yammer-web-components/dist/inbox/SubpageInboxDirect'
          ),
      },
      {
        page: 'InboxAll',
        path: inboxAllSubpath,
        exact: true,
        hero: 'feed',
        loader: () =>
          import(/* webpackChunkName: "4-page-inbox" */ '@ms/yammer-web-components/dist/inbox/SubpageInboxAll'),
      },
    ],
    fallbackRedirectRoute: {
      page: 'InboxFallback',
      hero: 'none',
      loader: () =>
        import(
          /* webpackChunkName: "4-subpage-inbox-redirect" */ '@ms/yammer-web-components/dist/inbox/SubpageInboxFallback'
        ),
    },
  },
  {
    page: 'CampaignsDashboard',
    path: '/admin/campaigns-dashboard',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-campaigns-dashboard" */ '../../pages/PageCampaignsDashboard'),
  },
  {
    page: 'CampaignsDashboardWithSubroute',
    path: '/admin/campaigns-dashboard/:campaignsDashboardSubRoute',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-campaigns-dashboard" */ '../../pages/PageCampaignsDashboard'),
  },
  {
    page: 'CampaignsHome',
    path: '/campaigns-hub',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-campaigns-hub" */ '../../pages/PageCampaignsHub'),
  },
  {
    /**
     * This route needs to be moved into the /insights root path. Work (for Web and Teams apps):
     * 1. Delete this route
     * 2. Update seeded experience links to use the alternate /insights URL
     * 3. Update any necessary UI in the insights pages to suppress links/features based on user permissions
     * 4. Add logic to the GroupFallback page (which currently directs to ALL or NEW page based on unread count) to first catch the legacy insights route and redirect to the new insights URL.
     *
     * https://yammer.visualstudio.com/engineering/_workitems/edit/329983
     */
    page: 'GroupInsights',
    path: '/groups/:groupId/activity/:groupActivitySubRoute?',
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-group-insights" */ '@ms/yammer-web-components/dist/group/PageGroupInsights'),
  },
  /**
   * All of these non-nested group routes do not align to a consistent Information Architecture and cannot be nested as-is.
   */
  {
    page: 'GroupCreate',
    path: '/create-group',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-create" */ '../../pages/PageGroupCreate'),
  },
  {
    page: 'GroupsDiscover',
    path: '/discover-groups',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-discover-groups" */ '../../pages/PageDiscoverGroups'),
  },
  {
    page: 'GroupsDiscoverWithSubroute',
    path: '/discover-groups/:discoverGroupsSubRoute',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-discover-groups" */ '../../pages/PageDiscoverGroups'),
  },
  {
    page: 'GroupCampaigns',
    path: '/groups/:groupId/campaigns',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-campaigns" */ '../../pages/PageGroupCampaigns'),
  },
  {
    page: 'M365CopilotSuggestedContent',
    path: '/groups/:groupId/m365-copilot-suggested-content',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-m365-copilot-suggested-content" */ '../../pages/PageM365CopilotSuggestedContent'
      ),
  },
  {
    page: 'M365CopilotSuggestedArticle',
    path: '/groups/:groupId/suggested-article',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-suggested-article" */ '../../pages/PageM365CopilotSuggestedArticle'),
  },
  {
    page: 'GroupTopQuestions',
    path: '/groups/:groupId/top-questions',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-top-questions" */ '@ms/yammer-web-components/dist/group/PageGroupTopQuestions'
      ),
  },
  {
    page: 'GroupSettings',
    path: '/groups/:groupId/settings',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-settings" */ '../../pages/PageGroupSettings'),
  },
  {
    page: 'GroupDelete',
    path: '/groups/:groupId/delete',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-delete" */ '../../pages/PageGroupDelete'),
  },
  {
    page: 'GroupInfo',
    path: '/groups/:groupId/info',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-info" */ '../../pages/PageGroupInfo'),
  },
  {
    page: 'GroupInsightsLegacy',
    path: '/groups/:groupId/insights',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-insights-legacy" */ '../../pages/PageGroupInsightsLegacy'),
  },
  {
    page: 'AMAGroupEvents',
    path: '/groups/:groupId/events/askmeanything',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-ama-community-events" */ '../../pages/PageAskMeAnythingCommunityEvents'),
  },
  {
    page: 'GroupMembershipApproval',
    path: groupMembershipApprovalPage,
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-group-membership-requests" */ '../../pages/PageGroupMembershipApproval'),
  },
  {
    path: groupParentPath,
    loader: () => import(/* webpackChunkName: "4-page-group" */ '../../pages/PageGroup'),
    childRoutes: [
      {
        page: 'GroupUnseen',
        path: groupFeedNewSubpath,
        exact: true,
        hero: 'feed',
        loader: () =>
          import(
            /* webpackChunkName: "4-subpage-group-feed" */ '@ms/yammer-web-components/dist/group/SubpageGroupFeedUnseen'
          ),
      },
      {
        page: 'GroupAll',
        path: groupFeedAllOrGenericFeedSubpath,
        exact: true,
        hero: 'feed',
        loader: () =>
          import(
            /* webpackChunkName: "4-subpage-group-feed" */ '@ms/yammer-web-components/dist/group/SubpageGroupFeedAll'
          ),
      },
      {
        page: 'GroupQuestions',
        path: groupFeedQuestionsSubpath,
        exact: true,
        hero: 'feed',
        loader: () =>
          import(
            /* webpackChunkName: "4-subpage-group-feed" */ '@ms/yammer-web-components/dist/group/SubpageGroupFeedQuestions'
          ),
      },
      {
        page: 'GroupUnansweredQuestions',
        path: groupFeedUnansweredQuestionsSubpath,
        exact: true,
        hero: 'feed',
        loader: () =>
          import(
            /* webpackChunkName: "4-subpage-group-feed" */ '@ms/yammer-web-components/dist/group/SubpageGroupFeedUnansweredQuestions'
          ),
      },
      {
        page: 'GroupNoRepliesQuestions',
        path: groupFeedNoRepliesQuestionsSubpath,
        exact: true,
        hero: 'feed',
        loader: () =>
          import(
            /* webpackChunkName: "4-subpage-group-feed" */ '@ms/yammer-web-components/dist/group/SubpageGroupFeedNoRepliesQuestions'
          ),
      },
      {
        page: 'GroupAbout',
        path: groupAboutSubpath,
        exact: true,
        hero: 'group_about',
        loader: () =>
          import(
            /* webpackChunkName: "4-subpage-group-about" */ '@ms/yammer-web-components/dist/group/SubpageGroupAbout'
          ),
      },
      {
        page: 'GroupEvents',
        path: groupEventsSubpath,
        exact: true,
        hero: 'group_events',
        loader: () =>
          import(
            /* webpackChunkName: "4-subpage-group-events" */ '@ms/yammer-web-components/dist/group/SubpageGroupEvents'
          ),
      },
      {
        page: 'GroupFiles',
        path: groupFilesSubpath,
        exact: true,
        hero: 'none',
        loader: () =>
          import(
            /* webpackChunkName: "4-subpage-group-files" */ '@ms/yammer-web-components/dist/group/SubpageGroupFiles'
          ),
      },
    ],
    fallbackRedirectRoute: {
      page: 'GroupFeedFallbackRedirect',
      hero: 'none',
      loader: () =>
        import(
          /* webpackChunkName: "4-subpage-group-fallbackredirect" */ '@ms/yammer-web-components/dist/group/SubpageGroupFeedFallback'
        ),
    },
  },
  {
    page: 'Campaign',
    path: '/campaigns/:campaignId',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-campaign" */ '../../pages/PageCampaign'),
  },
  {
    page: 'Thread',
    path: threadRoutePath,
    exact: true,
    hero: 'thread',
    loader: () => import(/* webpackChunkName: "4-page-thread" */ '../../pages/PageThread'),
  },
  {
    page: 'UserFeed',
    path: '/users/:userId/:userFeedSubRoute?',
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-user" */ '../../pages/PageUser'),
  },
  {
    page: 'UserAchievements',
    path: '/achievements/:userId',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-user-achievements" */ '@ms/yammer-web-components/dist/user/PageUserAchievements'
      ),
  },
  {
    page: 'TopicFeed',
    path: '/topics/:topicId',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-topic" */ '../../pages/PageTopic'),
  },
  {
    page: 'TopicWithSubroute',
    path: '/topics/:topicId/:topicFeedSubRoute',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-topic" */ '../../pages/PageTopic'),
  },
  {
    page: 'UsagePolicy',
    path: '/usage-policy',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-usagepolicy" */ '../../pages/PageUsagePolicy'),
  },
  {
    page: 'UserSettings',
    path: userSettingsPageRoute,
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-user-settings" */ '../../pages/PageUserSettings'),
  },
  {
    page: 'UserNotificationsSettings',
    path: userSettingsNotificationsPageRoute,
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-user-settings" */ '../../pages/PageUserSettings'),
  },
  {
    page: 'UserNotificationsCommunitiesSettings',
    path: userSettingsNotificationsCommunitiesPageRoute,
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-user-settings" */ '../../pages/PageUserSettings'),
  },
  {
    page: 'UserDelegationSettings',
    path: '/user-settings/delegates',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-delegation-settings" */ '../../pages/PageDelegationSettingsLifecycle'),
  },
  {
    page: 'AdminSettingsStoryline',
    path: storylineAdminSettingsUrl,
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-storyline-admin-settings" */ '../../pages/PageStorylineAdminSettingsLifecycle'
      ),
  },
  {
    page: 'AdminSettingsStorylineNotifications',
    path: '/admin/settings/storyline/advanced',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-storyline-admin-settings-advanced" */ '../../pages/PageStorylineAdminAdvancedSettingsLifecycle'
      ),
  },
  {
    page: 'LeadersManageLegacyRedirect',
    path: '/user-settings/leaders',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-legacy-leader-settings-redirect" */ '../../pages/PageLegacyManageLeadersRedirect'
      ),
  },
  {
    page: 'LeaderManageLegacyRedirect',
    path: leaderSettingsManageLeaderPage,
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-legacy-leader-settings-redirect" */ '../../pages/PageLegacyManageLeaderRedirect'
      ),
  },
  {
    page: 'AdminCopilotAdoption',
    path: m365CopilotAdoptionPageUrl,
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-admin-m365-copilot-adoption" */ '../../pages/PageM365CopilotAdoption'),
  },
  {
    page: 'NetworkAdminSettings',
    path: '/network-admin',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-delegation-settings" */ '../../pages/PageNetworkAdminSettings'),
  },
  {
    page: 'Search',
    path: searchPath,
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-search" */ '../../pages/PageSearch'),
  },
  {
    page: 'SearchLegacyRedirect',
    path: searchLegacyPath,
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-search-legacy" */ '@ms/yammer-search-components/dist/components/SearchPage/SearchLegacyRedirect'
      ),
  },
  {
    page: 'LeadershipCorner',
    path: '/leadership-corner',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-leadershipcorner" */ '../../pages/PageLeadershipCorner'),
  },
  {
    page: 'LeaderDashboard',
    path: '/leadership-corner/leader-dashboard',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-leadershipcorner" */ '../../pages/PageLeadershipCorner'),
  },
  {
    page: 'Leaders',
    path: '/leaders',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-leaders-directory" */ '../../pages/PageLeadersDirectory'),
  },
  {
    page: 'LeadersWithSubroute',
    path: '/leaders/:subRoute',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-leaders-directory" */ '../../pages/PageLeadersDirectory'),
  },
  {
    page: 'LeadersCuratedFeed',
    path: '/leadership-corner/featured-conversations',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-leaders-curated-feed" */ '@ms/yammer-web-components/dist/leadersCuratedFeed/PageLeadersCuratedFeedMain'
      ),
  },
  {
    page: 'QuestionThread',
    path: '/questions/:threadId/:topLevelMessageId?/:secondLevelMessageId?',
    exact: true,
    hero: 'thread',
    loader: () => import(/* webpackChunkName: "4-page-question-thread" */ '../../pages/PageQuestionThread'),
  },
  {
    page: 'Questions',
    path: '/questions',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-questions" */ '../../pages/PageQuestions'),
  },
  {
    page: 'QuestionsTopicsBrowse',
    path: '/topics-network-question/browse',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-topic-networkquestion-browse" */ '../../pages/PageTopicNetworkQuestionBrowse'
      ),
  },
  {
    page: 'QuestionsTopicsFeed',
    path: '/topics-network-question/:topicId',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-topic-networkquestion" */ '../../pages/PageTopicNetworkQuestion'),
  },
  {
    page: 'NetworkThemeFeed',
    path: '/network-themes/:themeId',
    exact: true,
    hero: 'feed',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-network-theme" */ '@ms/yammer-web-components/dist/feed/NetworkThemeFeed/PageNetworkThemeFeed'
      ),
  },
  {
    page: 'QuestionsTopicsFeedWithSubroute',
    path: '/topics-network-question/:topicId/:topicFeedSubRoute',
    exact: true,
    hero: 'feed',
    loader: () => import(/* webpackChunkName: "4-page-topic-networkquestion" */ '../../pages/PageTopicNetworkQuestion'),
  },
  {
    page: 'AdminHome',
    path: '/admin',
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-admin-settings" */ '../../pages/PageAdminSettings'),
  },
  {
    page: 'GroupInsightsOptimizer',
    path: insightsPremiumGroupOptimizationsPage,
    hero: 'none',
    exact: true,
    loader: () =>
      import(
        /* webpackChunkName: "4-page-insights-group-optimization" */ '@ms/yammer-web-components/dist/insights/PageInsightsGroupOptimization'
      ),
  },
  {
    page: 'Insights',
    path: '/insights',
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-insights-main" */ '@ms/yammer-web-components/dist/insights/PageInsightsMain'),
  },
  {
    page: 'GroupInfoLegacyRedirect',
    path: '/:networkPermaLink/legacy/groups/:groupId/info',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-info" */ '../../pages/PageGroupInfo'),
  },
  {
    page: 'GroupCreateLegacyRedirect',
    path: '/:networkPermaLink/legacy/create-group',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-create" */ '../../pages/PageGroupCreate'),
  },
  {
    page: 'GroupDiscoverLegacyRedirect',
    path: '/:networkPermaLink/legacy/groups',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-group-discover-legacy" */ '../../pages/PageGroupDiscoverLegacy'),
  },
  {
    page: 'UserStorylineLegacyRedirect',
    path: '/:networkPermaLink/users/:userId/storyline',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-storyline-legacy-redirect" */ '@ms/yammer-web-components/dist/route/StorylineRouter'
      ),
  },
  {
    page: 'CampaignLegacyRedirect',
    path: '/:networkPermaLink/campaigns/:campaignId',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-campaign-legacy-redirect" */ '@ms/yammer-web-components/dist/route/CampaignRouter'
      ),
  },
  {
    page: 'LegacyRouterURLFormat1',
    path: '/:networkPermaLink/:entityType/:entityId/legacy/*',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-legacyrouter" */ '@ms/yammer-web-components/dist/route/LegacyRouter'),
  },
  {
    page: 'LegacyRouterURLFormat2',
    path: '/:networkPermaLink/legacy/:entityType/:entityId?',
    exact: true,
    hero: 'none',
    loader: () =>
      import(/* webpackChunkName: "4-page-legacyrouter" */ '@ms/yammer-web-components/dist/route/LegacyRouter'),
  },
  {
    page: 'NetworkThemeThreads',
    path: '/theme-threads/:content',
    exact: true,
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-network-theme-threads" */ '@ms/yammer-web-components/dist/copilot/PageNetworkThemeThreads'
      ),
  },
  {
    page: 'CuratedThreads',
    path: '/curated-thread-list/:curatedThreadListId/:sourceFileId?/:groupId?',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-curated-threads" */ '../../pages/PageCuratedThreadList'),
  },
  {
    page: 'RootRedirect',
    path: '/',
    exact: true,
    hero: 'none',
    loader: () => import(/* webpackChunkName: "4-page-root-redirect" */ '../../pages/PageRoot'),
  },
  {
    page: 'LegacyRouteConverter',
    path: '*',
    hero: 'none',
    loader: () =>
      import(
        /* webpackChunkName: "4-page-legacyrouteconverter" */ '@ms/yammer-web-components/dist/route/LegacyRouteConverter'
      ),
  },
];
