import { useGetProductName } from '@ms/yammer-hooks/dist/rebranding';
import { useCurrentLocale, useCurrentLocaleDirection } from '@ms/yammer-i18n-react/dist/hooks';
import { useSetRTL } from '@ms/yammer-web-core-ui/dist/util/useSetRTL';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * Component that updates the language and text direction of the `<html>` tag.
 *
 * It shouldn't output anything visible!
 */
const AppDocumentLanguage: FC = () => {
  const lang = useCurrentLocale();
  const dir = useCurrentLocaleDirection();
  useSetRTL(dir);
  const productName = useGetProductName();

  return (
    <Helmet defaultTitle={productName} titleTemplate={`${productName} - %s`} defer={false}>
      <html lang={lang} dir={dir} />
    </Helmet>
  );
};

export default AppDocumentLanguage;
