import { useTranslations } from '@ms/yammer-i18n-react';
import ErrorStrings from '@ms/yammer-strings/dist/Error';
import Block from '@ms/yammer-web-core-ui/dist/components/Block';
import Clickable from '@ms/yammer-web-core-ui/dist/components/Clickable';
import FakeLink from '@ms/yammer-web-core-ui/dist/components/FakeLink';
import Image from '@ms/yammer-web-core-ui/dist/components/Image';
import Paragraph from '@ms/yammer-web-core-ui/dist/components/Paragraph';
import VerticalAlign from '@ms/yammer-web-core-ui/dist/components/VerticalAlign';
import { useTheme } from '@ms/yammer-web-core-ui/dist/util/theme';
import React, { FC } from 'react';

import { vivaEngageLogoPath } from '../../images';

import { getClassNames } from './FailedToLoad.styles.legacy';

const reload = () => window.location.reload();

interface FailedToLoadProps {
  readonly error?: unknown;
  readonly title?: string;
}

const FailedToLoad: FC<FailedToLoadProps> = ({ error = '', title = '' }) => {
  const t = useTranslations(ErrorStrings);

  const theme = useTheme();
  const classNames = getClassNames(theme);

  // brmckeon suggested this raw english fallback in case our translations failed to load too
  const fallbackCallToAction = (
    <>
      Please <FakeLink key="failed-to-load-link">try again</FakeLink> in a few minutes.
    </>
  );

  const preferredCallToAction = t.failedToLoadCallToAction({
    link: (text) => <FakeLink key="failed-to-load-link">{text}</FakeLink>,
  });

  const callToAction = preferredCallToAction || fallbackCallToAction;

  const heading = title || t.failedToLoadMessage();
  const content = error ? (
    <Block paddingTop={3}>{error as string}</Block>
  ) : (
    <Clickable onClick={reload} display="block" unstyled={true}>
      <Paragraph>{callToAction}</Paragraph>
    </Clickable>
  );

  return (
    <VerticalAlign horizontalAlign="center" height="100vh">
      <div className={classNames.yammerLogo}>
        <Image source={vivaEngageLogoPath} description="" shouldFadeIn={false} />
      </div>
      <Block textPreset="title3">{heading}</Block>
      {content}
    </VerticalAlign>
  );
};

export default FailedToLoad;
