import { noop } from 'lodash';
import React, { FC, useContext, useEffect, useRef } from 'react';

import Announcer, { Announce } from './Announcer';
import AnnouncerContext from './AnnouncerContext';

const AnnouncerProvider: FC = ({ children }) => {
  const announceImplRef = useRef<Announce>(noop);
  const activeChildAnnouncerRef = useRef<Announce | null>(null);
  const parentAnnouncerProvider = useContext(AnnouncerContext);

  const setAnnounce = (value: Announce) => {
    announceImplRef.current = value;
  };

  const announce = (text: string) => {
    const announceImpl = activeChildAnnouncerRef.current ?? announceImplRef.current;

    announceImpl(text);
  };

  useEffect(() => {
    parentAnnouncerProvider.setActiveChildAnnouncer(announce);

    return () => {
      parentAnnouncerProvider.clearActiveChildAnnouncer();
    };
  }, [parentAnnouncerProvider]);

  const setActiveChildAnnouncer = (value: Announce) => {
    activeChildAnnouncerRef.current = value;
  };

  const clearActiveChildAnnouncer = () => {
    activeChildAnnouncerRef.current = null;
  };

  const contextValue = {
    announce,
    setActiveChildAnnouncer,
    clearActiveChildAnnouncer,
  };

  return (
    <>
      <AnnouncerContext.Provider value={contextValue}>{children}</AnnouncerContext.Provider>
      <Announcer announceRef={setAnnounce} />
    </>
  );
};

export default AnnouncerProvider;
