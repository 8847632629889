import { useIsLeadershipManagementEnabled } from './useIsLeadershipManagementEnabled';
import { useViewerCanAccessLeaderManagement } from './useViewerCanAccessLeaderManagement';

type UseShowLeaderSettings = () => boolean;
export const useShowLeaderSettings: UseShowLeaderSettings = () => {
  const isLeadershipManagementEnabled = useIsLeadershipManagementEnabled();
  const canAccessLeadershipManagement = useViewerCanAccessLeaderManagement();

  return isLeadershipManagementEnabled && canAccessLeadershipManagement;
};
