import { Entity, getStatusForId } from '@ms/yammer-data-support';
import { Action } from 'redux';

import { FeatureTranslation } from '../../state/types';
import { PromiseThunkAction } from '../../types';
import { setLocale } from '../locales/actions';
import { getCurrentLocale } from '../locales/selectors';

import { getTranslations } from './selectors';
import { getFeatureTranslationKey } from './shared';

export type TranslationLoadFullfilledPayload = Record<string, FeatureTranslation>;
export interface TranslationsLoadFulfilledAction extends Action {
  readonly type: '@@yammer-i18n-react/TRANSLATIONS.LOAD_FULFILLED';
  readonly payload: TranslationLoadFullfilledPayload;
}

export type TranslationLoadPendingPayload = Entity;
export interface TranslationsLoadPendingAction extends Action {
  readonly type: '@@yammer-i18n-react/TRANSLATIONS.LOAD_PENDING';
  readonly payload: TranslationLoadPendingPayload;
}

export interface TranslationLoadRejectedPayload extends Entity {
  readonly error: unknown;
}
export interface TranslationsLoadRejectedAction extends Action {
  readonly type: '@@yammer-i18n-react/TRANSLATIONS.LOAD_REJECTED';
  readonly payload: TranslationLoadRejectedPayload;
  readonly error: true;
}

export interface LoadOptions {
  readonly feature: string;
}

const translationLoadFullfilled = (translations: FeatureTranslation): TranslationsLoadFulfilledAction => ({
  type: '@@yammer-i18n-react/TRANSLATIONS.LOAD_FULFILLED',
  payload: {
    [translations.id]: {
      id: translations.id,
      strings: translations.strings,
    },
  },
});

const translationLoadPending = (payload: TranslationLoadPendingPayload): TranslationsLoadPendingAction => ({
  type: '@@yammer-i18n-react/TRANSLATIONS.LOAD_PENDING',
  payload,
});

const translationLoadRejected = (payload: TranslationLoadRejectedPayload): TranslationsLoadRejectedAction => ({
  type: '@@yammer-i18n-react/TRANSLATIONS.LOAD_REJECTED',
  payload,
  error: true,
});

type Load = (loadOptions: LoadOptions) => PromiseThunkAction<void>;
export const load: Load =
  ({ feature }) =>
  async (dispatch, getState) => {
    const state = getState();
    const currentLocale = getCurrentLocale(state);
    const featureId = getFeatureTranslationKey(currentLocale, feature);
    const translations = getTranslations(state);
    const status = getStatusForId(translations, featureId);
    if (status === 'fulfilled' || status === 'pending' || status === 'rejected') {
      return;
    }

    try {
      dispatch(translationLoadPending({ id: featureId }));
      const response = await fetch(`${__webpack_public_path__}yammer-locale/${currentLocale}/${feature}.json`);

      if (!response.ok) {
        if (response.status === 404) {
          dispatch(setLocale('en-us'));
        }
        throw new Error(`${response.status} ${response.statusText}`);
      }

      const json = await response.json();
      dispatch(
        translationLoadFullfilled({
          id: featureId,
          strings: json,
        })
      );
    } catch (error) {
      dispatch(translationLoadRejected({ id: featureId, error }));
      throw error;
    }
  };
