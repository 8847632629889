import { useAndReportExperimentTreatment } from '@ms/yammer-hooks/dist/experiments';
import React, { FC } from 'react';

import LoadableMobileVivaAppBar from './LoadableMobileVivaAppBar';

const MobileVivaAppBarExperimentWrapper: FC = () => {
  const isEnabled = !!useAndReportExperimentTreatment('VivaAppBar');

  if (!isEnabled) {
    return null;
  }

  return <LoadableMobileVivaAppBar />;
};

export default MobileVivaAppBarExperimentWrapper;
