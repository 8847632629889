import React, { FC } from 'react';

import { useClassNames } from './Paragraph.styles';

/**
 * Paragraph is a simple styled replacement for a `p` tag.
 */
const Paragraph: FC = ({ children }) => (
  // eslint-disable-next-line react/forbid-elements
  <p className={useClassNames().root}>{children}</p>
);

export default Paragraph;
