import { ManagedApiLifecycle, managedApiAction } from '../../api';
import { normalizeAllLeadersInUsersNetwork } from '../../normalization/graphql/normalizeAllLeaders';
import {
  AllLeadersInUsersNetworkResponse,
  NormalizedAllLeadersInUsersNetworkEntities,
} from '../../normalization/graphql/normalizeAllLeaders.types';
import { reportError } from '../../telemetry';
import { PromiseThunkAction } from '../../types';
import { getUserNetworkLeadersListStatus } from '../userNetworkLeadersList/selectors';

import {
  UserNetworkLeadersListLoadFulfilledAction,
  UserNetworkLeadersListLoadPendingAction,
  UserNetworkLeadersListLoadRejectedAction,
} from './loadAllLeadersActions.types';
import { LoadAllLeadersInUsersNetworkRequestOptions, loadAllLeadersInUsersNetworkRequest } from './sharedAllLeaders';

const loadAllLeadersInUsersNetworkPending = (): UserNetworkLeadersListLoadPendingAction => ({
  type: 'ALL_LEADERS_IN_USERS_NETWORK.LOAD_PENDING',
});

const loadAllLeadersInUsersNetworkFulfilled = (
  entities: NormalizedAllLeadersInUsersNetworkEntities
): UserNetworkLeadersListLoadFulfilledAction => ({
  type: 'ALL_LEADERS_IN_USERS_NETWORK.LOAD_FULFILLED',
  payload: {
    entities,
  },
});

const loadAllLeadersInUsersNetworkRejected = (error: unknown): UserNetworkLeadersListLoadRejectedAction => ({
  type: 'ALL_LEADERS_IN_USERS_NETWORK.LOAD_REJECTED',
  payload: {
    error,
  },
  error: true,
});

type LoadAllLeadersInUsersNetworkLifeCycle = (
  includeLeaderAudiences: boolean | undefined
) => ManagedApiLifecycle<AllLeadersInUsersNetworkResponse>;
const loadAllLeadersInUsersNetworkLifeCycle: LoadAllLeadersInUsersNetworkLifeCycle = (includeLeaderAudiences) => ({
  pending: loadAllLeadersInUsersNetworkPending,
  fulfilled: (rawAllLeadersInUsersNetwork) => {
    const { entities } = normalizeAllLeadersInUsersNetwork(rawAllLeadersInUsersNetwork, includeLeaderAudiences);

    return loadAllLeadersInUsersNetworkFulfilled(entities);
  },
  rejected: (error: Error) => loadAllLeadersInUsersNetworkRejected(error),
});

type LoadAllLeadersActionCreator = (options: LoadAllLeadersInUsersNetworkRequestOptions) => PromiseThunkAction<void>;
export const loadAllLeadersActionCreator: LoadAllLeadersActionCreator = (options) => async (dispatch, getState) => {
  try {
    const state = getState();
    const status = getUserNetworkLeadersListStatus(state);

    if (status === 'pending') {
      return;
    }

    const request = loadAllLeadersInUsersNetworkRequest(options);
    const lifecycle = loadAllLeadersInUsersNetworkLifeCycle(options.includeLeaderAudiences);
    const action = managedApiAction({ request, lifecycle });

    await dispatch(action);
  } catch (error) {
    reportError({ error });
  }
};
