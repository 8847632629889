import {
  findById,
  findFirstUndismissedPromptKey,
  getStatusForId,
  hasCurrentlyVisiblePromptKey,
  isDataLoaded,
  isPromptDismissed,
  shouldDisplayTeachingBubble,
} from '@ms/yammer-data/dist/domains/dismissablePrompts/selectors';
import { DismissablePrompt, DismissablePromptKey } from '@ms/yammer-data/dist/state/types';

import { useSelector } from '../useSelector';

type UseStatus = (id: DismissablePrompt['id']) => ReturnType<typeof getStatusForId>;
export const useStatus: UseStatus = (id) => useSelector((state) => getStatusForId(state, id));

type UseIsDataLoaded = (id: DismissablePrompt['id']) => ReturnType<typeof isDataLoaded>;
export const useIsDataLoaded: UseIsDataLoaded = (id) => useSelector((state) => isDataLoaded(state, id));

type UseDismissablePrompt = (id: DismissablePrompt['id']) => ReturnType<typeof findById>;
export const useDismissablePrompt: UseDismissablePrompt = (id) => useSelector((state) => findById(state, id));

type UseFirstUndismissedPromptKey = (keys: DismissablePromptKey[]) => ReturnType<typeof findFirstUndismissedPromptKey>;
export const useFirstUndismissedPromptKey: UseFirstUndismissedPromptKey = (keys) =>
  useSelector((state) => findFirstUndismissedPromptKey(state, keys));

type UseHasCurrentlyVisiblePromptKey = () => boolean;
export const useHasCurrentlyVisiblePromptKey: UseHasCurrentlyVisiblePromptKey = () =>
  useSelector(hasCurrentlyVisiblePromptKey);

type UseShouldDisplayTeachingBubble = (id: DismissablePrompt['id'], isEnabled?: boolean) => boolean;
export const useShouldDisplayTeachingBubble: UseShouldDisplayTeachingBubble = (id, isEnabled = true) =>
  useSelector((state) => shouldDisplayTeachingBubble(state, id, isEnabled));

type UseIsPromptDismissed = (key: DismissablePromptKey) => ReturnType<typeof isPromptDismissed>;
export const useIsPromptDismissed: UseIsPromptDismissed = (key) =>
  useSelector((state) => isPromptDismissed(state, key));
