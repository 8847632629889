import { LazyRelayModuleWrapper } from '@ms/yammer-relay/dist/environment';
import React, { FC, useRef } from 'react';

import { useGlobalCopilotStore } from '../../store';

import { LoadableGlobalCopilotDrawer } from './LoadableGlobalCopilotDrawer';

interface DeferredGlobalCopilotDrawerProps {
  readonly isFeedbackEnabled: boolean;
}

export const DeferredGlobalCopilotDrawer: FC<DeferredGlobalCopilotDrawerProps> = ({ isFeedbackEnabled }) => {
  const { isOpen } = useGlobalCopilotStore();

  const hasLoaded = useRef(false);

  if (!hasLoaded.current && isOpen) {
    hasLoaded.current = true;
  }

  if (!hasLoaded.current) {
    return null;
  }

  return (
    <LazyRelayModuleWrapper>
      <LoadableGlobalCopilotDrawer isFeedbackEnabled={isFeedbackEnabled} />
    </LazyRelayModuleWrapper>
  );
};
