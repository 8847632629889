import React, { FC } from 'react';

import { useClassNameAndInlineStyles } from './StickyTop.styles';
import { StickyTopProps } from './StickyTop.types';

const StickyTop: FC<StickyTopProps> = ({ topOffset = 0, zIndex = 1, className, children }) => {
  const styling = useClassNameAndInlineStyles({ topOffset, zIndex, className });

  return (
    <div className={styling.className} style={styling.inlineStyles}>
      {children}
    </div>
  );
};

export default StickyTop;
