import React, { FC } from 'react';

import { LinkRenderingContext, LinkRenderingOptions, defaultOptions } from './LinkRenderingContext';

export interface LinkRenderingOptionsProviderProps {
  readonly options: Partial<LinkRenderingOptions>;
}

const LinkRenderingOptionsProvider: FC<LinkRenderingOptionsProviderProps> = ({ options, children }) => (
  <LinkRenderingContext.Provider value={{ ...defaultOptions, ...options }}>{children}</LinkRenderingContext.Provider>
);

export default LinkRenderingOptionsProvider;
