import { GenericThunkAction } from '@ms/yammer-data/dist/types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';

type ActionCreator<T extends unknown[]> = (...args: T) => Action | GenericThunkAction<void>;
type UseDispatchActionCallback = <T extends unknown[]>(creator: ActionCreator<T>, ...args: T) => () => void;
export const useDispatchActionCallback: UseDispatchActionCallback = (creator, ...args) => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(creator(...args));
  }, [dispatch, creator, ...args]); // eslint-disable-line react-hooks/exhaustive-deps
};
