import { schema } from 'normalizr';

import { processUserInformationBarrierSettings } from '../strategies/userInformationBarrierSettings';

export const userInformationBarrierSettingsSchema = new schema.Entity(
  'userInformationBarrierSettings',
  {},
  {
    idAttribute: 'id',
    processStrategy: processUserInformationBarrierSettings,
  }
);
