import { NotificationPaginations, YammerState } from '../../state/types';

export const notificationPageSize = 20;
export const maxCountToShow = 20;

type GetDisplayableUnseenCount = (state: YammerState) => string | undefined;
export const getDisplayableUnseenCount: GetDisplayableUnseenCount = (state) => {
  if (state.notificationPaginations == null || state.notificationPaginations.unseenCount <= 0) {
    return;
  }

  if (state.notificationPaginations.unseenCount >= maxCountToShow) {
    return `${maxCountToShow}+`;
  }

  return `${state.notificationPaginations.unseenCount}`;
};

type GetUnseenCount = (state: YammerState) => number;
export const getUnseenCount: GetUnseenCount = (state) =>
  state.notificationPaginations != null ? state.notificationPaginations.unseenCount : 0;

type GetNotificationPaginations = (state: YammerState) => NotificationPaginations;
export const getNotificationPaginations: GetNotificationPaginations = (state) => state.notificationPaginations;
