import { useCurrentLocale, useHaveTranslationsCompleted, useTranslations } from '@ms/yammer-i18n-react';
import { TranslationsContextProvider } from '@ms/yammer-web-support/dist/translations';
import React, { FC } from 'react';

const translationsContextProviderValue = {
  useLoader: useTranslations,
  useHaveTranslationsCompleted,
  useCurrentLocale,
};

const ReduxTranslationsContextProvider: FC = ({ children }) => (
  <TranslationsContextProvider value={translationsContextProviderValue}>{children}</TranslationsContextProvider>
);

export default ReduxTranslationsContextProvider;
