import { useCanAssignUserAdminRoles } from '../featureAccessSettings';
import { useShowLeaderSettings } from '../leaderSettings';
import { useCurrentSessionUser } from '../session';

type UseCanCurrentUserAccessAdminSettings = () => boolean;
export const useCanCurrentUserAccessAdminSettings: UseCanCurrentUserAccessAdminSettings = () => {
  const canAssignUserAdminRoles = useCanAssignUserAdminRoles();
  const { isNetworkAdmin } = useCurrentSessionUser();
  const showLeaderSettings = useShowLeaderSettings();

  return canAssignUserAdminRoles || showLeaderSettings || isNetworkAdmin;
};
