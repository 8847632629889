import { normalize } from 'normalizr';

import { UserNetworkLeadersTable } from '../../state/userNetworkLeadersList/types';

import {
  AllLeadersInUsersNetworkNormalizer,
  NormalizedAllLeadersInUsersNetworkEntities,
} from './normalizeAllLeaders.types';
import { leaderSchema } from './schemas/leader';
import { userCoverImageSchema } from './schemas/userCoverImage';
import { viewerIsFollowedByUserSchema } from './schemas/viewerIsFollowedByUser';
import { viewerIsFollowingUserSchema } from './schemas/viewerIsFollowingUser';
import { getNodesFromEdges } from './strategies/edges';
import { NormalizedUserHeaderEntities } from './types';

export const normalizeAllLeadersInUsersNetwork: AllLeadersInUsersNetworkNormalizer = (response) => {
  const allLeaders = response.data.allLeaders;
  const leaderNodes = getNodesFromEdges(allLeaders.edges);

  const { entities: entitiesForLeaders, result: leadersIdSet } = normalize<
    unknown,
    NormalizedAllLeadersInUsersNetworkEntities
  >(leaderNodes, [leaderSchema]);

  const userNetworkLeadersList: UserNetworkLeadersTable = {
    userNetworkLeaders: leadersIdSet,
    cursor: allLeaders.pageInfo.endCursor,
    hasNextPage: allLeaders.pageInfo.hasNextPage,
  };

  const { entities: entitiesForUserCoverImage } = normalize<unknown, NormalizedUserHeaderEntities>(leaderNodes, [
    userCoverImageSchema,
  ]);

  const { entities: entitiesForViewerIsFollowing } = normalize(leaderNodes, [viewerIsFollowingUserSchema]);
  const { entities: entitiesForViewerIsFollowedBy } = normalize(leaderNodes, [viewerIsFollowedByUserSchema]);

  const entities: NormalizedAllLeadersInUsersNetworkEntities = {
    ...entitiesForLeaders,
    ...entitiesForUserCoverImage,
    ...entitiesForViewerIsFollowing,
    ...entitiesForViewerIsFollowedBy,
    userNetworkLeadersList,
  };

  return {
    entities,
    result: undefined,
  };
};
