import React, { ComponentType, useCallback, useState } from 'react';
import { useIdleCallbackEffect } from 'react-timing-hooks';

import { getLoadedModule, loadModule } from '../lazyModuleCache';

import { LoadOnIdleProps } from './LoadOnIdle.types';

interface LazyLoadedComponent<PropsType extends object> {
  readonly Content: ComponentType<PropsType> | null;
}

export default function LoadOnIdle<PropsType extends object>(
  props: LoadOnIdleProps<PropsType>
): React.ReactElement | null {
  const { loader, Placeholder, ...componentProps } = props;

  const loadedModule = getLoadedModule(loader)?.default ?? null;
  const [LazyComponent, setLazyComponent] = useState<LazyLoadedComponent<PropsType>>({ Content: loadedModule });

  const loadContentComponent = useCallback(async () => {
    if (LazyComponent.Content) {
      return;
    }

    const module = await loadModule(loader);
    setLazyComponent({ Content: module.default });
  }, [LazyComponent.Content, loader]);

  useIdleCallbackEffect(
    (onIdle) => {
      onIdle(loadContentComponent);
    },
    [loader]
  );

  const Component = LazyComponent.Content ?? Placeholder;

  return <Component {...(componentProps as unknown as PropsType)} />;
}
