import { noop } from 'lodash';
import React, { FC, createContext, useCallback, useMemo, useState } from 'react';

import { ContentContainerContextValue } from './ContentContainerContextProvider.types';
import { ContentSidebarPosition } from './ContentTemplates';

export const ContentContainerContext = createContext<ContentContainerContextValue>({
  totalMountedSidebars: 0,
  onSidebarMounted: noop,
  onSidebarUnmounted: noop,
  sidebarPosition: 'right',
  contentContainerWidth: Infinity,
  onContentContainerWidthChanged: noop,
  shouldFillAvailableSpace: false,
  shouldDisableScroll: false,
  shouldShowSubHeaderAtAllBreakpoints: false,
  setContentContainerLayoutOptions: noop,
});

const ContentContainerContextProvider: FC = ({ children }) => {
  const [totalMountedSidebars, setTotalMountedSidebars] = useState(0);
  const [contentContainerWidth, setContentContainerWidth] = useState<number>(Infinity);
  const [sidebarPosition, setSidebarPosition] = useState<ContentSidebarPosition>('right');
  const [shouldFillAvailableSpace, setShouldFillAvailableSpace] = useState(false);
  const [shouldDisableScroll, setShouldDisableScroll] = useState(false);
  const [shouldShowSubHeaderAtAllBreakpoints, setShouldShowSubHeaderAtAllBreakpoints] = useState(false);

  const onSidebarMounted = useCallback(
    (position) => {
      setTotalMountedSidebars(totalMountedSidebars + 1);
      setSidebarPosition(position);
    },
    [totalMountedSidebars]
  );

  const onSidebarUnmounted = useCallback(() => {
    const newTotalMountedSidebars = Math.max(0, totalMountedSidebars - 1);
    setTotalMountedSidebars(newTotalMountedSidebars);

    if (newTotalMountedSidebars === 0) {
      setSidebarPosition('right');
    }
  }, [totalMountedSidebars]);

  const onContentContainerWidthChanged = useCallback(
    (newWidth: number) => {
      if (newWidth !== contentContainerWidth) {
        setContentContainerWidth(newWidth);
      }
    },
    [contentContainerWidth]
  );

  const setContentContainerLayoutOptions = useCallback((options) => {
    setShouldFillAvailableSpace(options.shouldFillAvailableSpace);
    setShouldDisableScroll(options.shouldDisableScroll);
    setShouldShowSubHeaderAtAllBreakpoints(options.shouldShowSubHeaderAtAllBreakpoints);
  }, []);

  const value: ContentContainerContextValue = useMemo(
    () => ({
      totalMountedSidebars,
      onSidebarMounted,
      onSidebarUnmounted,
      sidebarPosition,
      contentContainerWidth,
      onContentContainerWidthChanged,
      shouldFillAvailableSpace,
      shouldDisableScroll,
      shouldShowSubHeaderAtAllBreakpoints,
      setContentContainerLayoutOptions,
    }),
    [
      totalMountedSidebars,
      onSidebarMounted,
      onSidebarUnmounted,
      sidebarPosition,
      contentContainerWidth,
      onContentContainerWidthChanged,
      shouldFillAvailableSpace,
      shouldDisableScroll,
      shouldShowSubHeaderAtAllBreakpoints,
      setContentContainerLayoutOptions,
    ]
  );

  return <ContentContainerContext.Provider value={value}>{children}</ContentContainerContext.Provider>;
};

export default ContentContainerContextProvider;
