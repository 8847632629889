import { ReactNode } from 'react';

import { Strategy } from '../types';

const reactStrategy: Strategy<ReactNode> = (placeholders) => (_key, _match, wrapper, text) => {
  const trimmedText = text.trim();

  if (!Object.prototype.hasOwnProperty.call(placeholders, wrapper)) {
    throw new Error(`Wrapper "${wrapper}" was not provided`);
  }

  return placeholders[wrapper](trimmedText);
};

export default reactStrategy;
