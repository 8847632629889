import React, { FC, useRef } from 'react';

import Flex from '../Flex';
import Scrollable from '../Scrollable';
import { VisibilityObserverRootContextProvider } from '../VisibilityObserver';

import { getClassNames } from './AppLayoutRow.styles.legacy';
import { AppLayoutRowProps } from './AppLayoutRow.types';
import { useScrollToTopOnRouteChange } from './hooks';

const AppLayoutRow: FC<AppLayoutRowProps> = ({
  children,
  height,
  grow,
  paddingTop,
  paddingBottom,
  role,
  enableScrolling = false,
  forceScrollable = false,
  shrink,
  zIndex,
  depth,
}) => {
  const scrollableRef = useRef<HTMLElement>(null);
  useScrollToTopOnRouteChange(scrollableRef.current);

  const minHeight = grow ? 0 : undefined;

  const shouldWrapInScrollable = enableScrolling || forceScrollable;

  const content = shouldWrapInScrollable ? (
    <Scrollable width="100%" ref={scrollableRef} disableScrolling={!enableScrolling}>
      <VisibilityObserverRootContextProvider root={scrollableRef.current}>
        {children}
      </VisibilityObserverRootContextProvider>
    </Scrollable>
  ) : (
    children
  );

  const classNames = getClassNames(zIndex, depth);

  return (
    <Flex
      height={height}
      grow={grow}
      minHeight={minHeight}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      role={role}
      shrink={shrink}
      className={classNames.appLayoutRow}
    >
      {content}
    </Flex>
  );
};

export default AppLayoutRow;
