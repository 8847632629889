import { acquireToken } from '@ms/yammer-data/dist/authentication';
import { getIsEUDBFromToken } from '@ms/yammer-web-authenticators/dist/jwt';
import { useEffect, useState } from 'react';

import { useAdalResourceForYammer } from '../session';

type UseIsEUDB = () => boolean | null;
/**
 * Returns true if the user is in the EUDB, false if not.
 * Returns null while asynchronously acquiring the token.
 */
export const useIsEUDB: UseIsEUDB = () => {
  const yammerResource = useAdalResourceForYammer();
  const [isEUDB, setIsEUDB] = useState<boolean | null>(null);

  useEffect(() => {
    (async () => {
      const token = await acquireToken({ scopes: [yammerResource] });
      setIsEUDB(getIsEUDBFromToken(token));
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isEUDB;
};
