import { makeStyles, mergeClasses } from '@griffel/react';

import { ClassNameAndInlineStyles } from '../../util/styles/fluent9';
import { createTokens } from '../../util/theme/fluent9';

const { tokens, cssVariables } = createTokens(['topOffset', 'zIndex'], 'stickyTop');

const useClasses = makeStyles({
  root: {
    position: 'sticky',
    top: tokens.topOffset,
    zIndex: tokens.zIndex,
  },
});

interface StylingProps {
  readonly topOffset: number;
  readonly zIndex: number;
  readonly className?: string;
}

type UseClassNameAndInlineStyles = (props: StylingProps) => ClassNameAndInlineStyles;
export const useClassNameAndInlineStyles: UseClassNameAndInlineStyles = ({ topOffset, zIndex, className }) => {
  const classes = useClasses();

  return {
    className: mergeClasses(classes.root, className),
    inlineStyles: {
      [cssVariables.topOffset]: `${topOffset}px`,
      [cssVariables.zIndex]: zIndex,
    },
  };
};
