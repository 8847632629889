// https://nolanlawson.com/2018/09/25/accurately-measuring-layout-on-the-web/
export type RequestAnimationFrameAfterStyleAndLayout = (callback: (calledAfterStyleAndLayout: boolean) => void) => void;
export const requestAnimationFrameAfterStyleAndLayout: RequestAnimationFrameAfterStyleAndLayout = (callback) => {
  // Check to see if the page is currently visible, as requestAnimationFrame won't be called if not.
  if (window.document && window.document.visibilityState === 'visible') {
    requestAnimationFrame(() => {
      setTimeout(() => {
        callback(true);
      });
    });
  } else {
    callback(false);
  }
};
