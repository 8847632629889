import { useLoadDismissablePrompts, useShouldStartDismissablePrompt } from '../dismissablePrompts';

const promptKey = 'FirstRunExperience';

type UseShouldStartFirstRunExperience = () => boolean;
export const useShouldStartFirstRunExperience: UseShouldStartFirstRunExperience = () => {
  useLoadDismissablePrompts({ promptKeys: [promptKey] });

  return useShouldStartDismissablePrompt(promptKey);
};
