import ErrorBoundary, { ErrorBoundaryName } from '@ms/yammer-libs-error-boundary';
import React, { FC, memo } from 'react';

import PageTelemetryContextProvider from '../PageTelemetryContextProvider';

import AppPageRoutesWithTelemetry from './AppPageRoutesWithTelemetry';
import { AppPageRoutesWithTelemetryProps } from './AppPageRoutesWithTelemetry.types';

const AppPageRoutesWithTelemetryBoundary: FC<AppPageRoutesWithTelemetryProps> = (props) => (
  <ErrorBoundary name={ErrorBoundaryName.AppPageRoutesWithTelemetry}>
    <PageTelemetryContextProvider>
      <AppPageRoutesWithTelemetry {...props} />
    </PageTelemetryContextProvider>
  </ErrorBoundary>
);

export default memo(AppPageRoutesWithTelemetryBoundary);
