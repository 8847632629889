import React, { FC } from 'react';

import { AppLayoutBreakpoint, useContainerWidth } from '../../../../util/responsiveness';
import { FlexItem } from '../../../Flex';
import { mainContentColumnMaxWidth } from '../../ContentMainColumn.types';
import ContentRow from '../../ContentRow';
import { useContentMaxWidth } from '../../hooks';

import { useClassNames } from './FooterRow.styles';

const FooterRow: FC = ({ children }) => {
  const classNames = useClassNames();
  const width = useContainerWidth();
  const contentMaxWidth = useContentMaxWidth();
  const isCompact = width < AppLayoutBreakpoint.Width1032;

  const footerRow = isCompact ? (
    children
  ) : (
    <FlexItem basis="100%" maxWidth={mainContentColumnMaxWidth}>
      {children}
    </FlexItem>
  );

  return (
    <footer className={classNames.footer}>
      <FlexItem grow={1} maxWidth={contentMaxWidth}>
        <ContentRow grow={1}>{footerRow}</ContentRow>
      </FlexItem>
    </footer>
  );
};

export default FooterRow;
