import { useMemo } from 'react';

import { useIsNetworkThemesEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useNetworkThemeFeedPageSettings: UseMainUIFeatureSettings<'networkThemeFeedPage'> = (options) => {
  const networkThemeOverrides = options?.overrides?.networkThemeFeedPage;
  const isNetworkThemesEnabled = useIsNetworkThemesEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: isNetworkThemesEnabled,
      ...networkThemeOverrides,
    }),
    [isNetworkThemesEnabled, networkThemeOverrides]
  );
};
