import { useSelectedOnBehalfOfUserId } from '../analyticsSettings/selectors';
import { useExperimentTreatmentWithoutReporting } from '../experiments';
import { useFeatureAccessSettings } from '../featureAccessSettings';

export const useIsQnAMyInsightsEntryPointVisible = (): boolean => {
  const isVivaAnswersEnabled = useExperimentTreatmentWithoutReporting('AnswersInVivaGA') === true;
  const viewerCanAccessNetworkQuestions = useFeatureAccessSettings()?.viewerCanAccessNetworkQuestions;
  const selectedOnBehalfOfUserId = useSelectedOnBehalfOfUserId();
  const isLeaderSelectedOnBehalfOfViewer = !!selectedOnBehalfOfUserId;

  return isVivaAnswersEnabled && viewerCanAccessNetworkQuestions === true && isLeaderSelectedOnBehalfOfViewer === false;
};
