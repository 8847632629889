import { getFirstMeaningfulPaintContext, setFirstMeaningfulPaintContext } from '@ms/yammer-telemetry-store';
import { PageLoadPerformanceEventProperties, PerformanceEventProperties } from '@ms/yammer-telemetry-support';

import { endAndReportPerformanceMeasure, getPerformanceMeasureEntry } from './performance';

type FirstMeaningfulPaintCallback = (firstMeaningfulPaintDuration: number) => void;
let firstMeaningfulPaintCallback: FirstMeaningfulPaintCallback;

type SetFirstMeaningfulPaintCallback = (callback: FirstMeaningfulPaintCallback) => void;
export const setFirstMeaningfulPaintCallback: SetFirstMeaningfulPaintCallback = (callback) => {
  firstMeaningfulPaintCallback = callback;
};

export interface ReportFirstMeaningfulPaintOptions {
  readonly eventProperties: PerformanceEventProperties;
}

type ReportFirstMeaningfulPaint = (options: ReportFirstMeaningfulPaintOptions) => void;
export const reportFirstMeaningfulPaint: ReportFirstMeaningfulPaint = ({ eventProperties }) => {
  const fmpContext = getFirstMeaningfulPaintContext();
  const { authPageLoadDuration, firstMeaningfulPaintReported } = fmpContext;

  if (firstMeaningfulPaintReported) {
    return;
  }

  const eventName = 'first_meaningful_paint';

  endAndReportPerformanceMeasure({
    eventName,
    measureKey: eventName,
    fromNavigationStart: true,
    durationOffset: authPageLoadDuration,
    eventProperties,
  });

  setFirstMeaningfulPaintContext({ ...fmpContext, firstMeaningfulPaintReported: true });

  if (firstMeaningfulPaintCallback) {
    const { duration } = getPerformanceMeasureEntry(eventName);
    firstMeaningfulPaintCallback(duration);
  }
};

export interface ReportFirstPageLoadOptions {
  readonly eventProperties: PageLoadPerformanceEventProperties;
}

type ReportFirstPageLoad = (options: ReportFirstPageLoadOptions) => void;
export const reportFirstPageLoad: ReportFirstPageLoad = ({ eventProperties }) => {
  const fmpContext = getFirstMeaningfulPaintContext();
  const { authPageLoadDuration, firstPageLoadReported, firstPageLoadAbandoned } = fmpContext;

  if (firstPageLoadReported || firstPageLoadAbandoned) {
    return;
  }

  const eventName = 'first_page_load';

  endAndReportPerformanceMeasure({
    eventName,
    measureKey: eventName,
    fromNavigationStart: true,
    durationOffset: authPageLoadDuration,
    eventProperties: {
      ...eventProperties,
      interrupted: false,
    },
  });

  setFirstMeaningfulPaintContext({ ...fmpContext, firstPageLoadReported: true });
};

export interface CancelFirstPageLoadMeasureOptions {
  readonly eventProperties: PageLoadPerformanceEventProperties;
}

type CancelFirstPageLoadMeasure = (options: CancelFirstPageLoadMeasureOptions) => void;

export const cancelFirstPageLoadMeasure: CancelFirstPageLoadMeasure = ({ eventProperties }) => {
  const eventName = 'first_page_load';
  const fmpContext = getFirstMeaningfulPaintContext();

  if (!fmpContext.firstPageLoadReported && !fmpContext.firstPageLoadAbandoned) {
    endAndReportPerformanceMeasure({
      eventName,
      measureKey: eventName,
      fromNavigationStart: true,
      eventProperties: {
        ...eventProperties,
        interrupted: true,
      },
    });

    setFirstMeaningfulPaintContext({ ...fmpContext, firstPageLoadAbandoned: true });
  }
};

export interface ReportAuthPageLoadOptions {
  readonly eventProperties: PerformanceEventProperties;
}

type ReportAuthPageLoad = (options: ReportAuthPageLoadOptions) => void;
export const reportAuthPageLoad: ReportAuthPageLoad = ({ eventProperties }) => {
  const eventName = 'auth_page_load';

  endAndReportPerformanceMeasure({
    eventName,
    measureKey: eventName,
    fromNavigationStart: true,
    eventProperties,
  });

  const fmpContext = getFirstMeaningfulPaintContext();
  const { duration } = getPerformanceMeasureEntry(eventName);

  setFirstMeaningfulPaintContext({ ...fmpContext, authPageLoadDuration: duration });
};
