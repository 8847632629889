export enum DateTimeFormatType {
  Time,
  NumericDay,
  ShortDay,
  WeekdayAndTime,
  WeekdayAndMonthAndNumericDay,
  MonthDayAndTime,
  MonthDayAndYear,
  MonthDayYearAndTime,
  ShortMonth,
  ShortMonthAndDay,
  ShortMonthDayAndYear,
  ShortMonthAndYear,
  ShortMonthDayAndTime,
  ShortMonthDayYearAndTime,
}

export const dateTimeFormatOptionsMap = {
  [DateTimeFormatType.Time]: {
    hour: 'numeric',
    minute: 'numeric',
  },
  [DateTimeFormatType.NumericDay]: {
    day: 'numeric',
  },
  [DateTimeFormatType.ShortDay]: {
    weekday: 'short',
  },
  [DateTimeFormatType.WeekdayAndTime]: {
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
  },
  [DateTimeFormatType.WeekdayAndMonthAndNumericDay]: {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  },
  [DateTimeFormatType.MonthDayAndTime]: {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  [DateTimeFormatType.MonthDayAndYear]: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  [DateTimeFormatType.MonthDayYearAndTime]: {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  [DateTimeFormatType.ShortMonth]: {
    month: 'short',
  },
  [DateTimeFormatType.ShortMonthAndDay]: {
    month: 'short',
    day: 'numeric',
  },
  [DateTimeFormatType.ShortMonthDayAndYear]: {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
  [DateTimeFormatType.ShortMonthAndYear]: {
    month: 'short',
    year: 'numeric',
  },
  [DateTimeFormatType.ShortMonthDayAndTime]: {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  [DateTimeFormatType.ShortMonthDayYearAndTime]: {
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
} as const;
