import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import { useTheme } from '../../util/theme';
import { useLinkClassNames } from '../../util/useLinkClassNames';

import { InternalLinkProps } from './InternalLink.types';
import { useToPropWithState } from './hooks';

const InternalLink: FC<InternalLinkProps> = ({
  to,
  onClick,
  children,
  ariaLabel,
  title,
  disallowTabFocus,
  ariaCurrent,
  onHover,
  onFocus,
  role,
  focusableRef,
  replace,
  ...restProps
}) => {
  const theme = useTheme();
  const tabIndex = disallowTabFocus ? -1 : undefined;
  const ariaCurrentValue = ariaCurrent ? 'page' : undefined;
  const linkRef = useRef<HTMLAnchorElement>(null);
  const className = useLinkClassNames({ theme, ...restProps });
  const toWithState = useToPropWithState(to);

  const focus = useCallback(() => {
    const link = linkRef.current;

    link?.focus();
  }, [linkRef]);

  useEffect(() => {
    focusableRef?.({ focus });
  }, [focus, focusableRef]);

  return (
    <Link
      to={toWithState}
      tabIndex={tabIndex}
      onClick={onClick}
      aria-label={ariaLabel}
      className={className.link}
      title={title}
      aria-current={ariaCurrentValue}
      onMouseEnter={onHover}
      onFocus={onFocus}
      role={role}
      innerRef={linkRef}
      replace={replace}
    >
      {children}
    </Link>
  );
};

export default InternalLink;
