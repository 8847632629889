import createCachedSelector from 're-reselect';

import {
  AttachmentReference,
  AttachmentType,
  File,
  FileWithType,
  ImageFile,
  ImageFileReference,
  ImageFileWithType,
  MediaLightboxInstance,
  Message,
  PreviewableAttachmentWithType,
  SharePointFileLink,
  SharePointFileLinkWithType,
  WebImage,
  WebImageReference,
  WebImageWithType,
  YammerState,
} from '../../state/types';
import { getFulfilledClientSharePointWebLinkEmbeds } from '../clientSharePointWebLinkEmbeds/selectors';
import { getFulfilledFiles } from '../files/selectors';
import { getFulfilledImageFiles } from '../imageFiles/selectors';
import { getFulfilledSharePointFileLinks } from '../sharePointFileLinks/selectors';
import { getFulfilledWebImages } from '../webImages/selectors';

const attachmentReferenceIsImageFile = (attachment: AttachmentReference): attachment is ImageFileReference =>
  attachment.schema === 'imageFiles';

const attachmentReferenceIsWebImage = (attachment: AttachmentReference): attachment is WebImageReference =>
  attachment.schema === 'webImages';

type GetMediaLightboxInstance = (state: YammerState) => MediaLightboxInstance | undefined;
export const getMediaLightboxInstance: GetMediaLightboxInstance = (state) => state.mediaLightbox.instance;

const imageFileWithType = (item: ImageFile): ImageFileWithType => ({
  item,
  type: AttachmentType.ImageFile,
});

const webImageWithType = (item: WebImage): WebImageWithType => ({
  item,
  type: AttachmentType.WebImage,
});

const fileWithType = (item: File): FileWithType => ({
  item,
  type: AttachmentType.File,
});

const sharePointFileLinkWithType = (item: SharePointFileLink): SharePointFileLinkWithType => ({
  item,
  type: AttachmentType.SharePointFileLink,
});

type GetMediaLightboxCurrentItem = (state: YammerState) => PreviewableAttachmentWithType | undefined;
export const getMediaLightboxCurrentItem: GetMediaLightboxCurrentItem = createCachedSelector(
  getMediaLightboxInstance,
  getFulfilledImageFiles,
  getFulfilledWebImages,
  getFulfilledFiles,
  getFulfilledSharePointFileLinks,
  getFulfilledClientSharePointWebLinkEmbeds,
  (lightbox, imageFiles, webImages, files, sharePointFileLinks, clientSharePointWebLinkEmbeds) => {
    if (!lightbox || !lightbox.currentItem) {
      return undefined;
    }

    const { id } = lightbox.currentItem;

    if (attachmentReferenceIsImageFile(lightbox.currentItem)) {
      return imageFileWithType(imageFiles[id]);
    }

    if (attachmentReferenceIsWebImage(lightbox.currentItem)) {
      return webImageWithType(webImages[id]);
    }

    const hasSharePointEmbedPreviewUrl = clientSharePointWebLinkEmbeds[id]?.embedUrl;

    if (hasSharePointEmbedPreviewUrl) {
      if (sharePointFileLinks[id]?.id) {
        return sharePointFileLinkWithType({
          ...sharePointFileLinks[id],
          fullPageEditorUrl: sharePointFileLinks[id].url,
          embeddablePreviewUrl: clientSharePointWebLinkEmbeds[id].embedUrl,
        });
      }

      return;
    }

    return fileWithType(files[id]);
  }
)(() => 'cache');

type GetMediaLightboxMessageId = (state: YammerState) => Message['id'] | undefined;
export const getMediaLightboxMessageId: GetMediaLightboxMessageId = (state) => {
  const instance = getMediaLightboxInstance(state);

  return instance && instance.messageId;
};
