export type ContentSidebarPosition = 'left' | 'right';

export const contentSidebarRightMaxWidth = 260;
export const contentSidebarLeftMaxWidth = 360;
export const sidebarItemGap = 4;

export interface ContentSidebarColumnProps {
  /**
   * Optional prop to set the content sidebar position to either the left of or right of content.
   * Defaults to 'right'.
   */
  readonly position?: ContentSidebarPosition;
}
