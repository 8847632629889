import { useMemo } from 'react';

import { useIsComplianceDataAdminEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useComplianceDataAdminPageSettings: UseMainUIFeatureSettings<'complianceDataAdminPage'> = (options) => {
  const complianceDataAdminPageSettingsOverrides = options?.overrides?.complianceDataAdminPage;

  const viewerCanManageComplianceData = useIsComplianceDataAdminEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: viewerCanManageComplianceData,
      ...complianceDataAdminPageSettingsOverrides,
    }),
    [complianceDataAdminPageSettingsOverrides, viewerCanManageComplianceData]
  );
};
