import { ReportAnalyticsEvent } from '@ms/yammer-data/dist/telemetry';
import { createContext, useContext } from 'react';

import { mockedReportAnalyticsEvent } from './types';
export interface TelemetryOptions {
  readonly reportAnalyticsEvent: ReportAnalyticsEvent;
}

export const defaultOptions: TelemetryOptions = {
  reportAnalyticsEvent: mockedReportAnalyticsEvent,
};

const TelemetryContext = createContext(defaultOptions);
export const TelemetryContextProvider = TelemetryContext.Provider;

export type UseTelemetryOptions = () => TelemetryOptions;
export const useTelemetryOptions: UseTelemetryOptions = () => useContext(TelemetryContext);
