import { Message, PlainMessage, ProtobufMessageClass, encodeMessage } from '@ms/yammer-protobuf';
import { loadReportOneDSEvent } from '@ms/yammer-telemetry-1ds';
import { getTelemetryClientConfig } from '@ms/yammer-telemetry-store';

import { reportTelemetryEvent } from '../report';
import { loadProtobufRequestFunctions } from '../report/protobuf/lazyExports';
import { AnalyticsV2EventProps } from '../report/protobuf/request/createAnalyticsV2Event';

import { reportInfo } from './info';

interface LoggingOptions {
  readonly eventName: string;
  readonly shouldReportErrors: boolean;
}

interface ReportUsageWithTypeNameOptions extends AnalyticsV2EventProps {
  readonly loggingOptions?: LoggingOptions;
}

export type ReportAnalyticsEvent = <T extends Message<T>>(
  MessageClass: ProtobufMessageClass<T>,
  plainMessage: PlainMessage<T>,
  loggingOptions?: LoggingOptions
) => Promise<void>;
export const reportAnalyticsEvent: ReportAnalyticsEvent = async (MessageClass, plainMessage, loggingOptions) => {
  const { value, typeName } = encodeMessage(MessageClass, plainMessage);

  const { oneDSInstrumentationKey, is1DSTelemetryEnabled } = getTelemetryClientConfig();

  if (oneDSInstrumentationKey && is1DSTelemetryEnabled) {
    const { reportOneDSEvent } = await loadReportOneDSEvent();

    reportOneDSEvent({
      baseType: 'AnalyticsV2',
      name: typeName,
      data: plainMessage,
    });

    return;
  }

  reportUsageV2WithTypeName({ value, typeName, loggingOptions });
};

type ReportUsageV2WithTypeName = (options: ReportUsageWithTypeNameOptions) => void;
const reportUsageV2WithTypeName: ReportUsageV2WithTypeName = async ({ loggingOptions, ...analyticsOptions }) => {
  try {
    const { createAnalyticsV2Event } = await loadProtobufRequestFunctions();

    const analyticsV2Event = createAnalyticsV2Event(analyticsOptions);

    reportTelemetryEvent(analyticsV2Event);
  } catch (err) {
    if (loggingOptions?.shouldReportErrors) {
      reportInfo({
        eventName: 'analytics_v2_event_errored',
        eventProperties: {
          errorMessage: (err as Error).message,
          name: loggingOptions.eventName,
        },
      });
    }
  }
};
