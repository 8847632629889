import React, { FC, useCallback, useEffect, useRef } from 'react';

import { useTheme } from '../../util/theme';
import FakeLink from '../FakeLink';

import { ClickableProps } from './Clickable.types';
import { useClickableClassNames } from './useClickableClassNames';

const Clickable: FC<ClickableProps> = ({
  ariaLabel,
  ariaSelected,
  ariaChecked,
  ariaExpanded,
  role,
  title,
  unstyled,
  disabled,
  disallowTabFocus,
  onClick,
  onFocus,
  onHover,
  children,
  display,
  className,
  color,
  colorOverride,
  activeColor,
  focusOutline,
  focusableRef,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const theme = useTheme();
  const tabIndex = disallowTabFocus ? -1 : undefined;

  const classNames = useClickableClassNames({
    display,
    color,
    colorOverride,
    activeColor,
    disabled,
    theme,
    focusOutline,
    className,
  });

  const focus = useCallback(() => {
    const button = buttonRef.current;
    // istanbul ignore else
    if (button) {
      button.focus();
    }
  }, [buttonRef]);

  useEffect(() => {
    if (focusableRef) {
      focusableRef({ focus });
    }
  }, [focus, focusableRef]);

  const buttonChildContent = unstyled || disabled ? children : <FakeLink>{children}</FakeLink>;

  return (
    // eslint-disable-next-line react/forbid-elements
    <button
      className={classNames.clickable}
      aria-label={ariaLabel}
      aria-selected={ariaSelected}
      aria-checked={ariaChecked}
      aria-expanded={ariaExpanded}
      role={role}
      title={title}
      onClick={onClick}
      onFocus={onFocus}
      onMouseEnter={onHover}
      disabled={disabled}
      type="button"
      ref={buttonRef}
      tabIndex={tabIndex}
    >
      {buttonChildContent}
    </button>
  );
};

/**
 * A `Clickable` is an accessible, clickable area that accepts arbitrary children. It is styled
 * like a link by default, but can also be unstyled. Under the hood `Clickable` simply wraps
 * content in a `button` element.
 */
export default Clickable;
