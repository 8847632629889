import { Theme, createDarkTheme, createLightTheme } from '@fluentui/react-components';
import { createBrandVariants } from '@fluentui/react-migration-v8-v9';

import type { ITheme } from '..';

import { getTypographyValuesInRemUnits } from './typography';

/**
 * Takes Fluent 9 theme and returns a new Fluent 9 theme with typography px values converted to rems.
 */
export const createCoreUIFluentTheme = (theme: Theme): Theme => ({
  ...theme,
  ...getTypographyValuesInRemUnits(theme),
});

/**
 * Takes a Fluent 8 theme and returns a new Fluent 9 theme with typography values converted to rems
 * and brand colors from the given Fluent 8 theme.
 */
export const createCoreUIFluentThemeFromV8 = (theme: ITheme): Theme => {
  const brandVariants = createBrandVariants(theme.palette);
  const baseTheme = theme.isInverted ? createDarkTheme(brandVariants) : createLightTheme(brandVariants);

  return createCoreUIFluentTheme(baseTheme);
};
