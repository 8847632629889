import { useContext } from 'react';

import { ContainerWidthContext } from './ContainerWidthContext';

/**
 * Returns the container width provided by the nearest `<ContainerWidthContext.Provider>` component.
 * All CoreUI AppLayout columns and other layered surfaces (Panel, ModalDialog, etc.) will provide this context.
 *
 * Note that other layered surfaces external to CoreUI, like the SuiteHeader panels, will not have this context
 * available. If this context is necessary then the feature would need to wrap itself in a
 * `<ContainerWidthContext.Provider>` component use `useLayoutContainerQuery` to provide its value.
 */
export const useContainerWidth = () => useContext(ContainerWidthContext);
