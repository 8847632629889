import { RefObject } from 'react';

import {
  AppLayoutBreakpoint,
  ContentHeaderBreakpoint,
  FeatureBreakpoint,
  LegacyPageLayoutBreakpoint,
} from './breakpoints';
import { buildContainerQueryFromBreakpoints } from './buildContainerQueryFromBreakpoints';
import { useMeasureContainerWithResizeObserver } from './useMeasureContainerWithResizeObserver';

const containerBreakpointQuery = buildContainerQueryFromBreakpoints([
  AppLayoutBreakpoint,
  ContentHeaderBreakpoint,
  FeatureBreakpoint,
  LegacyPageLayoutBreakpoint,
]);

type UseMeasureContainerQueryWidth = (elementReference: RefObject<Element | null>) => number | undefined;
/**
 * Measures the available width of the given element reference. Uses ResizeObserver to re-run
 * when the element's width has changed and entered a different breakpoint region.
 * This hook incurs a render performance penalty by reading the DOM, it should only be used
 * when a "column" of content needs to both check the available width and store it as the
 * ContainerWidthContext value for other child components to consume.
 */
export const useMeasureContainerWidth: UseMeasureContainerQueryWidth = (elementReference) =>
  useMeasureContainerWithResizeObserver<string>({
    elementReference,
    query: containerBreakpointQuery,
    includeAvailableWidth: true,
  }).availableWidth;
