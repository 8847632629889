import { CopilotChatRef } from '@1js/officeaicopilot-chat-types';
import { create } from 'zustand';

export type GlobalCopilotInstance = CopilotChatRef | null;

interface GlobalCopilotInstanceStore {
  readonly instancePromise: Promise<GlobalCopilotInstance>;
  updateInstance(instance: GlobalCopilotInstance): void;
  resetInstancePromise(): void;
}

export const useGlobalCopilotInstanceStore = create<GlobalCopilotInstanceStore>((set) => {
  let updateInstance: (instance: GlobalCopilotInstance) => void;

  const createInstancePromise = () =>
    new Promise<GlobalCopilotInstance>((resolve) => {
      updateInstance = resolve;
    });

  return {
    resetInstancePromise: () => set({ instancePromise: createInstancePromise() }),
    instancePromise: createInstancePromise(),
    updateInstance: (instance) => updateInstance(instance),
  };
});
