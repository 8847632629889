import { ManagedApiLifecycle, ManagedApiRequest, managedApiAction } from '../..';
import { NormalizedPushSubscription } from '../../state/types';
import { reportError } from '../../telemetry';
import { PromiseThunkAction } from '../../types';
import { getSettings as getPushNotificationsSettings } from '../pushNotifications/selectors';
import { getAdalResourceForSubstrateNotifications, getSubstrateConfig, getSubstrateHost } from '../session/selectors';

import {
  PushSubscriptionsRegisterFulfilledAction,
  PushSubscriptionsRegisterPendingAction,
  PushSubscriptionsRegisterRejectedAction,
  PushSubscriptionsRegisterResponse,
} from './registerAction.types';
import { getSha256 } from './selectors';

const registerPending = (): PushSubscriptionsRegisterPendingAction => ({
  type: 'PUSH_SUBSCRIPTIONS.REGISTER_PENDING',
});

const registerFulfilled = (payload: NormalizedPushSubscription): PushSubscriptionsRegisterFulfilledAction => ({
  type: 'PUSH_SUBSCRIPTIONS.REGISTER_FULFILLED',
  payload,
});

const registerRejected = (error: unknown): PushSubscriptionsRegisterRejectedAction => ({
  type: 'PUSH_SUBSCRIPTIONS.REGISTER_REJECTED',
  payload: {
    error,
  },
  error: true,
});

interface GetRegisterRequestOptions {
  readonly host: string;
  readonly path: string;
  readonly body: PushSubscriptionJSON & {
    readonly clientId: string;
    readonly applicationServerKeyHash: string;
  };
  readonly resource: string;
  readonly scopes: string[];
}

type GetRegisterRequest = (options: GetRegisterRequestOptions) => ManagedApiRequest;
const getRegisterRequest: GetRegisterRequest = ({ host, path, body, resource, scopes }) => ({
  apiName: 'PushSubscriptionsRegister',
  requestType: 'Substrate',
  method: 'POST',
  host,
  path,
  body,
  resource,
  scopes,
});

const getRegisterLifecycle = (
  payload: NormalizedPushSubscription
): ManagedApiLifecycle<PushSubscriptionsRegisterResponse> => ({
  pending: () => registerPending(),
  fulfilled: (response) =>
    response.registered ? registerFulfilled(payload) : registerRejected('Could not register subscription'),
  rejected: (error) => registerRejected(error),
});

type PushSubscriptionsRegister = (
  subscription: PushSubscription,
  applicationServerKey: string
) => PromiseThunkAction<void>;
export const register: PushSubscriptionsRegister =
  (subscription, applicationServerKey) => async (dispatch, getState) => {
    const state = getState();
    const host = getSubstrateHost(state);
    const { pushSubscriptionsPath: path } = getSubstrateConfig(state);
    const { clientId } = getPushNotificationsSettings(state);
    const applicationServerKeyHash = getSha256(applicationServerKey);
    const body = { ...subscription.toJSON(), clientId, applicationServerKeyHash };
    const resource = getAdalResourceForSubstrateNotifications(state);
    const scopes = [resource];

    const request = getRegisterRequest({ host, path, body, resource, scopes });
    const lifecycle = getRegisterLifecycle({ endpoint: body.endpoint, clientId, applicationServerKeyHash });
    const action = managedApiAction({ request, lifecycle });

    try {
      await dispatch(action);
    } catch (error) {
      reportError({ error });
    }
  };
