import { TextProps } from '@fluentui/react-components';
import { makeStyles } from '@griffel/react';

import { tokens } from '../../theme/fluent9';

export type NumericFontSize = Exclude<TextProps['size'], undefined>;

interface FontStyles {
  readonly fontSize: string;
  readonly lineHeight: string;
}
const fontSizeMap: Record<NumericFontSize, FontStyles> = {
  100: { fontSize: tokens.fontSizeBase100, lineHeight: tokens.lineHeightBase100 },
  200: { fontSize: tokens.fontSizeBase200, lineHeight: tokens.lineHeightBase200 },
  300: { fontSize: tokens.fontSizeBase300, lineHeight: tokens.lineHeightBase300 },
  400: { fontSize: tokens.fontSizeBase400, lineHeight: tokens.lineHeightBase400 },
  500: { fontSize: tokens.fontSizeBase500, lineHeight: tokens.lineHeightBase500 },
  600: { fontSize: tokens.fontSizeBase600, lineHeight: tokens.lineHeightBase600 },
  700: { fontSize: tokens.fontSizeHero700, lineHeight: tokens.lineHeightHero700 },
  800: { fontSize: tokens.fontSizeHero800, lineHeight: tokens.lineHeightHero800 },
  900: { fontSize: tokens.fontSizeHero900, lineHeight: tokens.lineHeightHero900 },
  1000: { fontSize: tokens.fontSizeHero1000, lineHeight: tokens.lineHeightHero1000 },
};

export const numericFontSizes: NumericFontSize[] = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];

export const getFluentFontSizeTokenFromNumericFontSize = (size: NumericFontSize) => fontSizeMap[size].fontSize;
export const getFluentLineHeightTokenFromNumericFontSize = (size: NumericFontSize) => fontSizeMap[size].lineHeight;

const useFontSizeClassNames = makeStyles<NumericFontSize>(fontSizeMap);

/**
 * Returns a className with fontSize and lineHeight styles for the given fontSize.
 */
export const useFontSizeClassName = (size?: NumericFontSize) => {
  const fontSizeClassNames = useFontSizeClassNames();

  return size ? fontSizeClassNames[size] : undefined;
};
