import { useRenderToggleableContent } from '@ms/yammer-web-core-ui/dist/util/useRenderToggleableContent';
import { noop } from 'lodash';
import React, { FC, PropsWithChildren, createContext, useContext } from 'react';

const CampaignCreateModalContext = createContext<() => void>(noop);
export const useShowCampaignCreateModal = () => useContext<() => void>(CampaignCreateModalContext);

export interface CreateCampaignProviderProps {
  readonly campaignCreateModal: ReturnType<typeof useRenderToggleableContent>;
}

export const CreateCampaignProvider: FC<PropsWithChildren<CreateCampaignProviderProps>> = ({
  campaignCreateModal,
  children,
}) => {
  const { showContent, content } = campaignCreateModal;

  return (
    <>
      <CampaignCreateModalContext.Provider value={showContent}>{children}</CampaignCreateModalContext.Provider>
      {content}
    </>
  );
};
