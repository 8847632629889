import { useFeatureAccessSettings } from '../featureAccessSettings';
import { useHasUserNetworkLeaders } from '../userNetworkLeaders';
import { useLoadAllLeaders, useUserNetworkLeadersListStatus } from '../userNetworkLeadersList';

import { useCanUserAccessLeadershipCornerWithoutLeaders } from './useCanUserAccessLeadershipCornerWithoutLeaders';

type UseCanUserAccessLeadershipCorner = () => boolean;
export const useCanUserAccessLeadershipCorner: UseCanUserAccessLeadershipCorner = () => {
  useLoadAllLeaders({ leadersCount: 1 });

  const featureAccessSettings = useFeatureAccessSettings();

  const hasUserNetworkLeaders = useHasUserNetworkLeaders();
  const canUserAccessLeadershipCornerWithoutLeaders = useCanUserAccessLeadershipCornerWithoutLeaders();
  const isUserNetworkLeadersListFulfilled = useUserNetworkLeadersListStatus() === 'fulfilled';

  if (isUserNetworkLeadersListFulfilled && !hasUserNetworkLeaders) {
    return canUserAccessLeadershipCornerWithoutLeaders;
  }

  return !!featureAccessSettings?.viewerCanAccessLeadershipCorner;
};
