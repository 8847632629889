import {
  getPushSubscriptionServerKey,
  isApplicationServerKeyLoaded,
} from '@ms/yammer-data/dist/domains/pushSubscriptionServerKey/selectors';

import { useSelector } from '../useSelector';

type IsApplicationServerKeyLoaded = () => ReturnType<typeof isApplicationServerKeyLoaded>;
export const useIsApplicationServerKeyLoaded: IsApplicationServerKeyLoaded = () =>
  useSelector((state) => isApplicationServerKeyLoaded(state));

type GetPushSubscriptionServerKey = () => ReturnType<typeof getPushSubscriptionServerKey>;
export const useGetPushSubscriptionServerKey: GetPushSubscriptionServerKey = () =>
  useSelector((state) => getPushSubscriptionServerKey(state));
