import React, { FC, useMemo } from 'react';

import { AppContainerFUI9ThemePrivateContext, AppContainerFUI9ThemePrivateContextProviderProps } from './context';

export type { AppContainerFUI9ThemePrivateContextProviderProps };

const AppContainerFUI9ThemePrivateContextProvider: FC<AppContainerFUI9ThemePrivateContextProviderProps> = ({
  theme,
  dir,
  children,
}) => {
  const props = useMemo(() => ({ theme, dir }), [theme, dir]);

  return (
    <AppContainerFUI9ThemePrivateContext.Provider value={props}>
      {children}
    </AppContainerFUI9ThemePrivateContext.Provider>
  );
};

export default AppContainerFUI9ThemePrivateContextProvider;
