import { useLayoutEffect, useState } from 'react';

interface ElementSize {
  readonly height: number;
  readonly width: number;
}
type UseElementSize = (element: HTMLElement | null) => ElementSize;

/**
 * Hook to return the dimensions of a DOM element.
 *
 * Please note this is very likely to introduce performance issues!
 * Please DO NOT USE outside Core UI.
 */
const useElementSize: UseElementSize = (element) => {
  const [size, setSize] = useState<ElementSize>({ height: 0, width: 0 });

  useLayoutEffect(() => {
    if (!element) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const newHeight = entry.contentRect.height;
        const newWidth = entry.contentRect.width;
        if (size.height !== newHeight || size.width !== newWidth) {
          setSize({ height: newHeight, width: newWidth });
        }
      });
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  });

  return size;
};

export default useElementSize;
