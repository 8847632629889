import { useContext } from 'react';

import { AppLayoutContext } from '../AppLayoutContextProvider';
import { AppLayoutContextValue } from '../AppLayoutContextProvider.types';

export const useAppLayoutContext = () => useContext(AppLayoutContext);

type UseAppLayoutWidth = () => number;
export const useAppLayoutWidth: UseAppLayoutWidth = () => useAppLayoutContext()?.width || Infinity;

type UseAppLayoutGutterSize = () => number;
export const useAppLayoutGutterSize: UseAppLayoutGutterSize = () =>
  (useAppLayoutContext() as AppLayoutContextValue)?.gutterSize || 0;
