import { useIsAskMeAnythingEnabled } from '@ms/yammer-hooks/dist/askMeAnything';
import { useExperimentTreatmentWithoutReporting } from '@ms/yammer-hooks/dist/experiments';

import { UseUIFeatureCheck } from '../types';

export { useIsAskMeAnythingEnabled, useIsAskMeAnythingEnabled as useAreAMAEventsEnabled };

export const useIsCalendarInvitationEnabled: UseUIFeatureCheck = () => {
  const isAMAEnabled = useIsAskMeAnythingEnabled();
  const amaCalendarInvitationExperiment = useExperimentTreatmentWithoutReporting(
    'VivaEngageAmaCalendarInvitationToggle'
  );

  return isAMAEnabled && amaCalendarInvitationExperiment === true;
};

export const useIsOnlineMeetingEnabled: UseUIFeatureCheck = () => {
  const isAMAEnabled = useIsAskMeAnythingEnabled();
  const amaOnlineMeetingExperiment = useExperimentTreatmentWithoutReporting('TeamsQnAVivaEngageAmaIntegration');

  return isAMAEnabled && amaOnlineMeetingExperiment === true;
};

export const useIsMtoTeamsMeetingEnabled: UseUIFeatureCheck = () => {
  const isAMAEnabled = useIsAskMeAnythingEnabled();
  const mtoTeamsMeetingExperiment = useExperimentTreatmentWithoutReporting('MTOTeamsMeeting');

  return isAMAEnabled && mtoTeamsMeetingExperiment === true;
};

export const useIsTownhallEventEnabled: UseUIFeatureCheck = () => {
  const townhallEventExperiment = useExperimentTreatmentWithoutReporting('TeamsTownhallEventInVivaEngage');

  return townhallEventExperiment === true;
};
