import { FeatureRollout } from '@ms/yammer-data/dist/state/ecsFeatureRollouts/types';

import { useIsFeatureRolloutEnabled } from '../featureRollouts';
import { useCurrentUserFieldThrows } from '../users';

type UseCanCurrentUserAccessEnhancedUserProfile = () => boolean;
export const useCanCurrentUserAccessEnhancedUserProfile: UseCanCurrentUserAccessEnhancedUserProfile = () => {
  const isEnhancedUserProfileEnabled = useIsFeatureRolloutEnabled(FeatureRollout.EnhancedUserProfiles);
  const isCurrentUserGuest = useCurrentUserFieldThrows('isGuest');

  return isEnhancedUserProfileEnabled && !isCurrentUserGuest;
};
