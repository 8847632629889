import { useMemo } from 'react';

import { useIsPremiumCommunityInsightsV2Enabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useInsightsPremiumGroupPageSettings: UseMainUIFeatureSettings<'insightsPremiumGroupPage'> = (options) => {
  const insightsPremiumGroupPageOverrides = options?.overrides?.insightsPremiumGroupPage;

  const isEnabled = useIsPremiumCommunityInsightsV2Enabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: isEnabled,
      ...insightsPremiumGroupPageOverrides,
    }),
    [isEnabled, insightsPremiumGroupPageOverrides]
  );
};
