import { useMemo } from 'react';

import { useIsAchievementsAdminEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useAchievementsAdminPageSettings: UseMainUIFeatureSettings<'achievementsAdminPage'> = (options) => {
  const achievementsAdminPageOverrides = options?.overrides?.achievementsAdminPage;

  const isAchievementsAdminEnabled = useIsAchievementsAdminEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: isAchievementsAdminEnabled,
      ...achievementsAdminPageOverrides,
    }),
    [achievementsAdminPageOverrides, isAchievementsAdminEnabled]
  );
};
