import { schema } from 'normalizr';

import { processGroup } from '../strategies/group';

import { groupLinksSchema } from './groupLink';

export const groupSchema = new schema.Entity(
  'groups',
  {
    groupLinkId: groupLinksSchema,
  },
  { processStrategy: processGroup }
);
