import { combinePath } from '@ms/yammer-web-support/dist/location';
import { matchPath } from 'react-router-dom';

import { AppRoutes, PageRoute, ParentPageRoute } from '../AppPageRoutesWithTelemetry/AppPageRoutesWithTelemetry.types';
import { isParentRoute } from '../AppPageRoutesWithTelemetry/isParentRoute';

type PreloadPageAndSubpageAssets = (path: string | undefined, routes: AppRoutes) => void;

const hasMatch = (path: string, route: PageRoute | ParentPageRoute) =>
  !!matchPath(path, { path: route.path, exact: (route as PageRoute).exact });

export const preloadPageAndSubpageAssets: PreloadPageAndSubpageAssets = (path, routes) => {
  if (!path) {
    return;
  }

  routes.some((route) => {
    if (hasMatch(path, route)) {
      route.loader();

      if (isParentRoute(route)) {
        route.childRoutes.some((childRoute) => {
          if (hasMatch(path, { ...childRoute, path: combinePath(route.path, childRoute.path) })) {
            childRoute.loader();

            return true;
          }
        });
      }

      return true;
    }
  });
};
