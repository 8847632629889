import { isObjectLike } from 'lodash';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { InternalLinkLocationState } from '../InternalLink.types';

type UseCreateInternalLinkLocationState = () => InternalLinkLocationState;
export const useCreateInternalLinkLocationState: UseCreateInternalLinkLocationState = () => {
  const { pathname: prevPathname } = useLocation();

  return useMemo(() => ({ prevPathname }), [prevPathname]);
};

const isInternalLinkLocationState = (value: unknown): value is InternalLinkLocationState =>
  isObjectLike(value) && typeof (value as InternalLinkLocationState).prevPathname === 'string';

type UseCurrentInternalLinkLocationState = () => InternalLinkLocationState | undefined;
export const useCurrentInternalLinkLocationState: UseCurrentInternalLinkLocationState = () => {
  const { state } = useLocation();

  return useMemo(() => (isInternalLinkLocationState(state) ? state : undefined), [state]);
};
