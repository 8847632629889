import { WebClientPreference } from '@ms/yammer-graphql';
import { useShouldStartFirstRunExperience } from '@ms/yammer-hooks/dist/firstRunNewUser';
import { useCurrentSessionUser } from '@ms/yammer-hooks/dist/session';
import { useIsSuggestedActionsLightboxEnabled } from '@ms/yammer-hooks/dist/suggestedActions';
import CurrentPromptCanShow from '@ms/yammer-web-components/dist/dismissablePromptView/CurrentPromptCanShow';
import React, { FC } from 'react';

import FirstRunExperienceDialog from './LoadableFirstRunExperienceDialog';
import SuggestedActionsLightboxDialog from './SuggestedActionsLightboxDialog';

const FirstRunExperience: FC = () => {
  const { userPreferences } = useCurrentSessionUser();
  const shouldShowFirstRunModal = useShouldStartFirstRunExperience();
  const shouldShowSuggestedActions = useIsSuggestedActionsLightboxEnabled();

  if (userPreferences.webClientPreference !== WebClientPreference.MODERN) {
    return null;
  }

  if (shouldShowFirstRunModal) {
    return (
      <CurrentPromptCanShow promptKey="FirstRunExperience">
        <FirstRunExperienceDialog />
      </CurrentPromptCanShow>
    );
  }

  return shouldShowSuggestedActions ? <SuggestedActionsLightboxDialog /> : null;
};

export default FirstRunExperience;
