import { useMemo } from 'react';

import { useIsInsightsQnAOrgEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useInsightsQnAOrgPageSettings: UseMainUIFeatureSettings<'insightsQnAOrgPage'> = (options) => {
  const insightsQnAOrgPageOverrides = options?.overrides?.insightsQnAOrgPage;

  const isInsightsQnAOrgEnabled = useIsInsightsQnAOrgEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: isInsightsQnAOrgEnabled,
      ...insightsQnAOrgPageOverrides,
    }),
    [isInsightsQnAOrgEnabled, insightsQnAOrgPageOverrides]
  );
};
