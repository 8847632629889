import { makeStyles } from '@griffel/react';

import { tokens } from '../../../../util/theme/fluent9';

export const useClassNames = makeStyles({
  footer: {
    width: '100%',
    backgroundColor: tokens.colorNeutralForegroundInverted,
    boxShadow: '0px 4.8px 14.4px rgba(0, 0, 0, 0.18)',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1,
  },
});
