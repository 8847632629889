const highSurrogateStart = '\uD800';
const highSurrogateEnd = '\uDBFF';

const isHighSurrogate = (c: string) => c >= highSurrogateStart && c <= highSurrogateEnd;

const defaultTargetLength = 100;
const defaultMaxLength = 150;
const defaultEllipsis = '...';

interface TruncateForScreenreaderArgs {
  readonly text: string;
  readonly targetLength?: number;
  readonly maxLength?: number;
  readonly ellipsis?: string;
}

// Truncate a large block of text to a smaller chunk for reading by screenreaders. This is typically paired with
// cases where we're letting the browser truncate text visually: if we don't set an explicit aria-label to some
// text we truncate ourselves, sighted users see nicely truncated text, but screenreader users will have the *entire*
// underlying text read back to them.
//
// Truncation can be complex due to internationalization issues: we attempt to find a natural breaking position
// in the target and max range. Even languages that don't use spacing between words to tend to use spacing between
// phrases, so this strategy should work even in those cases, provided we have enough length to work with.
//
// As a last resort, we hard truncate: in this case, we only take unicode surrogate pairs into account and avoid
// splitting them, as that would result in an invalid string. But we don't take into account things like combining
// characters or any of the many emoji (and other) multi-code-point sequences.

type TruncateForScreenreader = (args: TruncateForScreenreaderArgs) => string;
export const truncateForScreenreader: TruncateForScreenreader = ({
  text,
  targetLength = defaultTargetLength,
  maxLength = defaultMaxLength,
  ellipsis = defaultEllipsis,
}) => {
  if (text.length <= targetLength) {
    return text;
  }

  const remainder = text.substr(targetLength);
  const maxRemainderLength = maxLength - targetLength;

  const indexOfSpaceInRemainder = remainder.search(/\s/);
  const indexOfSpaceOrEndInRemainder = indexOfSpaceInRemainder === -1 ? remainder.length : indexOfSpaceInRemainder;

  if (indexOfSpaceOrEndInRemainder < maxRemainderLength) {
    const usableLength = targetLength + indexOfSpaceOrEndInRemainder;

    if (usableLength === text.length) {
      return text;
    }

    return `${text.substr(0, usableLength)}${ellipsis}`;
  }

  const lastCharacter = text[maxLength - 1];
  if (!isHighSurrogate(lastCharacter)) {
    return `${text.substr(0, maxLength)}${ellipsis}`;
  }

  const safeIndex = maxLength + 1;
  if (safeIndex === text.length) {
    return text;
  }

  return `${text.substr(0, safeIndex)}${ellipsis}`;
};
