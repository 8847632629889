import { useAndReportExperimentTreatment } from '../experiments';

import { useViewerCanAccessAnalyticsAdminSettings } from './selectors';

type UseShowAnalyticsSettings = () => boolean;
export const useShowAnalyticsSettings: UseShowAnalyticsSettings = () => {
  const isViewerCanAccessAnalyticsAdminSettings = useViewerCanAccessAnalyticsAdminSettings();
  const isVivaEngagePremium = useAndReportExperimentTreatment('VivaEngagePremium') === true;

  return isVivaEngagePremium && isViewerCanAccessAnalyticsAdminSettings;
};
