import { useMemo } from 'react';

import { useIsMTOPolicyAdminEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useMTOPolicyAdminPageSettings: UseMainUIFeatureSettings<'mtoPolicyAdminPage'> = (options) => {
  const mtoPolicyAdminPageSettingsOverrides = options?.overrides?.mtoPolicyAdminPage;

  const viewerCanManageMTOPolicy = useIsMTOPolicyAdminEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: viewerCanManageMTOPolicy,
      ...mtoPolicyAdminPageSettingsOverrides,
    }),
    [mtoPolicyAdminPageSettingsOverrides, viewerCanManageMTOPolicy]
  );
};
