import { RendererProvider } from '@fluentui/react-components';
import React, { FC } from 'react';

import { useNoopGriffelRenderer } from './hooks';

const GriffelNoopRendererProvider: FC = ({ children }) => {
  const noopRendererToPreventRuntimeStyleInjection = useNoopGriffelRenderer();
  const isProduction = process.env.NODE_ENV === 'production';

  if (!isProduction) {
    return <>{children}</>;
  }

  return <RendererProvider renderer={noopRendererToPreventRuntimeStyleInjection}>{children}</RendererProvider>;
};

export default GriffelNoopRendererProvider;
