import { useMemo } from 'react';

import { useIsViewOnlyModeAdminEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useViewOnlyModeAdminPageSettings: UseMainUIFeatureSettings<'viewOnlyModeAdminPage'> = (options) => {
  const viewOnlyModeAdminPageSettingsOverrides = options?.overrides?.viewOnlyModeAdminPage;

  const viewerCanManageRestrictedUsers = useIsViewOnlyModeAdminEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: viewerCanManageRestrictedUsers,
      ...viewOnlyModeAdminPageSettingsOverrides,
    }),
    [viewOnlyModeAdminPageSettingsOverrides, viewerCanManageRestrictedUsers]
  );
};
