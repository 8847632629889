import { makeStyles, shorthands } from '@griffel/react';

import { BorderRadius } from '../borders';

const useBorderRadiusClassNames = makeStyles<BorderRadius>({
  [BorderRadius.Square]: shorthands.borderRadius(BorderRadius.Square),
  [BorderRadius.Soft]: shorthands.borderRadius(BorderRadius.Soft),
  [BorderRadius.Round]: shorthands.borderRadius(BorderRadius.Round),
  [BorderRadius.VeryRound]: shorthands.borderRadius(BorderRadius.VeryRound),
  [BorderRadius.ExtremelyRound]: shorthands.borderRadius(BorderRadius.ExtremelyRound),
  [BorderRadius.Circle]: shorthands.borderRadius(BorderRadius.Circle),
});

export const useBorderRadiusClassName = (radius?: BorderRadius) => {
  const classNames = useBorderRadiusClassNames();

  return radius ? classNames[radius] : undefined;
};
