import { ManagedApiLifecycle, ManagedApiRequest, managedApiAction } from '../..';
import { NormalizedPushSubscription } from '../../state/types';
import { reportError } from '../../telemetry';
import { PromiseThunkAction } from '../../types';
import { getSettings as getPushNotificationsSettings } from '../pushNotifications/selectors';
import { getAdalResourceForSubstrateNotifications, getSubstrateConfig, getSubstrateHost } from '../session/selectors';

import {
  PushSubscriptionsUnregisterFulfilledAction,
  PushSubscriptionsUnregisterPendingAction,
  PushSubscriptionsUnregisterRejectedAction,
  PushSubscriptionsUnregisterResponse,
} from './unregisterAction.types';

const unregisterPending = (): PushSubscriptionsUnregisterPendingAction => ({
  type: 'PUSH_SUBSCRIPTIONS.UNREGISTER_PENDING',
});

const unregisterFulfilled = (payload: NormalizedPushSubscription): PushSubscriptionsUnregisterFulfilledAction => ({
  type: 'PUSH_SUBSCRIPTIONS.UNREGISTER_FULFILLED',
  payload,
});

const unregisterRejected = (error: unknown): PushSubscriptionsUnregisterRejectedAction => ({
  type: 'PUSH_SUBSCRIPTIONS.UNREGISTER_REJECTED',
  payload: {
    error,
  },
  error: true,
});

interface GetUnregisterRequestOptions {
  readonly host: string;
  readonly path: string;
  readonly body: {
    readonly endpoint: string;
    readonly clientId: string;
  };
  readonly resource: string;
  readonly scopes: string[];
}

type GetUnregisterRequest = (options: GetUnregisterRequestOptions) => ManagedApiRequest;
const getUnregisterRequest: GetUnregisterRequest = ({ host, path, body, resource, scopes }) => ({
  apiName: 'PushSubscriptionsUnregister',
  requestType: 'Substrate',
  method: 'POST',
  host,
  path,
  body,
  resource,
  scopes,
});

const getUnregisterLifecycle = (
  body: NormalizedPushSubscription
): ManagedApiLifecycle<PushSubscriptionsUnregisterResponse> => ({
  pending: () => unregisterPending(),
  fulfilled: (response) =>
    response.unregistered ? unregisterFulfilled(body) : unregisterRejected('Could not unregister subscription'),
  rejected: (error) => unregisterRejected(error),
});

type PushSubscriptionsUnregister = (subscription: PushSubscription) => PromiseThunkAction<void>;
export const unregister: PushSubscriptionsUnregister = (subscription) => async (dispatch, getState) => {
  const state = getState();
  const host = getSubstrateHost(state);
  const { pushSubscriptionsPath: path } = getSubstrateConfig(state);
  const { clientId } = getPushNotificationsSettings(state);
  const body = { endpoint: subscription.endpoint, clientId };
  const resource = getAdalResourceForSubstrateNotifications(state);
  const scopes = [resource];

  const request = getUnregisterRequest({ host, path: `${path}/Delete`, body, resource, scopes });
  const lifecycle = getUnregisterLifecycle(body);
  const action = managedApiAction({ request, lifecycle });

  try {
    await dispatch(action);
  } catch (error) {
    reportError({ error });
  }
};
