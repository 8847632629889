import { useMemo } from 'react';

import { ITheme } from '../../../util/theme';
import { Theme, createCoreUIFluentThemeFromV8 } from '../../../util/theme/fluent9';

export const useFluent9Theme = (theme: ITheme, fluent9Theme?: Theme): Theme =>
  useMemo(() => {
    if (fluent9Theme) {
      return fluent9Theme;
    }

    return createCoreUIFluentThemeFromV8(theme);
  }, [fluent9Theme, theme]);
