import { RefObject, useLayoutEffect, useState } from 'react';

import { SizeQueriesMap } from './useMeasureContainerWithResizeObserver.types';

const getMatchingSize = <T extends string>(query: SizeQueriesMap<T>, entry: ResizeObserverEntry): T | undefined => {
  for (const key of Object.keys(query)) {
    const { width, height } = entry.contentRect;
    const { maxHeight = Infinity, maxWidth = Infinity, minHeight = 0, minWidth = 0 } = query[key as T];

    if (height <= maxHeight && height >= minHeight && width <= maxWidth && width >= minWidth) {
      return key as T;
    }
  }
};

interface UseMeasureContainerWithResizeObserverOptions<T extends string> {
  readonly elementReference: RefObject<Element | null>;
  readonly query: SizeQueriesMap<T>;
  readonly includeAvailableWidth?: boolean;
}

/**
 * `useMeasureContainerWithResizeObserver` uses the `ResizeObserver` API to allow conditional child rendering based on the component's
 * container height or width.
 */
// eslint-disable-next-line func-style
const useMeasureContainerWithResizeObserver = <T extends string>({
  elementReference,
  query,
  includeAvailableWidth = false,
}: UseMeasureContainerWithResizeObserverOptions<T>) => {
  const [size, setSize] = useState<T | undefined>();
  const [availableWidth, setAvailableWidth] = useState<number | undefined>();

  useLayoutEffect(() => {
    if (elementReference.current) {
      const resizeObserver = new ResizeObserver(([entry]) => {
        setSize(getMatchingSize(query, entry));

        if (includeAvailableWidth) {
          setAvailableWidth(entry.contentRect.width);
        }
      });

      resizeObserver.observe(elementReference.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [elementReference, query, includeAvailableWidth]);

  return {
    size,
    availableWidth,
  };
};

export { useMeasureContainerWithResizeObserver };
