import { createCSSVariable, cssVar } from '../../styling';

import { CoreUICSSVariablesObject, CoreUITokensObject } from './tokens.types';

interface CreateTokensReturnValue<TokenName extends string, LocalNamespace extends string> {
  readonly tokens: CoreUITokensObject<LocalNamespace, TokenName>;
  readonly cssVariables: CoreUICSSVariablesObject<LocalNamespace, TokenName>;
}
type CreateTokens = <TokenName extends string, LocalNamespace extends string>(
  keys: TokenName[],
  localNamespace: LocalNamespace
) => CreateTokensReturnValue<TokenName, LocalNamespace>;

export const createTokens: CreateTokens = <TokenName extends string, LocalNamespace extends string>(
  keys: TokenName[],
  localNamespace: LocalNamespace
) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const tokensObject = { tokens: {}, cssVariables: {} } as CreateTokensReturnValue<TokenName, LocalNamespace>;
  keys.forEach((key) => {
    const variable = createCSSVariable(key, localNamespace);
    tokensObject.cssVariables[key] = variable;
    tokensObject.tokens[key] = cssVar(variable);
  });

  return tokensObject;
};
