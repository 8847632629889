import { RefObject, createContext, useContext } from 'react';

enum ArticleScrollRef {
  scrollDestinationRef = 'scrollDestinationRef',
}

type ArticleScrollRefs = Record<ArticleScrollRef, RefObject<HTMLDivElement> | undefined>;

const defaultOptions: ArticleScrollRefs = {
  scrollDestinationRef: undefined,
};

export const ArticleScrollContext = createContext(defaultOptions);

export const useArticleScrollContext = () => useContext(ArticleScrollContext);
