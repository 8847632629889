import {
  getPersistedSuiteHeaderThemeIsInverted,
  getSuiteHeaderTheme,
} from '@ms/yammer-data/dist/domains/suiteHeaderTheme/selectors';

import { useSelector } from '../useSelector';

type UseSuiteHeaderTheme = () => ReturnType<typeof getSuiteHeaderTheme>;
export const useSuiteHeaderTheme: UseSuiteHeaderTheme = () => useSelector(getSuiteHeaderTheme);

type UsePersistedSuiteHeaderIsInverted = () => ReturnType<typeof getPersistedSuiteHeaderThemeIsInverted>;
export const usePersistedSuiteHeaderIsInverted: UsePersistedSuiteHeaderIsInverted = () =>
  useSelector(getPersistedSuiteHeaderThemeIsInverted);

type UseIsDefaultTheme = () => boolean;
export const useIsDefaultTheme: UseIsDefaultTheme = () => useSuiteHeaderTheme().isDefault;
