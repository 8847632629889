import { getSettings } from '@ms/yammer-data/dist/domains/pushNotifications/selectors';
import { FeatureRollout } from '@ms/yammer-data/dist/state/types';
import { doesBrowserSupportPushNotifications } from '@ms/yammer-web-support/dist/pushNotifications';

import { useIsFeatureRolloutEnabled } from '../featureRollouts';
import { useIsCurrentNetworkPushNotificationsEnabled } from '../session';
import { useSelector } from '../useSelector';

type UseSettings = () => ReturnType<typeof getSettings>;
export const useSettings: UseSettings = () => useSelector(getSettings);

export const useIsSupported = (): boolean => {
  const isFeatureEnabled = useIsFeatureRolloutEnabled(FeatureRollout.PushNotifications);
  const isNetworkSettingEnabled = useIsCurrentNetworkPushNotificationsEnabled();

  return isFeatureEnabled && isNetworkSettingEnabled && doesBrowserSupportPushNotifications();
};

export const useIsEnabled = (): boolean => {
  const isSupported = useIsSupported();
  const { enabled: isUserSettingEnabled } = useSettings();

  return isUserSettingEnabled && isSupported;
};
