import { getGutterValueInPixels } from '../../../util/styles/gutters';
import { mainContentColumnMaxWidth } from '../ContentMainColumn.types';
import {
  ContentSidebarPosition,
  contentSidebarLeftMaxWidth,
  contentSidebarRightMaxWidth,
} from '../ContentSidebarColumn.types';

import { useAppLayoutContext, useAppLayoutGutterSize } from './useAppLayoutContext';
import { useContentContainerContext } from './useContentContainerContext';

const getContentMaxWidth = (
  gutterSize: number,
  removeContentPadding: boolean,
  sidebarPosition?: ContentSidebarPosition
) => {
  const gutterInPixels = getGutterValueInPixels(gutterSize);
  const totalGutterColumns = removeContentPadding ? 1 : 3;
  const sidebarWidth = sidebarPosition === 'left' ? contentSidebarLeftMaxWidth : contentSidebarRightMaxWidth;

  return mainContentColumnMaxWidth + sidebarWidth + gutterInPixels * totalGutterColumns;
};

type UseContentMaxWidth = () => number | undefined;
export const useContentMaxWidth: UseContentMaxWidth = () => {
  const { sidebarPosition } = useContentContainerContext();
  const { shouldFillAvailableSpace } = useContentContainerContext();
  const gutterSize = useAppLayoutGutterSize();
  const appLayoutContext = useAppLayoutContext();
  const removeContentPadding = appLayoutContext?.removeContentPadding || false;

  if (shouldFillAvailableSpace) {
    return undefined;
  }

  return getContentMaxWidth(gutterSize, removeContentPadding, sidebarPosition);
};
