import { useMemo } from 'react';

import { useIsInsightsCampaignEnabled, useIsQuestionsOnlyMode } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useInsightsCampaignPageSettings: UseMainUIFeatureSettings<'insightsCampaignPage'> = (options) => {
  const insightsCampaignPageOverrides = options?.overrides?.insightsCampaignPage;

  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();
  const isInsightsCampaignsEnabled = useIsInsightsCampaignEnabled();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        ...insightsCampaignPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: isInsightsCampaignsEnabled,
      ...insightsCampaignPageOverrides,
    };
  }, [insightsCampaignPageOverrides, isInsightsCampaignsEnabled, isQuestionsOnlyMode]);
};
