// @generated by protoc-gen-es v1.3.0
// @generated from file current/campaigns/campaign_created.proto (package events, syntax proto2)
/* eslint-disable */
// @ts-nocheck

import { proto2 } from "@bufbuild/protobuf";

/**
 * @generated from message events.CampaignCreatedV1
 */
export const CampaignCreatedV1 = proto2.makeMessageType(
  "events.CampaignCreatedV1",
  () => [
    { no: 1, name: "default_publisher_message_type", kind: "enum", T: proto2.getEnumType(CampaignCreatedV1_MessageType) },
    { no: 2, name: "campaign_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
  ],
);

/**
 * @generated from enum events.CampaignCreatedV1.MessageType
 */
export const CampaignCreatedV1_MessageType = proto2.makeEnum(
  "events.CampaignCreatedV1.MessageType",
  [
    {no: 0, name: "PRAISE"},
    {no: 1, name: "QUESTION"},
    {no: 2, name: "DISCUSSION"},
    {no: 3, name: "POLL"},
  ],
);

/**
 * @generated from message events.CampaignCreatedV2
 */
export const CampaignCreatedV2 = proto2.makeMessageType(
  "events.CampaignCreatedV2",
  () => [
    { no: 1, name: "default_publisher_message_type", kind: "enum", T: proto2.getEnumType(CampaignCreatedV2_MessageType) },
    { no: 2, name: "campaign_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 3, name: "group_id", kind: "scalar", T: 4 /* ScalarType.UINT64 */, opt: true },
    { no: 4, name: "entrypoint", kind: "enum", T: proto2.getEnumType(CampaignCreatedV2_Entrypoint) },
  ],
);

/**
 * @generated from enum events.CampaignCreatedV2.MessageType
 */
export const CampaignCreatedV2_MessageType = proto2.makeEnum(
  "events.CampaignCreatedV2.MessageType",
  [
    {no: 0, name: "PRAISE"},
    {no: 1, name: "QUESTION"},
    {no: 2, name: "DISCUSSION"},
    {no: 3, name: "POLL"},
  ],
);

/**
 * @generated from enum events.CampaignCreatedV2.Entrypoint
 */
export const CampaignCreatedV2_Entrypoint = proto2.makeEnum(
  "events.CampaignCreatedV2.Entrypoint",
  [
    {no: 0, name: "CAMPAIGN_MANAGEMENT_PAGE"},
    {no: 1, name: "GROUP_CAMPAIGN_MANAGEMENT_PAGE"},
    {no: 2, name: "CAMPAIGN_WIDGET"},
    {no: 3, name: "UNIVERSAL_CREATE_BUTTON"},
    {no: 4, name: "COMMS_DASHBOARD"},
  ],
);

