import { getExperimentTreatment } from '@ms/yammer-data/dist/domains/experiments/selectors';
import { Experiment } from '@ms/yammer-data/dist/state/types';
import { useEffect } from 'react';

import { useExperimentTreatmentWithoutReporting } from './selectors';
import { useReportExperimentTreatmentCallback } from './useReportExperimentTreatmentCallback';

type UseAndReportExperimentTreatment = (experiment: Experiment) => ReturnType<typeof getExperimentTreatment>;
export const useAndReportExperimentTreatment: UseAndReportExperimentTreatment = (experiment) => {
  const treatment = useExperimentTreatmentWithoutReporting(experiment);
  const reportExperimentTreatmentCallback = useReportExperimentTreatmentCallback(experiment, treatment);

  useEffect(() => {
    reportExperimentTreatmentCallback();
  }, [experiment, treatment, reportExperimentTreatmentCallback]);

  return treatment;
};
