import { getStatusForId as getStatus } from '@ms/yammer-data-support';
import { MessageContentType } from '@ms/yammer-graphql';
import createCachedSelector from 're-reselect';

import { Group, GroupPublisherSettingsType, OnBehalfOfUser, TeamsMeeting, User, YammerState } from '../../state/types';
import { createFindById, createFindByIdThrows } from '../findById';
import { getCurrentUser, getFulfilledUsers } from '../users/selectors';

export const findById = createFindById('groupPublisherSettings');
export const findByIdThrows = createFindByIdThrows('groupPublisherSettings');

type GetStatusForId = (state: YammerState, groupId: Group['id']) => ReturnType<typeof getStatus>;
export const getStatusForId: GetStatusForId = (state, groupId) => getStatus(state.groupPublisherSettings, groupId);

type GetThreadStarterDefaultContentType = (state: YammerState, groupId?: Group['id'] | null) => MessageContentType;
export const getThreadStarterDefaultContentType: GetThreadStarterDefaultContentType = (state, groupId) => {
  if (!groupId) {
    return MessageContentType.NORMAL;
  }

  const group = findById(state, groupId);

  if (!group) {
    return MessageContentType.NORMAL;
  }

  return group.threadStarterDefaultContentType;
};

type GetIsDefaultPostTypeQuestion = (
  state: YammerState,
  groupId?: Group['id'] | null,
  teamsMeetingId?: TeamsMeeting['id']
) => boolean;
export const getIsDefaultPostTypeQuestion: GetIsDefaultPostTypeQuestion = (state, groupId, teamsMeetingId) => {
  const defaultPostType = getThreadStarterDefaultContentType(state, groupId);

  if (teamsMeetingId) {
    return true;
  }

  if (!groupId) {
    return false;
  }

  return defaultPostType === MessageContentType.QUESTION;
};

type IsFulfilled = (state: YammerState, groupId: Group['id']) => boolean;
export const isFulfilled: IsFulfilled = (state, groupId) =>
  getStatus(state.groupPublisherSettings, groupId) === 'fulfilled';

type GetSelectedSenderIdForGroup = (
  state: YammerState,
  groupId: Group['id'] | null
) => GroupPublisherSettingsType['selectedSenderId'];
export const getSelectedSenderIdForGroup: GetSelectedSenderIdForGroup = (state, groupId) => {
  if (groupId) {
    const groupPublisherSettings = findById(state, groupId);
    if (groupPublisherSettings && groupPublisherSettings.selectedSenderId) {
      return groupPublisherSettings.selectedSenderId;
    }
  }

  return undefined;
};

const emptySenders: ReturnType<GetOnBehalfOfSenders> = [];
type GetOnBehalfOfSenders = (state: YammerState, groupId: Group['id']) => OnBehalfOfUser[];
export const getOnBehalfOfSenders: GetOnBehalfOfSenders = (state, groupId) =>
  findById(state, groupId)?.onBehalfOfSenders ?? emptySenders;

type GetSendersToStartThreadInGroup = (state: YammerState, groupId: Group['id']) => User[];
export const getSendersToStartThreadInGroup: GetSendersToStartThreadInGroup = createCachedSelector(
  getOnBehalfOfSenders,
  getCurrentUser,
  getFulfilledUsers,
  getStatusForId,
  (onBehalfOfSenders, currentUser, users, groupPublisherSettingsStatus) => {
    if (groupPublisherSettingsStatus !== 'fulfilled') {
      return [currentUser];
    }

    return onBehalfOfSenders.reduce(
      (senders, user) => {
        if (!user.canStartThreadInGroup) {
          return senders;
        }

        return [...senders, users[user.nodeId]];
      },
      [currentUser]
    );
  }
)((_state, groupId) => groupId);

type GetSendersToReplyThreadInGroup = (state: YammerState, groupId: Group['id']) => User[];
export const getSendersToReplyThreadInGroup: GetSendersToReplyThreadInGroup = createCachedSelector(
  getOnBehalfOfSenders,
  getCurrentUser,
  getFulfilledUsers,
  getStatusForId,
  (onBehalfOfSenders, currentUser, users, groupPublisherSettingsStatus) => {
    if (groupPublisherSettingsStatus !== 'fulfilled') {
      return [currentUser];
    }

    return onBehalfOfSenders.reduce(
      (senders, user) => {
        if (!user.canViewThreadsInGroup) {
          return senders;
        }

        return [...senders, users[user.nodeId]];
      },
      [currentUser]
    );
  }
)((_state, groupId) => groupId);
