import { makeStyles, mergeClasses } from '@griffel/react';

import { createTokens } from '../../theme/fluent9';
import { getGutterValue } from '../gutters';

import { ClassNameAndInlineStyles } from './types';

export type GapPropName =
  /**
   * Number of gutter units to be added as gap between items.
   */
  | 'gap'
  /**
   * Number of gutter units to be added as gap between columns. Will override a "gap" only for column spacing.
   */
  | 'columnGap'
  /**
   * Number of gutter units to be added as gap between rows. Will override a "gap" only for row spacing.
   */
  | 'rowGap';

export type GapProps = {
  [key in GapPropName]?: number;
};

const orderedGapPropNames: GapPropName[] = ['gap', 'columnGap', 'rowGap'];

const { tokens, cssVariables } = createTokens(orderedGapPropNames, 'gap');

type UseGap = (props: GapProps) => ClassNameAndInlineStyles;
export const useGap: UseGap = (props) => {
  const paddingClassNames = useGapClassNames();

  const providedGapProps = orderedGapPropNames.filter((propName) => props[propName] != null);

  const className = mergeClasses(...providedGapProps.map((prop) => paddingClassNames[prop]));
  const inlineStyles = providedGapProps.reduce(
    (styles, prop) => ({
      ...styles,
      [cssVariables[prop]]: getGutterValue(props[prop] as number),
    }),
    {}
  );

  return {
    className,
    inlineStyles,
  };
};

const useGapClassNames = makeStyles<GapPropName>({
  gap: {
    columnGap: tokens.gap,
    rowGap: tokens.gap,
  },
  columnGap: { columnGap: tokens.columnGap },
  rowGap: { rowGap: tokens.rowGap },
});
