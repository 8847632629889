import { reportUsage } from '@ms/yammer-data/dist/telemetry';
import { GetBaseUrlProps, getBaseUrl } from '@ms/yammer-web-support/dist/location';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useWebHostName } from '../session';

interface NavigateMessageEventData {
  readonly type: 'NAVIGATE';
  readonly payload: {
    readonly url: string;
  };
}

interface NotificationShownMessageEventData {
  readonly type: 'NOTIFICATION_SHOWN';
}

interface NotificationClickedMessageEventData {
  readonly type: 'NOTIFICATION_CLICKED';
}

type ServiceWorkerMessageEventData =
  | NavigateMessageEventData
  | NotificationShownMessageEventData
  | NotificationClickedMessageEventData;

const navigateToUrl = (history: ReturnType<typeof useHistory>, url: string, host: GetBaseUrlProps['host']) => {
  const baseUrl = getBaseUrl({ host });

  if (!url.startsWith(baseUrl)) {
    return;
  }

  history.push(url.replace(baseUrl, ''));
};

export const useServiceWorkerMessages = (): void => {
  const history = useHistory();
  const host = useWebHostName();

  const onMessage = useCallback(
    (event: MessageEvent<ServiceWorkerMessageEventData | undefined>) => {
      switch (event.data?.type) {
        case 'NAVIGATE':
          navigateToUrl(history, event.data.payload.url, host);
          break;
        case 'NOTIFICATION_SHOWN':
          reportUsage({ eventName: 'push_notifications_notification_shown' });
          break;
        case 'NOTIFICATION_CLICKED':
          reportUsage({ eventName: 'push_notifications_notification_clicked' });
          break;
      }
    },
    [history, host]
  );

  useEffect(() => {
    if (!('serviceWorker' in navigator)) {
      return;
    }

    navigator.serviceWorker.addEventListener('message', onMessage);

    return () => {
      navigator.serviceWorker.removeEventListener('message', onMessage);
    };
  }, [onMessage]);
};
