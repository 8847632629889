import { useSelector } from 'react-redux';

import { StringWrapperCtor } from '../../components/useTranslations';
import { YammerI18nState } from '../../state/types';

import { haveFeatureTranslationsCompleted } from './selectors';

type UseHaveTranslationsCompleted = <T>(
  Strings: StringWrapperCtor<T>
) => ReturnType<typeof haveFeatureTranslationsCompleted>;
export const useHaveTranslationsCompleted: UseHaveTranslationsCompleted = (Strings) =>
  useSelector((state: YammerI18nState) => haveFeatureTranslationsCompleted(state, Strings.feature));
