import {
  getExperimentTreatment,
  getExperimentTreatmentIfActive,
  getExperimentsOverview,
  hasExperimentOverride,
  isUserTreatedInExperiment,
  shouldReportExperimentTreatedEvent,
} from '@ms/yammer-data/dist/domains/experiments/selectors';
import { Experiment } from '@ms/yammer-data/dist/state/types';

import { useSelector } from '../useSelector';

type UseIsUserTreatedInExperiment = (experiment: Experiment) => ReturnType<typeof isUserTreatedInExperiment>;
export const useIsUserTreatedInExperiment: UseIsUserTreatedInExperiment = (experiment) =>
  useSelector((state) => isUserTreatedInExperiment(state, experiment));

type UseShouldReportExperimentTreatedEvent = (
  experiment: Experiment
) => ReturnType<typeof shouldReportExperimentTreatedEvent>;
export const useShouldReportExperimentTreatedEvent: UseShouldReportExperimentTreatedEvent = (experiment) =>
  useSelector((state) => shouldReportExperimentTreatedEvent(state, experiment));

type UseExperimentTreatmentWithoutReporting = (experiment: Experiment) => ReturnType<typeof getExperimentTreatment>;
export const useExperimentTreatmentWithoutReporting: UseExperimentTreatmentWithoutReporting = (experiment) =>
  useSelector((state) => getExperimentTreatment(state, experiment));

type UseHasExperimentOverride = (experiment: Experiment) => ReturnType<typeof hasExperimentOverride>;
export const useHasExperimentOverride: UseHasExperimentOverride = (experiment) =>
  useSelector((state) => hasExperimentOverride(state, experiment));

type UseExperimentsOverview = () => ReturnType<typeof getExperimentsOverview>;
export const useExperimentsOverview: UseExperimentsOverview = () =>
  useSelector((state) => getExperimentsOverview(state));

type UseExperimentTreatmentIfActive = (experiment: Experiment) => ReturnType<typeof getExperimentTreatmentIfActive>;
export const useExperimentTreatmentIfActive: UseExperimentTreatmentIfActive = (experiment) =>
  useSelector((state) => getExperimentTreatmentIfActive(state, experiment));
