import { schema } from 'normalizr';

import { processLeader } from '../strategies/leader';

import { userSchema } from './user';

export const leaderSchema = new schema.Entity(
  'userNetworkLeaders',
  { id: userSchema },
  { processStrategy: processLeader }
);

export const leaderEdgesSchema = [new schema.Object({ node: leaderSchema })];
