import { stringify } from 'querystring';

import { GroupSortOrder } from '@ms/yammer-data/dist/normalization/types';

export const discoverGroupsUrl = '/discover-groups';
export const discoverGroupsRecommendedUrl = '/discover-groups/recommended';
export const discoverGroupsAllUrl = '/discover-groups/all';
export const discoverGroupsExploreUrl = '/discover-groups/explore';

export const queryParamSortBy = 'sortBy';

export enum GroupSortOrderCustom {
  RECOMMENDED = 'Recommended',
  OFFICIAL = 'Official',
}

export type AllTabGroupSortOrder = GroupSortOrder | GroupSortOrderCustom;

export const GroupSortQueryParam: Record<AllTabGroupSortOrder, string> = {
  [GroupSortOrderCustom.OFFICIAL]: 'official',
  [GroupSortOrderCustom.RECOMMENDED]: 'recommended',
  [GroupSortOrder.MEMBER_COUNT_DESC]: 'members',
  [GroupSortOrder.CREATION_DATE_DESC]: 'createdAt',
  [GroupSortOrder.NAME_ASC]: 'name',
};

type DiscoverGroupsAllPageWithParameters = (sortBy: AllTabGroupSortOrder) => string;

export const discoverGroupsAllPageWithParameters: DiscoverGroupsAllPageWithParameters = (sortBy) => {
  const sortByParam = { [queryParamSortBy]: GroupSortQueryParam[sortBy] };

  return `${discoverGroupsAllUrl}?${stringify(sortByParam)}`;
};
