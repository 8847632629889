import { useShouldStartOutageBanner } from '@ms/yammer-hooks/dist/outageBanner';
import React, { ReactNode, Suspense } from 'react';

import LoadableOutageBanner from './LoadableOutageBanner';

type UseOutageBanner = () => ReactNode;
export const useOutageBanner: UseOutageBanner = () => {
  const shouldShowOutageBanner = useShouldStartOutageBanner();

  const banner = (
    <Suspense fallback={null}>
      <LoadableOutageBanner />
    </Suspense>
  );

  return shouldShowOutageBanner ? banner : null;
};
