export const REGEXP_PLACEHOLDER = /{([^{}]+)}/g; // e.g. `{count} more`
export const REGEXP_WRAPPER = /\[(\w+)\]([^[\]]*)\[\/\]/g; // e.g. `[link]{count} more[/]`
export const REGEXP_WRAPPER_ARG = /\{\$([0-9]+)\}/g; // e.g. `<a href="{$0}">{$1}</a>`

export const DEFAULT_LOCALE = 'en-US';

export const KEY_SEPARATOR = '|';

export const LIST_SEPARATOR_TWO = 'listSeparator1';
export const LIST_SEPARATOR_START = 'listSeparator2';
export const LIST_SEPARATOR_MIDDLE = 'listSeparator3';
export const LIST_SEPARATOR_LAST = 'listSeparator4';

export const PLURAL_PLACEHOLDER_NAME = 'count';
