import { makeStyles } from '@griffel/react';

import { FlexAlign } from './alignItems.styles';

const useAlignSelfClassNames = makeStyles<FlexAlign>({
  'flex-start': {
    alignSelf: 'flex-start',
  },
  'flex-end': {
    alignSelf: 'flex-end',
  },
  center: {
    alignSelf: 'center',
  },
  stretch: {
    alignSelf: 'stretch',
  },
  baseline: {
    alignSelf: 'baseline',
  },
});

export const useAlignSelfClassName = (align?: FlexAlign) => {
  const classNames = useAlignSelfClassNames();

  return align ? classNames[align] : undefined;
};
