import { loadUserInformationBarrierSettings } from '@ms/yammer-data/dist/domains/userInformationBarrierSettings/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useIsInformationBarrierEnabled } from '../informationBarrierSettings';
import { useCurrentUserFieldThrows } from '../users';

import { useUserInformationBarrierSettings } from './selectors';

type UseLoadUserInformationBarrierSettings = () => void;
export const useLoadUserInformationBarrierSettings: UseLoadUserInformationBarrierSettings = () => {
  const dispatch = useDispatch();
  const userId = useCurrentUserFieldThrows('id');
  const isInformationBarrierEnabled = useIsInformationBarrierEnabled();
  const userInformationBarrierSettings = useUserInformationBarrierSettings(userId);

  useEffect(() => {
    if (userId && isInformationBarrierEnabled && !userInformationBarrierSettings) {
      dispatch(loadUserInformationBarrierSettings(userId));
    }
  }, [dispatch, isInformationBarrierEnabled, userId, userInformationBarrierSettings]);
};
