import React, { FC } from 'react';

import { useContainerWidth } from '../../../../util/responsiveness';
import Flex from '../../../Flex';
import {
  useAppLayoutContext,
  useAppLayoutGutterSize,
  useAppLayoutWidth,
  useContentContainerContext,
  useContentMaxWidth,
  useIsContentSidebarCurrentlyHiddenByNarrowWidth,
} from '../../hooks';

import { useClassName } from './MainAreaRowContent.styles';
import { MainAreaRowContentProps } from './MainAreaRowContent.types';
import SidebarPanelButtonStickyColumn from './SidebarPanelButtonStickyColumn';
import { shouldShowSubheaderAtWidth } from './SubHeaderRow';

const MainAreaRowContent: FC<MainAreaRowContentProps> = ({ hasHeroBackground, children }) => {
  const gutterSize = useAppLayoutGutterSize();
  const contentMaxWidth = useContentMaxWidth();
  const { shouldFillAvailableSpace } = useContentContainerContext();
  const appLayoutContext = useAppLayoutContext();
  const paddingTop = shouldFillAvailableSpace || appLayoutContext?.removeContentPadding ? 0 : gutterSize;
  const height = shouldFillAvailableSpace ? '100%' : undefined;
  const maxWidth = shouldFillAvailableSpace ? '100%' : contentMaxWidth;

  const appLayoutWidth = useAppLayoutWidth();
  const isSidebarSuppressed = useIsContentSidebarCurrentlyHiddenByNarrowWidth();
  const shouldSidebarPanelButtonBeDisplayedAtBreakpoint = !shouldShowSubheaderAtWidth(appLayoutWidth);
  const sidebarPanelButton =
    isSidebarSuppressed && shouldSidebarPanelButtonBeDisplayedAtBreakpoint ? <SidebarPanelButtonStickyColumn /> : null;

  const containerWidth = useContainerWidth();
  const className = useClassName({
    hasHeroBackground,
    containerWidth,
  });

  return (
    <Flex justifyContent="center" grow={1} paddingTop={paddingTop} className={className} height={height}>
      <Flex direction="column" rowGap={gutterSize} grow={1} maxWidth={maxWidth}>
        {children}
      </Flex>
      {sidebarPanelButton}
    </Flex>
  );
};

export default MainAreaRowContent;
