import { PaletteColor, addAlpha } from '../../util/colors';
import { BorderRadius } from '../../util/styles/borders';
import { IProcessedStyleSet, IStyle, ITheme, memoizeFunction, mergeStyleSets } from '../../util/styling';

import { IconButtonProps, IconButtonSize } from './IconButton.types';

export interface IconButtonClassNamesProps {
  readonly theme: ITheme;
  readonly size: IconButtonSize;
  readonly borderRadius: BorderRadius;
  readonly backgroundColor: IconButtonProps['backgroundColor'];
  readonly backgroundHoverColor: IconButtonProps['backgroundHoverColor'];
  readonly useTransparentBackground: boolean;
  readonly color?: PaletteColor;
  readonly className?: string;
}

export interface IconButtonStyles {
  readonly iconButton: IStyle;
  readonly icon: IStyle;
  readonly badge: IStyle;
}

const getBackgroundAlphaColor = (backgroundColor: string, useTransparentBackground: boolean, alphaValue: number) =>
  useTransparentBackground ? addAlpha(backgroundColor, alphaValue) : backgroundColor;

const fontSizes: Record<IconButtonSize, number> = {
  [12]: 8,
  [18]: 12,
  [24]: 14,
  [32]: 18,
};

export const getClassNamesMemoized = memoizeFunction(
  (
    theme: ITheme,
    size: IconButtonSize,
    backgroundColor: IconButtonClassNamesProps['backgroundColor'],
    backgroundHoverColor: IconButtonClassNamesProps['backgroundHoverColor'],
    useTransparentBackground: IconButtonClassNamesProps['useTransparentBackground'],
    borderRadius: BorderRadius,
    color?: PaletteColor,
    className?: string
  ) => {
    const iconColor = color ? theme.palette[color] : undefined;

    return mergeStyleSets({
      iconButton: [
        {
          position: 'relative',
          borderColor: 'transparent',
          borderStyle: 'solid',
          color: iconColor,
          backgroundColor: backgroundColor
            ? getBackgroundAlphaColor(theme.palette[backgroundColor], useTransparentBackground, 40)
            : undefined,
          borderWidth: 1,
          borderRadius,
          height: size,
          width: size,
          lineHeight: size,
          fontSize: fontSizes[size],
          display: 'inline-block',
          textAlign: 'center',
          selectors: {
            ':hover': {
              backgroundColor: backgroundHoverColor
                ? getBackgroundAlphaColor(theme.palette[backgroundHoverColor], useTransparentBackground, 60)
                : undefined,
            },
            ':active, :hover, :focus': {
              color: iconColor,
            },
          },
        },
        className,
      ],
      icon: {
        display: 'block',
        lineHeight: 0,
      },
      badge: {
        position: 'absolute',
        top: -4,
        left: 15,
        lineHeight: 0,
        border: `1px solid ${theme.palette.white}`,
        borderRadius: BorderRadius.ExtremelyRound,
      },
    });
  }
);

export type GetClassNames = (props: IconButtonClassNamesProps) => IProcessedStyleSet<IconButtonStyles>;
export const getClassNames: GetClassNames = ({
  theme,
  size,
  backgroundColor,
  backgroundHoverColor,
  useTransparentBackground,
  borderRadius,
  color,
  className,
}) =>
  getClassNamesMemoized(
    theme,
    size,
    backgroundColor,
    backgroundHoverColor,
    useTransparentBackground,
    borderRadius,
    color,
    className
  );
