import queryMultiTenantOrganization from '@ms/yammer-graphql/dist/operations/queryMultiTenantOrganization.graphql';

import { GraphqlApiRequest, ManagedApiLifecycle, getGraphqlApiRequest, managedApiAction } from '../..';
import { normalizeMultiTenantOrganization } from '../../normalization/graphql/normalizeMultiTenantOrganization';
import { NormalizedMultiTenantOrganization } from '../../normalization/graphql/normalizeMultiTenantOrganization.types';
import { reportError } from '../../telemetry';
import { PromiseThunkAction } from '../../types';

import {
  MultiTenantOrganizationClientLoadFulfilledAction,
  MultiTenantOrganizationClientLoadPendingAction,
  MultiTenantOrganizationClientLoadRejectedAction,
  MultiTenantOrganizationClientLoadResponse,
} from './actions.types';
import { getMultiTenantOrganizationStatus } from './selectors';

const loadMultiTenantOrganizationPending = (): MultiTenantOrganizationClientLoadPendingAction => ({
  type: 'MULTI_TENANT_ORGANIZATION.LOAD_PENDING',
});

const loadMultiTenantOrganizationFulfilled = (
  response: NormalizedMultiTenantOrganization | null
): MultiTenantOrganizationClientLoadFulfilledAction => {
  const payload = {
    entities: response?.entities ?? null,
  };

  return {
    type: 'MULTI_TENANT_ORGANIZATION.LOAD_FULFILLED',
    payload,
  };
};

const loadMultiTenantOrganizationRejected = (error: unknown): MultiTenantOrganizationClientLoadRejectedAction => ({
  type: 'MULTI_TENANT_ORGANIZATION.LOAD_REJECTED',
  payload: {
    error,
  },
  error: true,
});

type Load = () => PromiseThunkAction<void>;
export const load: Load = () => async (dispatch, getState) => {
  const state = getState();
  const status = getMultiTenantOrganizationStatus(state);

  if (status === 'pending') {
    return;
  }

  const request: GraphqlApiRequest = getGraphqlApiRequest({
    body: {
      operationName: 'MultiTenantOrganizationClients',
      query: queryMultiTenantOrganization,
    },
  });
  const lifecycle: ManagedApiLifecycle<MultiTenantOrganizationClientLoadResponse> = {
    pending: () => loadMultiTenantOrganizationPending(),
    fulfilled: (response) => {
      const normalized = normalizeMultiTenantOrganization(response);

      return loadMultiTenantOrganizationFulfilled(normalized);
    },
    rejected: (error) => loadMultiTenantOrganizationRejected(error),
  };

  const action = managedApiAction({ request, lifecycle });

  try {
    await dispatch(action);
  } catch (error) {
    reportError({ error });
  }
};
