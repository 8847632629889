import { lazyLoadOnRender } from '@ms/yammer-libs-lazy';

const LazyRestrictedUserBanner = () =>
  import(/* webpackChunkName: "4-feat-restricted-user-banner" */ './RestrictedUserBanner');

const LoadableRestrictedUserBanner = lazyLoadOnRender({
  loader: LazyRestrictedUserBanner,
});

LoadableRestrictedUserBanner.displayName = 'RestrictedUserBanner';

export default LoadableRestrictedUserBanner;
