import { createContext, useContext } from 'react';

import { Theme, webLightTheme } from '../../util/theme/fluent9';

export interface AppContainerFUI9ThemePrivateContextProviderProps {
  readonly theme: Theme;
  readonly dir: 'ltr' | 'rtl';
}
export const AppContainerFUI9ThemePrivateContext = createContext<AppContainerFUI9ThemePrivateContextProviderProps>({
  theme: webLightTheme,
  dir: 'ltr',
});

export const useAppContainerFUI9ThemePrivateContext = () => useContext(AppContainerFUI9ThemePrivateContext);
