import { schema } from 'normalizr';

import { processViewerIsFollowedByUser } from '../strategies/viewerIsFollowedByUser';

export const viewerIsFollowedByUserSchema = new schema.Entity(
  'viewerIsFollowedByUsers',
  {},
  { processStrategy: processViewerIsFollowedByUser }
);

export type ViewerIsFollowedByUserEdgesSchema = typeof viewerIsFollowedByUserEdgesSchema;
export const viewerIsFollowedByUserEdgesSchema = [new schema.Object({ node: viewerIsFollowedByUserSchema })];
