import { useMemo } from 'react';

import { useIsMoreForYouEnabled, useIsQuestionsOnlyMode } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useThreadPageSettings: UseMainUIFeatureSettings<'threadPage'> = (options) => {
  const threadPageOverrides = options?.overrides?.threadPage;

  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();
  const moreForYou = useIsMoreForYouEnabled();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: true,
        groupsExplorerCard: false,
        moreForYou: false,
        toolbarFollow: false,
        toolbarMarkAsRead: false,
        ...threadPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: true,
      groupsExplorerCard: false,
      moreForYou,
      toolbarFollow: true,
      toolbarMarkAsRead: true,
      ...threadPageOverrides,
    };
  }, [isQuestionsOnlyMode, moreForYou, threadPageOverrides]);
};
