import { StorylineSettingsClients } from '@ms/yammer-graphql';

import { StorylineSettings } from '../../state/types';

type StorylineSettingsNormalizer = (response: { readonly data: StorylineSettingsClients }) => StorylineSettings;

export const normalizeStorylineSettings: StorylineSettingsNormalizer = (response) => ({
  canUseDelegateTeamsOnStoryline: response.data.settings.storyline.canUseDelegateTeamsOnStoryline,
  viewerCanAccessStories: response.data.settings.stories.viewerCanAccessStories,
  viewerCanAccessStoryline: response.data.settings.storyline.viewerCanAccessStoryline,
  viewerCanPostStorylineAnnouncement: response.data.settings.storyline.viewerCanPostStorylineAnnouncement,
  viewerCanPostToStoryline: !!response.data.viewer.user.wallFeed?.viewerCanStartThread,
  viewerCanPromoteConversationInStoryline: response.data.settings.storyline.viewerCanPromoteConversationInStoryline,
  isStorylineMultiTenantOrganizationEnabled: response.data.settings.storyline.isStorylineMultiTenantOrganizationEnabled,
});
