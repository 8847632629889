import { makeStyles, mergeClasses } from '@griffel/react';

import { AppLayoutBreakpoint } from '../../../../util/responsiveness';
import { coreuiTokens } from '../../../../util/theme/fluent9';

interface Props {
  readonly hasHeroBackground: boolean;
  readonly containerWidth: number;
}

type UseClassName = (props: Props) => string;
export const useClassName: UseClassName = ({ hasHeroBackground, containerWidth }) => {
  const classNames = useHeroBackgroundClasses();
  const shouldShowHeroBackgroundAtCurrentBreakpoint = containerWidth >= AppLayoutBreakpoint.Width812;
  const shouldShowHeroBackground = hasHeroBackground && shouldShowHeroBackgroundAtCurrentBreakpoint;

  return mergeClasses(shouldShowHeroBackground && classNames.root, 'qaMainAreaRowContent');
};

const useHeroBackgroundClasses = makeStyles({
  root: {
    backgroundImage: `linear-gradient(200deg, ${coreuiTokens.colorBlueBackgroundGradient} 16%, transparent 28%)`,
    backgroundSize: '100% 100vw',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 0,
    backgroundPositionY: 0,
  },
});
