import { AppActivityState, UpdateAppActivityStateClientsVariables } from '@ms/yammer-graphql';
import mutationUpdateAppActivityState from '@ms/yammer-graphql/dist/operations/mutationUpdateAppActivityState.graphql';

import { GraphqlApiRequest, ManagedApiLifecycle, getGraphqlApiRequest, managedApiAction } from '../..';
import { reportError } from '../../telemetry';
import { PromiseThunkAction } from '../../types';

import {
  UpdateUserPresenceFulfilledAction,
  UpdateUserPresencePendingAction,
  UpdateUserPresenceRejectedAction,
  UpdateUserPresenceResponse,
} from './actions.types';

const updateUserPresencePending = (): UpdateUserPresencePendingAction => ({
  type: 'USER_PRESENCE.UPDATED_PENDING',
});

const updateUserPresenceFulfilled = (): UpdateUserPresenceFulfilledAction => ({
  type: 'USER_PRESENCE.UPDATED_FULFILLED',
});

const updateUserPresenceRejected = (error: unknown): UpdateUserPresenceRejectedAction => ({
  type: 'USER_PRESENCE.UPDATED_REJECTED',
  payload: {
    error,
  },
  error: true,
});

type UpdateUserPresence = (isActive: boolean) => PromiseThunkAction<void>;
export const updateUserPresence: UpdateUserPresence = (isActive) => async (dispatch) => {
  const appActivityState = isActive ? AppActivityState.ACTIVE_WEB : AppActivityState.IDLE_WEB;
  const request: GraphqlApiRequest<UpdateAppActivityStateClientsVariables> = getGraphqlApiRequest({
    body: {
      operationName: 'UpdateAppActivityStateClients',
      query: mutationUpdateAppActivityState,
      variables: {
        input: {
          appActivityState,
        },
      },
    },
  });
  const lifecycle: ManagedApiLifecycle<UpdateUserPresenceResponse> = {
    pending: () => updateUserPresencePending(),
    fulfilled: () => updateUserPresenceFulfilled(),
    rejected: (error) => updateUserPresenceRejected(error),
  };

  try {
    await dispatch(managedApiAction({ request, lifecycle }));
  } catch (error) {
    reportError({
      error,
    });
  }
};
