import React, { FC, ReactNode, Suspense, lazy, useEffect, useState } from 'react';
import type { IEnvironment } from 'relay-runtime';

import { ErrorBoundary } from '../../components/ErrorBoundary';

import { useRelayContext } from './LazyRelayAppWrapper';

const LazyRelayEnvironmentProvider = lazy(() =>
  import(/* webpackChunkName: "4-feat-relay" */ 'react-relay/hooks').then(({ RelayEnvironmentProvider }) => ({
    default: RelayEnvironmentProvider,
  })),
);

interface LazyRelayModuleWrapperProps {
  readonly fallback?: ReactNode;
  readonly error?: ReactNode;
}
/*
 * This component is used to wrap modules in a RelayEnvironmentProvider.
 * It will read the lazy environment from the context and pass it to a RelayEnvironmentProvider.
 * The component will render the fallback if the Relay environment is not ready.
 * The component will render the error if the Relay environment failed to load.
 */
export const LazyRelayModuleWrapper: FC<LazyRelayModuleWrapperProps> = ({
  children,
  fallback = null,
  error = null,
}) => {
  const relayContext = useRelayContext();
  const [environment, setEnvironment] = useState<IEnvironment | undefined>(undefined);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let aborted = false;

    relayContext.environment
      ?.then((value) => {
        if (aborted) {
          return;
        }

        setEnvironment(value);
      })
      .catch(() => {
        if (aborted) {
          return;
        }

        setHasError(true);
      });

    return () => {
      aborted = true;
    };
  }, [relayContext]);

  if (hasError) {
    return <>{error}</>;
  }

  if (environment == null) {
    return <>{fallback}</>;
  }

  return (
    <LazyRelayEnvironmentProvider environment={environment}>
      <ErrorBoundary>
        <Suspense fallback={null}>{children}</Suspense>
      </ErrorBoundary>
    </LazyRelayEnvironmentProvider>
  );
};
