import { lazyLoadOnRender } from '@ms/yammer-libs-lazy';
import React, { FC } from 'react';

const loader = () => import(/* webpackChunkName: "4-feat-suiteheader" */ './SuiteHeaderBoundary');

const LazySuiteHeader = lazyLoadOnRender({ loader });

const height = 48;

const LoadableSuiteHeader: FC = () => (
  <div style={{ height }}>
    <LazySuiteHeader />
  </div>
);

export default LoadableSuiteHeader;
