import React, { FC, useCallback, useEffect, useRef } from 'react';

import FocusPlaceholder, { Focus } from '../FocusPlaceholder';

import { FocusCatcherProps } from './FocusCatcher.types';
import { containsValidFocus } from './containsValidFocus';

const FocusCatcher: FC<FocusCatcherProps> = ({ state, fullHeight = false, children }) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const containsFocus = useRef<boolean>(false);
  const focusPlaceholder = useRef<Focus>();

  const onBlur = useCallback(() => {
    containsFocus.current = false;
  }, []);

  const onFocus = useCallback(() => {
    containsFocus.current = true;
  }, []);

  const setPlaceholderFocus = useCallback((focus: Focus) => {
    focusPlaceholder.current = focus;
  }, []);

  useEffect(() => {
    if (containsFocus.current) {
      // If focus is somewhere valid, assume it was put there intentionally - eg.
      // by some component focusing a child as it mounts - and leave it where it is.
      const focusIsValid = containsValidFocus(rootRef.current, document.activeElement);
      if (!focusIsValid && focusPlaceholder.current) {
        focusPlaceholder.current();

        // We may not get an onBlur event (quirk of how React handles some types of events)
        // as a result of moving focus to the placeholder; so mark this as false explicitly.
        containsFocus.current = false;
      }
    }
  }, [state]);

  const styles = fullHeight ? { height: '100%' } : undefined;

  return (
    <>
      <FocusPlaceholder focusRef={setPlaceholderFocus} />
      <div onBlur={onBlur} onFocus={onFocus} ref={rootRef} style={styles}>
        {children}
      </div>
    </>
  );
};

export default FocusCatcher;
