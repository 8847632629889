import { PageName, UiComponentName } from '@ms/yammer-telemetry-support';
import { createContext } from 'react';

export interface RouteProps {
  readonly page: PageName;
  readonly path?: string;
  readonly hero: UiComponentName;
}

export interface PageTelemetryContextValue extends RouteProps {
  readonly isRoutelessApp: boolean;
  readonly reportHeroComponentRendered: (component: UiComponentName) => void;
  readonly setRoute: (route: RouteProps, pathname: string) => void;
}

export const PageTelemetryContext = createContext<PageTelemetryContextValue | undefined>(undefined);
