import { useMemo } from 'react';

import { useIsNetworkAdminEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useConfigureTenantAdminPageSettings: UseMainUIFeatureSettings<'configureTenantAdminPage'> = (options) => {
  const configureTenantPageOverrides = options?.overrides?.configureTenantAdminPage;

  const isConfigureTenantEnabled = useIsNetworkAdminEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: isConfigureTenantEnabled,
      ...configureTenantPageOverrides,
    }),
    [configureTenantPageOverrides, isConfigureTenantEnabled]
  );
};
