import { schema } from 'normalizr';

import { processViewerIsFollowingUser } from '../strategies/viewerIsFollowingUser';

export const viewerIsFollowingUserSchema = new schema.Entity(
  'viewerIsFollowingUsers',
  {},
  { processStrategy: processViewerIsFollowingUser }
);

export type ViewerIsFollowingUserEdgesSchema = typeof viewerIsFollowingUserEdgesSchema;
export const viewerIsFollowingUserEdgesSchema = [new schema.Object({ node: viewerIsFollowingUserSchema })];
