import useIsFocused from '@ms/yammer-web-core-ui/dist/util/useIsFocused';
import React, { FC } from 'react';

import { useClassNames } from './ShowWhenContainsFocus.styles';

interface ShowWhenContainsFocusProps {
  readonly alignWithContent?: boolean;
}

/**
 * `ShowWhenContainsFocus` hides its content from sight. When it's focused via keyboard
 * tabbing it will make its content visible, positioning itself absolutely above the page at
 * the top left of the nearest non-static container element. Its primary use case is to
 * display a "Skip to main content" link as the first focusable element on the page.
 */
const ShowWhenContainsFocus: FC<ShowWhenContainsFocusProps> = ({ alignWithContent = false, children }) => {
  const { onFocus, onBlur, isFocused } = useIsFocused();
  const classNames = useClassNames(isFocused, alignWithContent);

  return (
    <div className={classNames.root} onFocus={onFocus} onBlur={onBlur}>
      {children}
    </div>
  );
};

export default ShowWhenContainsFocus;
