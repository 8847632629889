import { useIsCurrentUserRestricted } from '@ms/yammer-hooks/dist/session/selectors';
import { useLeadershipCornerBanner } from '@ms/yammer-web-components/dist/leadershipCorner/LeadershipCornerBanner';
import { useOutageBanner } from '@ms/yammer-web-components/dist/outageBanner/hooks';
import RestrictedUserBanner from '@ms/yammer-web-components/dist/user/RestrictedUserBanner';
import Flex, { FlexItem } from '@ms/yammer-web-core-ui/dist/components/Flex';
import React, { useCallback } from 'react';

export const useGetBannersCallback = () => {
  const outageBanner = useOutageBanner();
  const leadershipCornerBanner = useLeadershipCornerBanner();
  const shouldShowRestrictedUserBanner = useIsCurrentUserRestricted();

  return useCallback(() => {
    const hasAnyBanner = outageBanner || leadershipCornerBanner || shouldShowRestrictedUserBanner;
    const outageBannerContent = outageBanner ? <FlexItem>{outageBanner}</FlexItem> : null;
    const leadershipCornerBannerContent = leadershipCornerBanner ? <FlexItem>{leadershipCornerBanner}</FlexItem> : null;
    const restrictedUserBannerContent = shouldShowRestrictedUserBanner ? (
      <FlexItem>
        <RestrictedUserBanner />
      </FlexItem>
    ) : null;

    const renderContent = hasAnyBanner ? (
      <Flex grow={1} direction="column">
        {outageBannerContent}
        {leadershipCornerBannerContent}
        {restrictedUserBannerContent}
      </Flex>
    ) : null;

    return renderContent;
  }, [outageBanner, leadershipCornerBanner, shouldShowRestrictedUserBanner]);
};
