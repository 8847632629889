import { EntityStatus, YammerState, getStatus } from '../../state/types';

type GetUserAchievementsSettingsStatus = (state: YammerState) => EntityStatus;
export const getUserAchievementsSettingsStatus: GetUserAchievementsSettingsStatus = (state) =>
  getStatus(state.userAchievementsSettings);

export const getViewerCanEditUserAchievementsSettings = (state: YammerState) =>
  state.userAchievementsSettings.fulfilled?.viewerCanEditSettings || false;

export const getUserAchievementsSettings = (state: YammerState) => state.userAchievementsSettings.fulfilled;
