import React, { ComponentType, PropsWithoutRef, Suspense, lazy } from 'react';

import { LoadOnRenderOptions } from './loadOnRender.types';

/**
 * This is a route-based lazy-loader function. It returns a component which lazy-loads a component
 * as a separate JavaScript bundle when rendered. It will show the "loading" component using React.Suspense
 * while waiting for the bundle to load.
 */
export default function loadOnRender<T extends object>({
  loader,
  loading: Loading,
  fallback,
}: LoadOnRenderOptions<T>): ComponentType<PropsWithoutRef<T>> {
  const LazyLoadedComponent = lazy(loader);
  const placeholder = fallback || (Loading ? <Loading /> : null);

  return (props) => (
    <Suspense fallback={placeholder}>
      <LazyLoadedComponent {...props} />
    </Suspense>
  );
}
