import { makeStyles } from '@griffel/react';

import { TextAlign, TextDirection } from '../fonts';

import { ClassNameAndInlineStyles } from './types';

export type { TextAlign };

export interface UseTextAlignProps {
  readonly align?: TextAlign;
  readonly dir?: TextDirection;
}
type UseTextAlign = (props: UseTextAlignProps) => ClassNameAndInlineStyles;
export const useTextAlign: UseTextAlign = (props) => {
  const alignClassNames = useTextAlignClassNames();
  const className = props.align ? alignClassNames[props.align] : undefined;
  const inlineStyles: ClassNameAndInlineStyles['inlineStyles'] = {};

  if (!props.align) {
    if (props.dir === 'rtl') {
      inlineStyles.textAlign = 'right';
    }

    if (props.dir === 'ltr') {
      inlineStyles.textAlign = 'left';
    }
  }

  return {
    className,
    inlineStyles,
  };
};

const useTextAlignClassNames = makeStyles<TextAlign>({
  left: {
    textAlign: 'left',
  },
  center: {
    textAlign: 'center',
  },
  right: {
    textAlign: 'right',
  },
});
