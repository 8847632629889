import React, { FC, useRef } from 'react';

import { ArticleScrollContext } from './context';

const ArticleScrollProvider: FC = ({ children }) => {
  const scrollDestinationRef = useRef(null);

  return <ArticleScrollContext.Provider value={{ scrollDestinationRef }}>{children}</ArticleScrollContext.Provider>;
};

export default ArticleScrollProvider;
