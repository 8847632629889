import { CampaignDefaultThreadStarterContentType } from '@ms/yammer-graphql';
import { CampaignCreatedV2_MessageType as ProtobufCampaignMessageType } from '@ms/yammer-protobuf/dist/events/current/campaigns/campaign_created_pb';

import { Campaign } from '../../../../state/campaigns/types';

const protobufCampaignMessageTypeMap = {
  [CampaignDefaultThreadStarterContentType.NORMAL]: ProtobufCampaignMessageType.DISCUSSION,
  [CampaignDefaultThreadStarterContentType.POLL]: ProtobufCampaignMessageType.POLL,
  [CampaignDefaultThreadStarterContentType.PRAISE]: ProtobufCampaignMessageType.PRAISE,
  [CampaignDefaultThreadStarterContentType.QUESTION]: ProtobufCampaignMessageType.QUESTION,
};

type GetProtobufCampaignMessageType = (
  publisherDefaultMessageType: Campaign['threadStarterDefaultContentType'] | undefined | null
) => ProtobufCampaignMessageType;
export const getProtobufCampaignMessageType: GetProtobufCampaignMessageType = (publisherDefaultMessageType) =>
  (publisherDefaultMessageType && protobufCampaignMessageTypeMap[publisherDefaultMessageType]) ??
  ProtobufCampaignMessageType.DISCUSSION;
