import React, { FC } from 'react';

import { screenreaderOnlyInlineStyles } from '../../util/styles/screenreaderOnly';

/**
 * A `ScreenreaderText` simply hides its content from sight without hiding it from screenreaders.
 * Use this component whenever a screenreader should be able to read aloud additional context for
 * your UI features.
 */

const ScreenreaderText: FC = ({ children }) => <span style={screenreaderOnlyInlineStyles}>{children}</span>;

export default ScreenreaderText;
