import { TypographyStyles, typographyStyles as typographyPresetStyles } from '@fluentui/react-components';
import { makeStyles } from '@griffel/react';

export type TypographyPreset = keyof TypographyStyles;
export { typographyPresetStyles };

export const useTypographyPresetClassName = (preset?: TypographyPreset) => {
  const typographyPresetClassNames = useTypographyPresetClassNames();

  return preset ? typographyPresetClassNames[preset] : undefined;
};

const useTypographyPresetClassNames = makeStyles<TypographyPreset>(typographyPresetStyles);

export const getFluentFontSizeTokenFromPreset = (preset: TypographyPreset) => typographyPresetStyles[preset].fontSize;
export const getFluentLineHeightTokenFromPreset = (preset: TypographyPreset) =>
  typographyPresetStyles[preset].lineHeight;
