import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { usePrevious } from '../../util/usePrevious';

import { getClassNames } from './FocusPlaceholder.styles.legacy';
import { FocusPlaceholderProps } from './FocusPlaceholder.types';

const FocusPlaceholder: FC<FocusPlaceholderProps> = ({ focusRef }) => {
  const placeholderRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useState(false);
  const wasActive = usePrevious(isActive);

  const focus = useCallback(() => {
    setIsActive(true);
  }, []);

  useEffect(() => {
    focusRef(focus);
  }, [focusRef, focus]);

  useEffect(() => {
    if (isActive && !wasActive) {
      // istanbul ignore next
      if (placeholderRef.current) {
        placeholderRef.current.focus();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const onPlaceholderBlur = useCallback(() => {
    // Filter out blur events due to user switching tabs; we only care about the case
    // where the user actually tabs away from this element.
    if (placeholderRef.current !== document.activeElement) {
      setIsActive(false);
    }
  }, []);

  if (!isActive) {
    return null;
  }

  const classNames = getClassNames();

  // nbsp aria-label and child space necessary for screenreader compat; do not remove
  // without verifying that it still works with JAWS, VoiceOver, etc!
  return (
    <div role="presentation" className={classNames.anchor} tabIndex={-1}>
      <div
        className={classNames.placeholder}
        tabIndex={-1}
        ref={placeholderRef}
        onBlur={onPlaceholderBlur}
        aria-label="&nbsp;"
      >
        {' '}
      </div>
    </div>
  );
};

export default FocusPlaceholder;
