import { noop } from 'lodash';
import React, { FC } from 'react';

import { ContainerWidthProvider } from '../../../../util/responsiveness';
import Flex from '../../../Flex';
import AppLayout from '../../AppLayout';
import AppLayoutRow from '../../AppLayoutRow';
import { useContentContainerContext } from '../../hooks';

import HeaderRow from './HeaderRow';
import LoadableBackButtonRow from './LoadableBackButtonRow';
import MainAreaRow from './MainAreaRow';
import OuterLeftNav from './OuterLeftNav';
import SubHeaderRow from './SubHeaderRow';
import { TemplateProps } from './Template.types';

const Template: FC<TemplateProps> = ({
  header,
  headerDepth,
  subHeader,
  banner,
  hasHeroBackground,
  footer,
  openContentSidebarAriaLabel,
  closeContentSidebarAriaLabel,
  hasBackButton,
  backButtonAriaLabel = '',
  onBackButtonClick = noop,
  children,
  leftNav,
  outerLeftNav,
  backgroundColor,
  removeContentPadding = false,
}) => {
  const { shouldFillAvailableSpace } = useContentContainerContext();
  const bannerRow = banner ? <AppLayoutRow>{banner}</AppLayoutRow> : null;
  const shouldShowBackButton = hasBackButton && !shouldFillAvailableSpace;

  const backButton = shouldShowBackButton ? (
    <LoadableBackButtonRow hasLeftNav={!!leftNav} ariaLabel={backButtonAriaLabel} onClick={onBackButtonClick} />
  ) : null;

  return (
    <AppLayout
      disableNativePageScroll={true}
      closeContentSidebarAriaLabel={closeContentSidebarAriaLabel}
      openContentSidebarAriaLabel={openContentSidebarAriaLabel}
      hasBackButton={shouldShowBackButton}
      hasOuterLeftNav={!!outerLeftNav}
      height="100dvh"
      backgroundColor={backgroundColor}
      removeContentPadding={removeContentPadding}
    >
      <HeaderRow depth={headerDepth}>{header}</HeaderRow>
      <SubHeaderRow>{subHeader}</SubHeaderRow>
      {backButton}
      {bannerRow}
      <AppLayoutRow grow={1}>
        <OuterLeftNav>{outerLeftNav}</OuterLeftNav>
        <Flex grow={1} direction="column" height="100%" maxHeight="100%">
          <ContainerWidthProvider>
            <MainAreaRow hasHeroBackground={hasHeroBackground} footer={footer} leftNav={leftNav}>
              {children}
            </MainAreaRow>
          </ContainerWidthProvider>
        </Flex>
      </AppLayoutRow>
    </AppLayout>
  );
};

export default Template;
