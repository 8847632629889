import { combinePath } from '@ms/yammer-web-support/dist/location';
import React, { FC, memo, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { RouteComponentPreloaderProps, SubrouteComponentPreloaderProps } from './SubrouteComponentPreloader.types';

const RouteComponentPreloader: FC<RouteComponentPreloaderProps> = ({ route }) => {
  const match = useRouteMatch({
    path: route.path,
    exact: route.exact,
  });

  useMemo(() => {
    if (match) {
      route.loader();
    }
  }, [match, route]);

  return null;
};

const SubrouteComponentPreloader: FC<SubrouteComponentPreloaderProps> = ({ parentRoute }) => (
  <>
    {parentRoute.childRoutes.map((route) => (
      <RouteComponentPreloader key={route.path} route={{ ...route, path: combinePath(parentRoute.path, route.path) }} />
    ))}
  </>
);

export default memo(SubrouteComponentPreloader);
