import { error } from '@ms/yammer-console-logging';
import { PhraseMap } from '@ms/yammer-i18n';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentLocale } from '../domains/locales/selectors';
import { load as loadTranslations } from '../domains/translations/actions';
import { getTranslationDataForFeature } from '../domains/translations/selectors';
import { YammerI18nState } from '../state/types';

export interface StringWrapperCtor<T> {
  readonly feature: string;
  new (locale: string, phrases: PhraseMap): T;
}

export type UseTranslations = <T>(Strings: StringWrapperCtor<T>) => T;
export const useTranslations: UseTranslations = (from) => {
  const rawTranslations = useSelector((state: YammerI18nState) => getTranslationDataForFeature(state, from.feature));
  const locale = useSelector(getCurrentLocale);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await dispatch<any>(loadTranslations(from));
      } catch (err) {
        error(err);
      }
    })();
  }, [dispatch, from]);

  return useMemo(() => new from(locale, rawTranslations), [from, locale, rawTranslations]);
};
