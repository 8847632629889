import { makeStyles } from '@griffel/react';

import { Display } from '../display';

export type { Display };

export const useDisplayClassName = (display?: Display) => {
  const displayClassNames = useDisplayClassNames();

  return display ? displayClassNames[display] : undefined;
};

const useDisplayClassNames = makeStyles<Display>({
  inline: { display: 'inline' },
  block: { display: 'block' },
  'inline-block': { display: 'inline-block' },
  flex: { display: 'flex' },
  'inline-flex': { display: 'inline-flex' },
  grid: { display: 'grid' },
  'inline-grid': { display: 'inline-grid' },
  contents: { display: 'contents' },
  none: { display: 'none' },
});
