import { makeStyles, shorthands } from '@griffel/react';

import { tokens } from '../../../../util/theme/fluent9';

export const useClassNames = makeStyles({
  leftNavContainer: {
    ...shorthands.borderRight('1px', 'solid', `${tokens.colorNeutralStroke1}`),
    backgroundColor: tokens.colorNeutralBackground2,
    zIndex: 2,
  },
});
