import {
  getFeatureAccessInsights,
  getFeatureAccessInsightsStatus,
} from '@ms/yammer-data/dist/domains/featureAccessInsights/selectors';

import { useSelector } from '../useSelector';

type UseFeatureAccessInsightsStatus = () => ReturnType<typeof getFeatureAccessInsightsStatus>;
export const useFeatureAccessInsightsStatus: UseFeatureAccessInsightsStatus = () =>
  useSelector(getFeatureAccessInsightsStatus);

type UseFeatureAccessInsights = () => ReturnType<typeof getFeatureAccessInsights>;
export const useFeatureAccessInsights: UseFeatureAccessInsights = () => useSelector(getFeatureAccessInsights);
