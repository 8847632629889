import { useMemo } from 'react';

import { useIsQuestionsOnlyMode, useIsTownhallEventEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useTownhallEditEventPageSettings: UseMainUIFeatureSettings<'townhallEditEventPage'> = (options) => {
  const townhallEditEventPageOverrides = options?.overrides?.townhallEditEventPage;

  const isTownhallEventEnabled = useIsTownhallEventEnabled();
  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        ...townhallEditEventPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: isTownhallEventEnabled,
      ...townhallEditEventPageOverrides,
    };
  }, [isTownhallEventEnabled, isQuestionsOnlyMode, townhallEditEventPageOverrides]);
};
