import { DismissablePromptKey } from '@ms/yammer-data/dist/state/types';
import { useSetCurrentlyVisiblePromptKey } from '@ms/yammer-hooks/dist/dismissablePromptView';
import { useShouldDisplayTeachingBubble } from '@ms/yammer-hooks/dist/dismissablePrompts';
import React, { FC } from 'react';

interface CurrentPromptCanShowProps {
  readonly promptKey: DismissablePromptKey;
  readonly isEnabled?: boolean;
}

const CurrentPromptCanShow: FC<CurrentPromptCanShowProps> = ({ children, promptKey, isEnabled = true }) => {
  const shouldDisplayTeachingBubble = useShouldDisplayTeachingBubble(promptKey, isEnabled);

  useSetCurrentlyVisiblePromptKey(promptKey, shouldDisplayTeachingBubble);

  if (shouldDisplayTeachingBubble && children) {
    return <>{children}</>;
  }

  return null;
};

export default CurrentPromptCanShow;
