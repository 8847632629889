import { makeStyles, mergeClasses, shorthands } from '@griffel/react';

import { createTokens } from '../../theme/fluent9';
import { TextWrapStyle } from '../fonts';

import { ClassNameAndInlineStyles } from './types';

export { TextWrapStyle };

export interface UseTextWrapProps {
  readonly textWrap?: TextWrapStyle;
  readonly maxLines?: number;
}
const { tokens, cssVariables } = createTokens(['maxLines'], 'textWrap');

type UseTextWrap = (props: UseTextWrapProps) => ClassNameAndInlineStyles;
export const useTextWrap: UseTextWrap = (props) => {
  const textWrapClassNames = useTextWrapClassNames();

  const classNames: string[] = [];
  const inlineStyles: Record<string, string | number> = {};

  if (props.textWrap === TextWrapStyle.BreakWords) {
    classNames.push(textWrapClassNames[TextWrapStyle.BreakWords]);
  }

  if (props.textWrap === TextWrapStyle.BreakWordsAndPreserveTextFormatting) {
    classNames.push(
      textWrapClassNames[TextWrapStyle.PreserveTextFormatting],
      textWrapClassNames[TextWrapStyle.BreakWords]
    );
  }

  if (props.textWrap === TextWrapStyle.Ellipsis) {
    classNames.push(textWrapClassNames[TextWrapStyle.Ellipsis]);
  }

  const hasValidMaxLines = props.maxLines && props.maxLines > 0;
  if (props.textWrap === TextWrapStyle.MultiLineEllipsis && hasValidMaxLines) {
    classNames.push(textWrapClassNames[TextWrapStyle.MultiLineEllipsis]);
    inlineStyles[cssVariables.maxLines] = props.maxLines;
  }

  if (props.textWrap === TextWrapStyle.PreserveTextFormatting) {
    classNames.push(textWrapClassNames[TextWrapStyle.PreserveTextFormatting]);
  }

  if (props.textWrap === TextWrapStyle.PreventWrap) {
    classNames.push(textWrapClassNames[TextWrapStyle.PreventWrap]);
  }

  return {
    className: mergeClasses(...classNames),
    inlineStyles,
  };
};

const useTextWrapClassNames = makeStyles({
  [TextWrapStyle.BreakWords]: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },
  [TextWrapStyle.Ellipsis]: {
    ...shorthands.overflow('hidden'),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  [TextWrapStyle.MultiLineEllipsis]: {
    ...shorthands.overflow('hidden'),
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: tokens.maxLines,
  },
  [TextWrapStyle.PreventWrap]: {
    whiteSpace: 'nowrap',
  },
  [TextWrapStyle.PreserveTextFormatting]: {
    whiteSpace: 'pre-wrap',
  },
});
