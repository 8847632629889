import { ComponentClassNames } from '../../../../util/classNames';
import { Depth, getDepthBoxShadow } from '../../../../util/styles/depth';
import { ITheme, memoizeFunction, mergeStyleSets } from '../../../../util/styling';

type HeaderClassNames = ComponentClassNames<'header'>;

export const getClassNames = memoizeFunction((theme: ITheme, depth: Depth): HeaderClassNames => {
  const boxShadow = getDepthBoxShadow(depth);
  const backgroundColor = theme.isInverted
    ? theme.semanticColors.bodyBackground
    : theme.semanticColors.bodyStandoutBackground;

  return mergeStyleSets({
    header: {
      boxShadow,
      backgroundColor,
    },
  });
});
