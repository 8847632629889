import { useStatus } from './selectors';
import { useO365ShellShim } from './useO365ShellShim';

type UseShouldRenderVivaAppBar = () => boolean;
export const useIsSuiteHeaderAvailable: UseShouldRenderVivaAppBar = () => {
  const isLoaded = useStatus() === 'fulfilled';
  const isOneShellShimAvailable = !!useO365ShellShim();

  return isLoaded && isOneShellShimAvailable;
};
