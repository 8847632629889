import React, { FC } from 'react';

import Flex from '../../../Flex';
import { useAppLayoutWidth, useContentContainerContext } from '../../hooks';

import { useClassNames } from './OuterLeftNav.styles';
import { shouldShowSubheaderAtWidth } from './SubHeaderRow';

export const outerLeftNavWidth = 68;

const OuterLeftNav: FC = ({ children }) => {
  const classNames = useClassNames();
  const width = useAppLayoutWidth();
  const { shouldShowSubHeaderAtAllBreakpoints } = useContentContainerContext();
  const isSubheaderDisplayedAtCurrentWidth = shouldShowSubheaderAtWidth(width);
  const shouldRender = children && !isSubheaderDisplayedAtCurrentWidth && !shouldShowSubHeaderAtAllBreakpoints;

  if (!shouldRender) {
    return null;
  }

  return (
    <Flex direction="column" shrink={0} width={outerLeftNavWidth} className={classNames.outerLeftNav}>
      {children}
    </Flex>
  );
};
export default OuterLeftNav;
