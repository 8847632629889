import { SuggestedActionSurface } from '@ms/yammer-graphql';
import {
  useLoadSuggestedActions,
  useSuggestedActionGroupsListForLightbox,
  useSuggestedActionUsersListForLightbox,
} from '@ms/yammer-hooks/dist/suggestedActions';

type UseShouldShowSuggestedActionsLightbox = () => boolean;
export const useShouldShowSuggestedActionsLightbox: UseShouldShowSuggestedActionsLightbox = () => {
  useLoadSuggestedActions(SuggestedActionSurface.WEB_LIGHTBOX);

  const suggestedGroups = useSuggestedActionGroupsListForLightbox();
  const suggestedUsers = useSuggestedActionUsersListForLightbox();
  if (suggestedGroups.length > 0 || suggestedUsers.length >= 4) {
    return true;
  }

  return false;
};
