import { useEffect, useRef } from 'react';
import { useIdle } from 'react-use';

import { useUpdateUserPresenceCallback } from './useUpdateUserPresenceCallback';

const oneMinute = 60 * 1000;
const presenceUpdateInterval = 8 * oneMinute;

type UseSendPresenceState = () => void;
export const useSendPresenceState: UseSendPresenceState = () => {
  const updateUserPresenceCallback = useUpdateUserPresenceCallback();
  const idleState = useIdle();
  const idleStateRef = useRef(idleState);

  useEffect(() => {
    idleStateRef.current = idleState;
  }, [idleState]);

  useEffect(() => {
    const intervalId = window.setInterval(
      () => updateUserPresenceCallback(idleStateRef.current),
      presenceUpdateInterval
    );

    updateUserPresenceCallback(idleStateRef.current);

    return () => {
      window.clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
