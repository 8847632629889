import { load as loadStorylineSettings } from '@ms/yammer-data/dist/domains/storylineSettings/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

type UseLoadStorylineSettings = () => void;
export const useLoadStorylineSettings: UseLoadStorylineSettings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadStorylineSettings());
  }, [dispatch]);
};
