import { useMemo } from 'react';

import {
  useCanAccessCommunicationsDashboard,
  useCanAccessCommunicationsDashboardsDigitalSafetyPage,
} from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useCommsDashboardDigitalSafetyPageSettings: UseMainUIFeatureSettings<
  'communicationsDashboardDigitalSafetyPage'
> = (options) => {
  const canAccessDashboard = useCanAccessCommunicationsDashboard();
  const canAccessDigitalSafety = useCanAccessCommunicationsDashboardsDigitalSafetyPage();

  return useMemo(() => {
    const overrides = options?.overrides?.communicationsDashboardDigitalSafetyPage ?? {};

    return {
      [uiFeatureAccessSymbol]: canAccessDashboard && canAccessDigitalSafety,
      ...overrides,
    };
  }, [options, canAccessDashboard, canAccessDigitalSafety]);
};
