import type { FontSizeTokens, LineHeightTokens, Theme } from '@fluentui/react-components';

import { pxToRemString } from '../../styles/fonts';

type GetRemValueFromPxString = (value: string) => string;
const getRemValueFromPxString: GetRemValueFromPxString = (value) => {
  if (value.includes('px')) {
    return pxToRemString(parseInt(value, 10));
  }

  return value;
};

type ThemeTypography = FontSizeTokens & LineHeightTokens;

const v9ThemePixelValuesToConvertToRem: Extract<keyof ThemeTypography, string>[] = [
  'fontSizeBase100',
  'fontSizeBase200',
  'fontSizeBase300',
  'fontSizeBase400',
  'fontSizeBase500',
  'fontSizeBase600',
  'fontSizeHero700',
  'fontSizeHero800',
  'fontSizeHero900',
  'fontSizeHero1000',
  'lineHeightBase100',
  'lineHeightBase200',
  'lineHeightBase300',
  'lineHeightBase400',
  'lineHeightBase500',
  'lineHeightBase600',
  'lineHeightHero700',
  'lineHeightHero800',
  'lineHeightHero900',
  'lineHeightHero1000',
];

/**
 * Returns a Fluent 9 theme's typography fields with px values converted to rem values.
 * Note: Any fields with pixel values which have already been converted or have a non-px unit value will not be replaced.
 */
export const getTypographyValuesInRemUnits = (theme: Theme): ThemeTypography =>
  v9ThemePixelValuesToConvertToRem.reduce(
    (convertedRemItems: ThemeTypography, item) => ({
      ...convertedRemItems,
      [item]: getRemValueFromPxString(theme[item]),
    }),
    {} as unknown as ThemeTypography
  );
