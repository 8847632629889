import { MimePdf } from '../../api/types';
import { File, FileReference, FileWithGroupName } from '../files/types';
import { ImageFile, ImageFileReference } from '../imageFiles/types';
import { SharePointFileLink, SharePointFileLinkReference } from '../sharePointFileLinks/types';
import { SharePointVideoLink, SharePointVideoLinkReference } from '../sharePointVideoLinks/types';
import { SharePointWebLink, SharePointWebLinkReference } from '../sharePointWebLinks/types';
import { VideoFile, VideoFileReference } from '../videoFiles/types';
import { WebImage, WebImageReference } from '../webImages/types';
import { WebLink, WebLinkReference } from '../webLinks/types';
import { WebVideo, WebVideoReference } from '../webVideos/types';

export type Attachment =
  | FileWithGroupName
  | ImageFile
  | VideoFile
  | WebLink
  | SharePointFileLink
  | SharePointVideoLink
  | SharePointWebLink
  | WebImage
  | WebVideo;

export type AttachmentReference =
  | FileReference
  | ImageFileReference
  | VideoFileReference
  | WebLinkReference
  | SharePointFileLinkReference
  | SharePointVideoLinkReference
  | SharePointWebLinkReference
  | WebImageReference
  | WebVideoReference;

export enum AttachmentType {
  File = 'File',
  FileWithGroupName = 'FileWithGroupName',
  ImageFile = 'ImageFile',
  VideoFile = 'VideoFile',
  WebLink = 'WebLink',
  WebImage = 'WebImage',
  WebVideo = 'WebVideo',
  SharePointFileLink = 'SharePointFileLink',
  SharePointVideoLink = 'SharePointVideoLink',
  SharePointWebLink = 'SharePointWebLink',
}

type AttachmentSchema =
  | 'files'
  | 'imageFiles'
  | 'videoFiles'
  | 'webLinks'
  | 'sharePointFileLinks'
  | 'sharePointVideoLinks'
  | 'sharePointWebLinks'
  | 'webImages'
  | 'webVideos';

export const attachmentTypeToSchemaMap: Record<AttachmentType, AttachmentSchema> = {
  [AttachmentType.File]: 'files',
  [AttachmentType.FileWithGroupName]: 'files',
  [AttachmentType.ImageFile]: 'imageFiles',
  [AttachmentType.VideoFile]: 'videoFiles',
  [AttachmentType.WebLink]: 'webLinks',
  [AttachmentType.SharePointFileLink]: 'sharePointFileLinks',
  [AttachmentType.SharePointVideoLink]: 'sharePointVideoLinks',
  [AttachmentType.SharePointWebLink]: 'sharePointWebLinks',
  [AttachmentType.WebImage]: 'webImages',
  [AttachmentType.WebVideo]: 'webVideos',
};

export interface AttachmentWithTypeCreator<I extends Attachment, T extends AttachmentType> {
  readonly item: I;
  readonly type: T;
}

export type ImageReference = ImageFileReference | WebImageReference;
export type Image = ImageFile | WebImage;

export type ImageFileWithType = AttachmentWithTypeCreator<ImageFile, AttachmentType.ImageFile>;
export type WebImageWithType = AttachmentWithTypeCreator<WebImage, AttachmentType.WebImage>;
export type ImageWithType = ImageFileWithType | WebImageWithType;

export type Video = VideoFile | WebVideo | SharePointVideoLink;

export type VideoFileWithType = AttachmentWithTypeCreator<VideoFile, AttachmentType.VideoFile>;
export type WebVideoWithType = AttachmentWithTypeCreator<WebVideo, AttachmentType.WebVideo>;
export type SharePointVideoLinkWithType = AttachmentWithTypeCreator<
  SharePointVideoLink,
  AttachmentType.SharePointVideoLink
>;

export type VideoWithType = VideoFileWithType | WebVideoWithType | SharePointVideoLinkWithType;

export type Document = FileWithGroupName | SharePointFileLink;

export type FileWithType = AttachmentWithTypeCreator<File, AttachmentType.File>;
export type FileWithGroupNameWithType = AttachmentWithTypeCreator<FileWithGroupName, AttachmentType.FileWithGroupName>;
export type SharePointFileLinkWithType = AttachmentWithTypeCreator<
  SharePointFileLink,
  AttachmentType.SharePointFileLink
>;
export type DocumentWithType = FileWithGroupNameWithType | SharePointFileLinkWithType;

export type WebLinkWithType = AttachmentWithTypeCreator<WebLink, AttachmentType.WebLink>;

export type AttachmentWithType = ImageWithType | VideoWithType | DocumentWithType | FileWithType;

export type PreviewableAttachmentReference = ImageReference | FileReference;
export type PreviewableAttachmentWithType =
  | ImageWithType
  | FileWithType
  | FileWithGroupNameWithType
  | SharePointFileLinkWithType;

const typeGuardCreator =
  <AssertedType extends AttachmentWithType>(type: AttachmentType) =>
  (attachment: AttachmentWithType): attachment is AssertedType =>
    attachment.type === type;

export const isImageFile = typeGuardCreator<ImageFileWithType>(AttachmentType.ImageFile);
export const isWebImage = typeGuardCreator<WebImageWithType>(AttachmentType.WebImage);

export const isVideoFile = typeGuardCreator<VideoFileWithType>(AttachmentType.VideoFile);
export const isWebVideo = typeGuardCreator<WebVideoWithType>(AttachmentType.WebVideo);
export const isFileWithGroupName = typeGuardCreator<FileWithGroupNameWithType>(AttachmentType.FileWithGroupName);
export const isSharePointFileLink = typeGuardCreator<SharePointFileLinkWithType>(AttachmentType.SharePointFileLink);
export const isSharePointVideoAttachmentLink = typeGuardCreator<SharePointVideoLinkWithType>(
  AttachmentType.SharePointVideoLink
);

type IsGifImage = (image: ImageFile) => boolean;
export const isGifImage: IsGifImage = (image) => image.displayName.split('.').pop() === 'gif';

type IsPdfFile = (file: File) => boolean;
export const isPdfFile: IsPdfFile = (file) => file.mimeType === MimePdf;

type IsEditableFileLink = (file: Document) => boolean;
export const isEditableFileLink: IsEditableFileLink = (file) => file.fullPageEditorUrl != null;
