import { Experiment, ExperimentTreatmentValue, experimentConfigs } from '@ms/yammer-data/dist/state/types';
import { reportEcsExperimentTreatment } from '@ms/yammer-data/dist/telemetry';
import { useCallback } from 'react';

import { useEcsConfig } from '../ecs';

import { useShouldReportExperimentTreatedEvent } from './selectors';

type UseReportExperimentTreatmentCallback = (experiment: Experiment, treatment: ExperimentTreatmentValue) => () => void;
export const useReportExperimentTreatmentCallback: UseReportExperimentTreatmentCallback = (experiment, treatment) => {
  const etag = useEcsConfig().etag;
  const projectTeam = experimentConfigs[experiment].projectTeam;
  const shouldReportUserTreatedEvent = useShouldReportExperimentTreatedEvent(experiment);

  return useCallback(() => {
    if (shouldReportUserTreatedEvent) {
      reportEcsExperimentTreatment(experiment, treatment, projectTeam, etag);
    }
  }, [experiment, treatment, projectTeam, etag, shouldReportUserTreatedEvent]);
};
