import { load as loadSubscriptionServerKey } from '@ms/yammer-data/dist/domains/pushSubscriptionServerKey/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useIsEnabled as useIsPushNotificationsEnabled } from '../pushNotifications';

export const useLoadPushSubscriptionServerKey = () => {
  const dispatch = useDispatch();
  const isEnabled = useIsPushNotificationsEnabled();

  useEffect(() => {
    (async () => {
      if (isEnabled) {
        await dispatch(loadSubscriptionServerKey());
      }
    })();
  }, [dispatch, isEnabled]);
};
