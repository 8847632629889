import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

import { IsFocusVisibleClassName } from '../styling';

import { UseIsFocused } from './useIsFocused.types';

const validateFocus = (
  filterFocusToContainerRef: RefObject<HTMLElement | null> | undefined,
  ignoreMouseClickFocus: boolean
) => {
  const isKeyboardFocusEvent = document.body.classList.contains(IsFocusVisibleClassName);
  if (ignoreMouseClickFocus && !isKeyboardFocusEvent) {
    return false;
  }

  if (!filterFocusToContainerRef || !filterFocusToContainerRef.current) {
    return true;
  }

  return filterFocusToContainerRef.current.contains(document.activeElement);
};

const useIsFocused: UseIsFocused = (options) => {
  const filterFocusToContainerRef = options?.filterFocusToContainerRef;
  const ignoreMouseClickFocus = options?.ignoreMouseClickFocus ?? false;

  const [isFocused, setIsFocused] = useState(false);
  const blurTimeout = useRef<number>();

  const onFocus = useCallback(() => {
    window.clearTimeout(blurTimeout.current);

    const hasFocus = validateFocus(filterFocusToContainerRef, ignoreMouseClickFocus);

    setIsFocused(hasFocus);
  }, [filterFocusToContainerRef, ignoreMouseClickFocus]);

  const onBlur = useCallback(() => {
    blurTimeout.current = window.setTimeout(() => {
      setIsFocused(false);
    }, 0);
  }, []);

  useEffect(
    () => () => {
      window.clearTimeout(blurTimeout.current);
    },
    []
  );

  return {
    onFocus,
    onBlur,
    isFocused,
  };
};

export default useIsFocused;
