/* These styles are set inline to reduce the liklihood of being overridden. Similar to !important */
export const screenreaderOnlyInlineStyles: React.CSSProperties = {
  display: 'block',
  padding: 0,
  border: 0,
  clip: 'rect(0 0 0 0)',
  position: 'absolute',
  margin: '-1px',
  height: '1px',
  width: '1px',
  overflow: 'hidden',
  wordWrap: 'normal',
};
