import { createDarkTheme, createLightTheme } from '@fluentui/react-components';

import { createCoreUIFluentTheme } from './createFluent9Theme';

const brandRamp = {
  10: '#141E3B',
  20: '#192852',
  30: '#1E3269',
  40: '#213C85',
  50: '#2A4796',
  60: '#2E52B2',
  70: '#335CCC',
  80: '#3E69DE',
  90: '#4977EB',
  100: '#5885F5',
  110: '#6994FA',
  120: '#7EA4FC',
  130: '#95B6FC',
  140: '#B2CBFF',
  150: '#CCDCFF',
  160: '#E5EEFF',
};

export const webLightTheme = createCoreUIFluentTheme(createLightTheme(brandRamp));
export const webDarkTheme = createCoreUIFluentTheme(createDarkTheme(brandRamp));
