import { ComponentClassNames } from '../../util/classNames';
import { getSemanticPaletteColor } from '../../util/colors';
import { getViewportUnitsFromDynamicValue } from '../../util/styles/getViewportUnitsFromDynamicValue';
import { ITheme, memoizeFunction, mergeStyleSets } from '../../util/styling';

import { AppLayoutProps } from './AppLayout.types';

const dynamicHeightClassName = 'appLayout-dynamicHeight';

type GetClassNames = (
  height: AppLayoutProps['height'],
  disableNativePageScroll: boolean,
  theme: ITheme,
  backgroundColor?: AppLayoutProps['backgroundColor']
) => ComponentClassNames<'appLayout'>;
export const getClassNames: GetClassNames = memoizeFunction(
  (height, disableNativePageScroll, theme, backgroundColor) => {
    const viewportUnits = typeof height === 'string' ? getViewportUnitsFromDynamicValue(height) : undefined;

    const heightValue = viewportUnits !== undefined ? `${viewportUnits}vh` : height;
    const heightValueOverride = viewportUnits !== undefined ? height : undefined;

    return mergeStyleSets({
      appLayout: [
        {
          height: heightValue,
          [`&.${dynamicHeightClassName}`]: { height: heightValueOverride },
          backgroundColor: backgroundColor && getSemanticPaletteColor(theme, backgroundColor),
        },
        {
          ':global(html)': disableNativePageScroll ? { height: '100%', overscrollBehavior: 'none' } : undefined,
          ':global(body)': disableNativePageScroll ? { height: '100%', overflow: 'hidden' } : undefined,
        },
        dynamicHeightClassName,
      ],
    });
  }
);
