import { useMemo } from 'react';

import { useIsInsightsLeadersOrgPageEnabled, useIsQuestionsOnlyMode } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useInsightsLeadersOrgPageSettings: UseMainUIFeatureSettings<'insightsLeadersOrgPage'> = (options) => {
  const insightsLeadersOrgPageOverrides = options?.overrides?.insightsLeadersOrgPage;

  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();
  const isInsightsLeadersOrgEnabled = useIsInsightsLeadersOrgPageEnabled();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        ...insightsLeadersOrgPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: isInsightsLeadersOrgEnabled,
      ...insightsLeadersOrgPageOverrides,
    };
  }, [insightsLeadersOrgPageOverrides, isInsightsLeadersOrgEnabled, isQuestionsOnlyMode]);
};
