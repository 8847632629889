import React, { FC } from 'react';

export interface MaxZoomProps {
  readonly maxZoom: number;
  readonly allowClickThroughOnFrame?: boolean;
}

export const MaxZoom: FC<MaxZoomProps> = ({ maxZoom, children, allowClickThroughOnFrame }) => {
  const width = `${100 / maxZoom}%`;
  const height = width;
  const transform = `scale(${maxZoom})`;
  const pointerEvents = allowClickThroughOnFrame ? 'none' : undefined;

  return (
    <div style={{ width, height, transform, pointerEvents }} data-testid="maxzoom">
      {children}
    </div>
  );
};
