import { useMemo } from 'react';

import { useIsQuestionsOnlyMode } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useHomeFeedPageSettings: UseMainUIFeatureSettings<'homeFeedPage'> = (options) => {
  const homeFeedPageOverrides = options?.overrides?.homeFeedPage;
  const isQuestionsOnlyMode = useIsQuestionsOnlyMode();

  return useMemo(() => {
    if (isQuestionsOnlyMode) {
      return {
        [uiFeatureAccessSymbol]: false,
        ...homeFeedPageOverrides,
      };
    }

    return {
      [uiFeatureAccessSymbol]: true,
      ...homeFeedPageOverrides,
    };
  }, [homeFeedPageOverrides, isQuestionsOnlyMode]);
};
