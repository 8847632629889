import { useMemo } from 'react';

import { useIsAdminMuteUserAdminEnabled, useIsViewOnlyModeAdminEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useManageUsersAdminPageSettings: UseMainUIFeatureSettings<'manageUsersAdminPage'> = (options) => {
  const manageUsersAdminPageSettingsOverrides = options?.overrides?.manageUsersAdminPage;

  const viewerCanManageRestrictedUsers = useIsViewOnlyModeAdminEnabled();
  const viewerCanManageAdminMute = useIsAdminMuteUserAdminEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: viewerCanManageRestrictedUsers || viewerCanManageAdminMute,
      ...manageUsersAdminPageSettingsOverrides,
    }),
    [manageUsersAdminPageSettingsOverrides, viewerCanManageRestrictedUsers, viewerCanManageAdminMute]
  );
};
