import { getColorFromString, updateA } from '@fluentui/react/lib/Color';

/**
 * Generates a new CSS color value with alpha transparency.
 * @param color Any valid CSS color value
 * @param alphaPercentage Number between 0 and 100
 * @param invert Boolean, when true alphaPercentage is inverted.
 */
export const addAlpha: (color: string, alphaPercentage: number, invert?: boolean) => string | undefined = (
  color,
  alphaPercentage,
  invert
) => {
  const colorObject = getColorFromString(color);
  const a = invert ? 100 - alphaPercentage : alphaPercentage;

  return colorObject ? updateA(colorObject, a).str : undefined;
};
