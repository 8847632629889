import { useMemo } from 'react';

import { useIsInsightsQnAMyInsightsEnabled } from '../../featureChecks';
import { UseMainUIFeatureSettings, uiFeatureAccessSymbol } from '../../types';

export const useInsightsQnAMyInsightsPageSettings: UseMainUIFeatureSettings<'insightsQnAMyInsightsPage'> = (
  options
) => {
  const insightsQnAMyInsightsPageOverrides = options?.overrides?.insightsQnAMyInsightsPage;

  const isInsightsQnAMyInsightsEnabled = useIsInsightsQnAMyInsightsEnabled();

  return useMemo(
    () => ({
      [uiFeatureAccessSymbol]: isInsightsQnAMyInsightsEnabled,
      ...insightsQnAMyInsightsPageOverrides,
    }),
    [isInsightsQnAMyInsightsEnabled, insightsQnAMyInsightsPageOverrides]
  );
};
