import { ParsedUrlQuery, stringify } from 'querystring';

import { Message, Thread } from '@ms/yammer-data/dist/state/types';
import { LocationDescriptor } from 'history';

import { getUrlRootPath } from '../location/urlRootPath';

import { StateWithPrevPathname, ThreadPageQueryOptions } from './threads';

export const questionsPageUrl = '/questions';
export const searchPathPublisherQuestions = '/questions/publisher/search';

interface ShowPublisherQueryParameter {
  readonly showPublisher: boolean;
}

interface OpenReplyPublisherQueryParameter {
  readonly openReplyPublisher: boolean;
}

export type QuestionPageQueryOptions =
  | ThreadPageQueryOptions
  | ShowPublisherQueryParameter
  | OpenReplyPublisherQueryParameter;

const getLocationSearchQueryString = (queryOptions?: QuestionPageQueryOptions) =>
  queryOptions ? `?${stringify(queryOptions as unknown as ParsedUrlQuery)}` : undefined;

type QuestionPage = (questionId: Thread['id'], queryOptions?: QuestionPageQueryOptions) => string;
export const questionUrl: QuestionPage = (questionId, queryOptions) => {
  const queryString = getLocationSearchQueryString(queryOptions) ?? '';

  return `/questions/${questionId}${queryString}`;
};

type QuestionMessagePage = (messagePath: Message['id'][], queryOptions?: QuestionPageQueryOptions) => string;
export const questionMessagePage: QuestionMessagePage = (messagePath, queryOptions) => {
  const queryString = getLocationSearchQueryString(queryOptions) ?? '';

  return `/questions/${messagePath.join('/')}${queryString}`;
};

type QuestionMessagePageWithPreviousPathname = (
  ids: Message['id'][],
  prevPathname: string,
  options?: QuestionPageQueryOptions
) => LocationDescriptor<StateWithPrevPathname>;
export const questionMessagePageWithPreviousPathname: QuestionMessagePageWithPreviousPathname = (
  ids,
  prevPathname,
  options
) => ({
  pathname: questionMessagePage(ids),
  search: getLocationSearchQueryString(options),
  state: { prevPathname },
});

type QuestionThreadPageWithPreviousPathname = (
  id: Thread['id'],
  prevPathname: string,
  options?: QuestionPageQueryOptions
) => LocationDescriptor<StateWithPrevPathname>;
export const questionThreadPageWithPreviousPathname: QuestionThreadPageWithPreviousPathname = (
  id,
  prevPathname,
  options
) => ({
  pathname: questionUrl(id),
  search: getLocationSearchQueryString(options),
  state: { prevPathname },
});

export const isNetworkQuestionsFeed = () => {
  const rootPath = getUrlRootPath(window.location.pathname);

  return rootPath === 'questions';
};
