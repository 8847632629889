type GetViewportUnitsFromDynamicValue = (value: string) => number | undefined;

/**
 * Get the amount of viewport percentage units from a CSS dynamic length value.
 * @param value A CSS dynamic length value. (e.g. 100dvh)
 */
export const getViewportUnitsFromDynamicValue: GetViewportUnitsFromDynamicValue = (value) => {
  const [, viewportUnits] = /^(.+?)(?:dvh|dvw)$/.exec(value.trim()) ?? ([] as string | undefined[]);

  return viewportUnits ? +viewportUnits : undefined;
};
