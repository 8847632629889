import {
  getFeatureRolloutsOverview,
  hasFeatureRolloutOverride,
  isFeatureRolloutEnabled,
} from '@ms/yammer-data/dist/domains/featureRollouts/selectors';
import { FeatureRollout } from '@ms/yammer-data/dist/state/types';

import { useSelector } from '../useSelector';

type UseIsFeatureRolloutEnabled = (featureRollout: FeatureRollout) => ReturnType<typeof isFeatureRolloutEnabled>;
export const useIsFeatureRolloutEnabled: UseIsFeatureRolloutEnabled = (featureRollout) =>
  useSelector((state) => isFeatureRolloutEnabled(state, featureRollout));

type UseHasFeatureRolloutOverride = (featureRollout: FeatureRollout) => ReturnType<typeof hasFeatureRolloutOverride>;
export const useHasFeatureRolloutOverride: UseHasFeatureRolloutOverride = (featureRollout) =>
  useSelector((state) => hasFeatureRolloutOverride(state, featureRollout));

type UseFeatureRolloutsOverview = () => ReturnType<typeof getFeatureRolloutsOverview>;
export const useFeatureRolloutsOverview: UseFeatureRolloutsOverview = () =>
  useSelector((state) => getFeatureRolloutsOverview(state));
