import { pxToRawRemNumber } from './fonts';

export type CSSLengthUnit = string;

const gutterUnit = {
  px: 4,
  rem: pxToRawRemNumber(4),
};

const getRemGutterValue = (gutterUnits: number) => {
  const units = (gutterUnits * gutterUnit.rem).toFixed(1);

  return `${units}rem`;
};

const getPxGutterValue = (gutterUnits: number) => {
  const units = (gutterUnits * gutterUnit.px).toFixed(0);

  return `${units}px`;
};

export const getGutterValueInPixels: (gutterUnits: number) => number = (gutterUnits) =>
  Math.floor(gutterUnits * gutterUnit.px);

/**
 * Get a CSS unit length value from the number of gutter units desired.
 * @param gutterUnits The number of 4px gutter units desired.
 * @param resizeWithText Uses rem instead of px, which resizes with text if the user changes their browser's default font-size.
 */
export const getGutterValue: (gutterUnits: number, resizeWithText?: boolean) => CSSLengthUnit = (
  gutterUnits: number,
  resizeWithText?: boolean
) => {
  if (resizeWithText) {
    return getRemGutterValue(gutterUnits);
  }

  return getPxGutterValue(gutterUnits);
};
