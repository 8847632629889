import { EntityStatus, StorylineSettings, YammerState, getStatus } from '../../state/types';

type GetStorylineSettings = (state: YammerState) => StorylineSettings | undefined;
export const getStorylineSettings: GetStorylineSettings = (state: YammerState) => state.storylineSettings.fulfilled;

type GetStorylineSettingsStatus = (state: YammerState) => EntityStatus;
export const getStorylineSettingsStatus: GetStorylineSettingsStatus = (state) => getStatus(state.storylineSettings);

type GetIsStorylineMultiTenantOrganizationEnabled = (state: YammerState) => boolean;
export const getIsStorylineMultiTenantOrganizationEnabled: GetIsStorylineMultiTenantOrganizationEnabled = (state) =>
  getStorylineSettings(state)?.isStorylineMultiTenantOrganizationEnabled ?? false;

type isCurrentUserStoriesPreviewEnabled = (state: YammerState) => boolean;
export const isCurrentUserStoriesPreviewEnabled: isCurrentUserStoriesPreviewEnabled = (state) => {
  const storylineSettings = getStorylineSettings(state);

  const viewerCanAccessStories = !!storylineSettings?.viewerCanAccessStories;
  const viewerCanAccessStoryline = !!storylineSettings?.viewerCanAccessStoryline;

  return viewerCanAccessStories && viewerCanAccessStoryline;
};
