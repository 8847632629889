import { useExperimentTreatmentWithoutReporting } from '@ms/yammer-hooks/dist/experiments';
import { useViewerCanCreateArticle } from '@ms/yammer-hooks/dist/publishers';

import { UseUIFeatureCheck } from '../types';

export { useShouldShowUserPublisher as useIsUserPublisherEnabled } from '@ms/yammer-hooks/dist/publishers';

export const useIsArticlePublisherEnabled: UseUIFeatureCheck = () => {
  const isArticleTreatmentEnabled =
    useExperimentTreatmentWithoutReporting('LongFormContentArticlePublisher') === 'enabled';
  const viewerCanCreateArticle = useViewerCanCreateArticle();

  return isArticleTreatmentEnabled && viewerCanCreateArticle;
};
