import { useLayoutEffect } from 'react';

type UseAppendBodyClassName = (className: string, isPresent: boolean) => void;
const useAppendBodyClassName: UseAppendBodyClassName = (className, isPresent) => {
  useLayoutEffect(() => {
    const bodyElement = document.body;
    if (isPresent) {
      bodyElement.classList.add(className);
    } else {
      bodyElement.classList.remove(className);
    }

    return () => bodyElement.classList.remove(className);
  }, [className, isPresent]);
};

export default useAppendBodyClassName;
