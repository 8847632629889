import { DismissablePromptType } from '@ms/yammer-data/dist/state/types';

import { useLoadDismissablePromptsByType, useShouldStartDismissablePrompt } from '../dismissablePromptsByType';

const outagePromptTypes = [DismissablePromptType.LIVE_SITE_ISSUE];

type UseShouldStartOutageBanner = () => boolean;
export const useShouldStartOutageBanner: UseShouldStartOutageBanner = () => {
  useLoadDismissablePromptsByType(outagePromptTypes);

  return useShouldStartDismissablePrompt(DismissablePromptType.LIVE_SITE_ISSUE);
};
