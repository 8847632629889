import { isEqualWith } from 'lodash';
import { useRef } from 'react';

import { usePrevious } from './usePrevious';

export const useShallowMemo = <T extends object>(value: T): T => {
  const previous = usePrevious(value);
  const ref = useRef<T>(value);

  if (!previous) {
    return ref.current;
  }

  const isShallowEqual = isEqualWith(previous, value, (objA: T, objB: T) => {
    if (Object.is(objA, objB)) {
      return true;
    }

    const keysA = Object.keys(objA) as (keyof T)[];
    const keysB = Object.keys(objB) as (keyof T)[];

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (const key of keysA) {
      if (!Object.prototype.hasOwnProperty.call(objB, key) || !Object.is(objA[key], objB[key])) {
        return false;
      }
    }

    return true;
  });

  if (!isShallowEqual) {
    ref.current = value;
  }

  return ref.current;
};
