import { useContentContainerContext } from './useContentContainerContext';
import { useShouldRenderContentSidebarAtCurrentWidth } from './useShouldRenderContentSidebarAtCurrentWidth';

type UseIsSidebarCurrentlyHiddenByNarrowWidth = () => boolean;
export const useIsContentSidebarCurrentlyHiddenByNarrowWidth: UseIsSidebarCurrentlyHiddenByNarrowWidth = () => {
  const hasSidebar = useContentContainerContext().totalMountedSidebars > 0;
  const isSidebarSuppressedForCurrentWidth = !useShouldRenderContentSidebarAtCurrentWidth();

  return hasSidebar && isSidebarSuppressedForCurrentWidth;
};
