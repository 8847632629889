import { createContext, useContext } from 'react';

import { passthroughRenderer } from './renderer';
import { RenderGroupReferenceType, RenderUserReferenceType } from './renderer/types';

export interface EntityWrapperContextOptions {
  readonly renderGroupReference: RenderGroupReferenceType;
  readonly renderUserReference: RenderUserReferenceType;
}

export const defaultOptions: EntityWrapperContextOptions = {
  renderGroupReference: passthroughRenderer,
  renderUserReference: passthroughRenderer,
};

export const EntityWrapperContext = createContext(defaultOptions);

export type UseEntityWrapperContext = () => EntityWrapperContextOptions;
export const useEntityWrapperContext: UseEntityWrapperContext = () => useContext(EntityWrapperContext);
